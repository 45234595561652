import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { CertificatesService } from '../../../company/services/certificates.service';
import { CompanyCertificate } from '../../../models/company-certificate.model';
import { Company } from '../../../models/company.model';
import { SortableDirective } from '../../directives/sortable.directive';

@Component({
  selector: 'certificates-table',
  templateUrl: './certificates-table.component.html',
  styleUrls: ['./certificates-table.component.scss']
})
export class CertificatesTableComponent implements OnInit, OnDestroy {

  @ViewChild('tableSortable') private readonly table: SortableDirective;

  @Input() public adminMode: boolean;
  @Input() public reloadCertificates: BehaviorSubject<any>;
  @Input() public company: Company;

  public certificates: CompanyCertificate[];
  public deletedCertificate = new EventEmitter();

  public media_fullscreen: boolean;
  public selected_media: number;
  private sub: Subscription;

  constructor(
    public certificatesService: CertificatesService
  ) { }

  ngOnInit(): void {
    if (!this.reloadCertificates) {
      this.reloadCertificates = new BehaviorSubject(null);
    }

    this.sub = this.reloadCertificates.pipe(
      switchMap(values => {
        this.certificates = null;
        return this.certificatesService.get(this.company.id);
      })
    ).subscribe(certificates => {
      const atLeastOneUnexpired = certificates.find(certificate => !certificate.expired);

      this.certificates = atLeastOneUnexpired ? certificates : [];
    });
  }

  // Delete
  public deleteCertificate(certificate: CompanyCertificate): void {
    this.certificatesService.delete(this.company.id, certificate.id).subscribe(response => {
      for (let i in this.certificates) {
        if (this.certificates[i].id === certificate.id) {
          this.certificates.splice(parseInt(i), 1);
          break;
        }
      }
      this.table.collection = this.certificates;
      this.deletedCertificate.emit();
    });
  }

  // Fullscreen mode
  public toggleFullscreen(e?: Event, i?: number): void {
    if (e) e.stopImmediatePropagation();

    if (this.adminMode) {
      this.selected_media = i;
      this.media_fullscreen = !this.media_fullscreen;
    } else {
      let cert = this.certificates[i];
      window.open(cert.image, "agree_cert_" + cert.id);
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.media_fullscreen) {
      event.stopImmediatePropagation();
      switch (event.key) {
        case 'Escape':
          this.toggleFullscreen();
          break;
      };
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
