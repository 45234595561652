<ng-template #modal>
  <!-- MODAL ADD TO MY NETWORK -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'COUNTERPARTIES.NETWORK.MODAL.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="row" *ngIf="company.hasModule('market')">
      <div class="col-xs-12">
        <p>{{ 'COUNTERPARTIES.NETWORK.MODAL.DESCRIPTION'|translate }}</p>
      </div>
    </div>
    <!-- TABS SINGLE/BULK INVITE -->
    <tabset>
      <tab [disabled]="processing" (selectTab)="bulkmode = false"
      heading="{{ 'COUNTERPARTIES.NETWORK.MODAL.SINGLE_LOAD'|translate }}">
        <!-- SINGLE INVITE START -->
        <single-invite *ngIf="!bulkmode"
        [company]="company"
        [activities]="activities"
        [user]="user"
        [submitEvent]="submitEvent.asObservable()"
        (submitErrorMessageEmitter)="setSubmitErrorMessage($event)"
        (formOnChangeEmitter)="onFormChange($event)"
        (closeModalEmitter)="closeModal()"></single-invite>
        <!-- SINGLE INVITE END -->
      </tab>
      <tab *ngIf="company.market.country_id" [disabled]="processing" (selectTab)="bulkmode = true"
      heading="{{ 'COUNTERPARTIES.NETWORK.MODAL.BULK_LOAD'|translate }}">
        <!-- BULK INVITE START -->
        <bulk-invite *ngIf="bulkmode"
        [company]="company"
        [activities]="activities"
        [user]="user"
        [submitEvent]="submitEvent.asObservable()"
        (submitErrorMessageEmitter)="setSubmitErrorMessage($event)"
        (formOnChangeEmitter)="onFormChange($event)"
        (closeModalEmitter)="closeModal()"></bulk-invite>
        <!-- BULK INVITE END -->
      </tab>
    </tabset>
    <alert type="danger" dismissible="true" class="text-left" *ngIf="submitErrorMessage">
      <span [innerHTML]="submitErrorMessage|translate"></span>
    </alert>
  </div>
  <div class="modal-footer" *ngIf="activities">
    <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing" data-dismiss="modal">
      {{ 'GLOBAL.CANCEL'|translate }}
    </button>
    <button (click)="emitSubmitEvent()" type="button" class="btn btn-agree" [disabled]="processing || childForm?.pristine || childForm?.invalid">
      {{ 'GLOBAL.ADD'|translate }}
    </button>
  </div>
</ng-template>