import { ChangeDetectorRef, Directive, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { RouteSpyService } from '../services/route-spy.service';

@Directive({
  selector: '[withRoute]',
  exportAs: 'withRoute'
})
export class WithRouteDirective implements OnChanges, OnDestroy {

  @Input() private withRoute: string;

  public active = false;

  private subscriptions: Subscription[] = [];
  // private params: any = {};

  constructor(
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private routeSpyService: RouteSpyService
  ) {
    this.subscriptions.push(this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.getValues();
      }
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.withRoute) {
      this.getValues();
      this.cdRef.detectChanges();
    }
  }

  private getValues(): void {
    // this.params = {};
    this.active = false;

    if (this.withRoute) {
      let snapshot = this.routeSpyService.getSnapshot(this.withRoute);

      if (snapshot) {
        // this.params = snapshot.params;
        this.active = true;
      }
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
