<span *ngIf="data && !loading && data.total_pages > 1" class="paginator">
  <div class="btn-group hidden-xs" dropdown>
    <button id="button-pgntr" dropdownToggle type="button" class="btn btn-link dropdown-toggle" aria-controls="dropdown-pgntr"
    title="{{ 'PAGINATOR.SUMMARY'|translate:{ page: data.current_page, total: data.total_pages } }}">
      {{ data.range.from | number:'1.0-0':currentLang }} - {{ data.range.to | number:'1.0-0':currentLang }}
      <span class="hidden-sm">{{ 'PAGINATOR.OF' | translate }} {{ data.total | number:'1.0-0':currentLang }}</span>
      <!-- <i class="material-symbols-rounded">more_vert</i> -->
    </button>
    <ul id="dropdown-pgntr" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-pgntr">
      <li [class.disabled]="data.current_page === 1" role="menuitem">
        <a class="dropdown-item" (click)="goTo(1)">{{ 'PAGINATOR.FIRST_PAGE' | translate }}</a>
      </li>
      <li [class.disabled]="data.current_page === data.total_pages" role="menuitem">
        <a class="dropdown-item" (click)="goTo(data.total_pages)">{{ 'PAGINATOR.LAST_PAGE' | translate }}</a>
      </li>
      <ng-container *ngIf="menuPages">
        <li class="divider dropdown-divider"></li>
        <ng-container *ngFor="let page of menuPages;">
          <li [class.disabled]="data.current_page === page" role="menuitem">
            <a class="dropdown-item" (click)="goTo(page)">
              [
              {{ ((page - 1) * data.per_page) + 1 | number:'1.0-0':currentLang }}
              ...
              {{ [page * data.per_page, data.total] | min | number:'1.0-0':currentLang }}
              ]
              <span *ngIf="data.current_page === page" class="material-symbols-rounded">arrow_left</span>
            </a>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </div>
  <div class="btn-group arrows">
    <label class="btn btn-xs btn-default"
    title="{{ 'PAGINATOR.PREV_PAGE'|translate }}"
    [class.disabled]="data.current_page === 1"
    (click)="goTo(data.current_page - 1)"><i class="material-symbols-rounded">navigate_before</i></label>
    <label class="btn btn-xs btn-default"
    title="{{ 'PAGINATOR.NEXT_PAGE'|translate }}"
    [class.disabled]="data.current_page === data.total_pages"
    (click)="goTo(data.current_page + 1)"><i class="material-symbols-rounded">navigate_next</i></label>
  </div>
</span>