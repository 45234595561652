<a
[class.disabled]="disabled"
*ngIf="!isEditing; else form"
(click)="startEditing()">{{ innerValue?.name || ('GLOBAL.NOT_AVAILABLE_ABBR'|translate) }}</a>
<ng-template #form>
  <select class="form-control"
  [class.has-placeholder]="!selectedId"
  *ngIf="isEditing"
  [disabled]="disabled"
  (ngModelChange)="endEditing()"
  (blur)="endEditing()"
  [(ngModel)]="selectedId" #element>
    <option
    *ngIf="!selectedId"
    [ngValue]="undefined" [selected]="true" [disabled]="true">{{ 'GLOBAL.SELECT'|translate }}</option>
    <option *ngFor="let option of options"
    [ngValue]="option.id">{{ option.name }}</option>
  </select>
</ng-template>