import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class ComponentCommService {

  public events = new EventEmitter();

  constructor() { }

  public emit(value: any = undefined): void {
    this.events.emit(value);
  }
}
