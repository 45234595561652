import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderByPipe } from 'ngx-pipes';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[sortable]',
  exportAs: 'tableSortable'
})
export class SortableDirective<T = any> implements OnInit, OnDestroy {

  @Input('sortable') collection: T[] | T;
  /** If the ordering is by URL it will be ignored. */
  @Input('defaultSortBy') public sortBy: string;
  /** Indicates if the ordering is given by query or is only on screen. */
  @Input() public variableInUrl: boolean;

  public subs: Subscription;

  private params = {};

  /** @ignore */
  constructor(
    private orderByPipe: OrderByPipe,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  /** @ignore */
  ngOnInit(): void {
    if (this.variableInUrl) {
      this.sortBy = undefined;
      this.subs = this.route.parent.queryParams.subscribe(response => {
        // Create a shallow copy to avoid mutation
        this.params = { ...response };
        if (response['order_by']) this.sortBy = response['order_by'];
      });
    } else this.sortCollection();
  }

  private sortCollection(): void {
    if (!this.variableInUrl && this.collection) {
      // Only order if the ordering is not given by URL
      this.collection = this.orderByPipe.transform(this.collection, this.sortBy);
    }
  }

  public sort(key: string): void {
    if (key) {
      if (key === this.sortBy) {
        // If it is the same key, toggles
        this.sortBy = '-' + key;
      } else {
        this.sortBy = key;
      }

      if (this.variableInUrl) {
        this.params['order_by'] = this.sortBy;

        this.router.navigate([], {
          queryParams: this.params,
          relativeTo: this.route
        });
      } else this.sortCollection();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    if (this.subs) this.subs.unsubscribe();
  }
}
