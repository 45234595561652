<ng-template #previewModal>
  <!-- modal -->
  <div class="modal-header" clickStop>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close" [disabled]="processing">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title" *ngIf="filename">
      {{ title || filename }}
      <small *ngIf="filesize">&mdash; {{ filesize|filesize }}</small>
    </h4>
  </div>
  <div class="modal-body" clickStop>
    <div class="loading" *ngIf="processing">
      <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div>

    <!-- No data -->
    <div class="no-data" *ngIf="!processing && !filePreview.url">
      <i class="material-symbols-rounded">error_outline</i>
      <span [innerHtml]="'GLOBAL.DATA_ERROR_AVAILABLE'|translate"></span>
    </div>

    <ng-container *ngIf="filePreview?.url">
      <ng-container [ngSwitch]="filePreview.viewer">        
        <img *ngSwitchCase="'image'"
        class="img-responsive"
        [src]="filePreview.url">

        <video *ngSwitchCase="'video'"
        controls
        preload="metadata"
        [src]="filePreview.url"></video>

        <ngx-doc-viewer *ngSwitchDefault
        [url]="filePreview.url" [viewer]="filePreview.viewer"></ngx-doc-viewer>
      </ng-container>
    </ng-container>
  </div>
  <div class="modal-footer" *ngIf="observable" clickStop>
    <button type="button"
    class="btn btn-default" (click)="download()" [disabled]="processing">
      <i class="material-symbols-rounded">save_alt</i> {{ 'GLOBAL.DOWNLOAD'|translate }}
    </button>
  </div>
</ng-template>