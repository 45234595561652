<ng-template #envelopeModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'SIGNATURES.CREATE_ENVELOPE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <form id="envelope-form" #envelopeForm="ngForm" (ngSubmit)="envelopeForm.valid && submit()">
      <div class="row">
        <!-- Subject & expiration -->
        <div class="col-xs-12">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'SIGNATURES.SUBJECT'|translate }}</label>
            <input type="text" name="subject"
            [modalFocus]="true" [disabled]="processing" [(ngModel)]="envelope.subject"
            maxlength="64"
            class="form-control input-lg" autocomplete="off" required>
            <error-list></error-list>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Files -->
        <div class="col-xs-12">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'SIGNATURES.ADD_FILES'|translate }}</label>
            <file-input drop-area=".modal-dialog" inputId="documents" [files]="[]"
            [max-size]="10485760" accept=".pdf"
            [(ngModel)]="envelopeFiles"
            [disabled]="processing"
            name="files" required [max-files]="20">
            </file-input>
            <error-list></error-list>
            <div class="help-block small">{{ 'ATTACHMENTS.PLACEHOLDER'|translate }}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Recipients -->
        <div class="col-xs-12">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'SIGNATURES.RECIPIENTS_COMPANIES'|translate }}</label>
            <selectize [required]="true" [searchURL]="companyService.getCompanies(company.id, {
              all: true
            })"
            [(ngModel)]="envelope.companies"
            [minLength]="1" [disabled]="processing" name="recipientsSelect"
            placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"></selectize>
            <error-list></error-list>
            <div class="help-block small" [innerHtml]="'SIGNATURES.RECIPIENTS_HELP'|translate"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Message -->
        <div class="col-xs-12">
          <div class="form-group" hasError>
            <label class="control-label">
              {{ 'SIGNATURES.MESSAGE'|translate }}
              <small class="text-muted">({{ 'GLOBAL.OPTIONAL'|translate }})</small>
            </label>
            <textarea class="form-control" rows="6" name="message" [disabled]="processing"
            [(ngModel)]="envelope.message"
            placeholder="{{ 'SIGNATURES.MESSAGE_PLACEHOLDER'|translate }}"></textarea>
            <error-list></error-list>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{
      'GLOBAL.CANCEL'|translate }}</button>
    <button type="submit" form="envelope-form" class="btn btn-agree" [disabled]="processing" data-dismiss="modal">{{
      'BARTERS.CREATE_AND_SEND'|translate }}</button>
  </div>
</ng-template>

<ng-template #historyModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ envelope.subject }}</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <!-- Envelope details -->
      <div class="col-xs-6">
        <table-detail [header]="'GLOBAL.STATUS'|translate">
          <ng-container [ngSwitch]="envelope.status.id">
            <span *ngSwitchCase="10" class="text-info">{{ 'SIGNATURES.STATUS.WAITING_SIGNERS'|translate }}</span>
            <span *ngSwitchCase="20" class="text-muted">{{ 'SIGNATURES.STATUS.PENDING'|translate }}</span>
            <span *ngSwitchCase="30">{{ 'SIGNATURES.STATUS.COMPLETED'|translate }}</span>
            <span *ngSwitchCase="40" class="text-danger">{{ 'SIGNATURES.STATUS.CANCELED'|translate }}</span>
          </ng-container>
        </table-detail>
      </div>
      <div class="col-xs-6">
        <table-detail [header]="'SIGNATURES.DOCUMENTS'|translate">
          <div *ngFor="let file of envelope.files; let i = index">
            <span class="micro text-muted">{{ i + 1 }}.</span>&nbsp;
            <samp class="small"><a (click)="filePreviewer.preview(getObservable(file.id))" [class.disabled]="processing">{{ file.name }}</a></samp>
          </div>
        </table-detail>
      </div>
    </div>
    <trackrecord
    entity="{{ envelope.entity }}"
    [ids]="[envelope.id]"
    [show]="true"></trackrecord>
  </div>
  <!-- <div class="modal-footer">
      <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
      <button type="submit" form="envelope-form" class="btn btn-agree" [disabled]="processing" data-dismiss="modal">{{ 'BARTERS.CREATE_AND_SEND'|translate }}</button>
    </div> -->
</ng-template>

<ng-template #signersModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'SIGNATURES.DEFINE_SIGNERS'|translate }}</h4>
  </div>
  <div class="modal-body">
    <p class="text-muted" [innerHtml]="'SIGNATURES.DEFINE_SIGNERS_HELP'|translate"></p>
    <ng-container *ngIf="companySigners; else loading">
      <ng-container *ngIf="companySigners.length > 0">
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label class="control-label">{{ 'SIGNATURES.REQUIRED_SIGNERS'|translate }}</label>
              <selectize name="signers" placeholder="{{ 'GLOBAL.SELECT'|translate }}" [minLength]="1"
              [options]="companySigners"
              [disabled]="processing"
              type="assignee"
              [(ngModel)]="envelopeSigners"></selectize>
              <a class="small"
              (click)="newSigner()"
              [class.disabled]="processing">{{ 'SIGNATURES.ADD_MORE_SIGNERS'|translate }}</a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <table-detail [header]="'SIGNATURES.DOCUMENTS'|translate">
              <div *ngFor="let file of envelope.files; let i = index">
                <span class="micro text-muted">{{ i + 1 }}.</span>&nbsp;
                <samp class="small"><a (click)="filePreviewer.preview(getObservable(file.id))" [class.disabled]="processing">{{ file.name }}</a></samp>
              </div>
            </table-detail>
          </div>
        </div>
      </ng-container>
      <div class="row" *ngIf="companySigners.length === 0">
        <div class="col-xs-12">
          <!-- No data -->
          <div class="no-data">
            <i class="material-symbols-rounded">not_interested</i>
            {{ 'SIGNATURES.NO_SIGNERS'|translate }}
            <div><a class="btn btn-link bt-sm"
            (click)="newSigner()"
            [class.disabled]="processing">{{ 'SIGNATURES.ADD_SIGNER'|translate }}</a></div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
    <button type="button" class="btn btn-agree"
    (onConfirm)="submitSigners()"
    [confirm-options]="{
      body: 'SIGNATURES.DEFINE_SIGNERS_CONFIRM'|translate
    }"
    [disabled]="processing || envelopeSigners.length === 0">{{ 'GLOBAL.CONFIRM'|translate }}</button>
  </div>
</ng-template>

<!-- TODO: Analyze how to replace with the confirm directive -->
<agree-confirm #cancelConfirm
title="{{ 'GLOBAL.ARE_YOU_SURE'|translate }}"
body="{{ 'GLOBAL.CANNOT_UNDONE'|translate }}"
confirm-button="{{ 'GLOBAL.YES_WITHDRAW'|translate }}"
cancel-button="{{ 'GLOBAL.CANCEL'|translate }}"></agree-confirm>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>

<ag-signers-modals #signersModals
(submitted)="refreshSigners($event)"
[company]="company"></ag-signers-modals>

<ag-file-preview #filePreviewer></ag-file-preview>