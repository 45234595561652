import { forwardRef } from '@angular/core';
import { Type } from "class-transformer";

import { PaymentCondition } from './payment-condition.model';

export class PaymentDetail {
  @Type(forwardRef(() => Date) as any)

  @Type(() => Date)
  date: Date;

  exchange_rate: string;

  @Type(forwardRef(() => PaymentCondition) as any)
  payment_condition: PaymentCondition;
  other: string;
}
