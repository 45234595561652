import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../models/company.model';
import { Currency } from '../../../models/currency.model';
import { CurrentDateService } from '../../../services/current-date.service';
import { MarketService } from '../../../services/market.service';
import { startOfDay } from '../../../utilities/date';

@Component({
  selector: 'ag-volume-calculator-generic',
  templateUrl: './volume-calculator-generic.component.html',
  styleUrls: ['./volume-calculator-generic.component.scss']
})
export class VolumeCalculatorGenericComponent implements OnDestroy {

  @ViewChild('modalTemplate', { static: true }) private readonly modalTemplate: TemplateRef<any>;

  @Input() public company: Company;
  @Input() public amount: number;
  @Input() public currency: Currency;
  @Input() public price: number;
  @Input() public rate: number;
  @Input('tax-rate') public tax_rate: number = 0;
  @Input() public warranty: number;
  @Input('granting-date') public granting_date: Date;
  @Input('due-date') public due_date: Date;

  /** [[Market]] supported [[Currency|Currencies]]. */
  public currencies: Currency[];
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean;
  public today: Date;
  public results: any;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private currentDate: CurrentDateService,
    private modalService: BsModalService,
    private marketService: MarketService
  ) {
    this.today = this.currentDate.get();
  }

  /** Opens modal. */
  public show(): void {
    this.loadCurrencies();
    if (!this.granting_date) {
      this.granting_date = startOfDay(new Date(this.today.getTime() + (1000 * 60 * 60 * 24)));
    }
    this.openModal(this.modalTemplate);
  }

  public update(): void {
    this.results = undefined;

    if (this.due_date &&
      this.price) {
      const days = Math.ceil((this.due_date.getTime() - this.granting_date.getTime()) / (1000 * 60 * 60 * 24));

      if (days > 0) {
        const interest = ((this.rate / 100) / 365) * days * this.amount;
        const taxes = interest * (this.tax_rate / 100);
        const subtotal = this.amount + interest + taxes;
        const warranty = subtotal * ((this.warranty || 0) / 100);
        const total = subtotal + warranty;
        const volume = total / this.price;
        const volume30 = Math.ceil(volume / 30) * 30;

        this.results = {
          days: days,
          interest: interest,
          taxes: taxes,
          subtotal: subtotal,
          warranty: warranty,
          total: total,
          volume: volume,
          volume30: volume30
        };
      }
    }
  }

  private loadCurrencies(): void {
    if (!this.currencies) {
      this.loading = true;
      this.subscriptions.push(this.marketService.watchCurrencies().subscribe(currencies => {
        if (!this.currency) this.currency = currencies[0];
        this.currencies = currencies;
        this.loading = false;
      }));
    }
  }

  /** 
   * Customizes the default Angular option comparison algorithm
   * @ignore */
  public compareSlug(a: { slug: string }, b: { slug: string }): boolean {
    return (!a && !b) || (a && b && a.slug === b.slug);
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      // if (!this.succesfullySubmitted) this.onCancel.emit();
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
