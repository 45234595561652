import { Component, forwardRef, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../models/company.model';
import { FormReuseService } from '../../services/form-reuse.service';

@Component({
  selector: 'ag-form-reuse',
  templateUrl: './form-reuse.component.html',
  styleUrls: ['./form-reuse.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormReuseComponent),
      multi: true,
    },
  ]
})
export class FormReuseComponent implements OnDestroy, ControlValueAccessor {

  @ViewChild('modal', { static: true }) private readonly modal: TemplateRef<any>;

  @Input() public company: Company;
  @Input() public form: string;
  @Input() public map: {
    [field: string]: {
      label?: string, // Customizable translatable label
      skip?: boolean // Wether the field should be auto filled or not
    }
  };

  public lastSelection: { [field: string]: boolean };
  public lastSubmittedData: { [field: string]: any };
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private modalService: BsModalService,
    private formReuseService: FormReuseService
  ) { }

  public open(): void {
    this.loadData();
    this.openModal(this.modal);
  }

  /**
   * Fetch the last submitted model and selection.
   */
  private loadData(): void {
    if (this.form && !this.lastSubmittedData) {
      this.loading = true;
      // Load based on account and form unique identifier
      this.subscriptions.push(this.formReuseService.get(this.company.id, this.form).subscribe(response => {
        this.lastSubmittedData = response.data;
        this.lastSelection = response.selection || {};

        this.loading = false;
      }));
    }
  }

  public submit(form: NgForm): void {
    for (const field in form.controls) {
      const value = form.controls[field].value;

      if (value) {
        // Apply this field
        this.formdata[field] = this.lastSubmittedData[field];
      }
    }

    this.saveSelection();
  }

  /**
   * Save the fields that were selected to be reused.
   */
  private saveSelection(): void {
    this.processing = true;

    this.subscriptions.push(this.formReuseService.selection(this.company.id, this.form, this.lastSelection).subscribe(response => {
      this.closeModal(() => {
        this.processing = false;
        this.lastSubmittedData = undefined;
      });
    }));
  }

  /**
   * Save the data submitted to be reused.
   */
  public save(callback?: Function): void {
    this.processing = true;

    this.subscriptions.push(this.formReuseService.save(this.company.id, this.form, this.formdata).subscribe(response => {
      this.processing = false;
      if (callback) callback();
    }));
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  // ngModel
  private _value: any;
  public get formdata(): any { return this._value }
  public set formdata(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.formdata = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
