<ng-template #modal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'FINTECH.SCORING_XLS_DATA.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <p>{{ 'FINTECH.SCORING_XLS_DATA.DESCRIPTION'|translate }}</p>
    <div class="well well-sm">
      <ngx-json-viewer [json]="jsonData.data" [expanded]="false"></ngx-json-viewer>
    </div>
    <p>{{ 'FINTECH.SCORING_XLS_DATA.CTA'|translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" class="btn btn-link"
    [disabled]="processing"
    data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
    <button type="button" class="btn btn-agree"
    [disabled]="processing"
    (click)="apply()">{{ 'GLOBAL.YES_CONFIRM'|translate}}</button>
  </div>
</ng-template>