<a class="small"
*ngIf="!disabled"
title="{{ 'GLOBAL.EDIT' | translate }}"
(click)="open()"
clickStop>
  <i class="material-symbols-rounded">edit</i>
</a>

<ng-template #modal>
  <!-- modal -->
  <!-- <div class="modal-header">
    <button type="button" class="close" [disabled]="disabled" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">Scoring Template</h4>
  </div> -->
  <div class="modal-body">
    <form id="text-form" #textForm="ngForm" (ngSubmit)="textForm.valid && submit()">
      <div class="form-group" hasError>
        <label for="text-{{ uuid }}" class="control-label" *ngIf="label">{{ label }}</label>
        <input id="text-{{ uuid }}" type="text" class="form-control input-lg"
        name="text-{{ uuid }}"
        [(ngModel)]="tempValue" required
        [disabled]="disabled"
        [modalFocus]="true">
        <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
        <error-list></error-list>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" [disabled]="disabled" class="btn btn-link" data-dismiss="modal">
      {{ 'GLOBAL.CANCEL'|translate }}
    </button>
    <button type="submit" form="text-form" class="btn btn-agree" [disabled]="disabled || tempValue === value">
      {{ 'GLOBAL.EDIT'|translate }}
    </button>
  </div>
</ng-template>