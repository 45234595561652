import { Directive, forwardRef, EventEmitter, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AgMultiselectionDirective),
  multi: true
};

@Directive({
  selector: '[AgMultiselection]',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class AgMultiselectionDirective implements ControlValueAccessor {

  @Input() key: string = 'id';

  private innerValue: any[];
  private _set: any[] = [];

  toggleAll = new EventEmitter<boolean>();
  changed = new EventEmitter();

  constructor(
  ) { }

  private onTouchedCallback: () => void = () => { };
  private onChangeCallback: (_: any) => void = () => { };

  get set(): any[] {
    return this._set;
  };

  get value(): any[] {
    return this.innerValue;
  };

  set value(v: any[]) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  public add(newItem: any): void {
    const exists = this._set.findIndex(item => item[this.key] === newItem[this.key]) !== -1;
    if (!exists) this._set.push(newItem);
  }

  onBlur(): void {
    this.onTouchedCallback();
  }

  writeValue(value: any[]) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }

    if (value && value.length === 0) {
      this.toggleAll.emit(false);
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
