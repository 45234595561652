import { Component, Input, HostListener } from '@angular/core';
import { SortableDirective } from '../../directives/sortable.directive';

@Component({
  selector: 'th[sortBy]',
  templateUrl: './th-sortable.component.html',
  styleUrls: ['./th-sortable.component.scss']
})
export class ThSortableComponent<T = any> {

  @Input() public sortBy: string;

  @HostListener('click', ['$event'])
  doClick(event) {
    this.table.sort(this.sortBy);
  }

  constructor(
    public table: SortableDirective
  ) { }
}
