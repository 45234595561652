import { forwardRef } from '@angular/core';
import { Exclude, Type } from "class-transformer";

import { DeliveryType } from "./delivery-type.model";
import { GeoSelection } from './geo-selection.model';

export class DeliveryDetail {
  locations: GeoSelection[];
  geolocations: any[];

  @Type(forwardRef(() => DeliveryType) as any)
  delivery_type: DeliveryType;

  // _intDateFrom: number;

  /**
   * ### Related UI components:
   * - [[DateRangeComponent|date-range]]
   */
  @Type(() => Date)
  date_to: Date;

  /**
   * ### Related UI components:
   * - [[DateRangeComponent|date-range]]
   */
  @Type(() => Date)
  date_from: Date;

  @Exclude()
  private _range: [Date, Date];

  get range(): [Date, Date] {
    if (!this._range || this.date_from !== this._range[0] || this.date_to !== this._range[1]) {
      this._range = this.date_from && this.date_to ? [this.date_from, this.date_to] : null;
    }
    return this._range;
  }
  set range(range: [Date, Date]) {
    this.date_from = range ? range[0] : null;
    this.date_to = range ? range[1] : null;
  }

  get intDateFrom(): number {
    if (!this.date_from) return 0;

    const year = this.date_from.getFullYear();
    const y = '' + year;

    const month = this.date_from.getMonth();
    const m = month < 10 ? '0' + month : '' + month;

    const date = this.date_from.getDate();
    const d = date < 10 ? '0' + date : '' + date;

    return parseInt(y + m + d);
  }

  constructor() {
    this.date_to = null;
    this.date_from = null;
    this.locations = [];
  }
}
