<ng-container *ngIf="doa; else loading">
  <h5 *ngIf="doa.name"
  class="no-margin-top">{{ doa.name }}</h5>
  <table class="table table-detail table-condensed">
    <thead>
      <tr>
        <th colspan="4">
          <div class="flex-center-distributed">
            <div class="grow truncate"
            [innerHtml]="'FINTECH.DOA_APPROVERS'|translate"></div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let approver of doa.approvers; let i = index">
        <tr class="tr-align-center">
          <!-- Index -->
          <td class="sap micro">{{ i + 1 }}</td>
          <!-- User data -->
          <td>
            <b [class.highlighted-text]="user.id === approver.user.id">{{ approver.user.name + ' ' + approver.user.last_name }}</b>
            <div class="small text-muted">{{ approver.user.email }}</div>
          </td>
          <!-- Desicion -->
          <td class="sap text-center">
            <ng-container *ngIf="doa.decision(approver.id); let decision">
              <span *ngIf="decision.votedAt; else showVote"
              class="material-symbols-rounded"
              [class.text-success]="decision.vote === 'APPROVED'"
              [class.text-danger]="decision.vote === 'DISAPPROVED'"
              [class.text-warning]="decision.vote === 'ABSTENTION'">{{
                decision.vote === 'APPROVED' ? 'thumb_up' :
                decision.vote === 'DISAPPROVED' ? 'thumb_down_alt' : 'highlight_off' }}</span>
    
              <ng-template #showVote>
                <ng-container *ngIf="decision.vote === 'PENDING' && doa.status === 'PENDING'">
                  <ng-container *ngIf="user.id === approver.user.id; else waitingVote">
                    <button class="btn btn-sm btn-danger" type="button" [disabled]="processing"
                      (click)="vote(decision, 'DISAPPROVED')" clickStop>
                      <span class="material-symbols-rounded">thumb_down_alt</span>
                    </button>
                    &nbsp;
                    <button class="btn btn-sm btn-agree" type="button" [disabled]="processing"
                      (click)="vote(decision, 'APPROVED')" clickStop>
                      <span class="material-symbols-rounded">thumb_up</span>
                    </button>
                  </ng-container>
                </ng-container>
              </ng-template>
            </ng-container>
          </td>
          <!-- Date -->
          <td class="sap">
            <ng-container *ngIf="doa.decision(approver.id); let decision">
              <ag-timestamp *ngIf="decision.votedAt" [date]="decision.votedAt"></ag-timestamp>      
            </ng-container>
          </td>
        </tr>
        <tr *ngIf="getComments(approver.id)" class="tr-align-center">
          <td class="td-empty" colspan="4">
              <ag-history-comments [compact]="true" [comments]="getComments(approver.id)" [company]="company" [user]="user">
              </ag-history-comments>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-container>

<ng-template #waitingVote>
  <span class="small text-muted">{{ 'DOA.WAITING'|translate }}</span>
</ng-template>

<ng-template #reasonModal>
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'GLOBAL.ARE_YOU_SURE'|translate }}</h4>
  </div>
  <form>
    <div class="modal-body">
      <p [innerHtml]="'GLOBAL.CANNOT_UNDONE'|translate"></p>
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group" hasError>
            <label class="control-label">
              {{ 'GLOBAL.OBSERVATIONS'|translate }}
              <small *ngIf="desicion?.vote === 'APPROVED'" class="text-muted">
                ({{ 'GLOBAL.OPTIONAL'|translate}})
              </small>
            </label>
            <textarea class="form-control" rows="3" [modalFocus]="desicion?.vote === 'DISAPPROVED'"
              [required]="desicion?.vote === 'DISAPPROVED'" name="reason" maxlength="1000"
              [disabled]="processing" [(ngModel)]="desicion.comment">
            </textarea>
            <error-list></error-list>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button (click)="closeModal()" type="button" class="btn btn-link" [disabled]="processing"
        data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
      <button type="submit" class="btn btn-agree btn-possibility"
        [disabled]="processing"
        (click)="submit()">
        {{ desicion?.vote === 'APPROVED' ? ('GLOBAL.YES_APPROVE'|translate) : ('GLOBAL.YES_REJECT'|translate)}}
      </button>
    </div>
  </form>
</ng-template>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>