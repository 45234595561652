import { Component, OnDestroy, OnInit, QueryList } from '@angular/core';
import { NgModel, NgModelGroup } from '@angular/forms';
import { Subscription, debounceTime } from 'rxjs';

import { HasErrorDirective } from '../../directives/has-error.directive';

@Component({
  selector: 'error-list',
  templateUrl: './error-list.component.html',
  styleUrls: ['./error-list.component.css']
})
export class ErrorListComponent implements OnInit, OnDestroy {

  public models: QueryList<NgModel>;
  public modelGroup: NgModelGroup;
  private subscriptions: Subscription[] = [];

  constructor(
    private hasError: HasErrorDirective
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.hasError.modelsChange.pipe(
      debounceTime(100)
    ).subscribe(() => {
      this.getModels();
    }));

    // Is this necessary?
    // setTimeout(() => {
    //   this.getModels();
    // });
  }

  private getModels(): void {
    this.modelGroup = this.hasError.getModelGroup();
    this.models = this.hasError.getModels();
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
