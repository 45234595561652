<!-- <div class="gauge" style="width: 200px; --rotation:83deg; --color:#5cb85c; --background:#e9ecef;"> -->
<div class="gauge-container"
title="{{ label }} ({{ percentage|percent }})">
  <div class="gauge hidden-print" *ngIf="max > 0; else noMax"
  style="--rotation: {{ rotation }}deg; --color: {{ color }};">
    <div class="percentage"></div>
    <div class="mask"></div>
    <span class="value">{{ value|number:'1.0-0':currentLang }} <small class="faded">/ {{ max|number:'1.0-0':currentLang }}</small></span>
  </div>
  <div *ngIf="max > 0"
  class="visible-print text-center small">
    {{ value|number:'1.0-0':currentLang }} <small class="faded">/ {{ max|number:'1.0-0':currentLang }}</small>
  </div>
  <div *ngIf="label" class="glabel small clamp-2 text-center">{{ label }}</div>
</div>

<ng-template #noMax>
  <div class="empty-gauge hidden-print">
    <span class="material-symbols-rounded faded">speed</span>
  </div>
</ng-template>