<ag-file-preview #filePreviewer></ag-file-preview>

<ng-template #modal>
  <!-- MODAL ADD TO MY NETWORK -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'GLOBAL.SHARED_FILES'|translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <form #fileManagerForm="ngForm">
          <div class="form-group" hasError hasErrorOn="dirty">
            <label class="control-label">
              {{ 'GLOBAL.COMPANY'|translate }}
            </label>
            <ng-container *ngIf="!sharingCompany; else selectedCpmpany">
              <selectize required [maxItems]="1" [searchURL]="companyService.getCompanies(company.id, {
                all: true
              })" [(ngModel)]="sharingCompany" name="applicant"
              (ngModelChange)="setCompany(sharingCompany)"
              placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}" [disabled]="processing"></selectize>
              <!-- <div class="flex-center-distributed spaced small">
                <a (click)="fiscal_id_mode = !fiscal_id_mode">{{ 'COMPANY.NOT_LISTED'|translate }}</a>
              </div> -->
            </ng-container>
            <ng-template #selectedCpmpany>
              <div class="editable-pill">
                <button [disabled]="processing" type="button" class="close"
                  (click)="reset()"><span aria-hidden="true">&times;</span></button>
                <company-info [company]="sharingCompany"
                [activity]="false"></company-info>
              </div>
            </ng-template>
          </div>
        </form>
      </div>
    </div>

    <div class="row" *ngIf="sharingCompany">
      <div class="col-xs-12">
        <div *ngIf="files; else loading">
          <form *ngIf="files.length > 10">
            <div class="form-group has-button">
              <!-- <label for="filterKey"><span class="material-symbols-rounded">search</span>&nbsp;</label> -->
              <input [(ngModel)]="filterKey" name="filterKey" autocomplete="off" type="text" class="form-control"
                id="filterKey" placeholder="{{ 'MESSENGER_CENTER.SEARCH_PLACEHOLDER'|translate }}">
              <span *ngIf="filterKey !== undefined" (click)="filterKey = undefined"
                class="glyphicon glyphicon-remove-sign form-control-button" aria-hidden="true"></span>
            </div>
          </form>

          <table
          class="table table-hover no-margin"
          [sortable]="files"
          #filesTable="tableSortable"
          defaultSortBy="-createdAt">
            <thead>
              <tr class="tr-align-center">
                <th class="sap"></th>
                <th sortBy="name">{{ 'GLOBAL.NAME'|translate }}</th>
                <th class="sap" sortBy="createdAt">
                  {{ "GLOBAL.DATE" | translate }}
                </th>
                <th sortBy="size"></th>
                <th class="hidden-xs sap"
                *ngIf="editable">
                  <abbr title tooltip="{{ 'GLOBAL.DUE_DATE'|translate }}"
                  [adaptivePosition]="false">{{ 'GLOBAL.DUE_DATE_ABBR'|translate }}</abbr>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let file of filesTable.collection">
                <tr *ngIf="!filterKey || (
                  ((file.name + file.extension)| test: filterKey: 'igm') ||
                  (file.label| test: filterKey: 'igm') ||
                  (file.type|json| test: filterKey: 'igm')
                )"
                class="tr-align-center clickable"
                (click)="preview(file)">
                  <td class="sap">
                    <span class="material-symbols-rounded text-muted"
                    title="{{ file.contentType }}">{{ iconClass(file.contentType) }}</span>
                  </td>
                  <td>
                    <span [innerHtml]="file.name + file.extension|highlighter:filterKey"></span>
                    <div class="micro text-muted" [innerHtml]="file.label || file.type|highlighter:filterKey"></div>
                  </td>
                  <td class="sap">
                    <ag-timestamp [date]="file.createdAt"></ag-timestamp>
                  </td>
                  <td class="sap text-right text-muted small"
                  title="{{ file.size|number }}">{{ file.size|filesize }}</td>
                  <td class="hidden-xs sap small"
                  *ngIf="editable">
                    <div class="flex-center-distributed"
                    clickStop>
                      <span
                      #dp="bsDatepicker"
                      [bsValue]="file.dueDate"
                      (bsValueChange)="editDueDate(file, $event)"
                      [minDate]="today"
                      bsDatepicker>
                        <date-md *ngIf="file.dueDate; else none" [date]="file.dueDate" [style]="true"></date-md>
                      </span>
                      <button
                      class="show-onhover btn btn-link btn-xs"
                      title="{{ 'GLOBAL.EDIT' | translate }}"
                      type="button"
                      clickStop
                      (click)="dp.toggle()"
                      [attr.aria-expanded]="dp.isOpen"
                      [disabled]="processing">
                        <i class="material-symbols-rounded">edit</i>
                      </button>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <ng-container *ngIf="files.length === 0">
            <!-- No data -->
            <div class="no-data">
              <i class="material-symbols-rounded">not_interested</i>
              {{ 'GLOBAL.NO_DATA'|translate }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>