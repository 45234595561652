import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Media } from '../../../models/media.model';
import { ListNamesPipe } from '../../../pipes/list-names.pipe';
import { checkAcceptFile } from '../../../utilities/file';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileInputComponent),
  multi: true
};

export const CUSTOM_VALIDATOR_ACCESSOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => FileInputComponent),
  multi: true,
}

/**
 * Extends and customize the native component: `<input type="file">`.
 *
 * ## Usage
 * ``` html
 * <file-input required
 * name="attachment" inputId="attachment"
 * drop-area="#drop"
 * [disabled]="processing"
 * [(ngModel)]="attachment"
 * [max-size]="5242880"
 * accept="image/*, .pdf, .doc, .docx"></file-input>
 * ```
 */
@Component({
  selector: 'file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_VALIDATOR_ACCESSOR]
})
export class FileInputComponent implements ControlValueAccessor, Validator {

  /** Default files drop area. */
  @ViewChild('defaultDrop', { static: true }) private readonly defaultDrop: ElementRef;

  /** Selector to set the files drop area. */
  @Input('drop-area') public dropArea: string;
  @Input() public files: Media[] = [];
  /** Maximum number of selectable files. */
  @Input('max-files') public maxFiles: number = 1;
  /** Name of the form control. */
  @Input() public name: string;
  /** Input element id. */
  @Input() public inputId: string;
  /**
   * https://agreemarket.atlassian.net/wiki/spaces/DEV/pages/90112001/Web+application+UI#Archivos
   */
  @Input('max-size') public maxSize: number = 5242880;
  /**
   * Specify what file types the user can attach.
   *
   * Works as
   * [[https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept|HTML attribute: accept]].
   */
  @Input() public accept: string = "";
  @Input() public disabled: boolean;
  @Input() public hasAnomFile: boolean;
  @Input() public deletedFile: Event;

  @Output() readonly delete = new EventEmitter();
  @Output() readonly onRemove = new EventEmitter();

  public filesToUpload: any[] = [];
  public isValid: boolean = true;
  public processing: boolean = false;

  private innerValue: any;

  /** @ignore */
  constructor(
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private listNamesPipe: ListNamesPipe
  ) { }

  /** @ignore */
  ngOnInit(): void {
    // Setup drag and drop
    let da: Element = this.defaultDrop.nativeElement;

    if (this.dropArea) {
      const db = document.querySelector(this.dropArea);
      if (db) da = db;
    }

    this.setupDrop(da);
  }

  private showToastMessage(message: string): void {
    this.toastrService.warning(message, undefined, {
      closeButton: true
    });
  }

  private setupDrop(el: Element): void {
    const dragger = (e: DragEvent) => {
      if (!this.disabled) {
        e.stopPropagation();
        e.preventDefault();
        if (!this.disabled) this.defaultDrop.nativeElement.className = 'is-dragging';
      }
    };
    const removeDrag = () => {
      if (!this.disabled) this.defaultDrop.nativeElement.className = '';
    };

    el.addEventListener("dragenter", dragger, false);
    el.addEventListener("dragover", dragger, false);
    el.addEventListener("drop", (e: DragEvent) => {
      if (!this.disabled) {
        dragger(e);

        // Triggered by drop
        this.addFiles(e.dataTransfer.files);

        removeDrag();
      }
    }, false);
    el.addEventListener("dragleave", removeDrag, false);
    el.addEventListener("dragend", removeDrag, false);
  }

  public removeFiles(): void {
    this.value = undefined;
    this.onRemove.emit()
  }

  public removeFile(fileIndex: number): void {
    let clean = [];
    for (let index = 0; index < this.innerValue.length; index++) {
      if (index !== fileIndex) clean.push(this.innerValue[index]);
    }

    this.filesFingerprint.splice(fileIndex, 1);

    this.innerValue = clean;
    this.onRemove.emit()

    this.parseFileList();
    this.onChangeCallback(this.innerValue);
    this.propagateChange(this.innerValue);
  }

  get value(): FileList {
    return this.innerValue;
  };

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: FileList) => void = noop;
  private propagateChange = (_: any) => { };

  private filesFingerprint: string[] = [];

  private generateFileFingerprint(f: File): string {
    const properties = ['name', 'lastModified', 'size', 'type', 'webkitRelativePath'];
    let fp = [];

    properties.forEach(key => {
      if (f[key]) {
        fp.push(String(f[key]));
      }
    });

    return fp.join('_');
  }

  set value(fl: FileList) {
    if (fl) {
      if (!Array.isArray(this.innerValue)) this.innerValue = [];

      let rejectedFiles: File[] = [];
      let newFiles: File[] = [];

      for (let i = 0, l = fl.length; i < l; i++) {
        const file = fl[i];
        const fp = this.generateFileFingerprint(file);

        if (!this.filesFingerprint.includes(fp)) {
          // Prevent the same file from being added twice
          if ((file.size || 0) <= this.maxSize &&
            checkAcceptFile(file, this.accept)) {
            this.filesFingerprint.push(fp);
            newFiles.push(file);
          } else {
            rejectedFiles.push(file);
          }
        }
      }

      this.innerValue = this.innerValue.concat(newFiles);

      if (rejectedFiles.length) {
        this.showToastMessage(this.translateService.instant('FILE_INPUT.REJECTED', { files: this.listNamesPipe.transform(rejectedFiles) }));
      }
    } else {
      this.innerValue = fl;
      this.filesFingerprint = [];
    }

    this.parseFileList();

    this.onChangeCallback(this.innerValue);
    this.propagateChange(this.innerValue);

  }

  public validate(control: AbstractControl): { maxFiles: number; } {
    // let isValid:boolean = true;
    if (control.value && typeof control.value === 'object' && (this.files.length + this.filesToUpload.length > this.maxFiles)) {
      this.isValid = false;
    } else {
      this.isValid = true;
    }

    return this.isValid ? null : {
      "maxFiles": this.maxFiles
    };
  }

  private parseFileList(): void {
    this.filesToUpload = []; // Reset files

    const imageType = /image.*/;

    if (this.innerValue) {
      this.innerValue.forEach(file => {
        if (!file.type.match(imageType)) {
          this.filesToUpload.push(file);
        } else {
          const reader = new FileReader();
          reader.onload = (function (arr, f) {
            return function (e) {
              arr.push({
                uri: e.target.result,
                name: f.name,
                size: f.size
              });
            };
          })(this.filesToUpload, file);
          reader.readAsDataURL(file);
        }
      });
    }
  }

  selectedValues(): any {
    if (typeof this.innerValue !== 'string') {
      return this.innerValue;
    } else {
      return [];
    }
  }

  public hasFileSelected(): boolean {
    return this.innerValue && typeof this.innerValue !== 'string' && this.innerValue.length >= this.maxFiles;
  }

  onBlur(): void {
    this.onTouchedCallback();
  }

  writeValue(value: FileList) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public addFiles(files: FileList): void {
    // Triggered by input component
    let filesSoFar = this.files.length + this.filesToUpload.length;
    if (filesSoFar + files.length > this.maxFiles) {
      this.showToastMessage(this.translateService.instant('ERROR_LIST.MAX_FILES', { max: this.maxFiles }));
    } else this.value = files;
  }

  deleteFile(inputId, file) {
    this.delete.emit({
      inputId: inputId,
      file: file
    });
  }
}
