import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, combineLatest } from 'rxjs';

import { User } from '../../../auth/models/user.model';
import { LoginService } from '../../../auth/services/login.service';
import { Company } from '../../../models/company.model';
import { Preset } from '../../../models/preset.model';
import { CompanyService } from '../../../services/company.service';
import { PresetService } from '../../services/preset.service';

@Component({
  selector: 'ag-preset-filters',
  templateUrl: './preset-filters.component.html',
  styleUrls: ['./preset-filters.component.scss']
})
export class PresetFiltersComponent implements OnInit, OnDestroy {

  @ViewChild('saveModal', { static: true }) private saveModal: TemplateRef<any>;

  @Input() public user: User;
  @Input() public key: string;
  @Input() public compact: boolean;
  @Input() public private: boolean = true;
  @Input() public placement: string = 'right';
  @Input() public processing: boolean;

  public company: Company;
  public newPreset: Preset;
  public presetsApplied: boolean;
  public currentPreset: Preset;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private companyService: CompanyService,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router,
    private presetService: PresetService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(combineLatest({
      company: this.companyService.watch(),
      user: this.loginService.getCurrentUser(),
      preset: this.presetService.presetFilter$,
      params: this.route.params
    }).subscribe((data: { company: Company; user: User; params: Params; preset: Preset }) => {
      this.company = data.company ?? null;
      this.user = data.user ?? null;
      this.currentPreset = data.preset;
      this.setKey();
    }));
  }

  private getPresetKey(routeCurrentCollection: string, key: string): string {
    let presetKey: string = key || '';

    if (routeCurrentCollection) {
      if (presetKey) {
        presetKey += `-${routeCurrentCollection}`;
      } else {
        presetKey += `${routeCurrentCollection}`;
      }
    }

    return `filters-${presetKey}`;
  }

  private setKey(): void {
    const routeCurrentCollection: string = this.route.snapshot.params.collection;
    const keyFromRoute = this.route.snapshot.data['presetKey'];

    this.key = this.getPresetKey(routeCurrentCollection, keyFromRoute);
  }

  private applyQueryParams(presetsQueryParams: string): void {
    const [url, inheritParams] = this.router.url.split('?');
    const queryParams = this.route.snapshot.queryParamMap;

    const urlParams = new URLSearchParams(presetsQueryParams ? presetsQueryParams : inheritParams);

    urlParams.forEach((value, key) => {
      queryParams[key] = value;
    });
    delete queryParams['params'];

    this.router.navigate([url], { queryParams });
  };

  public setPreset(preset: Preset): void {
    this.processing = true;
    this.newPreset = new Preset(preset);

    this.subscriptions.push(
      combineLatest({
        create: this.presetService.create(this.newPreset),
        deleteOld: this.presetService.delete(preset)
      }).subscribe((res) => {
        this.applyQueryParams(preset.data?.queryParams);
        this.presetService.setPresetFilter(this.newPreset);
        this.processing = false;
      })
    );
  }

  public deleteCurrentPreset(): void {
    this.processing = true;

    this.subscriptions.push(
      this.presetService.delete(this.currentPreset).subscribe(() => {
        const [currentUrl] = this.router.url.split('?');

        this.presetService.setPresetFilter(null);
        this.router.navigate([currentUrl], { queryParams: this.getOriginalFilters() });
        this.processing = false;
      })
    )
  }

  public handleDelete(presets: Preset[]): void {
    const lastPreset = presets.pop();

    if (!lastPreset) {
      const [currentUrl] = this.router.url.split('?');

      this.presetService.setPresetFilter(null);
      this.router.navigate([currentUrl], { queryParams: this.getOriginalFilters() });
    } else {
      this.presetService.setPresetFilter(lastPreset);
      this.applyQueryParams(lastPreset.data?.queryParams ?? "");
    }
  }

  private getOriginalFilters(): Object {
    const searchParams = new URLSearchParams(this.presetService.originalFilters$.getValue());
    const queryParamsObject = {};

    searchParams.forEach((value, key) => {
      queryParamsObject[key] = value;
    });

    return queryParamsObject;
  }

  public save(): void {
    this.newPreset = new Preset({
      companyId: this.company.id,
      userId: this.user.id,
      key: this.key,
      private: this.private,
      data: { queryParams: this.getQueryParams() }
    });

    this.openModal(this.saveModal);
  }

  public submit(): void {
    this.processing = true;

    this.subscriptions.push(this.presetService.create(this.newPreset).subscribe((id: string) => {
      const preset: Preset = {
        id: id,
        ...this.newPreset
      };

      this.processing = false;
      this.presetService.setPresetFilter(preset);
      this.closeModal();
    }));
  }

  /**
   * Return queryParams as string
   */
  private getQueryParams(): string {
    // Create a shallow copy to avoid mutation
    const params = { ...this.route.snapshot.queryParams };

    delete params['page'];

    return '?' + new URLSearchParams(params).toString();
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
