import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { BehaviorSubject, Observable, map } from 'rxjs';

import { JSend } from '../models/jsend.model';
import { Tenant } from '../models/tenant.model';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  private baseUrl: string = '/:apiTenant/tenants';
  private tentantByUrl: string = this.baseUrl + '/url/:url';
  private tenantById: string = this.baseUrl + '/:tenantId';

  private _tenant = new BehaviorSubject<Tenant>(null);

  constructor(private http: HttpClient) { }

  /** Get current tenant */
  get tenant(): Tenant {
    return this._tenant.getValue();
  }

  public getTenants(): Observable<Tenant[]> {
    const stream = this.http.get<JSend<Tenant[]>>(this.baseUrl);

    return stream.pipe(map(response => plainToInstance(Tenant, response.data)));
  }

  /** SuperAdmin */
  public create(tenant: Tenant): Observable<Tenant> {
    let url = this.baseUrl;

    const stream = this.http.post<JSend<Tenant>>(url, tenant);

    return stream.pipe(map(response => {
      if (response.status === 'success') return plainToInstance(Tenant, response.data);
      else throw new Error(String(response.data));
    }));
  }

  public get(tenantById: string): Observable<Tenant> {
    let url = this.tenantById
      .replace(":tenantById", tenantById);

    const stream = this.http.get<JSend<Tenant>>(url);

    return stream.pipe(map(response => {
      if (response.status === 'success') return plainToInstance(Tenant, response.data);
      else throw new Error(String(response.data));
    }));
  }

  /**
   * Get tenant information by id or hostname/base_url
   */
  private getByUrl(tenantUrl: string): Observable<Tenant | null> {
    let url = this.tentantByUrl
      .replace(':url', tenantUrl);

    const stream = this.http.get<JSend<Tenant>>(url);

    return stream.pipe(map(response => {
      if (response.status === 'success') return plainToInstance(Tenant, response.data);
      else throw new Error(String(response.data));
    }));
  }

  /**
   * Initialize tenant
   */
  public initTenant(): void {
    const hostname = window.location.hostname === 'localhost' ? 'app.dev.agree.ag' : window.location.hostname;

    this.getByUrl(hostname).subscribe(tenant => {
      this._tenant.next(tenant);
    });
  }
}
