import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Company } from '../../../models/company.model';
import { Quantity } from '../../../models/quantity.model';

/**
 * Form to edit [[Quantity|volume]].
 *
 */
@Component({
  selector: 'quantity-input',
  templateUrl: './quantity-input.component.html',
  styleUrls: ['./quantity-input.component.scss']
})
export class QuantityInputComponent {

  @Input() public quantity: Quantity;
  @Input() public company: Company;
  /** The minimum value to accept for this entry. */
  @Input() public minValue?: number = 0;
  @Input() public maxValue?: number = 9999999;
  /** An example value to display inside the field when empty. */
  @Input() public placeholder?: string = "0.00";
  @Input() public disabled?: boolean = false;
  @Input() public required: boolean = true;

  @Output() readonly quantityChange = new EventEmitter<string>();

  constructor() { }

}
