<samp *ngIf="batches?.length" [tooltip]="slotsTemplate" placement="left" container="body">{{
  batches|pluck:'slots_total'|sum }}</samp>
<ng-template #slotsTemplate>
  <table class="tt-details">
    <tbody>
      <tr *ngFor="let d of details|keyvalue" class="tr-align-center">
        <td class="text-left">{{ d.key|translate }}</td>
        <td class="text-right">{{ d.value }}</td>
      </tr>
    </tbody>
  </table>
</ng-template>