import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ThemeLanguages } from '../models/theme.model';
import { ThemeService } from '../services/theme.service';

/**
 * Translate texts by Theme, in case the text does not exist in the Theme dictionary
 * by default returns the language text
 */
@Pipe({
  name: 'translateByTheme',
  pure: false
})
export class TranslateByTheme implements PipeTransform {

  constructor(
    protected translateService: TranslateService,
    protected themeService: ThemeService
  ) { }

  transform(dictionaryKey: string, params: Record<string, any>): string {
    const themeLanguages: ThemeLanguages | undefined = this.themeService.theme.languages;
    const currentLang: string = this.translateService.currentLang;

    if (themeLanguages) {
      const messages = themeLanguages.messages[currentLang];
      const message = this.getKeyFromMessages(messages, dictionaryKey);

      if (message && params) {
        return this.interpolateParams(message, params);
      }

      return message || this.translateService.instant(dictionaryKey);
    } else {
      return this.translateService.instant(dictionaryKey);
    }
  }

  private getKeyFromMessages(messages: any, dictionaryKey: string): string | undefined {
    const parts = dictionaryKey.split('.');
    return this.getNestedValue(messages, parts);
  }

  private getNestedValue(obj: any, pathParts: string[]): any {
    if (!obj || typeof obj !== 'object') {
      return undefined;
    }

    const currentPart = pathParts[0];
    const remainingParts = pathParts.slice(1);

    if (currentPart in obj) {
      if (remainingParts.length === 0) {
        return obj[currentPart];
      } else {
        return this.getNestedValue(obj[currentPart], remainingParts);
      }
    } else {
      return undefined;
    }
  }

  private interpolateParams(message: string, params: Record<string, any>): string {
    Object.keys(params).forEach((key) => {
      const paramValue = params[key];
      message = message.replace(`{{${key}}}`, paramValue.toString());
    });

    return message;
  }
}
