import { Params } from "@angular/router";

import { CommercialZone } from "../../company/models/commercial-zone.model";
import { Market } from "../../company/modules/commercial/models/market.model";
import { CompanyActivity } from "../../models/company-activity.model";
import { Currency } from "../../models/currency.model";
import { DeliveryTypeGroup } from "../../models/delivery-type-group.model";
import { DeliveryType } from "../../models/delivery-type.model";
import { GroupBy } from "../../models/group-by.model";
import { Label } from "../../models/label.model";
import { Location } from "../../models/location.model";
import { Product } from "../../models/product.model";
import { Zone } from "../../models/zone.model";

type filterTypes = 'date_range' | 'list' | 'select' | 'multiple';

export class Filter {
  key: string;
  label: string;
  value?: any;
  options?: FilterOption[];
  type?: filterTypes;
  /**
   * Filters visible only for administrator views
   */
  admin?: boolean;

  constructor(data: Partial<Filter> = {}) {
    Object.assign(this, data);
    this.admin = data.admin === undefined ? false : data.admin;
    this.options = data.options ?? [];
  }
}

export class FilterOption {
  id: number | string;
  name: string;
  iconHtml?: string;
  class?: string;

  constructor(data: Partial<FilterOption> = {}) {
    Object.assign(this, data);
  }
}

export class AppliedFilter {
  key: string;
  id: string;
  title: string;
  value: string;
  type: filterTypes;

  constructor(data: Partial<AppliedFilter> = {}) {
    Object.assign(this, data);
  }
}

export class MarketAndParams {
  queryParams: Params;
  market: Market;
  params: Params;
}
export class FiltersData {
  products: Product[];
  zones: Zone[];
  zone: Zone[];
  locations: Location[];
  marketAndParams: MarketAndParams;
  delivery_types: GroupBy<DeliveryTypeGroup, DeliveryType>[];
  currencies: Currency[];
  labels: Label[];
  commercial_zones: CommercialZone[];
  activities: CompanyActivity[];

  constructor(data: Partial<FiltersData> = {}) {
    Object.assign(this, data);
  }
}

export class MonthStruct {
  id: string;
  name: string;

  constructor(data: Partial<MonthStruct> = {}) {
    Object.assign(this, data);
  }
}
