import { forwardRef } from '@angular/core';
import { Type } from "class-transformer";

import { User } from '../auth/models/user.model';
import { Company } from './company.model';

class TrackrecordChange {
  type: string;
  field: string;
  new: any;
  old: any;
}

export class TrackrecordStep {
  @Type(() => Date)
  date: Date;

  @Type(forwardRef(() => Company) as any)
  company?: Company;

  @Type(forwardRef(() => User) as any)
  user?: User;

  @Type(forwardRef(() => TrackrecordChange) as any)
  changes: TrackrecordChange[];

  tie_breaker?: boolean;

  action: string;
  entity: string;
  entityId: string | number;
  private?: boolean;
}