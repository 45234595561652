<div
class="btn-group"
dropdown
(onShown)="dropdownHandler(true)"
[class.hidden]="!company"
#presetDropdown="bs-dropdown">
  <button
  dropdownToggle
  type="button"
  class="btn btn-link btn-sm dropdown-toggle">
    <span class="material-symbols-rounded">tune</span>
    {{ title | translate }} <span class="caret"></span>
  </button>
  <ul
  *dropdownMenu
  class="dropdown-menu"
  role="menu"
  [class.dropdown-menu-right]="placement === 'right'">
    <ng-container *ngIf="!presets">
      <div class="loading loading-sm">
        <spinner></spinner>
      </div>
    </ng-container>

    <div class="presets-list">
      <li *ngFor="let preset of presets">
        <span
        class="dropdown-item"
        [class.active]="preset.id === selected?.id"
        (click)="set(preset)">
          <div class="flex-center-distributed">
            <span>{{ preset.name }}</span>
            <div class="grow micro text-muted" *ngIf="preset.private">
              &nbsp;&nbsp;<span class="material-symbols-rounded">lock</span>&nbsp;&nbsp;
            </div>
            <a
            title="{{ 'TEMPLATES_VIEW.DELETE' | translate }}"
            clickStop
            (onConfirm)="delete(preset)"
            [confirm-options]="{
              action: 'GLOBAL.YES_DELETE' | translate
            }"
            [class.disabled]="processing">
              <i class="material-symbols-rounded">delete</i>
            </a>
          </div>
        </span>
      </li>
      <li *ngIf="presets && presets.length === 0">
        <a class="dropdown-item disabled">{{ "PRESETS.NO_DATA" | translate }}</a>
      </li>
    </div>
    <ng-container *ngIf="canCreate && !exists && presets?.length < maxAllowedPresets">
      <li class="divider"></li>
      <li role="menuitem">
        <a
        class="dropdown-item"
        [class.disabled]="processing"
        (click)="create()">{{ "PRESETS.CREATE" | translate }}</a>
      </li>
    </ng-container>
  </ul>
</div>

<ng-template #createModal>
  <!-- modal -->
  <div class="modal-header">
    <button
    type="button"
    class="close"
    [disabled]="processing"
    (click)="closeModal()"
    aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ "PRESETS.NEW" | translate }}</h4>
  </div>
  <div class="modal-body">
    <form
    id="label-form"
    #presetForm="ngForm"
    (ngSubmit)="presetForm.valid && submit()">
      <div class="row">
        <div class="col-sm-7">
          <div class="form-group" hasError>
            <label class="control-label">{{ "GLOBAL.NAME" | translate }}</label>
            <input
            type="text"
            name="name"
            class="form-control"
            autocomplete="off"
            [disabled]="processing"
            maxlength="24"
            [modalFocus]="true"
            required
            [(ngModel)]="newPreset.name"/>
            <error-list></error-list>
          </div>
        </div>
        <div class="col-sm-5">
          <div class="form-group">
            <div class="checkbox flex-center-distributed">
              <div>
                <input
                type="checkbox"
                class="ios"
                name="private"
                id="preset_private"
                [disabled]="processing"
                [(ngModel)]="newPreset.private"/>
              </div>
              <label for="preset_private" class="grow">{{
                "ORDER_FORM.SCOPE.PRIVATE" | translate
              }}</label>
            </div>
            <span
            class="small help-block"
            [innerHtml]="'PRESETS.PRIVATE_HELP' | translate"></span>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
    (click)="closeModal()"
    [disabled]="processing"
    type="button"
    class="btn btn-link"
    data-dismiss="modal">
      {{ "GLOBAL.CANCEL" | translate }}
    </button>
    <button
    type="submit"
    form="label-form"
    class="btn btn-agree"
    [disabled]="processing || !presetForm.valid">
      {{ "GLOBAL.SAVE" | translate }}
    </button>
  </div>
</ng-template>
