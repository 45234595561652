import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, map, mergeMap, of, switchMap } from 'rxjs';

import { PresignedUrl, S3UploadedFile } from '../../models/fileS3.model';
import { JSend } from '../../models/jsend.model';

/**
 * File upload service to S3
 */
@Injectable({
  providedIn: 'root'
})
export class UploadS3Service {
  constructor(
    private http: HttpClient
  ) { }

  /**
   * Method that returns a number (n) of pre-signed urls for uploading files, 
   * along with the path and its generated key to identify the file to upload. 
   */
  private getPresignedUrls(url: string, fileCount: number): Observable<PresignedUrl[]> {
    return this.http.get<JSend<{ presigned_urls: PresignedUrl[] }>>(url, { params: { files: fileCount } }).pipe(
      map((response) => response.data.presigned_urls)
    );
  }

  /**
   * Method that takes care of uploading files to pre-signed urls
   */
  private uploadFiles(presignedUrls: PresignedUrl[], files: FileList | File[]): Observable<S3UploadedFile[]> {
    const uploadObservables = Array.from(files).map((file, index) => {
      const presignedUrl = presignedUrls[index];
  
      // Convert File to binary
      return this.readFileAsArrayBuffer(file).pipe(
        switchMap((arrayBuffer) => {
          // Set headers
          const headers = new HttpHeaders({
            'Content-Type': file.type
          });
          // Upload file
          return this.http.put(presignedUrl.url, arrayBuffer, { headers }).pipe(
            map(() => new S3UploadedFile({ file: presignedUrl.key, name: file.name }))
          );
        })
      );
    });
  
    return forkJoin(uploadObservables);
  }
  
  private readFileAsArrayBuffer(file: File): Observable<ArrayBuffer> {
    return new Observable((observer) => {
      if(!file){
        return observer.complete();
      }

      const reader = new FileReader();
  
      reader.onload = () => {
        observer.next(reader.result as ArrayBuffer);
        observer.complete();
      };
  
      reader.onerror = (error) => {
        observer.error(error);
      };
  
      reader.readAsArrayBuffer(file);
    });
  }

  /**
   * Upload files to S3
   */
  public upload(url: string, files: FileList | File[]): Observable<S3UploadedFile[]> {
    const validFiles = Array.from(files).filter(file => file);

    if(!validFiles.length) {
      return of([]);
    };

    return this.getPresignedUrls(url, validFiles.length).pipe(
      mergeMap(presignedUrls => this.uploadFiles(presignedUrls, validFiles))
    );
  }
}
