import { Type } from "class-transformer";

export class AttachmentFolder {
  /** Agree's internal unique identifier. */
  readonly id: number;
  account_id: number;

  @Type(() => Date)
  created_at: Date;

  name: string;
  entity: string;
  entity_id: string;
  external_application_id?: string;

  @Type(() => Attachment)
  files: Attachment[];
}

class Attachment {
  /** Agree's internal unique identifier. */
  readonly id: number;
  account_id: number;

  @Type(() => Date)
  created_at: Date;

  filename: string;
  type: string;
  size: number;

  private: boolean;
}