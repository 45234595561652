import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EditInPlaceComponent),
  multi: true
};

@Component({
  selector: 'edit-in-place',
  templateUrl: './edit-in-place.component.html',
  styleUrls: ['./edit-in-place.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class EditInPlaceComponent implements ControlValueAccessor {

  @ViewChild('element') private readonly inputElement: ElementRef;

  @Input() public label: string;
  @Input() public options: any[];
  @Input() public disabled: boolean;

  @Output() readonly onChange = new EventEmitter();

  public isEditing: boolean = false;
  public innerValue: any;
  public selectedId: number;

  constructor() { }

  public startEditing(): void {
    this.isEditing = true;
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    }, 0);
  }

  public endEditing(): void {
    setTimeout(() => {
      if (this.selectedId) {
        let value = this.options.find(option => option.id === this.selectedId);

        let a = {
          id: value.id,
          name: value.name
        }
        let b = {
          id: this.innerValue ? this.innerValue.id : undefined,
          name: this.innerValue ? this.innerValue.name : ''
        }
        if (JSON.stringify(a) !== JSON.stringify(b)) {
          this.value = value;
          this.onChange.emit();
        }
      }
    }, 0);
    this.isEditing = false;
  }

  private onTouchedCallback: () => void = () => { };
  private onChangeCallback: (_) => void = () => { };

  set value(v) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  get value(): void {
    return this.innerValue;
  };

  onBlur(): void {
    this.onTouchedCallback();
  }

  writeValue(value) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      if (value) {
        this.selectedId = value.id;
      }
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
