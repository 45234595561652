/**
 * Most primitive unit model, generally used as a template on which to extend
 * more complex or dedicated models.
 */
export class PrimitiveUnit {
  /** Agree's internal unique identifier. */
  readonly id: number | string;
  name: string;
  readonly slug?: string;

  constructor(data: Partial<PrimitiveUnit> = {}) {
    Object.assign(this, data);
  }
}

/** Volume unit. */
export class Unit extends PrimitiveUnit {
  readonly abbrev: string;
  readonly name_plural: string;

  constructor(data: Partial<Unit> = {}) {
    super(data);
  }
}