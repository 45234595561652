/**
 * Adds filters to a given URL based on the provided filters object.
 * If a queryMap is provided, existing keys in the filters object will be replaced with the ones defined in the queryMap.
 *
 * @param {string} url - The base URL to which filters will be added.
 * @param {Record<string, any>} [filters] - An object containing filter key-value pairs to be appended to the URL.
 * @param {Record<string, string>} [queryMap] - An optional object that maps original filter keys to new query parameter keys.
 * @param {string[]} [ignoreFilters] - An optional array of filter keys to be ignored while building the query string.
 * @returns {string} - The URL with appended filters as query parameters.
 */
export function buildFilters(
  url: string,
  filters?: Record<string, any>,
  queryMap?: Record<string, string>,
  ignoreFilters?: string[]
): string {
  if (!filters) return url;

  const params = Object.keys(filters)
    .filter(key => filters[key] && !ignoreFilters?.includes(key))
    .map(key => `${queryMap?.[key] ?? key}=${filters[key]}`);

  if (params.length > 0) {
    url += url.includes('?') ? '&' : '?';
    url += params.join('&');
  }

  return url;
}
