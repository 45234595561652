import { Directive, Input } from '@angular/core';
import { Validator, ValidatorFn, NG_VALIDATORS, UntypedFormGroup } from '@angular/forms';

export function MatchesValidator(firstKey: string, secondKey: string): ValidatorFn {

  return (group: UntypedFormGroup): { [key: string]: any } => {

    let firstControl = group.controls[firstKey];
    let secondControl = group.controls[secondKey];

    if (firstControl && secondControl) {
      let isValid = firstControl.value === secondControl.value;

      return firstControl.value && secondControl.value && !isValid ? {
        'matches': {
          'first': firstKey,
          'second': secondKey
        }
      } : null;
    } else {
      return null;
    }
  };
}

@Directive({
  selector: '[matches]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MatchesDirective, multi: true }]
})
export class MatchesDirective implements Validator {

  @Input('matches') private matches: string[];

  private validatorFn;

  constructor() { }

  validate(group: UntypedFormGroup): { [key: string]: any } {
    this.validatorFn = MatchesValidator(this.matches[0], this.matches[1]);
    return this.validatorFn(group);
  }
}
