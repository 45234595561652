import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { CommercialZone } from '../../../company/models/commercial-zone.model';
import { CommercialZonesService } from '../../../company/services/commercial-zones.service';
import { Company } from '../../../models/company.model';
import { EntityBinding } from '../../../models/entity-binding.model';
import { CompanyService } from '../../../services/company.service';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { SubscriptionManager } from '../../../utilities/subscription-manager';

@Component({
  selector: 'ag-commercial-zones-modal',
  templateUrl: './commercial-zones-modal.component.html',
  styleUrls: ['./commercial-zones-modal.component.scss']
})
export class CommercialZonesModalComponent extends SubscriptionManager implements OnDestroy {

  @ViewChild('modal', { static: true }) private readonly modal: TemplateRef<any>;

  @Input() public company: Company;

  public commercialZones: CommercialZone[];
  public dirty: boolean;
  public editingCompany: Company;
  public fiscal_id: string;
  public fiscal_id_mode: boolean;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
  */
  public processing: boolean;

  private _editingCompanies: { [fiscal_id: string]: Company };
  private modalRef: BsModalRef;
  private modalSub: Subscription;

  constructor(
    private commercialZonesService: CommercialZonesService,
    public companyService: CompanyService,
    private modalService: BsModalService,
    private dataDogLoggerService: DataDogLoggerService
  ) {
    super();
  }

  public open(company: Company = undefined): void {
    this.reset();

    if (company) {
      this.setCompany(company);
    }
    this.openModal(this.modal);
  }

  public reset(): void {
    this.fiscal_id = undefined;
    this.editingCompany = undefined;
  }

  public onChangeFiscalId(event: NgModel): void {
    if (event.valid) {
      if (this._editingCompanies && this._editingCompanies[this.fiscal_id]) this.editingCompany = this._editingCompanies[this.fiscal_id];
      else {
        this.fiscalIdSearch(this.fiscal_id);
      }
    } else {
      this.editingCompany = undefined;
    }
  }

  private fiscalIdSearch(fiscalId: string): void {
    this.processing = true;

    this.subscriptions.push(this.companyService.getCompaniesByFiscalValue(this.company.id, this.company.market.country_id, fiscalId).subscribe(companies => {
      this.processing = false;
      this.setCompany(companies[0]);
    }));
  }

  public setCompany(company: Company): void {
    if (company.commercial_zones) {
      this._editingCompanies = { ...(this._editingCompanies || {}), [company.fiscal_id.value]: company };

      this.editingCompany = this._editingCompanies[company.fiscal_id.value] || null;
      this.dirty = false;

      this.getCommercialZones();
    } else {
      this.fiscalIdSearch(company.fiscal_id.value);
    }
  }

  private getCommercialZones(force?: boolean): void {
    if (!this.commercialZones || force) {
      this.processing = true;

      this.subscriptions.push(this.commercialZonesService.watch(this.company.id).subscribe(commercialZones => {
        if (!commercialZones) return;

        this.commercialZones = commercialZones;
        this.processing = false;
      }));
    }
  }

  /**
   * Adds and/or removes [[CommercialZone|Commercial zone]] to current
   * selection.
   */
  public applyChanges(): void {
    let payload: EntityBinding[] = [];

    this.commercialZones.forEach(zone => {
      const existing = this.editingCompany.commercial_zones.find(commercialZone => commercialZone.id == zone.id);
      let itemLabel: EntityBinding = new EntityBinding({
        id: zone.id,
        value: Boolean(existing),
        entity: {
          id: this.editingCompany.id,
          type: this.editingCompany.entity
        }
      });

      payload.push(itemLabel);
    });

    if (payload.length) {
      this.processing = true;

      this.subscriptions.push(this.commercialZonesService.bind(this.company.id, payload).subscribe({
        next: response => {
          // Once applied close dropdown
          this.closeModal();
          this.processing = false;
        },
        error: error => {
          // Once applied close dropdown
          this.closeModal();
          this.processing = false;
          this.dataDogLoggerService.warn(error.message, error.error);
        }
      }));
    } else this.closeModal();
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.closeModal();
    super.ngOnDestroy();
  }
}
