import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  /**
   * Replaces all occurrences of a specified substring with a replacement string in the given value.
   * 
   * @param {string} value - The input string in which to perform the replacement.
   * @param {string} strToReplace - The substring to replace.
   * @param {string} replacementStr - The string to replace the substring with.
   * @returns {string} - The transformed string with all occurrences of the substring replaced.
   */
  transform(value: string, strToReplace: string, replacementStr: string): string {
    if (!value || !strToReplace || !replacementStr) {
      return value;
    }

    return value.replace(new RegExp(this.escapeStr(strToReplace), 'g'), replacementStr);
  }

  /**
   * Escapes special characters in a string for use in a regular expression.
   * 
   * @param {string} str - The string to escape.
   * @returns {string} - The escaped string.
   * @private
   */
  private escapeStr(str: string): string {
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  }
}
