<ng-container>
  <div class="input-group">
    <input type="number" [placeholder]="placeholder"
      class="form-control" name="volumeValue"
      [(ngModel)]="quantity.value" [required]="required" number
      [min]="minValue" [max]="maxValue" autocomplete="off"
      [disabled]="disabled">

      <div dropdown agSelect name="quantityUnit"
      class="input-group-btn"
      [(ngModel)]="quantity.unit"
      [isDisabled]="disabled"
      [class.single-unit]="company.market.quantity_units.length <= 1">
        <button dropdownToggle type="button" class="btn btn-default dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
          {{ quantity?.unit.name_plural ||
            quantity?.unit.abbrev ||
            quantity?.unit.name }}
          <span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
          <li *ngFor="let u of company.market.quantity_units" [agOption]="u">
            <a>{{ u.name_plural }}</a>
          </li>
        </ul>
      </div>

  </div>
</ng-container>

