<div *ngIf="comments" class="messages">
    <div *ngFor="let comment of comments; let i = index" class="m-row">
        <!-- Message -->
        <div class="m-bubble" 
            *ngIf="comment.reason"
            [class.in]="comment.company?.id !== company.id" 
            [class.out]="comment.company?.id === company.id"
            [class.same-user]="i > 0 && (comment.user.id === comments[i - 1].user.id && comment.company.id === company.id)"
            >
            <!-- Normal bubble -->
            <ng-container *ngIf="!compact; else compactBubble">
                <div class="m-text">
                    <!-- User -->
                    <div class="m-user">
                        <span
                        [class.highlighted-text]="comment.user?.id === user.id">{{ comment.user.name }} {{ comment.user.last_name }}</span>
                        <small *ngIf="comment.company && comment.company.id !== company.id" class="lighten text-muted">
                            - {{ comment.company.name}}
                        </small>
                    </div>
        
                    <!-- Reason -->
                    <span>{{ comment.reason }}</span>
        
                    <!-- Date -->
                    <div class="m-time">
                        <ag-timestamp [date]="comment.created_at"></ag-timestamp>
                    </div>
                </div>
            </ng-container>

            <!-- Compact Bubble -->
            <ng-template #compactBubble>
                <div class="m-text m-text--compact">
                    <div class="m-text__head">  
                        <!-- User -->
                        <div class="m-user">
                            {{ comment.user.name }} {{ comment.user.last_name }}
                            <small *ngIf="comment.company && comment.company.id !== company.id" class="lighten text-muted">
                                - {{ comment.company?.name}}
                            </small>
                        </div>
                
                        <!-- Date -->
                        <div class="m-time">
                            <ag-timestamp [inline]="true" [date]="comment.created_at"></ag-timestamp>
                        </div>
                    </div>
                
                    <div class="m-text__body">
                        <!-- Reason -->
                        <span>{{ comment.reason }}</span>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

