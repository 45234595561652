import { Directive, Input, Output, EventEmitter, ElementRef, AfterContentInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ClipboardService } from "ngx-clipboard";

/**
 * Adds a button next to the element to copy the text
 * 
 * ## Usage
 * ``` html
 * <span [agClipboard]>Algun texto...</span>
 * ```
 * In the case of a link, the attribute [link]="true" is added.
 *  ``` html
 * <span [agClipboard]='contracts/23123' [link]="true">Algun texto...</span>
 * result: http://localhost:4200/contracts/23123
 * ```
 * 
 */
@Directive({
  selector: '[agClipboard]',
})
export class ClipboardDirective implements AfterContentInit {

  @Input('agClipboard') public payload!: string;
  @Input('link') public link: boolean;

  @Output() public readonly copied: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private el: ElementRef,
    private clipboardService: ClipboardService,
    private translateService: TranslateService
  ) { }

  public onClick(event: MouseEvent): void {
    let textToCopy = this.payload;

    if (!textToCopy) textToCopy = this.el.nativeElement.textContent;

    if (this.link) textToCopy = window.location.origin + textToCopy;

    this.clipboardService.copy(textToCopy);
    this.copied.emit();

    event.preventDefault();
  }

  ngAfterContentInit(): void {
    // HTML/CSS Config
    const clipboardEl = document.createElement('a')
    clipboardEl.classList.add('material-symbols-rounded');
    clipboardEl.style.fontSize = '14px';
    clipboardEl.style.lineHeight = '1.5';
    clipboardEl.style.marginLeft = '7px';
    clipboardEl.style.color = '#285f8f';
    clipboardEl.innerHTML = 'content_copy';
    clipboardEl.title = this.translateService.instant('GLOBAL.COPY_CLIPBOARD');
    clipboardEl.addEventListener('click', (e) => this.onClick(e))

    // Get the parent element
    let parentElement = this.el.nativeElement.parentNode

    // Insert the new element
    parentElement.appendChild(clipboardEl)
  }
}
