import { Component, Input, OnDestroy } from '@angular/core';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';

import { endOfDay, startOfDay } from '../../../utilities/date';
import { SubscriptionManager } from '../../../utilities/subscription-manager';
import { FilterSetComponent } from '../filter-set/filter-set.component';

@Component({
  selector: 'filter-date-range',
  templateUrl: './filter-date-range.component.html',
  styleUrls: ['./filter-date-range.component.scss']
})
export class FilterDateRangeComponent extends SubscriptionManager implements OnDestroy {

  @Input() private key: string;
  @Input() public label: string = 'FILTERS.SELECT_RANGE';
  @Input() public placement: 'top' | 'bottom' | 'left' | 'right' = 'right';
  @Input() public container: string = '.datepicker-container';
  @Input() public datePickerConfig: Partial<BsDaterangepickerConfig>

  public hide: boolean = true;
  public loaded: boolean = false;
  public title: string;
  public bsConfig: Partial<BsDaterangepickerConfig>;

  private _initialBsConfig = {
    containerClass: 'theme-green',
    dateInputFormat: "DD/MM/YYYY",
    selectWeek: true,
    adaptivePosition: true
  };

  constructor(
    private filterset: FilterSetComponent
  ) {
    super();

    this.subscriptions.push(
      this.filterset.filters.subscribe(res => {
        this.title = this.filterset.filterList[this.key].label;

        if (res.find(element => element.key === this.key)) {
          this.hide = false;
        } else {
          this.loaded = true;
          this.hide = true;
        }
      })
    );

    this.filterset.countSuscriberChilds();
  }

  ngOnInit(): void {
    this.bsConfig = { ...this.datePickerConfig, ...this._initialBsConfig };
  }

  public selectDateRange(dates: Date[]): void {
    const start = startOfDay(new Date(dates[0])).toISOString();
    const end = endOfDay(new Date(dates[1])).toISOString();

    this.filterset.showFilters();
    this.filterset.addFilterData(this.key, `range:${start},${end}`);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy()
  }
}
