<ng-template #modalTemplate>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'VOLUME.CALCULATOR'|translate }}</h4>
  </div>
  <div class="modal-body">
    <form id="calc-form" #calcForm="ngForm">
      <div class="row">
        <div class="col-xs-6">
          <div class="form-group">
            <label class="control-label">{{ 'VOLUME.AMOUNT'|translate }}</label>
            <div class="input-group">
              <span class="input-group-addon">{{ data.amount.unit?.name || '$' }}</span>
              <input type="number" placeholder="0" step="0.01" class="form-control" name="amount"
              [modalFocus]="!readonly && !(data.amount.value > 0)"
              [(ngModel)]="data.amount.value"
              (ngModelChange)="calculate(calcForm)"
              required
              [readonly]="readonly"
              [min]="0.01"
              [max]="9999999999">
            </div>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="form-group">
            <label class="control-label">{{ 'VOLUME.PRICE'|translate }}
              <span class="currency">/{{ business_detail.quantity.unit.abbrev }}</span>
            </label>
            <div class="input-group">
              <span class="input-group-addon">{{ data.amount.unit?.name || '$' }}</span>
              <input type="number" placeholder="0" step="0.01" class="form-control" name="price"
              [modalFocus]="!readonly && data.amount.value > 0 && !(data.price > 0)"
              [(ngModel)]="data.price"
              (ngModelChange)="calculate(calcForm)"
              required
              [readonly]="readonly"
              [min]="0.01"
              [max]="9999999999">
            </div>
            <p class="help-block"
            *ngIf="data.price > 0 && data.vat">IVA ({{ data.vat|number }}%):
              <price-display [price]="{
                type: 'flat',
                unit: data.amount.unit,
                value: data.vat * (data.price / 100)
              }"></price-display>
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-6">
          <div class="form-group">
            <label class="control-label">{{ 'VOLUME.ADDITIONAL'|translate }}
              <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
            </label>
            <div class="input-group">
              <span class="input-group-addon">{{ data.amount.unit?.name || '$' }}</span>
              <input type="number" placeholder="0" step="0.01" class="form-control" name="additionals"
              [(ngModel)]="data.additionals"
              (ngModelChange)="calculate(calcForm)"
              [readonly]="readonly"
              [min]="0"
              [max]="9999999999">
            </div>
            <p class="help-block">{{ 'VOLUME.ADDITIONAL_HELP'|translate }}</p>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="form-group">
            <label class="control-label">{{ 'VOLUME.WARRANTY'|translate }}
              <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
            </label>
            <div class="input-group">
              <input type="number" placeholder="0" step="0.01" class="form-control" name="warranty"
              [(ngModel)]="data.warranty"
              (ngModelChange)="calculate(calcForm)"
              [readonly]="readonly"
              [min]="0"
              [max]="100">
              <span class="input-group-addon">%</span>
            </div>
          </div>
        </div>
      </div>
    </form>
    <table *ngIf="calcForm.valid"
    class="table no-margin">
      <tbody>
        <tr *ngIf="data.vat > 0" class="small">
          <th>{{ 'GLOBAL.PRICE'|translate }} /{{ business_detail.quantity.unit.abbrev }} ({{ 'VOLUME.VAT_INCLUDED'|translate }})</th>
          <td class="text-right">
            <price-display [price]="{
              type: 'flat',
              unit: data.amount.unit,
              value: priceWithVat
            }"></price-display>
          </td>
        </tr>
        <tr class="small">
          <th>{{ 'VOLUME.TOTAL_AMOUNT'|translate }}</th>
          <td class="text-right">
            <price-display [price]="{
              type: 'flat',
              unit: data.amount.unit,
              value: total
            }"></price-display>
          </td>
        </tr>
        <tr [class.bigger]="!(data.warranty > 0)">
          <th>{{ 'VOLUME.NEEDED'|translate }}</th>
          <td class="text-right">
            {{ calculatedQuantity|number }} <span class="currency">{{ business_detail.quantity.unit.abbrev }}</span>
          </td>
        </tr>
        <tr *ngIf="data.warranty > 0" class="bigger">
          <th>{{ 'VOLUME.NEEDED_WARRANTY'|translate }}</th>
          <td class="text-right">
            {{ suggested|number }} <span class="currency">{{ business_detail.quantity.unit.abbrev }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer" *ngIf="!readonly">
    <button type="button" (click)="closeModal()" class="btn btn-link" data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
    <button type="button" form="calc-form" class="btn btn-agree" [disabled]="!calcForm.valid"
    (click)="done()">{{ 'VOLUME.APPLY'|translate }}</button>
  </div>
</ng-template>