import { Type } from "class-transformer";
import { Account } from "../../auth/models/account.model";
import { Company } from "../../models/company.model";

export class DoA {
  /** Agree's internal unique identifier. */
  readonly id: string;

  @Type(() => Date)
  readonly createdAt: Date;

  readonly status: 'APPROVED' | 'DISAPPROVED' | 'RETIRED' | 'PENDING';

  readonly company: Company;

  @Type(() => Date)
  dueDate?: Date;

  @Type(() => Account)
  approvers: Account[];

  @Type(() => ApproverDecision)
  readonly decisions: ApproverDecision[];

  ordered: boolean;

  // @Type(() => Account)
  // readonly account: Account;

  entity: {
    id: number | string;
    /** Bindeable entities must have an entity() get method. */
    type: string;
  };

  name?: string;

  public decision(accountId: number): ApproverDecision {
    return this.decisions.find(decision => decision.accountId === accountId);
  }

  public getApprovers(): any {
    const approversData = this.decisions.map(desicion => {
      return {
        user: { ...this.approvers.find(approver => approver.id === desicion.accountId).user },
        ...desicion
      }
    });

    return approversData;
  }

  public getApproversPositions(): any {
    if (this.status === 'PENDING' && this.ordered) {
      const desicion = this.decisions.find(desicion => desicion.vote === 'PENDING');

      if (!desicion) return false;

      const positions = {
        prev: { approver: null, position: 'prev' },
        current: { approver: null, position: 'current' },
        next: { approver: null, position: 'next' },
      };

      // Current approver
      positions.current.approver = this.approvers.find(approver => approver.id === desicion.accountId);

      // Previous approver
      this.approvers.map((approver, index) => {
        if (approver.id === desicion.accountId) { //current
          return positions.prev.approver = this.approvers[index - 1];
        }
      });

      // Next approver
      this.approvers.map((approver, index) => {
        if (approver.id === desicion.accountId) { //current
          return positions.next.approver = this.approvers[index + 1];
        }
      });

      return positions;
    }
  }

  constructor(data: Partial<DoA> = {}) {
    Object.assign(this, data);
  }
}

export class ApproverDecision {
  readonly accountId: number;

  @Type(() => Date)
  readonly createdAt: Date;

  @Type(() => Date)
  readonly votedAt?: Date;

  vote: 'APPROVED' | 'DISAPPROVED' | 'ABSTENTION' | 'PENDING';

  comment?: string;
}
