<ng-template #modal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">
      {{ title }}
    </h4>
  </div>
  <div class="modal-body">
    <tabset *ngIf="html">
      <tab heading="{{ 'FILE_INPUT.PREVIEW'|translate }}">
        <div [innerHtml]="html"></div>
      </tab>
      <tab heading="Source">
        <div class="text-right">
          <label
          class="btn btn-link btn-sm"
          title="{{ 'GLOBAL.COPY_CLIPBOARD'|translate }}"
          (click)="htmlClipboardService.copy('pre#html')">
            <!-- <span class="material-symbols-rounded">content_copy</span> -->
            {{ 'GLOBAL.COPY_CLIPBOARD'|translate }}
          </label>
        </div>
        <pre id="html" class="no-margin">{{ htmlString }}</pre>
      </tab>
      <!-- <tab heading="Source" #tabSource="tab">
        <div class="well well-sm no-margin"
        *ngIf="tabSource.active">
          <ngx-json-viewer [json]="html" [depth]="2"></ngx-json-viewer>
        </div>
      </tab> -->
    </tabset>
  </div>
</ng-template>