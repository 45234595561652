import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { Company } from '../../../models/company.model';
import { EntityBinding } from '../../../models/entity-binding.model';
import { Envelope } from '../../../models/envelope.model';
import { SignaturesService } from '../../../services/signatures.service';
import { EnvelopesModalsComponent } from '../envelopes-modals/envelopes-modals.component';

/**
 * Component that allows you to attach and display [[Envelope|Envelopes]] to an
 * entity.
 * 
 * ## Usage
 * ``` html
 * <ag-related-envelopes
 * entity="negotiation"
 * [entity-id]="negotiation.id"
 * [company]="company"></ag-related-envelopes>
 * ```
 */
@Component({
  selector: 'ag-related-envelopes',
  templateUrl: './related-envelopes.component.html',
  styleUrls: ['./related-envelopes.component.scss']
})
export class RelatedEnvelopesComponent implements OnInit, OnDestroy {

  @ViewChild('envelopesModals', { static: true }) private readonly envelopesModals: EnvelopesModalsComponent;

  @Input() public company: Company;
  /**
   * Entity type. Eg: negotiation, invoice, etc.
   * 
   * Either this attribute or [[external_id|external-id]] is required.
   */
  @Input() public entity: string;
  /**
   * Entity unique identifier.
   * 
   * This attribute in combination with the [[entity|entity type]] is the
   * unique reference to the virtual file folder.
   * 
   * This attribute is required.
   */
  @Input('entity-id') public entity_id: number;
  /**
   * External entity unique identifier.
   * 
   * If this attribute is set the [[entity|entity type]] will be "external".
   * 
   * Either this attribute or [[entity|entity type]] is required.
   */
  @Input('external-id') public external_id: number;
  /**
   * With compact true only the button to create an envelope is displayed.
   */
  @Input() public compact: boolean = false;
  @Input() public canCreate: boolean = true;
  /** It specifies that the component should be disabled. */
  @Input() public set disabled(flag: boolean) {
    this._disabled = flag;
  };
  public get disabled(): boolean {
    return (this._disabled || this.processing);
  }

  /** Flag used to indicate if the component is loading information. */
  public loading: boolean;
  public envelopes: Envelope[];

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  private processing: boolean;
  private _disabled: boolean;
  private pusherSubscription: Subscription;

  constructor(
    private signaturesService: SignaturesService
  ) { }

  ngOnInit(): void {
    if (!this.compact) {
      this.loadData();
    }
  }

  private loadData(): void {
    this.loading = true;

    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();

    this.pusherSubscription = this.signaturesService.watch(this.company.id, {
      entity: this.entity,
      entity_id: this.entity_id
    }).subscribe(response => {
      if (!response) return;

      // this.paginationData = parsePagination(response.headers);
      this.dataLoaded(response.body);
    });
  }

  private dataLoaded(envelopes: Envelope[]): void {
    this.envelopes = envelopes;
    this.loading = false;
  }

  public create(): void {
    const bindingData = new EntityBinding({
      value: true,
      entity: {
        id: this.entity_id,
        type: this.entity
      }
    });
    this.envelopesModals.create([bindingData]);
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    if (this.pusherSubscription) this.pusherSubscription.unsubscribe();
  }
}
