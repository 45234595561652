import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'batch-finder',
  templateUrl: './batch-finder.component.html',
  styleUrls: ['./batch-finder.component.scss']
})
export class BatchFinderComponent implements OnInit {

  @Output() onChange = new EventEmitter();

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public batches: any[];
  public related_batches: any[];

  private _batch: any;

  public set batch(v: any) {
    this._batch = v;

    if (v) {
      if (v.level === 4) {
        this.setValue(v);
      } else this.getRelatedBatches();
    } else {
      this.related_batches = undefined;
      this.setValue(undefined);
    }
  }

  public get batch(): any {
    return this._batch;
  }

  constructor() { }

  ngOnInit(): void {
    this.batches = this.genFakeData(50);
  }

  private genFakeData(min: number, max?: number, forced_level?: number): any[] {
    let
      farmer = { "id": 30, "is_new": false, "name": "Los Ombúes", "logo": "https:\/\/agreemarket-fas-dev.s3.us-east-2.amazonaws.com\/fas\/companies\/5ba7e7ccafa9c\/932832_2018-09-23.jpeg", "fiscal_id": { "type": "cuit", "value": "20123456780" }, "activity": { "id": 1, "name": "Productor" }, "verification_date": "2018-09-24 12:08:55", "approved": true, "address": { "country": { "id": 10, "iso": "ar", "name": "Argentina" } } },
      exporter = { "id": 31, "is_new": false, "name": "South Grains Corp.", "logo": "https:\/\/agreemarket-fas-dev.s3.us-east-2.amazonaws.com\/fas\/companies\/5ba7e897984ca\/372420_2018-09-23.png", "fiscal_id": { "type": "cuit", "value": "20123456780" }, "activity": { "id": 3, "name": "Exportador" }, "verification_date": "2018-09-24 12:09:03", "approved": true, "address": { "country": { "id": 10, "iso": "ar", "name": "Argentina" } } },
      grain_elevator = { "id": 38, "is_new": false, "name": "Silos Norte S.A.", "logo": "https:\/\/agreemarket-fas-dev.s3.us-east-2.amazonaws.com\/fas\/default-thumbs\/acop-05.png", "fiscal_id": { "type": "cuit", "value": "20123456780" }, "activity": { "id": 2, "name": "Acopiador / Consignatario" }, "verification_date": "2018-11-08 18:28:49", "approved": true, "address": { "country": { "id": 10, "iso": "ar", "name": "Argentina" } } },
      port = { "id": 61, "is_new": false, "name": "Gestión de Puerto Quequén", "logo": "https:\/\/agreemarket-fas-dev.s3.us-east-2.amazonaws.com\/fas\/default-thumbs\/prod-05.png", "fiscal_id": { "type": "cuit", "value": "20123456780" }, "activity": { "id": 7, "name": "Puerto" }, "verification_date": "2019-01-30 20:49:04", "approved": true, "address": { "country": { "id": 10, "iso": "ar", "name": "Argentina" } } },
      shipper = { "id": 39, "is_new": false, "name": "Antares International S.A.", "logo": "https:\/\/agreemarket-fas-dev.s3.us-east-2.amazonaws.com\/fas\/default-thumbs\/prod-02.png", "fiscal_id": { "type": "cuit", "value": "20123456780" }, "activity": { "id": 1, "name": "Naviera" }, "verification_date": "2018-11-08 18:28:44", "approved": true, "address": { "country": { "id": 10, "iso": "ar", "name": "Argentina" } } },
      fakeFarmersNames = ['Las Acacias', 'Los Ombúes', 'Gutierrez, Fabio', 'Estancia Los Candiles', 'Romero Agro S.A.', 'Campo Austral', 'V&L Campo SRL', 'Forlan, Roberto S.A.'],
      level_prefix = [
        'SH',
        'PO',
        'SI',
        'AC',
        'LO'
      ],
      variedad = ['AL', 'AN', 'DA', 'JE', 'SC', 'SH', 'TR', 'OV'],
      rnd = function (min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
      },
      genBatch = function (lvl?: number) {
        let year = rnd(15, 19),
          crop = year + '/' + (year + 1),
          level = lvl || rnd(0, 4),
          response = {
            date: new Date(year + 1, rnd(0, 11), rnd(1, 29)),
            id: rnd(1, 99999999),
            name: level_prefix[level] + "-" + year + (year + 1) + variedad[rnd(0, variedad.length - 1)] + "-" + rnd(1000, 9999),
            level: level,
            crop: crop,
            product: {
              name: 'Cebada Cervecera'
            },
            company: null,
          };

        if (level === 4) {
          response.company = JSON.parse(JSON.stringify(farmer));
          response.company.name = fakeFarmersNames[rnd(0, fakeFarmersNames.length - 1)];
        } else if (level === 3) {
          response.company = JSON.parse(JSON.stringify(grain_elevator));
        } else if (level === 2) {
          response.company = JSON.parse(JSON.stringify(exporter));
        } else if (level === 1) {
          response.company = JSON.parse(JSON.stringify(port));
        } else if (level === 0) {
          response.company = JSON.parse(JSON.stringify(shipper));
        }

        return response;
      },
      genMultiple = function (cant, func, level?: number) {
        let response = [];
        for (let i = 0; i < cant; i++) {
          response.push(func(level));
        }
        return response;
      };

    return genMultiple(rnd(min, (max || min)), genBatch, forced_level);
  }

  public getRelatedBatches(): void {
    let min = 30 / (this.batch.level + 1);
    this.related_batches = this.genFakeData(min, min * 2, 4);
  }

  public setValue(v: any): void {
    this.onChange.emit(v);
  }
}
