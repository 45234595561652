import { Component, Input } from '@angular/core';

@Component({
  selector: 'ag-expandable-content',
  templateUrl: './expandable-content.component.html',
  styleUrls: ['./expandable-content.component.scss']
})
export class ExpandableContentComponent {

  @Input('line-height') public line_height: number = 1.428571429;
  @Input('lines-to-show') public lines_to_show: number = 6;

  public expanded: boolean;

  constructor() { }
}
