import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';
import Theme from '../models/theme.model';
import themes from '../themes';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private _theme$: BehaviorSubject<Theme> = new BehaviorSubject<Theme>(null);
  private _defaultTheme: Theme = themes['agree'];
  private _themes = themes;

  constructor() {
    const hostname = window.location.hostname;
    const theme = this.searchTheme(hostname);

    if (theme) {
      this._theme$.next(theme);
    } else {
      this._theme$.next(this._defaultTheme);
    }
  }

  get theme(): Theme {
    return this._theme$.getValue();
  }

  get themes(): Theme[] {
    const themes = [];
    Object.keys(this._themes).forEach(themeKey => themes.push(this._themes[themeKey]));

    return themes;
  }

  public applyTheme(): void {
    const theme = this.theme;

    if (!window.document.body.classList.contains(theme.cssClass)) {
      window.document.body.classList.add(theme.cssClass)
    }

    this.injectFavicons(theme);
  }

  // public injectFonts(theme): void {
  // }

  private injectFavicons(theme: Theme): void {
    const head = document.querySelector('head');

    for (const favicon of theme.favicons) {
      const newFavicon = document.createElement('link');

      newFavicon.rel = favicon.rel ?? 'icon';
      newFavicon.type = favicon.type;
      newFavicon.href = favicon.href;

      if (favicon.sizes) {
        newFavicon.setAttribute('sizes', favicon.sizes);
      }

      head.appendChild(newFavicon);
    }
  }

  private searchTheme(hostname: string): Theme {
    const matchingTheme = Object.keys(themes)
      .map(key => themes[key])
      .find(theme => theme.hostnames && theme.hostnames[environment.env] === hostname);

    return matchingTheme || null;
  }
}
