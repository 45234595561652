import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CompanyCertificate } from '../../models/company-certificate.model';
import { constructFormData } from '../../utilities/construct-form-data';

@Injectable()
export class CertificatesService {

  private certificatesUrl = '/:apiBase/companies/:companyId/certificates';
  private certificateUrl = this.certificatesUrl + '/:certificateId';

  constructor(
    private http: HttpClient
  ) { }

  public get(companyId: number): Observable<CompanyCertificate[]> {
    const url = this.certificatesUrl.replace(':companyId', companyId + '');
    const stream = this.http.get<CompanyCertificate[]>(url);

    return stream.pipe(map(company_certificates => {
      return plainToInstance(CompanyCertificate, company_certificates);
    }));
  }

  public create(companyId: number, company_certificate: CompanyCertificate): Observable<CompanyCertificate> {
    const url = this.certificatesUrl.replace(':companyId', companyId + '');
    const form_data = constructFormData(company_certificate);

    return this.http.post<CompanyCertificate>(url, form_data);
  }

  public delete(companyId: number, companyCertificateId: number): Observable<any> {
    const url = this.certificateUrl
      .replace(':companyId', companyId + '')
      .replace(':certificateId', companyCertificateId + '');

    return this.http.delete(url);
  }

  public getTypes(): Observable<any> {
    return this.http.get('/:apiBase/certificate-types');
  }

  public getEntities(): Observable<any> {
    return this.http.get('/:apiBase/certificate-entities');
  }

  public getByType(typeId: number): Observable<any> {
    const url = '/:apiBase/certificate-types/:typeId/certificates';

    return this.http.get(url.replace(':typeId', typeId + ''));
  }
}
