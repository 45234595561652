import { HttpClient } from '@angular/common/http';
import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

import { DataDogLoggerService } from '../../services/data-dog-logger.service';

@Directive({
  selector: '[assetInclude]'
})
export class AssetIncludeDirective implements OnChanges {

  @Input('assetInclude') private url: string;

  private attempts: number;
  private extension: string;

  constructor(
    private http: HttpClient,
    private elementRef: ElementRef,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    const index = this.url.lastIndexOf('.');
    this.extension = this.url.slice(index);
    this.attempts = 0;
    this.loadAsset(this.url);
  }

  private loadAsset(url: string): void {
    if (url && this.attempts < 3) {
      this.attempts++;
      this.http.get(url, {
        responseType: 'text'
      }).subscribe({
        next: content => {
          this.elementRef.nativeElement.innerHTML = content;
          // Scroll container to the top
          this.elementRef.nativeElement.scrollTop = 0;
        },
        error: error => {
          this.elementRef.nativeElement.innerHTML = "";

          // Retry
          const index = url.lastIndexOf('-');
          this.loadAsset(url.slice(0, index) + this.extension);
          this.dataDogLoggerService.warn(error.message, error.error);
        }
      });
    }
  }

  private retry(): void {

  }
}
