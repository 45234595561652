import { Type } from "class-transformer";
import { Company } from "../../../../models/company.model";
import { Product } from "../../../../models/product.model";
import { UnloadLocation } from "./unload-location.model";

/**
 * It is a declarative document that proves the existence of a transport
 * [[Contract|Contract]]. In some countries it is mandatory for the transport
 * of [[Product|Products]].
 *
 * This is Agree's digital representation of that document.
 *
 * ### Related UI components:
 * - [[TrucksTableComponent]]
 * @deprecated
 */
export class Waybill {
  /** Agree's internal unique identifier. */
  readonly id: number; // Internal ID

  /**
   * Source channel used to import.
   *
   * | ID | Channel        |
   * |---:|----------------|
   * |  1 | API            |
   * |  2 | Imported files |
   * |  3 | UI             |
   * |  4 | AFIP WS        |
   * |  5 | File           |
   */
  readonly import_channel_id: number;

  /** Date of issue */
  @Type(() => Date)
  date: Date; // Fecha emision - XLS > Columna D
  waybill_number: number; // Nro. de la carta de porte - XLS > Columna B
  /**
   * Numerical code issued by the Argentine state that must be consigned in
   * each Waybill that covers the transfer of grains. It is obtained prior to
   * transportation and must be linked to a valid Waybill.
   *
   * Maximum characters: 12.
   */
  ctg?: number;

  /** Maximum characters: 12. */
  renspa?: string;

  // 1 - INTERVINIENTES

  /** All the [[Company|Companies]] involved in this Waybill. */
  actors: {
    holder?: Company, // Mapeo de XLS > Columnas L y M
    intermediary?: Company, // Mapeo de XLS > Columnas N y O
    commercial_sender?: Company, // Mapeo de XLS > Columnas P y Q
    buying_broker?: Company, // Mapeo de XLS > Columnas T y U (o del corredor que lo carga?)
    term_market?: Company,
    selling_broker?: Company,
    representative?: Company, // Mapeo de XLS > Columnas AT y AU
    receiver?: Company,
    destination?: Company, // Mapeo de XLS > Columnas V y W
    carrier_broker?: Company,
    carrier?: Company,
    driver?: Company
  };

  // 2 - GRANOS / ESPECIES

  product: Product; // Mapeo de XLS > Columna F

  /** Maximum characters: 8. */
  product_type?: string;

  /** Maximum characters: 8. */
  crop?: string;

  /**
   * Maximum characters: 8.
   * @deprecated
   */
  contract_reference?: string;

  weighed_destination: boolean;

  cargo: number;

  /** Maximum characters: 78. */
  observations?: string;

  @Type(() => UnloadLocation)
  origin?: UnloadLocation;

  // 3 - DESTINO

  @Type(() => UnloadLocation)
  destination?: UnloadLocation;

  // 4 - DATOS DEL TRANSPORTE

  /** Discounts applied to cargo. */
  wastage: {
    humidity: {
      percentaje: number; // Porcentaje - XLS > Columna AD
      net: number; // Kilos - XLS > Columna AE
    };
    weed: {
      percentaje: number; // Porcentaje - XLS > Columna AF
      net: number; // Kilos - XLS > Columna AG
    };
    foreign_bodies: {
      percentaje: number; // Porcentaje - XLS > Columna AH
      net: number; // Kilos - XLS > Columna AI
    };
    damaged: {
      percentaje: number; // Porcentaje - XLS > Columna AJ
      net: number; // Kilos - XLS > Columna AK
    };
    other: {
      net: number; // Kilos - XLS > Columna AL
    };
    total: {
      net: number; // Kilos - XLS > Columna AM
    };
    balance: {
      net: number; // Kilos - XLS > Columna AN
    }
  };

  vehicle: {
    type?: string; // Puesto - XLS > Columna AO

    /** Maximum characters: 5. */
    license?: string; // Puesto - XLS > Columna AP

    /** Maximum characters: 5. */
    trailer?: string;

    /** Maximum characters: 28. */
    payer?: string;

    /** Maximum characters: 7. */
    km?: number;
  };

  freight: {
    /** Maximum characters: 5. */
    fee?: number;

    /** Maximum characters: 5. */
    reference?: number;

    /** Maximum characters: 7. */
    payed?: string;

    /** Maximum characters: 7. */
    pending?: string;
  }

  /** @ignore */
  constructor(data: Partial<Waybill> = {}) {
    this.product = new Product();
    this.origin = new UnloadLocation();
    this.destination = new UnloadLocation();

    this.actors = {
      holder: undefined,
      intermediary: undefined,
      commercial_sender: undefined,
      buying_broker: undefined,
      term_market: undefined,
      selling_broker: undefined,
      representative: undefined,
      receiver: undefined,
      destination: undefined,
      carrier_broker: undefined,
      carrier: undefined,
      driver: undefined
    };

    this.vehicle = {};
    this.freight = {};

    Object.assign(this, data);
  }
}
