import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class RouteSpyService {

  constructor(
    private activatedRoute: ActivatedRoute
  ) {
    /*
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        this.checkRoutes();
      }
    });
     */
  }

  getSnapshot(segment: string) {
    let snapshot = this.activatedRoute.snapshot;
    let path = "";

    while (snapshot) {

      path += (snapshot.routeConfig ? "/" + snapshot.routeConfig.path : "");

      if (path === segment) {
        break;
      }

      snapshot = snapshot.firstChild;
    }
    return snapshot;
  }
}
