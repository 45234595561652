import { Market } from "./market.model";

export class MarketType {
  id: number;
  name: string;
  logo: string;
  description: string;
  subdescription: string;
  sort: number;
}

export class GroupedMarkets {
  key: MarketType;
  values: Market[];
}