import { Directive, HostBinding } from '@angular/core';

import { AgContainerDirective } from './ag-container.directive';

@Directive({
  selector: '.ag-col-primary,.ag-col-secondary'
})
export class AgColumnDirective {

  @HostBinding('class.ag-col-expanded')
  private isExpanded = false;

  constructor(
    private agCointainer: AgContainerDirective
  ) { }

  expand(isExpanded) {
    this.isExpanded = isExpanded;
    this.agCointainer.childExpanded(this.isExpanded);
  }
}