import { Component, EventEmitter, Input, Output, SkipSelf } from '@angular/core';
import { ControlContainer } from '@angular/forms';

import { Currency } from '../../../models/currency.model';
import { Price } from '../../../models/price.model';

// Ver:
// https://stackoverflow.com/questions/39242219/angular2-nested-template-driven-form/46748943#46748943

/**
 * Form to edit [[Price|prices]]. Supports multiple types (flat, to be fixed,
 * premiums) and [[Currency|currencies]].
 */
@Component({
  selector: 'price-input',
  templateUrl: './price-input.component.html',
  styleUrls: ['./price-input.component.scss'],
  viewProviders: [{
    provide: ControlContainer,// useExisting: NgForm
    useFactory: (container: ControlContainer) => container,
    deps: [[new SkipSelf(), ControlContainer]],
  }]
})
export class PriceInputComponent {

  @Input() public price: Price;
  /** The minimum value to accept for this entry. */
  @Input() public minPrice?: number = 0;
  @Input() public maxPrice?: number = 999999999;
  @Input() public currencies: Currency[];
  /** An example value to display inside the field when empty. */
  @Input() public placeholder: string;
  @Input() public disabled?: boolean = false;
  @Input() public required: boolean = true;

  @Output() readonly priceChange = new EventEmitter<string>();

  constructor() { }

  public changeAbsValue(event): void {
    this.priceChange.emit(event);
  }
}
