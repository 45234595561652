import { Type } from "class-transformer";

import { Negotiation } from "../company/modules/commercial/models/negotiation.model";
import { Contract } from "../company/modules/contracts/models/contract.model";
import { Address } from './address.model';
import { Assignee } from "./assignee.model";
import { Company } from "./company.model";
import { Location } from './location.model';
import { Product } from "./product.model";
import { Slot } from "./slot.model";
import { User } from "../auth/models/user.model";

export class SlotHistory {
  @Type(() => Date)
  readonly date: Date;

  @Type(() => Company)
  readonly company: Company;

  readonly action: 'ACCEPTED_BATCH_PARTIALLY' | 'ACCEPTED_BATCH' | 'ASSIGNED_SLOT' | 'CREATE_BATCH' | 'REJECTED_BATCH' | 'RETURNED_BATCH' | 'SENT_BATCH' | 'WITHDREW_BATCH_PARTIALLY' | 'WITHDREW_BATCH';

  readonly batch_id: number;

  readonly user: User;
}

export class CPEActors {
  @Type(() => Company)
  titular_carta_de_porte?: Company;

  @Type(() => Company)
  remitente_comercial_productor?: Company = null;

  @Type(() => Company)
  remitente_comercial_venta_primaria?: Company = null;

  @Type(() => Company)
  remitente_comercial_venta_secundaria?: Company = null;

  @Type(() => Company)
  remitente_comercial_venta_secundaria_2?: Company = null;

  @Type(() => Company)
  mercado_a_termino?: Company = null;

  @Type(() => Company)
  corredor_venta_primaria?: Company = null;

  @Type(() => Company)
  corredor_venta_secundaria?: Company = null;

  @Type(() => Company)
  representante_entregador?: Company = null;

  @Type(() => Company)
  representante_recibidor?: Company = null;

  @Type(() => Company)
  destinatario?: Company = null;

  @Type(() => Company)
  destino?: Company = null;

  // @Type(() => Company)
  // empresa_transportista?: Company = null;

  // @Type(() => Company)
  // flete_pagador?: Company = null;

  // @Type(() => Company)
  // chofer?: Company = null;

  // @Type(() => Company)
  // intermediario_de_flete?: Company = null;

  @Type(() => Company)
  observaciones?: Company[] = null;

  constructor(data: Partial<CPEActors> = {}) {
    Object.assign(this, data);

    if (!data.observaciones) {
      this.observaciones = [];
    }
  }
}

export class ElectronicWaybill {
  @Type(() => CPEActors)
  actors?: CPEActors;
}

/**
 * A Slot Batch is a container of [[Slot|Slots]]. [[Slot|Slots]] are assigned,
 * accepted or rejected in Batches.
 *
 * By reassigning Batches, new child Batches of the current Batch are created.
 * This creates a hierarchical Batch chain. In an ideal scenario, the first
 * Batch represents the receiving capacity of a terminal and the Batches at the
 * end of the chain should correspond to the [[Company|Companies]] that will
 * load the trucks.
 *
 *
 * ### Related UI components:
 * - [[SlotsComponent]]
 */
export class SlotsBatch {
  /** Agree's internal unique identifier. */
  id: number;
  /**
   * Reference to the first Batch in the tree.
   */
  reference: number;
  /**
   * Reference to the parent Batch.
   */
  parent_id: number;
  /**
   * Batch creation date.
   *
   * *Not to be confused with the date of receipt of the [[Slot|Slots]].*
   */
  @Type(() => Date)
  date_issued: Date;

  status: {
    /**
     * ### Possible values
     * | id | description                           |
     * |---:|---------------------------------------|
     * |  1 | Sent to [[SlotsBatch.company]]        |
     * |  2 | Accepted by [[SlotsBatch.company]]    |
     * |  3 | Rejected by [[SlotsBatch.company]]    |
     * |  4 | Withdrawn by [[SlotsBatch.allocator]] |
     * |  5 | Returned by [[SlotsBatch.company]]    |
     */
    id: number;
    // type: string;
    /**
     * Use in case of rejection
     */
    reason?: string;
  }

  /**
   * [[Company]] that assigned the Batch.
   *
   * If there is a parent Batch it **MUST** match it's [[SlotsBatch.company|Company]].
   */
  @Type(() => Company)
  allocator?: Company;
  /**
   * Current [[Slot|Slots]] assignees.
   */
  assignees?: Assignee[];
  /**
   * [[Company]] that owns this Batch.
   */
  @Type(() => Company)
  company: Company;
  /**
   * Unassigned [[Slot|Slots]] in this Batch.
   */
  @Type(() => Slot)
  unassigned: Slot[];
  /**
   * Children Batches with assigned [[Slot|Slots]] in this Batch.
   */
  @Type(() => SlotsBatch)
  assignments: SlotsBatch[];
  /**
   * If there is a related [[Negotiation]] to this Batch.
   *
   * Typed as 'any' to prevent Cannot access 'Negotiation' before initialization error.
   */
  @Type(() => Negotiation)
  negotiation?: any;
  /**
   * If there is a related [[Contract]] to this Batch.
   *
   * Typed as 'any' to prevent Cannot access 'Contract' before initialization error.
   */
  @Type(() => Contract)
  contract?: any;
  /**
   * Additional instructions or comments defined by the
   * [[SlotsBatch.allocator|Allocator]].
   */
  observations?: string;

  /**
   * All the [[Company|Companies]] involved in the Waybill.
   * @deprecated
   */
  actors?: {
    // holder: Company, // Will be defined in the last batch child
    intermediary: Company,
    commercial_sender: Company,
    buying_broker: Company,
    term_market: Company,
    selling_broker: Company,
    representative: Company,
    receiver: Company,
    destination: Company,
    carrier_broker: Company,
    carrier: Company,
    driver: Company
  };

  /**
   * Overwrite the values defined in [[SlotsBatch.actors]] in the [[Waybill]].
   * @deprecated
   */
  waybillActors?: {
    intermediary?: WaybillActor,
    commercial_sender?: WaybillActor,
    buying_broker?: WaybillActor,
    term_market?: WaybillActor,
    selling_broker?: WaybillActor,
    representative?: WaybillActor,
    receiver?: WaybillActor,
    destination?: WaybillActor,
    carrier_broker?: WaybillActor,
    carrier?: WaybillActor,
    driver?: WaybillActor
  };

  /** Electronic Waybill. */
  @Type(() => ElectronicWaybill)
  cpe?: ElectronicWaybill;

  /**
   * Date this truck is expected at the terminal.
   */
  @Type(() => Date)
  date: Date;

  /**
   * [[Company]] at destination.
   */
  @Type(() => Company)
  recipient: Company;

  destination: Destination;

  @Type(() => Product)
  product?: Product;

  /** Extra quality conditions. */
  quality: any = {};

  /**
   * Indicates whether the [[SlotsBatch]] has not being terminated or cancelled.
   */
  get isAlive(): boolean {
    return [1, 2].includes(this.status.id);
  }

  /**
   * Returns all the [[Slot|Slots]] in this Batch.
   */
  get slots(): Slot[] {
    if (!this._slots) this.mapSlots();
    return this._slots;
  }

  /**
   * Returns the [[Slot|Slots]] quantity in this Batch.
   */
  get slots_total(): number {
    return this.slots.length || 0;
  }

  get slots_unassigned(): number {
    return this.unassigned.length || 0;
  }

  get slots_assigned(): number {
    return this.slots_total - this.slots_unassigned;
  }

  /** Data from its slots. */
  private _slots: Slot[];

  private mapSlots(): void {
    this._slots = [];
    if (this.unassigned) this._slots = this._slots.concat(this.unassigned);
    if (this.assignments) this.assignments.forEach(batch => {
      if (![3, 4, 5].includes(batch.status.id)) this._slots = this._slots.concat(batch.slots);
    });
  }
}

export class Destination {
  /** Agree's internal unique identifier. */
  readonly id: number;
  /** Destination owner. */
  @Type(() => Company)
  company: Company;

  location: Location;
  address: Address;

  /** STOP id. */
  readonly idTerminal?: number;

  name: string;
  label: string;
  terminal?: string;

  constructor(data: Partial<Destination> = {}) {
    this.address = new Address();

    Object.assign(this, data);
  }
}

class WaybillActor {
  name: string;
  fiscal_id: string;
  editable: boolean;
}
