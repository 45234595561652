<div class="loading" *ngIf="!certificates">
  <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
</div>

<div class="row" *ngIf="certificates">
  <div class="col-xs-12">

    <div class="no-data" *ngIf="certificates?.length === 0">
      <i class="material-symbols-rounded">not_interested</i>
      {{ 'CERTIFICATES.NO_DATA'|translate }}
    </div>

    <table class="table table-hover" [sortable]="certificates" #tableSortable="tableSortable" *ngIf="certificates?.length > 0">
      <!-- <caption></caption> -->
      <thead>
        <tr>
          <th class="sap"></th>
          <th class="hidden-xs" sortBy="type">{{ 'GLOBAL.TYPE'|translate }}</th>
          <th sortBy="name">{{ 'GLOBAL.NAME'|translate }}</th>
          <th class="sap" sortBy="verified"><i class="material-symbols-rounded">check</i></th>
          <th class="hidden-xs hidden-sm" sortBy="product_category">{{ 'CERTIFICATES.TABLE.PRODUCT_CATEGORY'|translate }}</th>
          <th class="hidden-xs hidden-sm" sortBy="number">{{ 'CERTIFICATES.TABLE.NUMBER'|translate }}</th>
          <th class="hidden-xs hidden-sm" sortBy="scope">{{ 'CERTIFICATES.TABLE.SCOPE'|translate }}</th>
          <th class="hidden-xs" sortBy="issued_by">{{ 'GLOBAL.ISSUED_BY'|translate }}</th>
          <th sortBy="date_from" class="sap text-center">{{ 'CERTIFICATES.TABLE.DATES'|translate }}</th>
          <th class="sap" *ngIf="adminMode"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let row of tableSortable.collection; let i = index">
          <tr class="tr-align-center" [class.expired]="row.expired" *ngIf="!row.expired || adminMode">
            <td class="sap">
              <div class="fullscreen" *ngIf="media_fullscreen && selected_media === i" (click)="toggleFullscreen($event)">
                <div class="media-browser">
                  <div class="arrow close">
                    <i class="material-symbols-rounded">close</i>
                  </div>
                </div>
                <div class="margin">
                  <div class="img" [style.backgroundImage]="'url(' + row.image + ')'"></div>        
                </div>
              </div>
              <!-- <div class="cert-img" (click)="toggleFullscreen($event, i)" [style.backgroundImage]="'url(' + certificate.media.uri + ')'"></div> -->
              <img class="cert-img" (click)="toggleFullscreen($event, i)" [src]="row.image">
            </td>
            <td class="small text-muted hidden-xs">{{ row.certificate_type.name }}</td>
            <td>
              <b>{{ row.certificate.name }}</b>
              <div class="small text-muted visible-xs visible-sm">{{ row.scope }}</div>
            </td>
            <td class="sap"><i *ngIf="row.verified" class="material-symbols-rounded text-success" title="{{ 'CERTIFICATES.TABLE.VERIFIED'|translate }}">check_circle</i></td>
            <td class="hidden-xs hidden-sm" [innerHtml]="row.category || '&#8212;'"></td>
            <td class="hidden-xs hidden-sm truncate"><code>{{ row.number }}</code></td>
            <td class="hidden-xs hidden-sm">{{ row.scope }}</td>
            <td class="hidden-xs small">{{ row.entity.name || row.entity.other }}</td>
            <td class="sap">
              <date-range
              [from]="row.valid_from"
              [to]="row.valid_to"></date-range>
            </td>
            <td class="sap" *ngIf="adminMode">
              <a
              title="{{ 'CERTIFICATES.DELETE_CONFIRM.MODAL_TITLE'|translate }}"
              (onConfirm)="deleteCertificate(row)"
              [confirm-options]="{
                title: 'CERTIFICATES.DELETE_CONFIRM.TITLE'|translate,
                action: 'GLOBAL.YES_DELETE'|translate
              }"
              [confirm-close]="deletedCertificate">
                <i class="material-symbols-rounded">delete</i>
              </a>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>