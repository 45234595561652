import { forwardRef } from '@angular/core';
import { Type } from "class-transformer";

import { Company } from '../../../../models/company.model';
import { Label } from '../../../../models/label.model';
import { Quantity } from '../../../../models/quantity.model';
import { SlotsBatch } from '../../../../models/slots-batch.model';
import { Contract, ContractSummary } from '../../contracts/models/contract.model';
import { Order } from './order.model';
import { Proposal } from './proposal.model';

/**
 * A Negotiation is the container for all the [[Proposal|Proposals]] including
 * the terms sent back and forth for an specific [[Order]] between the
 * counterparties.
 *
 * The counterparties are the [[Company]] owner of the [[Order]] and the
 * [[Company]] owner of the Negotiation.
 *
 * Negotiations always have one parent [[Order]] and may have multiple
 * [[Proposal|Proposals]].
 */
export class Negotiation {
  /** Agree's internal unique identifier. */
  id: number;

  /**
   * Owner [[Company]]
   */
  @Type(forwardRef(() => Company) as any)
  company: Company;

  /**
   * Parent [[Order]]
   */
  @Type(forwardRef(() => Order) as any)
  order: any; // If I declare this property as Order it throws an initialization error (TODO: Remove circular dependency)

  /**
   * ## Possible values
   * | id | status                       | id_group | name_group        |
   * |---:|------------------------------|---------:|-------------------|
   * |  1 | Counter-order sent           |        2 | Under negotiation |
   * |  2 | Counter-order replied        |        2 | Under negotiation |
   * |  3 | Pre-book                     |        2 | Under negotiation |
   * |  5 | Canceled                     |        3 | Closed            |
   * |  6 | Rejected                     |        3 | Closed            |
   * |  7 | Booked                       |        2 | Under negotiation |
   * |  8 | Pre-book rejected            |        3 | Closed            |
   * | 12 | Negotiation request          |        1 | Open              |
   * | 13 | Negotiation request accepted |        1 | Open              |
   * | 14 | Negotiation request rejected |        3 | Closed            |
   * | 15 | Disclosure request sent      |        2 | Under negotiation |
   * | 16 | Disclosure request accepted  |        2 | Under negotiation |
   * | 17 | Disclosure request rejected  |        2 | Under negotiation |
   * | 18 | No initial                   |        1 | Open              |
   * | 19 | Barter accepted              |        2 | Under negotiation |
   * | 20 | Cancellation request (Nego)  |        2 | Under negotiation |
   * | 21 | Cancellation request (Order) |        2 | Under negotiation |
   * | 22 | Cancelled                    |        3 | Closed            |
   */
  status: {
    id: number,
    name?: string,
    group?: {
      id: number,
      name?: string
    }
  };

  status_disclosure: {
    id: number,
    name: string
  };

  /**
   * User defined external information, for the current [[Company]] only.
   *
   * For example, a [[Contract]] reference for this Negotiation.
   *
   * ```data_external.reference.id``` is used to link a Neogtiation with an imported [[Contract]].
   */
  data_external: {
    reference: {
      id: string;
    }
  };

  /**
   * Amendments allows to book a Negotitation multiple times.
   */
  times_booked: number;

  /**
   * If the owner [[Company]] is a broker, this could include the represented [[Company|Companies]].
   */
  @Type(() => Company)
  represented: Company[];

  /**
   * For UI purposes (TODO: remove)
   * @ignore */
  props: {
    name: string,
    value: string | number,
    class?: string
  }[] = [];

  /**
   * Last time it was booked. Updated after each amendment.
   */
  @Type(() => Date)
  booking_date: Date;

  /**
   * First time it was booked.
   */
  @Type(() => Date)
  original_booking_date: Date;

  /**
   * Last time it was updated.
   */
  @Type(() => Date)
  updated_at: Date;

  /**
   * Current [[Proposal]].
   */
  @Type(forwardRef(() => Proposal) as any)
  proposal: Proposal;

  /**
   * Previous [[Proposal]].
   */
  @Type(forwardRef(() => Proposal) as any)
  previous_proposal?: Proposal;

  /**
   * Related [[Contact|Contacts]].
   */
  @Type(() => Contract)
  contracts?: Contract[];

  /**
   * Related [[Contact|Contacts]].
   */
  @Type(() => SlotsBatch)
  batches?: SlotsBatch[];

  slot_request?: any

  /**
 * Quantity of slots assigned for this [[Negotiation]] .
 * @returns The total number of slots assigned in the [[Negotiation]].
 */
  get assignedQuantity(): number {
    const batchesFiltered = this.batches.filter(batch => batch.status.id === 1 || batch.status.id === 2);
    let total = 0;
    batchesFiltered.forEach(batch => {
      total += batch.slots_total
    });

    return total;
  }

  get assignableSlots(): number {
    const pendingVolumeToApply = this.pendingVolumeToApply();
    return Math.ceil(pendingVolumeToApply / 30) + 1;
  }

  private pendingVolumeToApply(): number {
    const negotiationVolume = this.proposal.business_detail.quantity.value;
    const pendingVolume = negotiationVolume - this.appliedVolume.value;
    return pendingVolume;
  }

  get appliedVolume(): Quantity {
    if (this.summary && this.summary.applied_volume)
      return this.summary.applied_volume;
    return new Quantity({ value: 0 });
  }

  /** Used for sorting purposes. */
  get contract_reference(): string {
    const all_references = this.contracts.map(a => a.reference).sort();
    return all_references ? all_references[0] : '';
  }

  /** Consolidated data from related [[Contract|Contracts]]. */
  get summary(): ContractSummary {
    if (this.contracts?.length) {
      // Simply returns the summary from the first Contract. Probably needs a review later.
      return this.contracts[0].summary || undefined;
    }
    return undefined;
  }

  /**
   * Returns the buyer [[Company]].
   */
  get buyer(): Company {
    if (this.order.operation_type === 'compra') {
      return this.order.company;
    } else {
      return this.company;
    }
  }

  /**
   * Returns an Array of [[Company|Companies]] represented by the buyer broker.
   */
  get buyer_represented(): Company[] {
    if (this.order.operation_type === 'compra') {
      return this.order.represented;
    } else {
      return this.represented;
    }
  }

  /**
   * Returns an Array with the buyer [[Company.id|Company's ID]].
   */
  get buyers_id(): number[] {
    let buyers: number[] = this.buyer_represented.map(company => {
      return company.id;
    });

    if (this.buyer) buyers.push(this.buyer.id);

    return buyers;
  }

  /**
   * Returns an Array with the seller [[Company.id|Company's ID]].
   */
  get sellers_id(): number[] {
    let sellers: number[] = this.seller_represented.map(company => {
      return company.id;
    });

    if (this.seller) sellers.push(this.seller.id);

    return sellers;
  }

  /**
   * Returns the seller [[Company]].
   */
  get seller(): Company {
    if (this.order.operation_type === 'venta') {
      return this.order.company;
    } else {
      return this.company;
    }
  }

  /**
   * Returns an Array of [[Company|Companies]] represented by the seller broker.
   */
  get seller_represented(): Company[] {
    if (this.order.operation_type === 'venta') {
      return this.order.represented;
    } else {
      return this.represented;
    }
  }

  private _counterparties: Company[];

  /**
   * Returns an Array of counterparties in this Negotiation for the specified [[Company]] id.
   */
  public counterpartiesOf(companyId: number): Company[] {
    if (!this._counterparties) { // Only search once and store in memory
      // Consider negotiations without owner
      const owner: Company = this.company ? this.company : new Company();
      // Always prioritize those represented
      this._counterparties = this.represented.length ? this.represented : [owner];

      if (companyId === owner.id || // If my Company owns the Negotiation
        (this.represented.length && this.represented.map(o => o['id']).includes(companyId))) { // or is one of the Represented Companies
        // Assign the Order owner or represented
        // Always prioritize those represented
        this._counterparties = this.order.represented.length ? this.order.represented : [this.order.company];
      }
    }

    return this._counterparties;
  }

  /**
   * Returns an Array of involved Brokers (if any).
   */
  get brokers(): Company[] {
    let brokers: Company[] = [];
    if (this.order && this.order.company && this.order.company.activity.broker) {
      brokers.push(this.order.company);
    }
    if (this.company && this.company.activity.broker) {
      brokers.push(this.company);
    }
    return brokers;
  }

  linked_negotiations: number[];

  /** For labeleable entities. */
  get entity(): string { return 'negotiation'; }
  readonly labels?: Label[];

  constructor(data: Partial<Negotiation> = {}) {
    this.order = new Order();
    this.proposal = new Proposal();
    this.status = { id: null, name: null };

    Object.assign(this, data);
  }
}
