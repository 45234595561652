import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[clickStop]'
})
export class ClickStopDirective {

  constructor() { }

  @HostListener('click', ['$event'])
  stopPropagation(event:any) {
    event.stopPropagation();
  }
}
