import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { User } from '../../../auth/models/user.model';

@Component({
  selector: 'add-phone[user]',
  templateUrl: './add-phone.component.html',
  styleUrls: ['./add-phone.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddPhoneComponent {

  /** Current [[User]]. */
  @Input() public user: User;

  constructor() { }

}
