import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Company } from '../../../models/company.model';

/**
 * CompanyTable is a component that displays [[Company|Companies]] information
 * in a normalized way. You can customize the information detail.
 * 
 * ## Usage
 * ``` html
 * <company-table
 * [header]="'ORDER_SUMMARY.SELLER'|translate"
 * header-class="sell"
 * [activity]="false"
 * [companies]="[contract.seller]"></company-table>
 * ```
 * 
 * ### Related UI components:
 * - [[CounterOrderComponent]]
 * - [[OrderSummaryComponent]]
 * - [[ViewBarterComponent]]
 */
@Component({
  selector: 'company-table',
  templateUrl: './company-table.component.html',
  styleUrls: ['./company-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyTableComponent {

  /**
   * Indicates whether [[Company|Company's]] [[CompanyActivity|activity]]
   * should be displayed or not.
   */
  @Input() public activity: boolean = true;
  /**
   * [[Company|Companys]] to be displayed.
   */
  @Input() public companies: Company[];
  /**
   * Table header.
   */
  @Input() public header: string;
  /**
   * Optional [[CompanyTableComponent.header|header]] CSS class.
   */
  @Input('header-class') public header_class: string;

  /** @ignore */
  constructor() { }
}
