import { Type } from "class-transformer";

import { Company } from '../models/company.model';

export class AuctionData {
  step: number;

  @Type(() => Date)
  date_from: Date;

  @Type(() => Date)
  date_to: Date;

  extend_time: number;
  company_enrolled: boolean;

  @Type(() => Company)
  enrolled_companies: Company[];
}