import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { HTMLToClipboardService } from '../../services/html-to-clipboard.service';

@Component({
  selector: 'ag-html-modal',
  templateUrl: './html-modal.component.html',
  styleUrls: ['./html-modal.component.scss']
})
export class HtmlModalComponent implements OnDestroy {

  @ViewChild('modal', { static: true }) private readonly modal: TemplateRef<any>;

  public html: string;
  public htmlString: string;
  public title: string;

  private modalRef: BsModalRef;
  // private modalSub: Subscription;

  constructor(
    public htmlClipboardService: HTMLToClipboardService,
    private modalService: BsModalService
  ) { }

  public show(title: string = '', html: string): void {
    if (html) {
      this.title = title;
      this.html = html;
      this.htmlString = this.indentHtml(html);
      this.openModal(this.modal);
    }
  }

  private indentHtml(html: string): string {
    const format = (node: Element, level = 0) => {
      const indentBefore = new Array(level++ + 1).join('  '),
        indentAfter = new Array(level - 1).join('  ');
      let textNode;

      for (let i = 0; i < node.children.length; i++) {
        textNode = document.createTextNode((level > 1 ? '\n' : '') + indentBefore);
        node.insertBefore(textNode, node.children[i]);

        format(node.children[i], level);

        if (node.lastElementChild === node.children[i]) {
          textNode = document.createTextNode('\n' + indentAfter);
          node.appendChild(textNode);
        }
      }

      return node;
    };

    const div = document.createElement('div');
    div.innerHTML = html.trim();

    return format(div).innerHTML;
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    // this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
    //   this.modalSub.unsubscribe();
    //   this.modalRef = undefined;
    //   // Reset all values
    //   // this.processing = false;
    // });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.closeModal();
  }
}
