import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { Company } from '../../../models/company.model';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { checkAccept } from '../../../utilities/file';
import { AttachmentsService } from '../../services/attachments.service';
import { FilePreviewComponent } from '../file-preview/file-preview.component';

/**
 * Component that allows you to attach files and display files attached to an
 * entity.
 * 
 * The files are stored in a kind of virtual folder associated with the entity,
 * anyone with visibility of the entity can view, delete or add files.
 * 
 * ## Usage
 * ``` html
 * <attachments
 * entity="negotiation"
 * [entity-id]="negotiation.id"
 * [company]="company"
 * drop-area=".app-content"
 * (onFileAction)="reload()"></attachments>
 * ```
 * 
 * ### Related UI components:
 * - [[CounterOrderComponent]]
 * - [[ConfirmComponent]]
 */
@Component({
  selector: 'attachments[company]',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit, OnDestroy {

  @ViewChild('privateModal', { static: true }) private readonly privateModal: TemplateRef<any>;
  @ViewChild('filePreviewer', { static: true }) private readonly filePreviewer: FilePreviewComponent;
  /** Default files drop area. */
  @ViewChild('defaultDrop', { static: true }) private readonly defaultDrop: ElementRef;

  /** Maximum number of attachments for this entity. */
  @Input('max-files') public max_files: number = 1024;
  /**
   * Specify what file types the user can pick from the file input dialog box.
   * 
   * Works as
   * [[https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept|HTML attribute: accept]].
   */
  @Input() public accept: string = "";
  @Input() private company: Company;
  @Input() private allowPrivate: boolean;

  /** It specifies that the component should be disabled. */
  @Input() public set disabled(flag: boolean) {
    this._disabled = flag;
  };
  public get disabled(): boolean {
    return (this._disabled || this.processing);
  }

  /** Selector to set the files drop area. */
  @Input('drop-area') public dropArea: string;
  /**
   * Entity type. Eg: negotiation, invoice, etc.
   * 
   * Either this attribute or [[external_id|external-id]] is required.
   */
  @Input() public entity: string;
  /**
   * Entity unique identifier.
   * 
   * This attribute in combination with the [[entity|entity type]] is the
   * unique reference to the virtual file folder.
   * 
   * This attribute is required.
   */
  @Input('entity-id') public entity_id: string;
  /**
   * External entity unique identifier.
   * 
   * If this attribute is set the [[entity|entity type]] will be "external".
   * 
   * Either this attribute or [[entity|entity type]] is required.
   */
  @Input('external-id') public external_id: number;
  /** Allows to attach files from outside the component. */
  @Input() set attach(f: FileList) {
    this.setFiles(f);
  };

  @Output() private readonly onFileAction = new EventEmitter();
  /** Triggered when a SpreadSheet has been parsed. */
  @Output() private readonly onSpreadSheet: EventEmitter<File> = new EventEmitter();

  /** Triggered once a file has been deleted. */
  // public onFileDeleted = new EventEmitter();

  /** Flag used to indicate if the component is loading information. */
  public loading: boolean;
  /** Files in the [[entity]] folder. */
  public documents: {
    id: number,
    filename: string,
    size: number,
    type: string,
    private: boolean,
    created_at: Date
  }[] = [];
  public failed: FileList;
  public isDragging: boolean;
  /** Files being uploaded to the [[entity]] folder. */
  public uploading: FileList;
  public UUID: string;

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  private processing: boolean;
  private _disabled: boolean;
  private dropEl: HTMLElement;
  private dropLg: HTMLElement;
  private subscriptions: Subscription[] = [];
  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private capturedFiles: FileList;

  /** @ignore */
  constructor(
    private attachmentsService: AttachmentsService,
    private modalService: BsModalService,
    private dataDogLoggerService: DataDogLoggerService
  ) {
    this.UUID = 'attach-' + uuidv4();
  }

  /** @ignore */
  ngOnInit(): void {
    this.loadDocuments();

    // Setup drag and drop
    let da = this.defaultDrop.nativeElement;

    if (this.dropArea) {
      const db = document.querySelector(this.dropArea);
      if (db) da = db;
    }

    this.setupDrop(da);
  }

  private setupDrop(el: HTMLElement, lg?: HTMLElement): void {
    this.dropEl = el;
    this.dropLg = lg;

    el.addEventListener("dragenter", this.dragger, false);
    el.addEventListener("dragover", this.dragger, false);
    el.addEventListener("drop", this.drop, false);
    el.addEventListener("dragleave", this.removeDrag, false);
    el.addEventListener("dragend", this.removeDrag, false);
  }

  private killDrop(el: HTMLElement): void {
    // Removes all event listeners
    el.removeEventListener("dragenter", this.dragger);
    el.removeEventListener("dragover", this.dragger);
    el.removeEventListener("drop", this.drop);
    el.removeEventListener("dragleave", this.removeDrag);
    el.removeEventListener("dragend", this.removeDrag);
  }

  /**
   * Delays the setting of the variable to avoid multiple unnecessary triggers.
   */
  private _draggingSet: boolean;
  private _draggingTimeout: number;
  private setDragging(value: boolean): void {
    if (this._draggingSet !== value) {
      this._draggingSet = value;
      if (this._draggingTimeout) clearTimeout(this._draggingTimeout);
      if (this.isDragging !== this._draggingSet) this._draggingTimeout = window.setTimeout(() => {
        this.isDragging = this._draggingSet;
      }, 100);
    }
  }

  private dragger = (e: DragEvent) => {
    if (!this.disabled && !this.loading &&
      e.dataTransfer.types.includes('Files')) {
      this.setDragging(true);
      e.stopPropagation();
      e.preventDefault();
      if (this.dropLg) this.dropLg.className = 'is-dragging';
    }
  };

  private drop = (e: DragEvent) => {
    if (!this.disabled && !this.loading) {
      this.dragger(e);

      // Triggered by drop
      // Prevent to drag more files when the max files number is reached
      // The files property of DataTransfer objects can only be accessed from
      // within the drop event. For all other events, the files property will
      // be empty — because its underlying data store will be in a protected
      // mode.
      if (checkAccept(e.dataTransfer.files, this.accept)) this.setFiles(e.dataTransfer.files);
      this.removeDrag();
    }
  };

  private setFiles(f: FileList): void {
    if (f && f.length > 0) {
      this.capturedFiles = f;
      if (this.allowPrivate) {
        this.openModal(this.privateModal);
      } else this.upload();
    }
  }

  private removeDrag = (e?: DragEvent) => {
    this.setDragging(false);
    if (this.dropLg) this.dropLg.className = '';
  }

  private pluck(array, key: string) {
    return array.map(o => o[key]);
  }

  private loadDocuments(): void {
    if ((this.entity || this.external_id) && this.entity_id && !this.loading) {
      this.loading = true;

      this.subscriptions.push(this.attachmentsService.watchFolder(
        this.company.id,
        this.external_id ? "external" : this.entity,
        this.entity_id,
        this.external_id).subscribe(response => {
          if (response.length) this.documents = this.pluck(response, 'files').flat();
          this.loading = false;
        }));
    }
  }

  public upload(myCompanyOnly?: boolean): void {
    this.closeModal();
    const files = this.capturedFiles;
    if (files && this.uploading == undefined &&
      (this.entity || this.external_id) && this.entity_id) {
      if (files.length + this.documents.length > this.max_files) {
        // Handle this error
        // Max allowed files: this.max_files
      } else {
        this.processing = true;
        this.uploading = files;

        this.doFocusScroll();

        this.subscriptions.push(this.attachmentsService.attach(
          this.company.id,
          files,
          this.external_id ? "external" : this.entity,
          this.entity_id,
          this.external_id,
          Boolean(myCompanyOnly)
        ).subscribe({
          next: response => {
            this.parseXLS(this.uploading);
            this.uploading = undefined;
            if (response.length) this.documents = this.documents.concat(response);
            this.processing = false;
            this.onFileAction.emit();
          },
          error: error => {
            this.failed = this.uploading;
            this.uploading = undefined;
            this.processing = false;
            this.dataDogLoggerService.warn(error.message, error.error);
          }
        }));
      }
    }
  }

  private parseXLS(files: FileList): void {
    if (this.onSpreadSheet.observers.length > 0) {
      // Only if someone is listening
      for (let index = 0; index < files.length; index++) {
        this.onSpreadSheet.emit(files[index]);
      }
    }
  }

  private doFocusScroll(): void {
    // Scrolls the container into view
    const element = this.defaultDrop.nativeElement;

    requestAnimationFrame(function (): void {
      if (element.offsetParent) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    });
  }

  /** Deletes the file located at the specified index. */
  public delete(fileIndex: number): void {
    if (this.documents[fileIndex]) {
      this.processing = true;

      this.subscriptions.push(this.attachmentsService.delete(this.company.id, this.documents[fileIndex].id).subscribe(response => {
        if (response) this.documents.splice(fileIndex, 1);
        this.processing = false;
        // this.onFileDeleted.emit();
        this.onFileAction.emit();
      }));
    }
  }

  /** Opens the file located at the specified index. */
  public open(fileIndex: number): void {
    if (this.documents[fileIndex]) {
      this.processing = true;

      // Safari blocks any call to window.open() which is made inside an async call.
      const windowReference = window.open();
      this.subscriptions.push(this.attachmentsService.get(this.company.id, this.documents[fileIndex].id).subscribe({
        next: response => {
          if (response.url) {
            windowReference.location = response.url;
          } else {
            windowReference.close();
          }

          this.processing = false;
          this.onFileAction.emit();
        },
        error: error => {
          windowReference.close();
          this.processing = false;
          this.dataDogLoggerService.warn(error.message, error.error);
        }
      }));
    }
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** Try to preview the file located at the specified index in the browser. */
  public preview(fileIndex: number): void {
    const file = this.documents[fileIndex];

    if (file) {
      this.filePreviewer.preview(this.attachmentsService.get(this.company.id, file.id), file.filename, file.size);
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    if (this.dropEl) this.killDrop(this.dropEl);
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
