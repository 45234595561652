import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { TrackedControlDirective } from './tracked-control.directive';

@Directive({
  selector: '[trackedGroup]'
})
export class TrackedGroupDirective implements OnChanges {

  private initialValue: any;

  @Input() trackedGroupInitialValue: any;
  @Input() trackedGroupPreviousValue: any;
  @Input() trackedGroupDisplayAs: (a: any) => string;

  private trackedControls: TrackedControlDirective[] = [];
  private controls: BehaviorSubject<TrackedControlDirective[]> = new BehaviorSubject(null);

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.trackedGroupInitialValue) {
      if (
        !this.initialValue &&
        changes.trackedGroupInitialValue.firstChange
      ) {
        this.initialValue = JSON.parse(JSON.stringify(changes.trackedGroupInitialValue.currentValue));
      }
    }
  }

  public hasChanges = this.controls.pipe(
    filter(controls => !!controls),
    switchMap(controls => {
      return combineLatest(controls.map((control: TrackedControlDirective) => {
        return control.hasChanges;
      })).pipe(
        map((changes: boolean[]) => {
          return changes.reduce((anyChanges, hasChanges) => {
            return anyChanges || hasChanges;
          }, false);
        })
      );
    })
  );

  public hasDirtyChanges = this.controls.pipe(
    filter(controls => !!controls),
    switchMap(controls => {
      return combineLatest(controls.map((control: TrackedControlDirective) => {
        return control.hasDirtyChanges;
      })).pipe(
        map((changes: boolean[]) => {
          return changes.reduce((anyChanges, hasChanges) => {
            return anyChanges || hasChanges;
          }, false);
        })
      );
    })
  );

  addControl(trackedControl: TrackedControlDirective) {
    this.trackedControls.push(trackedControl);
    this.controls.next(this.trackedControls);
  }

  displayInitial(): string {
    if (!this.trackedGroupDisplayAs) {
      let controls = this.trackedControls;
      let result = controls.reduce((text: string, control: TrackedControlDirective) => {
        let t = control.displayInitial();
        return text + (t ? t + ' ' : '');
      }, '');
      return result ? result : '';
    } else {
      return this.display(this.initialValue);
    }
  }

  displayPrevious(): string {
    if (!this.trackedGroupDisplayAs) {
      let controls = this.trackedControls;
      let result = controls.reduce((text: string, control: TrackedControlDirective) => {
        let t = control.displayPrevious();
        return text + (t ? t + ' ' : '');
      }, '');
      return result ? result : '';
    } else {
      return this.display(this.trackedGroupPreviousValue);
    }
  }

  display(a) {
    return a && this.trackedGroupDisplayAs ? this.trackedGroupDisplayAs(a) : a;
  }

  discard(): void {
    this.trackedControls.forEach(control => {
      control.discard();
    });
  }
}
