import { Type } from "class-transformer";
import { Contract } from "../../contracts/models/contract.model";
import { Company } from "../../../../models/company.model";
import { Product } from "../../../../models/product.model";
import { Quantity } from "../../../../models/quantity.model";

/**
 * A Contract Application links a [[CartaPorteElectronica|Waybill]] with one
 * [[Contract]]. It also specifies the
 * [[ContractApplication.quantity|quantity applied]], once discounts have been
 * applied for non-compliance (for example, it was not the specified
 * [[Product]] quality).
 */
export class ContractApplication {
  /** Agree's internal unique identifier. */
  readonly id: number; // Internal ID

  /**
   * Source channel used to import.
   *
   * | ID | Channel        |
   * |---:|----------------|
   * |  1 | API            |
   * |  2 | Imported files |
   * |  3 | UI             |
   * |  4 | AFIP WS        |
   * |  5 | File           |
   */
  readonly import_channel_id: number;

  @Type(() => Date)
  readonly date: Date; // Fecha de aplicación

  @Type(() => Company)
  readonly company: Company;

  @Type(() => Product)
  readonly product: Product;

  readonly quantity: Quantity;
  /**
   * Potential inconsistencies in imported data.
   */
  readonly warnings?: string[];

  readonly contract: Contract;

  readonly numero_ctg: number;

  constructor(data: Partial<ContractApplication> = {}) {
    Object.assign(this, data);
  }
}
