import { Type } from "class-transformer";

import { AuctionBid } from './auction-bid.model';

class AuctionLimit {
  value: number;

  @Type(() => Date)
  date: Date;
}

export class AuctionHistory {
  @Type(() => AuctionBid)
  bid_list: AuctionBid[];

  @Type(() => AuctionLimit)
  limit?: AuctionLimit;

  @Type(() => Date)
  extend_to: Date;
}