<div class="scrollable-container" *ngIf="source.length > 0">
  <div class="item-container" *ngFor="let item of source" [class.disabled]="!keyMap[this.getKey(item)]">
    <label>
      <input type="checkbox" [value]="true" [ngModel]="keyMap[this.getKey(item)]" (ngModelChange)="keyMap[this.getKey(item)]=$event;optionChange()">
      <div class="item-label">
        <template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{item: item}"></template>
      </div>
    </label>
  </div>
</div>
