<table class="table table-detail table-condensed">
  <thead *ngIf="header">
    <tr>
      <th><span [class]="header_class">{{ header }}</span></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="companies?.length; else noDisclousure">
      <tr *ngFor="let company of companies">
        <td>
          <company-info [activity]="activity" [company]="company"></company-info>
        </td>
      </tr>
    </ng-container>
    <ng-template #noDisclousure>
      <!-- Sin disclosure -->
      <tr>
        <td>
          <!-- <button title="Solicitar información de contrapartes" class="btn btn-link btn-sm" [disabled]="true">
              Solicitar información de contrapartes
            </button> -->
          <mark class="text-muted">{{ 'ORDER_SUMMARY.NO_DISCLOSURE'|translate }}</mark>
        </td>
      </tr>
    </ng-template>
  </tbody>
</table>