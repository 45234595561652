import { Pipe, PipeTransform } from '@angular/core';

/**
 * Parses a template expression by replacing placeholders with values from a given object.
 * Supports formatting numbers and dates to the locale.
 * 
 * @param {string} expression - The template string containing placeholders in the format ${key}.
 * @param {any} valueObj - The object containing values to replace the placeholders.
 * @returns {string} - The parsed string with placeholders replaced by corresponding values.
 */
export function literalsParser(expression: string, valueObj: any): string {
  const templateMatcher = /\${\s?([^{}\s]*)\s?}/g;
  return expression.replace(templateMatcher, (substring, value, index) => {
    const output = value.split('.').reduce((o, i) => o ? o[i] : substring, valueObj);

    if (typeof output === 'number' && !Number.isNaN(output)) {
      // Format a number to locale
      return output.toLocaleString(navigator.language || "es");
    } else if (output instanceof Date) {
      // Format a date to locale
      return output.toLocaleDateString(navigator.language || "es");
    } else if (typeof output !== 'string') {
      return substring;
    }

    return output;
  });
}

@Pipe({
  name: 'literals'
})

export class LiteralsPipe implements PipeTransform {

  transform(value: string, data: any): string {
    return literalsParser(value, data);
  }
}
