import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable, map, of } from 'rxjs';

import { CountryCode } from '../models/country-code.model';
import { Location } from '../models/location.model';
import { Zone } from '../models/zone.model';

@Injectable()
export class LocationService {

  private locationsUrl = '/:apiBase/markets/:marketId/locations';
  private locationsByIdUrl = '/:apiBase/markets/:marketId/locations/:locationId';
  private zonesUrl = '/:apiBase/zones';
  // private provincesUrl = '/:apiBase/countries/:countryId/provinces';
  // private citiesUrl = '/:apiBase/countries/:countryId/provinces/:provinceId/cities';
  // private partidosUrl = '/:apiBase/countries/:countryId/provinces/:provinceId/partidos';
  private countryCodesUrl = '/:apiBase/country-codes';

  private _zones: Zone[];

  constructor(
    private http: HttpClient
  ) { }

  public getCountryCodes(): Observable<CountryCode[]> {
    return this.http.get<CountryCode[]>(this.countryCodesUrl);
  }

  public getCountryCode(countryId: number): Observable<CountryCode> {
    let url = this.countryCodesUrl + '/' + countryId;
    return this.http.get<CountryCode>(url);
  }

  /** @deprecated */
  // getProvinces(countryId: number) {
  //   let url = this.provincesUrl.replace(':countryId', countryId + '');
  //   return this.http.get<Location[]>(url);
  // }

  /** @deprecated */
  // getCities(countryId: number, provinceId: number) {
  //   let url = this.citiesUrl
  //     .replace(':countryId', countryId + '')
  //     .replace(':provinceId', provinceId + '');

  //   return this.http.get<Location[]>(url);
  // }

  /** @deprecated */
  // getPartidos(countryId: number, provinceId: number) {
  //   let url = this.partidosUrl
  //     .replace(':countryId', countryId + '')
  //     .replace(':provinceId', provinceId + '');

  //   return this.http.get<Location[]>(url);
  // }

  /** Return Market Locations. */
  private getLocations(marketId: number, query: string): Observable<Location[]> {
    const url = this.locationsUrl.replace(':marketId', marketId + '') + '?text-search=' + query;

    return this.http.get<Location[]>(url);
  }

  private getLocationsByZone(marketId: number, query: string, zones: Zone[]): Observable<Location[]> {
    let url = this.locationsUrl.replace(':marketId', marketId + '') + '?text-search=' + query;

    zones.forEach(zone => {
      url += '&filter[zone][]=' + zone.id;
    });

    return this.http.get<Location[]>(url);
  }

  public getLocationsById(marketId: number, locationId: number): Observable<Location[]> {
    let url = this.locationsByIdUrl.replace(':locationId', locationId + '');

    url = url.replace(':marketId', marketId + '');

    return this.http.get<Location[]>(url);
  }

  /**
    * Retrieves and caches the zones.
    *
    * @returns {Observable<StopStatus[]>} - An observable of the zones.
    */
  public getZones(): Observable<Zone[]> {
    if (this._zones) {
      return of(this._zones);
    }
    let url = this.zonesUrl;

    return this.http.get<Zone[]>(url).pipe(map(response => {
      // Cache response
      this._zones = plainToInstance(Zone, response);
      return this._zones;
    }));
  }

  public locationSolver(marketId: number): Function {
    return (query: string) => {
      return this.getLocations(marketId, query);
    }
  }

  /** @see getLocationsByZone */
  public locationSolverByZone(marketId: number, zones: Zone[]): Function {
    return (query: string) => {
      return this.getLocationsByZone(marketId, query, zones);
    }
  }
}
