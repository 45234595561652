import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { User } from '../../../auth/models/user.model';
import { Company } from '../../../models/company.model';

/**
 * Lista un array de comentarios detallando el usuario, la fecha y si existe tambien la company
 * Related UI: [[DoAComponent, ApplicationStepsComponent]]
 */

export class HistoryComment {
  user: User;
  reason: string;
  company?: Company;
  created_at: Date;
}

@Component({
  selector: 'ag-history-comments',
  templateUrl: './history-comments.component.html',
  styleUrls: ['./history-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoryCommentsComponent {

  @Input() public comments: HistoryComment[];
  @Input() public user: User;
  @Input() public company: Company;
  @Input() public compact: boolean = false;

  constructor() { }
}
