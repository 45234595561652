import { AfterViewInit, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { MapsAPILoader } from '@ng-maps/core';
import { v4 as uuidv4 } from 'uuid';
declare var google;

import { Order } from '../../../company/modules/commercial/models/order.model';
import { Company } from '../../../models/company.model';
import { GroupBy } from '../../../models/group-by.model';
import { Product } from '../../../models/product.model';

@Component({
  selector: 'gmap-view',
  templateUrl: './gmap-view.component.html',
  styleUrls: ['./gmap-view.component.scss']
})
export class GmapViewComponent implements AfterViewInit {

  @Input() set ordersByProduct(data: GroupBy<Product, Order>[]) {
    this.uniqueIds = {}; // Reset IDs map

    let uniqueId = new Date().getTime();

    data.forEach((group, gindex) => {
      group.values.forEach((order, oindex) => {
        this.uniqueIds[gindex + '_' + oindex] = uniqueId++; // This allows the UI to identify Order with no ID
      });
    });

    this._ordersByProduct = data;
  }
  get ordersByProduct(): GroupBy<Product, Order>[] {
    return this._ordersByProduct;
  }

  @Input() public companyId: number;
  @Input() public company: Company;

  public map;
  public uniqueIds: any;

  private UUID: string;
  private _ordersByProduct: GroupBy<Product, Order>[];
  private currentInfowindow;
  private latlngbounds;
  private locations_id;
  private markers: Array<any> = [];

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private router: Router
  ) {
    this.UUID = 'map-' + uuidv4();
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  private initMap(): void {
    this.locations_id = {};

    this.mapsAPILoader.load().then(() => {
      this.map = new google.maps.Map(document.getElementById('map'), {
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        clickableIcons: false,
        maxZoom: 16,
        minZoom: 3,
        mapId: this.UUID
      });

      this.latlngbounds = new google.maps.LatLngBounds();

      this.ordersByProduct.forEach((product, i) => {
        product.values.forEach((order, j) => {
          this.addMarker(order, this.uniqueIds[i + '_' + j]);
        });
      });

      // Markers Clustering
      const markerCluster = new MarkerClusterer(this.map, this.markers, {
        imagePath: '/assets/img/gmap/m'
      });

      // Center map and adjust Zoom based on the position of all markers.
      this.map.setCenter(this.latlngbounds.getCenter());
      this.map.fitBounds(this.latlngbounds);
    });
  }

  private addMarker(order: Order, uniqueId: number): void {
    if (!order.business_detail.delivery.geolocations) return;

    order.business_detail.delivery.geolocations.forEach((geolocation) => {
      // Apply offset to avoid overlapping
      if (this.locations_id[geolocation.place_id] != undefined) {
        this.locations_id[geolocation.place_id] += 0.002;
      } else this.locations_id[geolocation.place_id] = 0;

      const lng = geolocation.geometry.location.lng + this.locations_id[geolocation.place_id],
        lat = geolocation.geometry.location.lat + (Math.random() * 0.002) - 0.001,
        scope = this;

      let elem = document.getElementById(uniqueId + '_' + geolocation.place_id).firstElementChild;
      if (!elem) return;

      const infowindow = new google.maps.InfoWindow({
        // maxWidth: 250,
        content: elem
      });

      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: lat, lng: lng },
        map: this.map
      });

      marker.addListener('click', function (): void {
        if (scope.currentInfowindow) {
          scope.currentInfowindow.close();
        }
        scope.currentInfowindow = infowindow;
        infowindow.open(scope.map, marker);
      });

      // Extend each marker's position in LatLngBounds object.
      this.latlngbounds.extend(marker.position);

      // For clustering
      this.markers.push(marker);
    });
  }

  public gotoOrder(id): void {
    if (id) this.router.navigateByUrl('/company/' + this.companyId + '/order/' + id);
    else if (!this.company) this.router.navigateByUrl('/login');
  }
}
