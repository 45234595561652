<dl *ngIf="hide && options?.length">
  <dt>{{ title|translate }}</dt>
  <ng-container *ngFor="let option of options; let i = index">
    <dd
    *ngIf="i < maxItems"
    [class]="option.class">
      <a (click)="selectFilter(option.id)">
        <span *ngIf="option.iconHtml" [innerHtml]="option.iconHtml"></span>{{ option.name|translate }}
      </a>
    </dd>
  </ng-container>
  <dd class="text-right small"
  *ngIf="options?.length > maxItems">
    <a (click)="showAllOptions()">{{ 'FILTERS.SEE_ALL'|translate }}</a>
  </dd>
</dl>

<ng-template #moreOptions>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ title|translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-xs-12 columns">
        <p
        *ngFor="let option of options"
        [class]="option.class">
          <a (click)="selectFilter(option.id)">
            <span *ngIf="option.iconHtml" [innerHtml]="option.iconHtml"></span>{{ option.name }}
          </a>
        </p>
      </div>
    </div>
  </div>
</ng-template>