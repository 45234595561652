import { Directive, OnInit, Input } from '@angular/core';
import { Validator, AbstractControl, ValidatorFn, NG_VALIDATORS } from '@angular/forms';
import { validateRfc } from '../../models/fiscal-id.model';

@Directive({
  selector: '[validRfc]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidRfcDirective, multi: true }]
})
export class ValidRfcDirective implements OnInit, Validator {

  @Input('validRfc') private condition: boolean;

  private validatorFn: ValidatorFn;

  /** @ignore */
  constructor() { }

  /** @ignore */
  ngOnInit(): void {
    this.validatorFn = this.validator(this.condition);
  }

  /** @ignore */
  validate(control: AbstractControl): {} {
    this.validatorFn = this.validator(this.condition);
    return this.validatorFn(control);
  }

  private validator(condition: boolean, aceptarGenerico: boolean = true): ValidatorFn {
    return (control: AbstractControl): {} => {
      const rfc: string = control.value;
      if (!condition || !rfc) return true;

      return validateRfc(rfc) ? null : {
        "validFiscalId": false
      };
    }
  }
}
