import { instanceToPlain } from "class-transformer";

/**
 * Downloads the provided content as a JSON file.
 * The content is first converted to a plain object, excluding properties with specified prefixes.
 * 
 * @param {any} content - The content to be converted to JSON and downloaded.
 * @param {string} [fileName="export"] - The name of the file to be downloaded, default is "export".
 */
export function downloadAsJSON(content: any, fileName: string = "export") {
  const myFile = new Blob([JSON.stringify(instanceToPlain(content, { excludePrefixes: ['_'] }), undefined, 2)], { type: 'text/plain' });

  const a = document.createElement("a");
  a.classList.add("hidden");
  document.body.appendChild(a);

  const url = window.URL.createObjectURL(myFile);
  a.href = url;
  a.download = fileName + '.json';
  a.click();
  window.URL.revokeObjectURL(url);
}

/**
 * Recursively sorts the keys of an object and any nested objects or arrays.
 *
 * @param {any} obj - The object to be sorted.
 * @returns {any} - The object with sorted keys.
 */
function sortObjectKeys(obj: any): any {
  if (obj === null || obj === undefined || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    // Create a shallow copy to avoid mutation
    return sortObjectKeys({ ...obj });
  }

  return Object.keys(obj).sort().reduce((sortedObj, key) => {
    sortedObj[key] = sortObjectKeys(obj[key]);
    return sortedObj;
  }, {});
}

/**
 * Generates a simple hash code from an object by sorting its keys and hashing the resulting JSON string.
 *
 * @param {any} obj - The object to be hashed.
 * @returns {number} - The resulting hash code.
 */
export function simpleHash(obj: any): string {
  const source = JSON.stringify(sortObjectKeys(instanceToPlain(obj)));

  if (source.length === 0) return '0';

  let hash = 0;
  for (let i = 0; i < source.length; i++) {
    const charCode = source.charCodeAt(i);
    hash = ((hash << 5) - hash) + charCode;
    hash |= 0; // Convert to 32bit integer
  }

  return hash > 0 ? 'p' + hash : 'n' + Math.abs(hash);
}

/**
 * Checks if a given string is a valid JSON string.
 *
 * @param {string} str - The string to check.
 * @returns {any | false} The parsed JSON object if the string is valid JSON, otherwise false.
 *
 * @example
 * // Returns { "key": "value" }
 * isValidJSON('{"key": "value"}');
 *
 * @example
 * // Returns false
 * isValidJSON('Invalid JSON string');
 */
export function isValidJSON(str: string): any | false {
  try {
    return JSON.parse(str);
  } catch {
    return false;
  }
}
