import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ag-timestamp',
  templateUrl: './timestamp.component.html',
  styleUrls: ['./timestamp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimestampComponent implements OnInit {

  @Input() public date: Date;
  @Input() public showSeconds: boolean;
  @Input() public showTime: boolean = true;
  @Input() public inline: boolean;

  /** The language currently used. */
  public currentLang: string;

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;
  }
}
