import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PusherService } from '../../services/pusher.service';
import { AttachmentFolder } from '../models/attachment.model';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {

  private baseUrl: string = '/:apiFiles/companies/:companyId';
  private fileUrl: string = this.baseUrl + '/file';
  private fileByIdUrl: string = this.fileUrl + '/:fileId';
  private foldersUrl: string = this.baseUrl + '/folders';

  private _collectionSubjects: { [eventKey: string]: BehaviorSubject<AttachmentFolder[]> } = {};

  constructor(
    private http: HttpClient,
    private pusherService: PusherService
  ) { }

  private getFolder(companyId: number, entity: string, entityId: number | string, externalId?: number): Observable<AttachmentFolder[]> {
    const url = this.foldersUrl
      .replace(":companyId", companyId + '');

    // Params Object
    let params: {
      entity: string,
      entity_id: number | string,
      external_id?: number
    } = { entity, entity_id: entityId };
    if (externalId) params = { ...params, external_id: externalId };

    return this.http.get<AttachmentFolder[]>(url, { params: params }).pipe(map(response => {
      return plainToInstance(AttachmentFolder, response);
    }));
  }

  /**
   * Watches for changes in attachment folders for a given company, entity, entity ID, and optional external ID.
   *
   * @param {number} companyId - The ID of the company.
   * @param {string} entity - The entity type.
   * @param {number | string} entityId - The ID of the entity.
   * @param {number} [externalId] - The optional external ID.
   * @returns {Observable<AttachmentFolder[]>} - An observable of attachment folders.
   */
  public watchFolder(companyId: number, entity: string, entityId: (number | string), externalId?: number): Observable<AttachmentFolder[]> {
    const eventKey = [entity, String(entityId), externalId ? String(externalId) : null].filter(Boolean).join('_');

    return this.pusherService.subjectManager(
      {
        collection: this._collectionSubjects,
        key: eventKey,
        getData: () => this.getFolder(companyId, entity, entityId, externalId)
      },
      {
        channel: 'public',
        event: eventKey
      },
      []);
  }

  /** Returns a file. */
  public get(companyId: number, fileId: number): Observable<any> {
    const url = this.fileByIdUrl
      .replace(":companyId", companyId + '')
      .replace(":fileId", fileId + '');

    return this.http.get<any>(url);
  }

  /** Deletes a file. */
  public delete(companyId: number, fileId: number): Observable<boolean> {
    const url = this.fileByIdUrl
      .replace(":companyId", companyId + '')
      .replace(":fileId", fileId + '');

    return this.http.delete<boolean>(url);
  }

  public attach(companyId: number, files: FileList, entity: string, entityId: (number | string), externalId?: number, myCompanyOnly?: boolean): Observable<Array<any>> {
    const url = this.fileUrl
      .replace(":companyId", companyId + '');

    const form = new FormData();

    for (let i = 0; i < files.length; i++) {
      form.append('files[]', files[i]);
    }

    form.append("entity", entity);
    form.append("entity_id", String(entityId));
    if (externalId) form.append("external_id", String(externalId));
    if (myCompanyOnly) form.append("private", "true");

    return this.http.post<Array<any>>(url, form);
  }
}
