import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm, UntypedFormGroup } from '@angular/forms';

import { InvitationAbstract } from '../../../company/models/invitation-abstract';
import { CounterpartsService } from '../../../company/services/counterparts.service';
import { Company } from '../../../models/company.model';
import { Country } from '../../../models/country.model';
import { FiscalId } from '../../../models/fiscal-id.model';
import { CompanyService } from '../../../services/company.service';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { LocationService } from '../../../services/location.service';

@Component({
  selector: 'bulk-invite',
  templateUrl: './bulk-invite.component.html',
  styleUrls: ['./bulk-invite.component.scss']
})
export class BulkInviteComponent extends InvitationAbstract implements OnInit, OnDestroy, OnChanges {

  @ViewChild('bulkInvitationForm') private readonly bulkInvitationForm: NgForm;

  public selectedCountry: Country = null;

  public rowsNames: string[];
  public bulkmode: boolean;
  public new_companies: Company[];
  public companiesCollectionByBulkRowOnBulk = new Map<string, Company[]>();
  public selectedCompaniesOnBulk = new Map<string, Company>();

  /** @ignore */
  constructor(
    private counterpartsService: CounterpartsService,
    private companyService: CompanyService,
    private locationService: LocationService,
    private dataDogLoggerService: DataDogLoggerService
  ) {
    super();
  }

  /** @ignore */
  ngOnInit(): void {
    this.subscribeEventListeners()
    this.resetData();
    this.fetchCountries();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.bulkInvitationForm)
      this.formOnChangeEmitter.emit(this.bulkInvitationForm);
  }

  private subscribeEventListeners(): void {
    // Listening submitEvent from parent component
    this.subscriptions.push(this.submitEvent.subscribe(() => {
      this.bulkInvitationForm.ngSubmit.emit();
    }));

  }

  private fetchCountries(): void {
    if (!this.countries) this.subscriptions.push(this.locationService.getCountryCodes().subscribe(countries => {
      this.countries = countries;

      if (this.company.market.country_id) {
        this.selectedCountry = countries.filter(country => {
          return country.id === this.company.market.country_id;
        })[0];
      }
    }));
  }

  public addCompanies(n: number): void {
    for (let i = 0; i < n; i++) {
      // Add empty companies
      this.new_companies.push(new Company())
    }

    /* This is used by the ngFor, prevents unnecessary rendering and generates FormGroups IDs */
    this.rowsNames = new Array(this.new_companies.length).fill('').map((currentValue, index) => {
      return 'bulk_' + index;
    });
  }

  private resetData(): void {
    this.emitSubmitError(null);

    /* RESET values for BULK Invite */
    this.new_companies = [];
    this.companiesCollectionByBulkRowOnBulk = new Map<string, Company[]>();
    this.selectedCompaniesOnBulk = new Map<string, Company>();
    this.addCompanies(10);
  }

  public onFiscalIdInputChange(bulkRowIndex: string, fg: UntypedFormGroup): void {
    this.clearInviteFormRow(bulkRowIndex, fg);
    const isAnInvalidFiscalId = !fg.controls.fiscal_id.valid;
    if (isAnInvalidFiscalId) return;

    const fiscalId = fg.value.fiscal_id;
    if (!fiscalId) return;

    // Check for the fiscal id cache
    const companyFromCache = this.getCompanyFromCache(fiscalId);
    if (companyFromCache) {
      this.companiesCollectionByBulkRowOnBulk[bulkRowIndex] = companyFromCache;
      return
    }

    this.processing = true;
    this.subscriptions.push(this.companyService.getCompaniesByFiscalValue(this.company.id, this.selectedCountry.id, fiscalId).subscribe(companies => {
      this.companiesCollectionByBulkRowOnBulk[bulkRowIndex] = companies;
      this.addNewFiscalIdToCache(fiscalId, companies);
      this.processing = false;
    }));
  }

  public resetFormRow(index: number, formgroup: UntypedFormGroup): void {
    this.new_companies[index] = new Company();
    formgroup.reset();

    this.clearInviteFormRow('bulk_' + index, formgroup);

    formgroup.markAsPristine();
    formgroup.markAsUntouched();
  }

  private clearInviteFormRow(bulkRowIndex: string, formGroup: UntypedFormGroup): void {
    delete this.companiesCollectionByBulkRowOnBulk[bulkRowIndex];
    delete this.selectedCompaniesOnBulk[bulkRowIndex];

    formGroup.controls.name.setValue('');
    formGroup.controls.activity.setValue('');
    if (formGroup.controls.email) formGroup.controls.email.setValue('');
  }

  public onSelectCompany(companyId, bulkRowIndex: string, fg: UntypedFormGroup): void {
    this.selectedCompaniesOnBulk[bulkRowIndex] = this.companiesCollectionByBulkRowOnBulk[bulkRowIndex].find(company => company.id === parseInt(companyId))
    this.setCompanyInfoInRow(bulkRowIndex, this.selectedCompaniesOnBulk[bulkRowIndex], fg);
  }

  private setCompanyInfoInRow(bulkRowIndex: string, company: Company, fg: UntypedFormGroup): void {
    this.new_companies[bulkRowIndex] = company;
    if (company.activity) fg.controls.activity.setValue(company.activity.id);
    if (fg.controls.email) fg.controls.email.reset();
  }

  public submit(form: NgForm): void {
    this.emitSubmitError(null);

    this.processing = true;
    let guestAndUserHasTheSameEmail: boolean;
    let payload: Company[] = [];
    for (const bulkRowIndex in form.control.value) {
      const formRowValues = form.control.value[bulkRowIndex];

      const fiscalId = formRowValues['fiscal_id'];
      if (!fiscalId) continue

      // Selector Id Value
      const companyId = formRowValues['name']
      const activityId = formRowValues['activity'];
      const email = formRowValues['email'];
      // FAS-3091
      guestAndUserHasTheSameEmail = (email && email.includes(this.user.email));
      if (guestAndUserHasTheSameEmail) return
      let newCompany: Company = this.new_companies[bulkRowIndex];
      if (newCompany?.id) {
        // Existing Company
        const isAnImportedCompany = (newCompany.activation_level && newCompany.activation_level.id === 3)
        if (isAnImportedCompany) {
          // Overwrite Company values
          const companyName = this.companiesCollectionByBulkRowOnBulk[bulkRowIndex].find(company => company.id + '' === companyId).name;
          newCompany.name = companyName;
          newCompany.fiscal_id.value = fiscalId;
          newCompany.activity = this.activities.find(activity => activity.id === activityId);
          newCompany.admin_email = email;
        }
      } else {
        // When is a new Company => formRowValues['name'] is an input label so is the name value
        newCompany = this.newCompany(formRowValues['name'], fiscalId, activityId, email);
      }
      payload.push(newCompany);
    }

    if (guestAndUserHasTheSameEmail) {
      this.processing = false;
      const errorMessage = 'COUNTERPARTIES.NETWORK.MODAL.EMAILS_ERROR';
      this.emitSubmitError(errorMessage);
      return
    }
    payload = this.filterPayload(payload);

    if (payload.length)
      this.sendInvitations(payload);
    else
      this.closeModalEventEmit();
  }

  private filterPayload(payload: any): any {
    const fiscal_id = this.company.fiscal_id ? this.company.fiscal_id.value : undefined;
    const payloadFiltered = payload.filter((elem, index, self) =>
      elem.fiscal_id.value && // No fiscal ID
      (elem.fiscal_id.value !== fiscal_id) && // Same as Current Company
      index === self.findIndex((t) => (t.fiscal_id.value === elem.fiscal_id.value && t.name === elem.name)) // Duplicate
      //  &&
      // !this.counterparts.find(c => {
      //   const c_fiscal_id = c.fiscal_id ? c.fiscal_id.value : undefined;
      //   return c_fiscal_id === elem.fiscal_id.value
      // }) // Not in my network
    );

    return payloadFiltered;
  }

  private sendInvitations(payload: any): void {
    this.subscriptions.push(
      this.counterpartsService.invite(this.company.id, payload).subscribe({
        next: response => {
          this.closeModalEventEmit();
        },
        error: error => {
          this.processing = false;
          const errorMessage = error.error.message.ext_invalid_action || error.error.info.message;
          this.emitSubmitError(errorMessage);
          this.dataDogLoggerService.warn(error.message, error.error);
        }
      }));
  }

  private newCompany(name: string, fiscal_id: string, activity_id: number, email: string): Company {
    let c = new Company();
    c.fiscal_id = new FiscalId();

    c.name = name;
    c.fiscal_id.value = fiscal_id;
    c.activity = this.activities.find(activity => activity.id === activity_id);
    c.admin_email = email;

    // Doesn't work with bulk load
    if (this.selectedCountry) {
      c.address.country = this.selectedCountry;
      // Hardcoded fiscal_id type
      if (this.selectedCountry.fiscalIds.length) c.fiscal_id.type = this.selectedCountry.fiscalIds[0].type;
    }

    return c;
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    super.ngOnDestroy()
  }
}
