import { Component, ElementRef, Input, OnInit, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MapsAPILoader } from '@ng-maps/core';

import { environment } from '../../../../environments/environment';

declare var google;
const noop = () => { };

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => GooglePlacesComponent),
  multi: true
};

@Component({
  selector: 'google-places',
  templateUrl: './google-places.component.html',
  styleUrls: ['./google-places.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class GooglePlacesComponent implements OnInit {

  @ViewChild("search", { static: true }) private readonly searchElementRef: ElementRef;

  @Input() private maxItems: number;
  @Input() public required: boolean = false;
  @Input() public disabled: boolean;

  public hasMaxItems: boolean = false;
  public search_string: string;

  private innerValue: Array<any>;

  constructor(
    private mapsAPILoader: MapsAPILoader
  ) { }

  ngOnInit(): void {
    this.mapsAPILoader.load().then(() => {
      let options = { // Default options settings
        types: ["(regions)"],
        fields: ['name', 'formatted_address', 'place_id', 'geometry', 'address_components'],
        componentRestrictions: null
      };

      if (environment.google.maps.Autocomplete) { // Environment level settings
        options.componentRestrictions = environment.google.maps.Autocomplete.componentRestrictions;
      }

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, options);
      autocomplete.addListener("place_changed", () => {
        let place = autocomplete.getPlace();
        if (place.geometry) {
          let temp_locations = this.value || [];
          temp_locations.push(place);

          this.value = temp_locations;

          this.search_string = '';
          this.searchElementRef.nativeElement.focus();
          this.searchElementRef.nativeElement.blur();
        }
      });
    });
  }

  public removeGeolocation(place_id: string): void {
    this.value = this.value.filter(geolocation => {
      return geolocation.place_id !== place_id;
    });
  }

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: Array<any>) => void = noop;

  set value(geolocations) {
    this.innerValue = geolocations;
    this.onChangeCallback(this.innerValue);

    this.searchElementRef.nativeElement.focus();
    this.searchElementRef.nativeElement.blur();
    if (this.maxItems && this.maxItems === this.innerValue.length) {
      this.hasMaxItems = true;
    } else {
      this.hasMaxItems = false;
    }
  }

  get value(): any[] {
    return this.innerValue;
  };

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  writeValue(value) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }
}
