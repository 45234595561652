import { Injectable } from '@angular/core';

@Injectable()
export class LeaveGuard {

  constructor() { }

  canDeactivate(component: any) {
    return component.canLeave();
  }
}
