export class S3UploadedFile {
  file: string; // file and key are the same
  key: string;
  name: string;
  description?: string;

  constructor(data: Partial<S3UploadedFile> = {}) {
    Object.assign(this, data);
    this.key = data.file;
  }
}

export class PresignedUrl {
  url: string;
  path: string;
  key: string;
}
