export class Label {
  name: string;
  color: string;
  id: number;
  parent?: number;
  relevance: number;

  /** Used for UI purposes. */
  status?: boolean;
  /**
   * Represents a label partially assign to current selection.
   * Used for UI purposes.
   */
  partial?: boolean;

  constructor(data: Partial<Label> = {}) {
    this.color = '#000000';
    // this.status = false;

    Object.assign(this, data);
  }

  public reset(): void {
    this.status = false;
    this.partial = false;
  }
}