import { forwardRef } from '@angular/core';
import { Type } from "class-transformer";
import { User } from './user.model';
import { Company } from '../../models/company.model';
import { Role } from './role.model';
import { Invitation } from '../../models/invitation.model';

/**
 * An Account defines the [[Role]] that a [[User]] plays in a [[Company]].
 * 
 * There is only one Account per [[User]] and [[Company]].
 */
export class Account {
  /** Agree's internal unique identifier. */
  readonly id: number;

  @Type(forwardRef(() => User) as any)
  readonly user: User;

  readonly role: Role;

  @Type(forwardRef(() => Company) as any)
  readonly company: Company;
  readonly invitation?: Invitation;

  /** Parent account ID. */
  readonly parent?: {
    company: Company;
    id: number;
  };

  private _roleObject;

  get is(): {
    admin: boolean,
    commercial_with_users: boolean,
    commercial: boolean,
    counterpart_admin: boolean,
    logistics: boolean,
    operations: boolean,
    business_officer: boolean,
    financial: boolean
  } {
    if (!this._roleObject || this._roleObject._id !== this.role.id) {
      let admin = this.role.id === 1 || this.role.id === 10 || this.role.id === 11 || this.role.id === 12, // Admin or Super Admin
        commercial_with_users = this.role.id === 2;
      this._roleObject = {
        _id: this.role.id,
        admin: admin,
        commercial_with_users: admin || commercial_with_users,
        commercial: admin || commercial_with_users || this.role.id === 3,
        counterpart_admin: admin || this.role.id === 4,
        logistics: admin || this.role.id === 6,
        operations: admin || this.role.id === 5,
        business_officer: admin || this.role.id === 7,
        financial: admin || this.role.id === 8
      }
    }

    return this._roleObject;
  }

  /** For labeleable/zoneable entities. */
  get entity(): string { return 'account'; }

  get isRepresentative(): boolean {
    return this.parent?.company ? true : false;
  }

  /** 
   * @deprecated
   * @ignore */
  readonly company_id: number;

  /** 
   * @deprecated
   * @ignore */
  readonly user_id: number;

  /** 
   * @deprecated
   * @ignore */
  readonly role_id: number;

  constructor(data: Partial<Account> = {}) {
    Object.assign(this, data);
  }
}
