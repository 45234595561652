<div class="easy-step" [class.numbered]="numbered">
  <ng-container *ngFor="let step of steps">
    <ng-container *ngTemplateOutlet="stepTemplate; context: {
      step: step,
      grow: true
    }"></ng-container>
  </ng-container>
  <ng-container *ngIf="action">
    <ng-container *ngTemplateOutlet="stepTemplate; context: {
      step: action
    }"></ng-container>
  </ng-container>
</div>
<p class="small text-muted" *ngIf="footer" [innerHtml]="footer"></p>

<ng-template #stepTemplate let-step="step" let-grow="grow">
  <div [class.grow]="grow">
    <h5 class="underline"
    *ngIf="step.title"
    [innerHtml]="step.title"></h5>
    <div>
      <ul class="checked" *ngIf="step.bullets?.length">
        <li
        *ngFor="let bullet of step.bullets"
        [innerHtml]="bullet"></li>
      </ul>
      <button type="button"
      class="btn btn-agree btn-lg"
      *ngIf="step.button"
      (click)="actionClick.emit()">
        <i *ngIf="step.icon" class="material-symbols-rounded hidden-xs">{{ step.icon }}</i>
        {{ action.button }}
      </button>
    </div>
  </div>
</ng-template>