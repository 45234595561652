import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { instanceToInstance, plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JSend } from '../../models/jsend.model';

@Injectable({
  providedIn: 'root'
})
export class FormReuseService {

  private baseUrl: string = '/:apiBase/companies/:companyId/form-data/:formId';
  private dataPath: string = this.baseUrl + '/data';
  private selectionPath: string = this.baseUrl + '/selection';

  constructor(
    private http: HttpClient
  ) { }

  public get(companyId: number, formId: string): Observable<FormReuseData> {
    const url = this.baseUrl
      .replace(":companyId", companyId + '')
      .replace(":formId", formId);

    return this.http.get<JSend<{
      data: FormReuseData
    }>>(url).pipe(map(response => {
      return plainToInstance(FormReuseData, response.data);
    }));
  }

  public save(companyId: number, formId: string, data: { [field: string]: any }): Observable<any> {
    let temp = instanceToInstance(data);

    Object.keys(temp).forEach(key => {
      if (temp[key] === null || temp[key] === undefined) {
        // Remove null or undefined keys
        delete temp[key];
      }
    });

    if (Object.keys(temp).length > 0) {
      const url = this.dataPath
        .replace(":companyId", companyId + '')
        .replace(":formId", formId);
  
      return this.http.post<any>(url, temp);
    }
  }

  public selection(companyId: number, formId: string, selection: { [field: string]: boolean }): Observable<any> {
    const url = this.selectionPath
      .replace(":companyId", companyId + '')
      .replace(":formId", formId);

    return this.http.post<any>(url, selection);
  }
}

class FormReuseData {
  data: { [key: string]: any };
  selection: { [key: string]: boolean };
}