import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Market } from '../../../company/modules/commercial/models/market.model';
import { Order } from '../../../company/modules/commercial/models/order.model';
import { Company } from '../../../models/company.model';
import { GroupBy } from '../../../models/group-by.model';
import { Product } from '../../../models/product.model';

/**
 * OrdersTable is a component that displays a table of [[Order|Orders]].
 *
 * ## Usage
 * ``` html
 * <orders-table
 * [company]="company"
 * [hideColumns]="['cad']"
 * [orders]="ordersByProduct"
 * [(ngModel)]="selection"
 * [enableSelection]="company?.market.configuration.order.massive_book?.enabled"
 * [market]="company.market"></orders-table>
 * ```
 *
 * ### Related UI components:
 * - [[TradingOrdersComponent]]
 * - [[OrdersComponent]]
 * - [[LandingComponent]]
 */
@Component({
  selector: 'orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OrdersTableComponent),
    multi: true
  }]
})
export class OrdersTableComponent implements ControlValueAccessor {

  @Input() public company: Company;
  /** [[Order|Orders]] to dsiplay. */
  @Input() public orders: GroupBy<Product, Order>[];
  @Input() public market: Market;
  /** List of columns of the table to hide. */
  @Input() public hideColumns: string[] = [];
  /**
   * Whether the selection checkboxes are shown or not.
   * Only for offers.
  */
  @Input() public enableSelection: boolean;

  // ngModel
  private _value: Order[];
  public get selectedOrders(): Order[] { return this._value }
  public set selectedOrders(v: Order[]) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  /** @ignore */
  constructor() { }

  // TODO: This should be a pipe
  public countryAndPort(order: Order): string {
    return order.product.attributes.country.name + ' / ' + order.business_detail.port.name;
  }

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.selectedOrders = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
