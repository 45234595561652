import { Directive, Input, OnInit } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[validDNI]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidDNIDirective, multi: true }]
})
export class ValidDNIDirective implements OnInit, Validator {

  @Input('validDNI') private condition: boolean;

  private validatorFn: ValidatorFn;

  constructor() { }

  /** @ignore */
  ngOnInit(): void {
    this.validatorFn = this.validator(this.condition);
  }

  /** @ignore */
  validate(control: AbstractControl): {} {
    this.validatorFn = this.validator(this.condition);
    return this.validatorFn(control);
  }

  private validator(condition: boolean): ValidatorFn {
    return (control: AbstractControl): {} => {
      const dni: string = control.value;
      if (!condition || !dni) return true;

      return this.validateDNI(dni) ? null : {
        "validIdentityId": false
      };
    }
  }

  private validateDNI(dni: string): boolean {
    const re = /^\d{1,2}\.?\d{3}\.?\d{3}$/;

    return re.test(dni);
  }
}
