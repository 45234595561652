import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {

  @Output() public readonly clickOutside = new EventEmitter();

  @HostListener('document:click', ['$event.target'])
  public onClick(target): void {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    const cg1 = this.elementRef.nativeElement.getAttribute('data-click-group');
    let cg2;

    if (cg1) {
      let ancestor = target;
      while (ancestor && ancestor.nodeName !== 'BODY' && !cg2) {
        cg2 = ancestor.getAttribute('data-click-group');
        ancestor = ancestor.parentNode;
      }
    }

    const sameClickGroup = cg1 && cg2 && cg1 === cg2;

    if (!clickedInside && !sameClickGroup) {
      this.clickOutside.emit(target);
    }
  }

  constructor(private elementRef: ElementRef) {
  }
}
