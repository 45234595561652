import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { User } from '../auth/models/user.model';
import { EntityBinding } from '../models/entity-binding.model';
import { Envelope, EnvelopeSigner } from '../models/envelope.model';
import { JSend } from '../models/jsend.model';
import { PusherMessage } from '../models/pusher-message.model';
import { UploadS3Service } from '../ui/services/upload-s3.service';
import { buildFilters } from '../utilities/filters';
import { simpleHash } from '../utilities/json-tools';
import { PusherService } from './pusher.service';

@Injectable({
  providedIn: 'root'
})
export class SignaturesService {

  private baseUrl = '/:apiSignatures';
  private envelopesPath = this.baseUrl + '/envelopes/:companyId';
  private envelopeByIdPath = this.envelopesPath + '/:envelopeId';
  private signersPath = this.envelopeByIdPath + '/signers';
  private envelopeFilePath = this.envelopeByIdPath + '/files/:fileId';
  private envelopeSignedFilePath = this.envelopeFilePath + '/signed';
  private envelopeBindPath = this.envelopeByIdPath + '/bind';
  private uploadFilesEnvelopePath = this.envelopesPath + '/upload-url';

  private _collectionSubjects: { [eventKey: string]: BehaviorSubject<{ body: Envelope[], headers: HttpHeaders }> } = {};
  private _itemSubjects: { [envelopeId: number]: BehaviorSubject<Envelope> } = {};
  private readonly queryMap: Record<string, string> = {
    'envelope_status': 'filters[status]',
    'entity': 'filters[entity]',
    'entity_id': 'filters[entity_id]'
  };

  constructor(
    private http: HttpClient,
    private pusherService: PusherService,
    private uploadS3Service: UploadS3Service
  ) { }

  /** Retrieves [[Comapny|Company's]] [[Envelope|Envelopes]]. */
  public watch(companyId: number, filters?: any): Observable<{ body: Envelope[], headers: HttpHeaders }> {
    const eventKey = simpleHash(arguments);

    return this.pusherService.subjectManager(
      {
        collection: this._collectionSubjects,
        key: eventKey,
        getData: () => this.get(companyId, filters)
      },
      {
        channel: `company_${companyId}`,
        event: 'envelope'
      });
  }

  /** Retrieves an [[Envelope]] by ID. */
  public watchEnvelope(companyId: number, envelopeId: number): Observable<Envelope> {
    return this.pusherService.subjectManager(
      {
        collection: this._itemSubjects,
        key: envelopeId,
        getData: () => this.getEnvelope(companyId, envelopeId)
      },
      {
        channel: `company_${companyId}`,
        event: 'envelope',
        condition: (event: PusherMessage) => !event.data || event.data.id === envelopeId
      });
  }

  /** Retrieves [[Comapny|Company's]] [[Envelope|Envelopes]]. */
  private get(companyId?: number, filters?: any): Observable<{ body: Envelope[], headers: HttpHeaders }> {
    let url = this.envelopesPath
      .replace(":companyId", companyId + '');

    url = buildFilters(url, filters, this.queryMap);

    return this.http.get<JSend<{
      envelopes: Envelope[]
    }>>(url, { observe: 'response' }).pipe(map(response => {
      return { body: plainToInstance(Envelope, response.body.data.envelopes), headers: response.headers };
    }));
  }

  /** Retrieves an [[Envelope]] by ID. */
  private getEnvelope(companyId: number, envelopeId: number): Observable<Envelope> {
    const url = this.envelopeByIdPath
      .replace(":companyId", companyId + '')
      .replace(":envelopeId", envelopeId + '');

    return this.http.get<JSend<{
      envelope: Envelope
    }>>(url).pipe(map(response => {
      return plainToInstance(Envelope, response.data.envelope);
    }));
  }

  /** Creates an [[Envelope]]. */
  public create(companyId: number, envelope: Envelope, files: FileList): Observable<Envelope> {
    const url = this.envelopesPath
      .replace(":companyId", companyId + '');

    const uploadFilesUrl = this.uploadFilesEnvelopePath
      .replace(":companyId", companyId + '');

    return this.uploadS3Service.upload(uploadFilesUrl, files)
      .pipe(
        switchMap((files) => {
          envelope.files = files;
          return this.http.post<JSend<{ envelope: Envelope }>>(url, envelope);
        }),
        map(({ data }) => plainToInstance(Envelope, data.envelope))
      );
  }

  /** Edits an [[Envelope]]. */
  public edit(companyId: number, envelope: Envelope): Observable<Envelope> {
    const url = this.envelopeByIdPath
      .replace(":companyId", companyId + '')
      .replace(":envelopeId", envelope.id + '');

    return this.http.put<JSend<{
      envelope: Envelope
    }>>(url, envelope).pipe(map(response => {
      return plainToInstance(Envelope, response.data.envelope);
    }));
  }

  /**
   * Defines the [[EnvelopeSigner|Signers]] of an [[Envelope]] for my [[Company]].
   */
  public editSigners(companyId: number, envelopeId: number, user: User, signers: EnvelopeSigner[]): Observable<any> {
    const url = this.signersPath
      .replace(":companyId", companyId + '')
      .replace(":envelopeId", envelopeId + '');

    return this.http.post<any>(url, { data: signers, user_id: user.id });
  }

  public getFile(companyId: number, envelopeId: number, fileId: number, signed?: boolean): Observable<any> {
    const url = (signed ? this.envelopeSignedFilePath : this.envelopeFilePath)
      .replace(":fileId", fileId + '')
      .replace(":companyId", companyId + '')
      .replace(":envelopeId", envelopeId + '');

    return this.http.get<JSend<{
      file: any
    }>>(url).pipe(map(response => {
      return response.data.file;
    }));
  }

  /** Bind/unbind one ore more entities to an [[Envelope]]. */
  public bind(companyId: number, envelopeId: number, bindingData: EntityBinding[]): Observable<any> {
    const url = this.envelopeBindPath
      .replace(":companyId", companyId + '')
      .replace(":envelopeId", envelopeId + '');

    return this.http.post<any>(url, { data: bindingData });
  }
}
