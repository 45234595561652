
export class FilterMultiple {
  public key;
  public value;

  get queryParamKey(): string {
    const key = `filters[${this.key}][${this.value}]`;
    return key;
  }

  public parseKeyFromQueryParamKey(queryParamKey: string): string {
    // Example: filters['filterKey'][] => return 'filterKey'
    const isMultipleFilter = queryParamKey.includes('filters[');
    if (isMultipleFilter) {
      const key = queryParamKey.split('[')[1].split(']')[0];
      return key;
    }
    return queryParamKey;
  }

  constructor(data: Partial<FilterMultiple> = {}) {
    Object.assign(this, data);
  }
}