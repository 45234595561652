import { Directive, EventEmitter, Input, OnDestroy, Output, } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Observable } from "rxjs";

import { User } from "../../auth/models/user.model";
import { CompanyActivity } from "../../models/company-activity.model";
import { Company } from "../../models/company.model";
import { Country } from "../../models/country.model";
import { SubscriptionManager } from "../../utilities/subscription-manager";

@Directive()
export abstract class InvitationAbstract extends SubscriptionManager implements OnDestroy {

  @Input() public company: Company;
  @Input() public activities: CompanyActivity[];
  @Input() public user: User;
  /**
   * When the parent component emits the "submitEvent",
   * the subscription method is called
   */
  @Input() public submitEvent: Observable<void>;

  /**
   * When the this.closeModalEvent.emit() is emitted,
   * the parent execute this.closeModal()
   */
  @Output() readonly closeModalEmitter = new EventEmitter<string>();
  /**
   * Send and set the modal error in the parent component
   * Reset this.submitErrorMessage sending null
   */
  @Output() readonly submitErrorMessageEmitter = new EventEmitter<string>();
  @Output() readonly formOnChangeEmitter: EventEmitter<NgForm> = new EventEmitter<NgForm>();

  protected cacheCompaniesByCuit: any = {};
  public countries: Country[];
  public processing: boolean;

  /** @ignore */
  constructor() {
    super();
  }

  /**
   * Cache management methods
   */
  protected addNewFiscalIdToCache(fiscalId: string, companies: Company[]) {
    this.cacheCompaniesByCuit[fiscalId] = companies;
  }

  protected getCompanyFromCache(fiscalId: string) {
    if (!this.existInCompanyCache(fiscalId)) return null;

    return this.cacheCompaniesByCuit[fiscalId];
  }

  protected existInCompanyCache(fiscalId: string) {
    return this.cacheCompaniesByCuit.hasOwnProperty(fiscalId);
  }

  /**
   * Emits an event to close the parent modal
   */
  protected closeModalEventEmit(): void {
    this.closeModalEmitter.emit();
  }
  /**
   * Emits an event to show a message error
   */
  protected emitSubmitError(message: string) {
    this.submitErrorMessageEmitter.emit(message);
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    super.ngOnDestroy();
  }
}
