import { Directive, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

@Directive({
  selector: '[counter]'
})
export class CountDownDirective implements OnChanges, OnDestroy {

  @Input() counter: number;
  @Input() interval: number;

  @Output() readonly value = new EventEmitter<number>();

  private _counterSource$ = new Subject<any>();
  private _subscription = Subscription.EMPTY;

  constructor() {
    this._subscription = this._counterSource$.pipe(
      switchMap(({ interval, count }) =>
        timer(0, interval).pipe(
          take(count),
          tap(() => this.value.emit(--count))
        )
      )
    ).subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._counterSource$.next({ count: this.counter, interval: this.interval });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
