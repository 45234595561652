import { Component, EventEmitter, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { User } from '../../../auth/models/user.model';
import { LoginService } from '../../../auth/services/login.service';
import { CompanyActivity } from '../../../models/company-activity.model';
import { Company } from '../../../models/company.model';
import { MarketService } from '../../../services/market.service';
import { SubscriptionManager } from '../../../utilities/subscription-manager';

@Component({
  selector: 'ag-network-invite-modal',
  templateUrl: './network-invite-modal.component.html',
  styleUrls: ['./network-invite-modal.component.scss']
})
export class NetworkInviteModalComponent extends SubscriptionManager implements OnInit, OnDestroy {

  @ViewChild('modal', { static: true }) private readonly modal: TemplateRef<any>;

  @Input() public company: Company;

  public activities: CompanyActivity[];
  public bulkmode: boolean;
  /**
   * Form emited by the child invitation form
   */
  public childForm: NgForm;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
  */
  public processing: boolean;
  public submitErrorMessage: string;
  /**
   * Used to send the submit event to child form
   */
  public submitEvent = new EventEmitter<void>();
  public user: User;

  private modalRef: BsModalRef;
  private modalSub: Subscription;

  constructor(
    private marketService: MarketService,
    private modalService: BsModalService,
    private loginService: LoginService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    })
  }

  public emitSubmitEvent(): void {
    this.submitEvent.emit();
  }

  public open(): void {
    this.bulkmode = false;
    this.fetchActivities();
    // Puedo pasar countries y activities como param (aunque no es tan buena práctica)
    this.openModal(this.modal);
  }

  private fetchActivities(): void {
    /* Fetch activities once */
    if (!this.activities) {
      this.processing = true;
      this.subscriptions.push(this.marketService.watchActivities().subscribe(activities => {
        this.activities = activities;
        this.processing = false;
      }));
    }
  }

  /**
   * Set modal error from child components messages
   */
  public setSubmitErrorMessage(message: string): void {
    if (!message) {
      delete this.submitErrorMessage;
      return;
    }
    this.submitErrorMessage = message;
  }

  public onFormChange(form: NgForm): void {
    // Used to disable or able submit button
    this.childForm = form;
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.closeModal();
    super.ngOnDestroy();
  }
}
