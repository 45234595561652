import { CommonModule } from '@angular/common';
import { Component, Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';

@Directive({
  selector: '[onConfirm]'
})
export class ConfirmDirective implements OnDestroy {

  @Input('confirm-options') private confirmOptions: confirmModalOptions;
  /** Close confirmation modal based on an external event. */
  @Input('confirm-close') private closeOnEvent: Observable<any>;

  @Output() readonly onConfirm = new EventEmitter();
  @Output('confirmObservations') public readonly confirmObservations = new EventEmitter();

  private modalRef: BsModalRef;
  private subscriptions: Subscription[] = [];

  constructor(
    private modalService?: BsModalService
  ) { }

  @HostListener('click', ['$event']) onClick(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.openModal(this.confirmOptions);
  }

  /** Generic Modal trigger. */
  private openModal(opt?: confirmModalOptions): void {
    this.modalRef = this.modalService.show(ModalContentComponent);

    this.subscriptions.push(this.modalRef.content.onConfirm.subscribe((observations?: string) => {
      if (observations) {
        this.onConfirm.emit(observations);
      } else {
        this.onConfirm.emit();
      }

      if (this.closeOnEvent) {
        this.subscriptions.push(this.closeOnEvent.subscribe(() => {
          this.closeModal();
        }));
      } else this.closeModal();
    }));

    if (opt) {
      Object.assign(this.modalRef.content, opt);
    }
  }

  private closeModal(): void {
    if (this.modalRef) this.modalRef.hide();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();
  }
}

// Should we move this to a separate component?

@Component({
  selector: 'confirm-modal',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  template: `
    <div class="modal-header">
      <button [disabled]="processing" type="button" class="close" (click)="bsModalRef.hide()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <h4 class="modal-title">{{ title }}</h4>
    </div>
    <div class="modal-body">
      <p [innerHTML]="body"></p>
      <form *ngIf="observations !== null && observations !== undefined" style="margin-top: 2rem;">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group" >
              <label class="control-label" for="obs">{{'GLOBAL.OBSERVATIONS'|translate}}</label>
              <textarea class="form-control" name="obs" [(ngModel)]="observations"></textarea>
            </div>  
          </div>  
        </div>  
      </form>
    </div>
    <div class="modal-footer">
      <button (click)="bsModalRef.hide()" type="button" class="btn btn-link"
      [disabled]="processing"
      data-dismiss="modal">{{ cancel }}</button>
      <button (click)="confirm()" type="button"
      [disabled]="processing"
      class="btn btn-agree">{{ action }}</button>
    </div>
  `
})

class ModalContentComponent {

  public title: string;
  public body: string;
  public cancel: string;
  public action: string;
  public onConfirm = new EventEmitter();
  public observations: string;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;

  constructor(
    private translateService: TranslateService,
    public bsModalRef: BsModalRef
  ) {
    // Default legends
    this.title = this.translateService.instant('GLOBAL.ARE_YOU_SURE');
    this.body = this.translateService.instant('GLOBAL.CANNOT_UNDONE');
    this.cancel = this.translateService.instant('GLOBAL.CANCEL');
    this.action = this.translateService.instant('GLOBAL.YES_CONFIRM');
  }

  public confirm(): void {
    this.processing = true;
    this.onConfirm.emit(this.observations);
  }
}

class confirmModalOptions {
  title?: string;
  body?: string;
  cancel?: string;
  action?: string;
  observations?: string;
}