import { Type } from "class-transformer";
import { Location } from '../../../../models/location.model';

export class UnloadLocation {

  @Type(() => Date)
  date: Date;

  observations?: string;

  address?: any;
  terminal?: string;

  location: Location;

  weight: {
    /** Maximum characters: 5. */
    gross?: number,
    tare?: number,
    net?: number,
    estimate?: number
  };

  /** @ignore */
  constructor() {
    this.address = {};
    this.weight = {};
  }
}
