import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AppliedFilter } from '../../models/filter.model';
import { FilterMultiple } from '../filter-multiple-list/filter-multiple';
import { FilterSetComponent } from '../filter-set/filter-set.component';

@Component({
  selector: 'selected-filters',
  templateUrl: './selected-filters.component.html',
  styleUrls: ['./selected-filters.component.scss']
})

export class SelectedFiltersComponent implements OnDestroy {
  public selected: any[] = [];
  private subscriptions: Subscription[] = [];

  constructor(
    private filterset: FilterSetComponent
  ) {
    this.subscriptions.push(this.filterset.filters.subscribe(res => {
      const enabledKeys = this.filterset.checkKeys();
      this.selected = res.filter(value => enabledKeys.includes(value.key) && value.title);
    }));
  }

  public clearAllFilters(): void {
    this.filterset.clearAllFilters();
  }

  public remove(filter: AppliedFilter): void {
    if (filter.type === 'multiple') {
      const multipleFilter = new FilterMultiple({ key: filter.key, value: filter.id });
      this.filterset.removeFilter(multipleFilter.queryParamKey);
    } else {
      this.filterset.removeFilter(filter.key);
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
