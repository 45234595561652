import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Account } from '../../../auth/models/account.model';
import { User } from '../../../auth/models/user.model';
import { LoginService } from '../../../auth/services/login.service';
import { Market } from '../../../company/modules/commercial/models/market.model';
import { Company } from '../../../models/company.model';
import { EnvelopeSigner } from '../../../models/envelope.model';
import { FiscalId, personType, validateCuit } from '../../../models/fiscal-id.model';
import { CompanyService } from '../../../services/company.service';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { MarketService } from '../../../services/market.service';
import { SignersService } from '../../../services/signers.service';

@Component({
  selector: 'ag-signers-modals',
  templateUrl: './signers-modals.component.html',
  styleUrls: ['./signers-modals.component.css']
})
export class SignersModalsComponent implements OnInit, OnDestroy {

  @ViewChild('signerModal', { static: true }) private readonly signerModal: TemplateRef<any>;

  @Input() public company: Company;
  @Input() public companyId: number;
  @Input('request-file') public requestFile: boolean = true;

  @Output() public readonly submitted = new EventEmitter();

  public modalError: string;
  public market: Market;
  public newSigner: EnvelopeSigner;
  public newSigner_file: FileList;
  public person_type: 'PERSON' | 'COMPANY';
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;

  private account: Account;
  private user: User;
  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private companyService: CompanyService,
    private loginService: LoginService,
    private modalService: BsModalService,
    private signersService: SignersService,
    private marketService: MarketService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  ngOnInit(): void {
    this.getMarket();

    this.subscriptions.push(this.companyService.watchAccount().subscribe(account => {
      if (account) {
        this.account = account;
      }
    }));

    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    }));
  }

  private getMarket(): void {
    this.subscriptions.push(this.marketService.watch().subscribe(market => {
      this.market = market;
    }));
  }

  /** Opens the [[EnvelopeSigner|Signer]] creation modal. */
  public add(autofill?: boolean): void {
    if (!this.market) {
      // For admin manage purpose
      this.market = this.company.market;
    }

    if (!this.processing) {
      this.modalError = undefined;
      this.newSigner = new EnvelopeSigner({
        company_id: this.companyId || this.company.id,
        fiscal_id: new FiscalId(this.market.configuration.company.fiscal_id)
      });
      this.newSigner_file = undefined;
      this.person_type = personType(this.company.fiscal_id);

      if (autofill && this.person_type == 'PERSON') {
        const isFromCompany = (this.companyId || this.company.id) == this.account.company.id;

        this.newSigner = {
          ...this.newSigner,
          name: this.company.names || this.company.names,
          last_name: (this.company.first_surname || '') + (this.company.second_surname ? ' ' + this.company.second_surname : ''),
          fiscal_id: this.company.fiscal_id,
          email: isFromCompany ? this.user.email : '',
          phone: isFromCompany && this.user.phone?.phone_number ? this.user.phone.phone_area_code + ' ' + this.user.phone.phone_number : ''
        };
      }

      this.openModal(this.signerModal);
    }
  }

  /** Opens the [[EnvelopeSigner|Signer]] edit modal. */
  public edit(signer: EnvelopeSigner): void {
    if (!this.processing) {
      this.modalError = undefined;
      this.newSigner = new EnvelopeSigner(signer);
      this.newSigner_file = undefined;
      this.openModal(this.signerModal);
    }
  }

  /** Form's submit. */
  public submit(): void {
    this.processing = true;
    this.modalError = undefined;
    if (this.newSigner.id) {
      // Edit
    } else {
      // Create
      const file = this.newSigner_file?.length ? this.newSigner_file[0] : undefined;
      this.subscriptions.push(this.signersService.create(this.companyId || this.company.id, this.newSigner, file).subscribe({
        next: newSigner => {
          this.submitted.emit(newSigner);
          this.closeModal();
        },
        error: error => {
          this.modalError = error.error ? error.error.info.message : error;
          this.processing = false;
          this.dataDogLoggerService.warn(error.message, error.error);
        }
      }));
    }
  }

  public signerFiscalIdChange(e: string): void {
    switch (this.newSigner.fiscal_id.type) {
      case 'cuit':
        if (validateCuit(e)) {
          this.newSigner.identity_number = this.newSigner.fiscal_id.value.slice(2, -1);
        }
        break;

      case 'curp':
        this.newSigner.identity_number = this.newSigner.fiscal_id.value;
        break;
    }
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
