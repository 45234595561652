import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';

import { User } from '../../../auth/models/user.model';
import { LoginService } from '../../../auth/services/login.service';
import { Company } from '../../../models/company.model';
import { EntityBinding } from '../../../models/entity-binding.model';
import { Envelope, EnvelopeSigner } from '../../../models/envelope.model';
import { CompanyService } from '../../../services/company.service';
import { SignaturesService } from '../../../services/signatures.service';
import { SignersService } from '../../../services/signers.service';
import { ConfirmComponent } from '../confirm/confirm.component';
import { SignersModalsComponent } from '../signers-modals/signers-modals.component';

@Component({
  selector: 'ag-envelopes-modals',
  templateUrl: './envelopes-modals.component.html',
  styleUrls: ['./envelopes-modals.component.scss']
})
export class EnvelopesModalsComponent implements OnInit, OnDestroy {

  @ViewChild('envelopeModal', { static: true }) private readonly envelopeModal: TemplateRef<any>;
  @ViewChild('historyModal', { static: true }) private readonly historyModal: TemplateRef<any>;
  @ViewChild('signersModal', { static: true }) private readonly signersModal: TemplateRef<any>;
  @ViewChild('cancelConfirm', { static: true }) private readonly cancelConfirm: ConfirmComponent;
  @ViewChild('signersModals', { static: true }) private readonly signersModals: SignersModalsComponent;

  @Input() public company: Company;

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public companySigners: EnvelopeSigner[];
  public envelope: Envelope;
  public envelopeSigners: EnvelopeSigner[];
  public envelopeFiles: FileList;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];
  private bindingData: EntityBinding[];
  private user: User;

  constructor(
    public companyService: CompanyService,
    private modalService: BsModalService,
    private signaturesService: SignaturesService,
    private signersService: SignersService,
    private router: Router,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.user = this.loginService.getCurrentUser().getValue();
  }

  public create(bindingData: EntityBinding[] = undefined): void {
    this.bindingData = bindingData;
    this.envelope = new Envelope();
    this.openModal(this.envelopeModal);
  }

  public history(envelope: Envelope): void {
    this.envelope = envelope;
    this.openModal(this.historyModal, 'modal-lg');
  }

  public setSigners(envelope: Envelope): void {
    this.envelope = envelope;
    this.loadSigners();
    this.envelopeSigners = [];
    this.openModal(this.signersModal);
  }

  public cancel(envelope: Envelope): void {
    if (this.company.id === envelope.company.id) {
      this.subscriptions.push(this.cancelConfirm.askConfirmation().subscribe(confirmed => {
        if (confirmed) {
          this.processing = true;

          envelope.status.id = 40;
          envelope.cancel_reason = 'N/A';

          this.subscriptions.push(this.signaturesService.edit(this.company.id, envelope).subscribe(response => {
            this.processing = false;
          }));
        }
      }));
    }
  }

  public submit(): void {
    this.processing = true;

    this.subscriptions.push(this.signaturesService.create(this.company.id, this.envelope, this.envelopeFiles).subscribe(response => {
      if (this.bindingData) {
        this.signaturesService.bind(this.company.id, response.id, this.bindingData).subscribe(bind => {
          this.creationDone(response.id);
        });
      } else {
        this.creationDone(response.id);
      }
    }));
  }

  private creationDone(envelopeId: number): void {
    this.closeModal();
    this.router.navigate(['/company', this.company.id, 'envelopes', envelopeId]);
  }

  public submitSigners(): void {
    this.processing = true;

    this.subscriptions.push(this.signaturesService.editSigners(this.company.id, this.envelope.id, this.user, this.envelopeSigners).subscribe(response => {
      this.closeModal();
    }));
  }

  /**
   * Envelope's file observable based on [[EnvelopeFile.id|id]].
   */
  public getObservable(fileId: number): Observable<any> {
    return this.signaturesService.getFile(this.company.id, this.envelope.id, fileId, this.envelope.status.id === 30);
  }

  private loadSigners(): void {
    if (!this.companySigners) {
      this.processing = true;
      this.subscriptions.push(this.signersService.get(this.company.id).subscribe(signers => {
        this.companySigners = signers;
        this.processing = false;
      }));
    }
  }

  /**
   * Add the [[User|User's]] [[Company]] as a signer of the [[Envelope]].
   * 
   * Before implementing fix [[SelectizeComponent]] to reflect updates in the
   * ngModel.
   */
  public addMyCompany(): void {
    const alreadyAdded: boolean = Boolean(this.envelope.companies && this.envelope.companies.find(company => company.id === this.company.id));

    if (!alreadyAdded) {
      if (!this.envelope.companies) this.envelope.companies = [];
      this.envelope.companies.push(this.company);
    }
  }

  public newSigner(): void {
    this.closeModal();
    this.signersModals.add();
  }

  public refreshSigners(s: EnvelopeSigner): void {
    if (s) {
      this.companySigners.push(s);
    }
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
      this.envelope = undefined;
      this.envelopeFiles = undefined;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
