/**
 * A [[Company]] fiscal identification number.
 *
 * ### Related UI components:
 * - [[FiscalIdComponent]]
 */
export class FiscalId {
  /** Agree's internal unique slug. */
  type: string;
  value?: string;
  /**
   * Tax ID name.
   * 
   * ### Sample value
   * 'CUIT'
   */
  readonly label: string;
  /**
   * Check [[https://jsdaddy.github.io/ngx-mask-page/|ngx-mask documentation]].
   */
  readonly mask?: string;

  constructor(data: Partial<FiscalId> = {}) {
    Object.assign(this, data);
  }
}

export function validateCuit(cuit: string): boolean {
  let isValid: boolean = false;
  const allCharsEqual = /^(.)\1*$/;

  if (cuit && cuit.length === 11 && !allCharsEqual.test(cuit)) { // CUIT must have 11 digits
    const mult = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
    let total = 0;
    for (let i = 0; i < cuit.length - 1; i++) {
      total += parseInt(cuit.charAt(i)) * mult[i];
    }
    const mod = total % 11;
    const digito = mod === 0 ? 0 : mod === 1 ? 9 : 11 - mod;

    isValid = (digito === parseInt(cuit[10]));
  }

  return isValid;
}

export function validateRfc(rfc: string, aceptarGenerico: boolean = true): boolean {
  const exceptions: string[] = [
    'PEIC211118IS0', 'LBOV660219V45', 'CUS180523AH3', // For SAT WS sandbox testing
    'EDC930121E01', 'EDC930121E02', 'EDC930121E03', 'EDC930121E04', 'EDC930121E05', 'EDC930121E06', 'SEPJ650809JG1' // For CDC sandbox testing
  ];

  if (exceptions.includes(rfc)) return true;

  const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
  let regExpMatch = rfc.match(re);

  if (!regExpMatch) return false;

  // Separar el dígito verificador del resto del RFC
  const digitoVerificador = regExpMatch.pop(),
    rfcSinDigito = regExpMatch.slice(1).join(''),
    len = rfcSinDigito.length,
    // Obtener el digito esperado
    diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
    indice = len + 1;
  let suma = 0,
    digitoEsperado;

  if (len != 12) suma = 481; //Ajuste para persona moral

  for (let i = 0; i < len; i++) {
    suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
  }
  digitoEsperado = 11 - suma % 11;
  if (digitoEsperado == 11) digitoEsperado = 0;
  else if (digitoEsperado == 10) digitoEsperado = "A";

  // El dígito verificador coincide con el esperado?
  // o es un RFC Genérico (ventas a público general)?
  if ((digitoVerificador != digitoEsperado)
    && (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000"))
    return false;
  else if (!aceptarGenerico && rfcSinDigito + digitoVerificador == "XEXX010101000")
    return false;
  return true;
}

export function validateCURP(curp: string): boolean {
  const re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
  const validado = curp.match(re);

  if (!validado)  //Coincide con el formato general?
    return false;

  // Validar que coincida el dígito verificador
  const digitoVerificador = (curp17: string) => {
    // Fuente https://consultas.curp.gob.mx/CurpSP/
    const diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ";
    let lngSuma = 0.0;
    let lngDigito = 0.0;

    for (let i = 0; i < 17; i++) lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
    lngDigito = 10 - lngSuma % 10;
    if (lngDigito == 10) return 0;
    return lngDigito;
  };

  if (parseInt(validado[2]) != digitoVerificador(validado[1]))
    return false;

  return true; // Validado
}

export function personType(fiscal_id: FiscalId): 'PERSON' | 'COMPANY' {
  const parsedValue = String(fiscal_id.value);

  switch (fiscal_id.type) {
    case 'cuit':
      const startsWithAny = (str: string, matches: string[]): boolean => {
        let found: boolean;
        let i: number = 0;

        while (!found && i < matches.length) {
          found = str.startsWith(matches[i++]);
        }

        return found;
      };

      /**
       * Sets CUIT type based on:
       * - 20, 23, 24, 25, 26 y 27 para Personas Físicas
       * - 30, 33 y 34 para Personas Jurídicas
       */
      return startsWithAny(parsedValue, ['30', '33', '34']) ? 'COMPANY' : 'PERSON';

    case 'rfc':
      /**
       * El RFC de las personas físicas se compone de 13 caracteres
       * alfanuméricos, mientras que el RFC de las personas morales posee 12.
       */
      return parsedValue.length === 12 ? 'COMPANY' : 'PERSON';

    case 'curp':
      return 'PERSON';

    default:
      return 'COMPANY';
  }
}

/**
 * Validates a fiscal ID based on its type and optionally cleans it before validation.
 * 
 * @param {FiscalId} fiscal_id - The fiscal ID to validate.
 * @param {boolean} [clean=false] - Whether to clean the fiscal ID before validation.
 * @returns {string} - The fiscal ID value if the fiscal ID is valid, undefined otherwise.
 */
export function validFiscalId(fiscal_id: FiscalId, clean: boolean = false): string {
  if (!fiscal_id || !fiscal_id.value) return undefined;

  let id = fiscal_id.value.trim();

  switch (fiscal_id.type) {
    case 'cuit':
      if (clean) id = id.replace(/\D+/g, ''); // Removes all non digits
      return validateCuit(id) ? id : undefined;

    case 'rfc':
      if (clean) id = id.replace(/[^a-z0-9]/gi, ''); // Removes all non alphanumerics
      return validateRfc(id) ? id : undefined;

    case 'curp':
      if (clean) id = id.replace(/[^a-z0-9]/gi, ''); // Removes all non alphanumerics
      return validateCURP(id) ? id : undefined;

    default:
      console.warn('Unknown type: ', fiscal_id.type);
      return undefined;
  }
}