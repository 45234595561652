/** Type of participants in an [[FintechApplication]]. */
export const Participants = ['COMPANY', 'SUPPLIER', 'FUNDER', 'APPLICANT'] as const;

export type Participant = typeof Participants[number];

export class Assignment {
  participant: string;
  roles?: string[];
  users?: number[];

  constructor(data: Partial<Assignment> = {}) {
    Object.assign(this, data);
  }
};