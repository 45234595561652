<span #filterDropdown="bs-dropdown" class="filter-container"
*ngIf="type && innerValue"
dropdown clickStop
[container]="container"
[autoClose]="autoClose"
(onShown)="onShown()" 
(onHidden)="onHidden()" placement="{{ alignment === 'right' ? 'bottom right' : ''}}">
  <span id="filter-icon" title="{{ 'FILTERS.FILTER'|translate}}" dropdownToggle [class.active]="innerValue.source" aria-controls="filter-dropdown" class="toggle glyphicon glyphicon-filter" aria-hidden="true"></span>
  <ul id="filter-dropdown" *dropdownMenu class="dropdown-menu" [class.dropdown-menu-right]="alignment === 'right'" role="menu"
    aria-labelledby="filter-icon">
    <form #filterForm="ngForm" (ngSubmit)="filterForm.valid && onSubmit()" clickStop>

      <li role="menuitem">
        <div class="filter-label" *ngIf="label">{{ label }}</div>
        <div class="dropdown-customitem">
          <div *ngIf="innerValue.condition !== null && canDelete" class="pull-right" title="{{ 'FILTERS.CLEAR'|translate}}">
            <a (click)="removeFilter()"><span class="material-symbols-rounded">highlight_off</span></a>
          </div>
          <div class="form-group" hasError>
            <label for="condition_select">{{ 'FILTERS.FILTER_CONDITIONS'|translate}}:</label>
            <select class="form-control" id="condition_select" name="condition"
            [class.has-placeholder]="!innerValue.condition"
            (change)="innerValue.value = undefined"
            (ngModelChange)="focus()"
            [(ngModel)]="innerValue.condition"
            #condition>
              <option [ngValue]="undefined" [disabled]="true">{{ 'FILTERS.CONDITIONS.NONE'|translate}}</option>\
              <option *ngFor="let c of filterOptions(conditions[type])"
              [ngValue]="c.value">{{ c.name|translate }}</option>
            </select>
          </div>

          <ng-container *ngIf="type === 'string' &&
          innerValue.condition">
            <div class="form-group" hasError>
              <input type="text" class="form-control" placeholder="{{ 'FILTERS.TEXT_PLACEHOLDER'|translate}}"
              id="input-{{ UUID }}"
              autocomplete="off"
              name="text_value"
              maxlength="128"
              minlength="3"
              [(ngModel)]="innerValue.value"
              required>
              <error-list></error-list>
            </div>
          </ng-container>

          <ng-container *ngIf="type === 'date' &&
          ['when', 'before', 'after'].includes(innerValue.condition)">
            <div class="form-group" hasError>
              <input type="text" class="form-control"
              autocomplete="off"
              name="date_value"
              [(ngModel)]="innerValue.value"
              bsDatepicker
              (onShown)="onShowDatepicker()"
              (onHidden)="onHiddenDatepicker()"
              [bsConfig]="dateRangeDefaultConfig"
              placeholder="{{ 'GLOBAL.DATE_PLACEHOLDER'|translate }}" 
              required readonly>
            </div>
          </ng-container>

          <ng-container *ngIf="type === 'date' &&
          ['between'].includes(innerValue.condition)">
            <div class="form-group" hasError>
              <input type="text" class="form-control"
              autocomplete="off"
              name="date_value"
              bsDaterangepicker
              [bsValue]="innerValue.source"
              (onShown)="onShowDatepicker()"
              (onHidden)="onHiddenDatepicker()"
              (bsValueChange)="onRangePickerChange($event)"
              [bsConfig]="dateRangeDefaultConfig"
              placeholder="{{ 'GLOBAL.RANGE_PLACEHOLDER'|translate }}" 
              required readonly>
            </div>
          </ng-container>

          <ng-container *ngIf="type === 'number' &&
          innerValue.condition">
            <div class="form-group" hasError>
              <input type="number" class="form-control" placeholder="0"
              id="input-{{ UUID }}"
              autocomplete="off"
              name="number_value"
              [(ngModel)]="innerValue.value"
              required>
            </div>
          </ng-container>

        </div>
      </li>

      <li class="divider"></li>
      <!-- <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a></li> -->
      <li role="menuitem">
        <div class="dropdown-customitem text-right">
          <button type="button" (click)="filterDropdown.hide()" class="btn btn-link btn-sm">{{ 'GLOBAL.CANCEL'|translate}}</button>
          <button type="submit" class="btn btn-agree btn-sm">{{ 'FILTERS.FILTER'|translate}}</button>
        </div>
      </li>
    </form>
  </ul>
</span>