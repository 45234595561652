import { Component, Input } from '@angular/core';

@Component({
  selector: 'table-detail',
  templateUrl: './table-detail.component.html',
  styleUrls: ['./table-detail.component.scss']
})
export class TableDetailComponent {

  @Input() public header: string;
  @Input() public classes: string = "";
  /** If not defined, header is used. */
  @Input('header-title') public headerTitle: string;
  /** Align content to the right. */
  @Input() public right: boolean;
  @Input() public expanded: boolean = true;
  /** Indicates if the content of the table is collapsible. */
  @Input() public collapsible: boolean;

  /** @ignore */
  constructor() { }

  public toggle(): void {
    if (this.collapsible) {
      this.expanded = !this.expanded;
    }
  }
}
