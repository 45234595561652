<ng-container *ngIf="quantity; else none">
  <span class="text-warning small"
  [tooltip]="quantity.value? ('MAX_QUANTITY.MAX_QUANTITY'|translate) + ': ' + (quantity.value|number:'1.0-3':currentLang) + ' ' + quantity.unit.abbrev : null"
  *ngIf="quantity.type?.slug === 'maximum'">{{ 'MAX_QUANTITY.TO_BE_OFFERED'|translate }}</span>
  <ng-container *ngIf="quantity.type?.slug === 'fixed'">
    <ng-container *ngIf="quantity.value; else none">
      {{ integer|number:'1.0-0':currentLang }}<small *ngIf="decimals > 0">{{ (decimals|number:'1.3-3':currentLang)|slice: -4 }}</small>&nbsp;<span class="text-muted small">
        <ng-container *ngIf="compact">
          {{ quantity.unit.abbrev }}
        </ng-container>
        <ng-container *ngIf="!compact">
          <span class="hidden-xs">{{ quantity.unit.name_plural || quantity.unit.abbrev }}</span>
          <span class="visible-xs">{{ quantity.unit.abbrev }}</span>
        </ng-container>
      </span>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>