<ng-container *ngIf="price && price.type; else none">
  <ng-container *ngIf="price.type === 'flat'">
    <span class="price-tag" [class.text-danger]="price.value < 0">
      <ng-container *ngIf="price.unit">
        <span *ngIf="small" class="faded small">{{ price.unit.name }} </span>
        <span *ngIf="!small" class="currency">{{ price.unit.name }} </span>  
      </ng-container>
      <ng-container *ngIf="!editable">
        <ng-container *ngIf="price.value != undefined; else none">
          {{ integer|number:'1.0':currentLang }}<span class="cents">{{ decimals }}</span>
        </ng-container>
      </ng-container>
    </span>
    <input *ngIf="editable" class="form-control input-sm" type="number" [min]="0" [(ngModel)]="price.value">
  </ng-container>
  <ng-container *ngIf="price.type === 'premiums'">
    {{ price|price: (withoutYear ? true : false) }}
  </ng-container>
  <ng-container *ngIf="market &&
    price.quantity_unit &&
    market.quantity_units.length > 1 &&
    ['flat','premiums'].includes(price.type)">
    <span *ngIf="small" class="faded small">/{{ price.quantity_unit.abbrev }}</span>
    <span *ngIf="!small" class="currency">/{{ price.quantity_unit.abbrev }}</span>
  </ng-container>
  
  <ng-container *ngIf="price.type === 'to_be_fixed'">
    <span *ngIf="!showConditions" title="{{ 'PRICE_DISPLAY.TO_BE_FIXED'|translate }}">{{ 'PRICE_DISPLAY.TO_BE_FIXED_ABBR'|translate }}</span>
    <span *ngIf="showConditions">
      {{ 'PRICE_DISPLAY.TO_BE_FIXED'|translate }}
      <div class="text-left small conditions">
        {{ 'PRICE_FORM.TO_BE_FIXED.CONDITIONS'|translate }}
        <div class="lighten">{{ price.value }}</div>
      </div>
    </span>
    <!-- <span *ngIf="fixing_range" class="micro text-muted">
      <date-md [date]="fixing_range.to" [style]="true"></date-md>
    </span> -->
  </ng-container>
  
  <ng-container *ngIf="price.type === 'consignment'">
    <span *ngIf="!showConditions" title="{{ 'PRICE_DISPLAY.CONSIGNMENT'|translate }}">{{ 'PRICE_DISPLAY.CONSIGNMENT_ABBR'|translate }}</span>
    <span *ngIf="showConditions">
      {{ 'PRICE_DISPLAY.CONSIGNMENT'|translate }}
      <div class="text-left small conditions">
        {{ 'PRICE_DISPLAY.CONSIGNMENT_CONDITIONS'|translate }}
        <div class="lighten">{{ price.value }}</div>
      </div>
    </span>
  </ng-container>
</ng-container>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>