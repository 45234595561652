import { Injectable } from '@angular/core';
import { map, scan, switchMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../auth/models/user.model';
import { LoginService } from '../auth/services/login.service';
import { Company } from '../models/company.model';
import { CompanyService } from './company.service';

declare const Intercom: any;

/**
 * Implements the [[https://www.intercom.com/|Intercom]] Javascript API.
 * 
 * ## Usage
 * ``` typescript
 * import { IntercomService } from '../intercom.service';
 * ...
 * private intercomService: IntercomService;
 * ...
 * this.intercomService.track('phone-verified');
 * ```
 * 
 * More information at [[https://developers.intercom.com/]].
 */
@Injectable()
export class IntercomService {

  /** @ignore */
  constructor(
    private companyService: CompanyService,
    private loginService: LoginService
  ) {
    this.start(environment.intercomID);
  }

  private id: string;

  /**
   * Starts the user and company monitoring process and integrates with Intercom.
   * Subscribes to user and company changes and triggers Intercom events to boot, update, or shut down
   * the Intercom session based on the current user and company state.
   *
   * @param {string} id - The application ID for Intercom integration.
   */
  private start(id: string): void {
    if (!id) return;
    this.id = id;

    this.loginService.getCurrentUser().pipe(
      // Keep track of the last two users
      scan((acc, user: User) => [...acc.slice(-1), user], [null]),
      switchMap((users: User[]) =>
        this.companyService.watch().pipe(
          // Keep track of the last two companies
          scan((acc, company: Company) => [...acc.slice(-1), company], [null]),
          map((companies: Company[]) => ({ users, companies }))
        )
      )
    ).subscribe(({ users, companies }) => {
      const [prevUser, currUser] = users;
      const [prevCompany, currCompany] = companies;

      if (currUser) {
        // Boot Intercom if the user has changed
        if (!prevUser || currUser.email !== prevUser.email) {
          const attributes: any = {
            name: `${currUser.name} ${currUser.last_name}`,
            email: currUser.email,
            created_at: Math.round(new Date(currUser.created_at).getTime() / 1000),
            app_id: this.id,
            user_id: currUser.id
          };

          if (currUser.phone_verified) attributes.phone = `(${currUser.phone.phone_country_code.phone_code}) ${currUser.phone.phone_area_code} ${currUser.phone.phone_number}`;

          Intercom("boot", attributes);
        }

        // Update company information in Intercom
        if (currCompany) {
          Intercom("update", {
            email: currUser.email,
            companies: [{
              company_id: currCompany.id,
              name: currCompany.name,
              industry: currCompany.activity.label || currCompany.activity.name
            }]
          });
        } else if (prevCompany) {
          Intercom("update");
        }
      } else {
        // Shutdown Intercom if no current user is available
        Intercom('shutdown');
      }
    });
  }

  /**
   * Submits an event. This will associate the event with the currently logged
   * in [[User]] and send it to [[https://www.intercom.com/|Intercom]].
   * 
   * The final parameter is a map that can be used to send optional metadata
   * about the event.
   */
  public track(name: string, metadata?: any): void {
    Intercom('trackEvent', name, metadata);
  }
}
