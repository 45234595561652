import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, Validators, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[max]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxDirective, multi: true }]
})
export class MaxDirective implements Validator {

  @Input() private max: number;

  constructor() { }

  validate(control: AbstractControl): { [key: string]: any } {
    return this.max != null ? Validators.max(this.max)(control) : null;
  }
}
