<ng-template #signerModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ (newSigner.id ? 'SIGNATURES.EDIT_SIGNER': 'SIGNATURES.ADD_SIGNER')|translate }}</h4>
  </div>
  <div class="modal-body">
    <!-- <p>Ingrese el período que desea incluir en el reporte:</p> -->
    <form id="signer-form" #signerForm="ngForm" (ngSubmit)="signerForm.valid && submit()">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'GLOBAL.NAME'|translate }}</label>
            <input type="text" class="form-control input-lg"
            name="name" [(ngModel)]="newSigner.name" required
            [modalFocus]="true"
            maxlength="24"
            [disabled]="processing">
            <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            <error-list></error-list>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'SIGNUP.LAST_NAMES'|translate }}</label>
            <input type="text" class="form-control input-lg"
            name="last_name" [(ngModel)]="newSigner.last_name" required
            maxlength="24"
            [disabled]="processing">
            <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            <error-list></error-list>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group" hasError>
            <label class="control-label">{{ market?.configuration?.company.fiscal_id ? market.configuration.company.fiscal_id.label : (company.address.country?.fiscalIds[0]?.label ?  company.address.country.fiscalIds[0].label : 'REGISTER_COMPANY.FISCAL_IDENTIFICATION' |translate) }}</label>
            <input type="text" class="form-control input-lg"
            name="fiscal_id" [(ngModel)]="newSigner.fiscal_id.value" required
            maxlength="24"
            [disabled]="processing"
            [mask]="newSigner.fiscal_id.mask"
            [placeholder]="newSigner.fiscal_id.mask || ''"
            (ngModelChange)="signerFiscalIdChange($event)"
            [validCuit]="newSigner.fiscal_id.type === 'cuit'"
            [validRfc]="newSigner.fiscal_id.type === 'rfc'"
            [validCURP]="newSigner.fiscal_id.type === 'curp'">
            <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            <error-list></error-list>
          </div>
        </div>
        <div class="col-sm-6"
        [class.hidden]="newSigner.fiscal_id.type === 'curp'">
          <div class="form-group" hasError>
            <label class="control-label">{{ market?.configuration?.company.id_card ? market.configuration.company.id_card.label : ('TEAM_VIEW.IDENTITY_NUMER'|translate) }}</label>
            <input type="text" class="form-control input-lg"
            name="identity_number" [(ngModel)]="newSigner.identity_number" required
            maxlength="24"
            [disabled]="processing"
            [readonly]="newSigner.fiscal_id.type === 'curp'"
            [validCURP]="market?.configuration?.company.id_card.label == 'CURP'"
            [validDNI]="market?.configuration?.company.id_card.label == 'DNI'">
            <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            <error-list></error-list>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'TEAM_VIEW.EMAIL'|translate }}</label>
            <input type="email" class="form-control input-lg"
            name="email" [(ngModel)]="newSigner.email" required email
            maxlength="256"
            [disabled]="processing">
            <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            <error-list></error-list>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'REGISTER_COMPANY.TELEPHONE'|translate }}
              <small *ngIf="!market?.configuration?.signatures.requires_phone"
              class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
            </label>
            <input type="text" class="form-control input-lg"
            name="phone" [(ngModel)]="newSigner.phone"
            [required]="market?.configuration?.signatures.requires_phone"
            maxlength="128"
            [disabled]="processing">
            <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            <error-list></error-list>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="requestFile">
        <div class="col-xs-12" id="fa_power">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'SIGNATURES.SIGNER_AUTHORIZATION'|translate }}
              <small *ngIf="person_type !== 'COMPANY'"
              class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
            </label>
            <file-input name="power" inputId="power"
            [required]="person_type === 'COMPANY'"
            drop-area="#fa_power"
            [disabled]="processing"
            [(ngModel)]="newSigner_file" [max-size]="15728640" accept="image/*, .pdf, .doc, .docx"></file-input>
            <error-list></error-list>
            <small class="help-block" [innerHtml]="'SIGNATURES.SIGNER_AUTHORIZATION_HELP'|translate"></small>
          </div>
        </div>
      </div>
    </form>
    <alert
    *ngIf="modalError"
    type="danger">{{ modalError }}</alert>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" [disabled]="processing" class="btn btn-link" data-dismiss="modal">
      {{ 'GLOBAL.CANCEL'|translate }}
    </button>
    <button type="submit" form="signer-form" class="btn btn-agree" [disabled]="processing">
      {{ (newSigner.id ? 'GLOBAL.EDIT' : 'SIGNATURES.ADD_SIGNER')|translate }}
    </button>
  </div>
</ng-template>