import { HttpHeaders } from "@angular/common/http";

/**
 * Represents pagination details.
 */
export class Pagination {
  total: number;
  total_subitems?: number;
  per_page: number;
  current_page: number;
  total_pages: number;
  range: {
    from: number;
    to: number;
  };
}

/**
 * Parses pagination information from HTTP headers.
 * 
 * @param {HttpHeaders} headers - The HTTP headers to parse.
 * @returns {Pagination} - The parsed pagination details.
 */
export function parsePagination(headers: HttpHeaders): Pagination {
  const response: Pagination = {
    total: getNumber(headers, 'X-Total-Count'),
    per_page: getNumber(headers, 'X-Per-Page'),
    current_page: getNumber(headers, 'X-Current-Page'),
    range: {
      from: getNumber(headers, 'X-Item-Range-From'),
      to: getNumber(headers, 'X-Item-Range-To')
    },
    total_pages: getNumber(headers, 'X-Total-Pages') ?? totalPages(
      getNumber(headers, 'X-Total-Count'),
      getNumber(headers, 'X-Per-Page')
    )
  };

  const total_subitems = getNumber(headers, 'X-Subitem-Total-Count');

  if (total_subitems) response.total_subitems = total_subitems;

  return response;
}

/**
 * Retrieves the first value of a given header as a number.
 *
 * @param {HttpHeaders} headers - The HTTP headers to parse.
 * @param {string} name - The header name.
 * @returns The value string if the header exists, null otherwise
 */
function getNumber(headers: HttpHeaders, name: string): number {
  return Number(headers.get(name));
}

/**
 * Calculates the total number of pages based on total count and items per page.
 * 
 * @param {number} totalCount - The total number of items.
 * @param {number} perPage - The number of items per page.
 * @returns {number} - The total number of pages.
 */
function totalPages(totalCount: number, perPage: number): number {
  return Math.ceil(totalCount / perPage);
}
