import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { instanceToInstance } from 'class-transformer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { User } from '../../../auth/models/user.model';
import { LoginService } from '../../../auth/services/login.service';
import { Company } from '../../../models/company.model';
import { SubscriptionManager } from '../../../utilities/subscription-manager';
import { ApproverDecision, DoA } from '../../models/doa.model';
import { DoAService } from '../../services/doa.service';
import { HistoryComment } from '../history-comments/history-comments.component';

@Component({
  selector: 'ag-doa',
  templateUrl: './doa.component.html',
  styleUrls: ['./doa.component.scss']
})
export class DoaComponent extends SubscriptionManager implements OnInit, OnDestroy {

  @ViewChild('reasonModal', { static: true }) private readonly reasonModal: TemplateRef<any>;

  /**
   * Entity type. Eg: negotiation, invoice, etc.
   * 
   * Either this attribute or [[external_id|external-id]] is required.
   */
  @Input() public entity: string;
  /**
   * Entity unique identifier.
   * 
   * This attribute in combination with the [[entity|entity type]] is the
   * unique reference to the virtual file folder.
   * 
   * This attribute is required.
   */
  @Input('entity-id') public entity_id: string;
  @Input() public doa: DoA;
  @Input() public company: Company;

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  /** Current [[User]]. */
  public user: User;
  public desicion: ApproverDecision;

  private modalRef: BsModalRef;

  constructor(
    private loginService: LoginService,
    private doAService: DoAService,
    private modalService: BsModalService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    });
    if (!this.doa) this.loadData();
  }

  private loadData(): void {
    this.subscriptions.push(this.doAService.watchByCompany(this.company?.id, {
      // status: 'PENDING',
      order_by: '-created_at',
      entity: this.entity,
      entityId: this.entity_id
    }).subscribe(reponse => {
      this.doa = reponse.body[0];
    }));
  }

  public getComments(accountId: number): boolean | HistoryComment[] {
    const desicion = this.doa.decision(accountId);
    const approver = this.doa.approvers.find(approver => approver.id === accountId);

    if (desicion?.comment) {
      const historyComment: HistoryComment = {
        user: approver.user,
        reason: desicion.comment,
        created_at: desicion.votedAt,
        company: this.company
      }

      return [historyComment];
    };

    return false;
  }

  public vote(decision: ApproverDecision, vote: 'APPROVED' | 'DISAPPROVED' | 'ABSTENTION' | 'PENDING'): void {
    const d = instanceToInstance(decision)
    d.vote = vote;
    this.desicion = d;
    this.openModal(this.reasonModal);
  }

  public submit(): void {
    this.processing = true;

    this.subscriptions.push(this.doAService.vote(this.doa, this.desicion).subscribe(doa => {
      this.doa = doa;
      this.closeModal();
      this.processing = false;
      this.desicion = new ApproverDecision();
    }));
  }

  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c, ignoreBackdropClick: false });

    this.subscriptions.push(this.modalRef.onHide.subscribe((reason: string) => {
      // Reset all values
      this.modalRef = undefined;
      this.processing = false;
    }))
  }

  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      this.processing = false;
      if (onHide) this.subscriptions.push(this.modalRef.onHide.subscribe(onHide))
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    super.ngOnDestroy();
  }
}
