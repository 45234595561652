import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { LoginService } from '../auth/services/login.service';
import { HubSpotService } from './hub-spot.service';

@Injectable()
export class ErrorService {

  constructor(
    private loginService: LoginService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private hubSpotService: HubSpotService,
    private router: Router
  ) { }

  /**
   * Handles HTTP errors by displaying appropriate toast messages and logging the error.
   * 
   * @param {any} error - The error to handle.
   */
  public handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 401:
          // 401 => token expired or missing
          this.toastrService.info(this.translateService.instant('GLOBAL.APP_ERROR_401'));
          this.loginService.customLogout(this.router.url);
          break;

        case 403:
          this.showToastMessage(this.translateService.instant('GLOBAL.APP_ERROR_403'), {
            disableTimeOut: true,
            closeButton: true,
          }, () => {
            this.router.navigate(['companies']).then(() => {
              // Force refresh
              window.location.reload();
            });
          });
          break;

        case 404:
          this.showToastMessage(this.translateService.instant('GLOBAL.APP_ERROR_404'), {
            disableTimeOut: true,
            closeButton: true,
          }, () => {
            window.location.reload();
          });
          break;

        default:
          this.showToastMessage(this.translateService.instant('GLOBAL.APP_ERROR_DEFAULT'), {
            disableTimeOut: true
          }, () => {
            this.hubSpotService.open();
          });
          break;
      }
    } else {
      if (!environment.production) console.error(error);
    }
  }

  /**
   * Displays a toast message with the given options and executes a callback function when the toast is hidden.
   * 
   * @param {string} message - The message to display in the toast.
   * @param {Partial<IndividualConfig>} [options=null] - The options to customize the toast.
   * @param {Function} [onHidden] - The callback function to execute when the toast is hidden.
   * @private
   */
  private showToastMessage(message: string, options: Partial<IndividualConfig> = null, onHidden?: Function): void {
    const toast = this.toastrService.error(message, undefined, options);

    if (onHidden) toast.onHidden.pipe(take(1)).subscribe(() => {
      onHidden();
    });
  }
}
