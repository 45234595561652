import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Animated loading/processing indicator.
 * 
 * ## Usage
 * ``` html
 * <spinner message="Loading..."
 * [block-ui]="true"
 * [skip-zoomin]="false"></spinner>
 * ```
 */
@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {

  /** Text to display below the animated indicator. */
  @Input() public message: string;
  /** Whether the entire interface should be blocked or not. */
  @Input('block-ui') public blockUI: boolean;
  /** Whether to skip the initial animation or not. */
  @Input('skip-zoomin') public skipZoomIn: boolean;

  /** @ignore */
  constructor() { }
}
