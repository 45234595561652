<div class="toggle-group hidden-print" [class.disabled]="disabled">
  <label>
    <input type="checkbox" [(ngModel)]="value"
    [disabled]="disabled">
    <div class="switch" aria-hidden="true">
      <div class="switch-label">
        <div class="switch-inner material-symbols-rounded"></div>
        <div class="switch-btn"></div>
      </div>
    </div>
  </label>
</div>
