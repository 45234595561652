<div class="filters" [class.mobile-filters]="show">
  <div class="filters-header">
    <ng-container *ngIf="presets">
      <ag-preset-filters></ag-preset-filters>
    </ng-container>
    <selected-filters></selected-filters>
    <div class="switch" *ngIf="!allApplied">
      <button type="button"
      [class.active]="show" (click)="showFilters()" class="btn btn-default btn-block">
        <i class="material-symbols-rounded">ballot</i>&nbsp;
        <span *ngIf="!show">{{ 'FILTERS.SHOW'|translate }}</span>
        <span *ngIf="show">{{ 'FILTERS.HIDE'|translate }}</span>
      </button>
    </div>
  </div>
  <div class="options" #filtersContent>
    <ng-content></ng-content>
  </div>
</div>
