import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { User } from '../../../auth/models/user.model';
import { LoginService } from '../../../auth/services/login.service';
import { Company } from '../../../models/company.model';
import { Envelope, EnvelopeSigner } from '../../../models/envelope.model';
import { SignaturesService } from '../../../services/signatures.service';
import { SignersService } from '../../../services/signers.service';
import { FilePreviewComponent } from '../file-preview/file-preview.component';

/**
 * Shows the status of an [[Envelope|envelope]], [[EnvelopeSigner|signers]],
 * and [[EnvelopeFile|documents]].
 * 
 * ## Usage
 * ``` html
 * <envelope-status
 * [company]="company"
 * [envelope-id]="selected.envelope_id"></envelope-status>
 * ```
 */
@Component({
  selector: 'envelope-status',
  templateUrl: './envelope-status.component.html',
  styleUrls: ['./envelope-status.component.scss']
})
export class EnvelopeStatusComponent implements OnInit, OnDestroy {

  @ViewChild('filePreviewer', { static: true }) private readonly filePreviewer: FilePreviewComponent;

  @Input() public company: Company;
  @Input('envelope-id') public id: number;
  @Input() public set envelope(e: Envelope) {
    this._envelope = e;
    this.detectSignersStatuses(e);
  };

  public get envelope(): Envelope {
    return this._envelope;
  };

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public signer_status: any;
  /** Current [[User]]. */
  public user: User;

  private subscriptions: Subscription[] = [];
  private _envelope: Envelope;

  /** @ignore */
  constructor(
    private loginService: LoginService,
    private signaturesService: SignaturesService,
    private signersService: SignersService
  ) { }

  /** @ignore */
  ngOnInit(): void {
    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    }));

    this.loadEnvelope();
  }

  private loadEnvelope(): void {
    if (!this.envelope && this.id) {
      this.processing = true;

      this.subscriptions.push(this.signaturesService.watchEnvelope(this.company.id, this.id).subscribe(envelope => {
        if (!envelope) return;

        this.envelope = envelope;
        this.processing = false;
      }));
    }
  }

  private getMostRecent(d1: Date, d2: Date): Date {
    return d1.getTime() > d2.getTime() ? d1 : d2;
  }

  private detectSignersStatuses(envelope: Envelope): void {
    const total_files = envelope.files.length;
    let signatures_pending = {};
    let last_signature = {};

    envelope.files.forEach(file => {
      file.signatures?.forEach(signature => {
        if (!signatures_pending[signature.signer.id]) {
          signatures_pending[signature.signer.id] = total_files;
          last_signature[signature.signer.id] = signature.signed_at;
        }
        signatures_pending[signature.signer.id]--;
        last_signature[signature.signer.id] = this.getMostRecent(last_signature[signature.signer.id], signature.signed_at);
      });
    });

    this.signer_status = {};
    envelope.signers.forEach(signer => {
      this.signer_status[signer.id] = {
        date: last_signature[signer.id],
        /**
         * | Value | Description |
         * |------:|-------------|
         * |     0 | Pending     |
         * |     1 | Partially   |
         * |     2 | Completed   |
         */
        status: (signatures_pending[signer.id] != undefined) ?
          (signatures_pending[signer.id] === 0 ? 2 : 1) : 0
      }
    });
  }

  public getObservable(fileId: number): Observable<any> {
    return this.signaturesService.getFile(this.company.id, this.id || this.envelope.id, fileId, this.envelope.status.id === 30);
  }

  /** Opens the [[EnvelopeSigner|signer's]] power document. */
  public getSignerFile(signer: EnvelopeSigner): void {
    if (signer && signer.file) {
      this.processing = true;
      const observable = this.signersService.getFile(this.company.id, signer.id, signer.file);

      this.subscriptions.push(observable.subscribe(response => {
        this.processing = false;
        this.filePreviewer.preview(observable, response.name, undefined, response.url);
      }));
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
