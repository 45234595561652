import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Company } from '../../../models/company.model';

@Component({
  selector: 'related-products',
  templateUrl: './related-products.component.html',
  styleUrls: ['./related-products.component.scss']
})
export class RelatedProductsComponent {

  @Input() public orders; // We need a more generic approach to render all kind of things on a map
  @Input() public company: Company;

  constructor(
    private router: Router
  ) { }

  public gotoOrder(id): void {
    if (id) this.router.navigateByUrl('/company/' + this.company.id + '/order/' + id);
  }

  public scroll(el, dir: number = 1): void {
    el.scrollBy({
      // top: 100, // could be negative value
      left: el.offsetWidth * dir,
      behavior: 'smooth'
    });
  }
}
