import { Component, Input, HostListener } from '@angular/core';

@Component({
  selector: 'media-browser',
  templateUrl: './media-browser.component.html',
  styleUrls: ['./media-browser.component.scss']
})
export class MediaBrowserComponent {

  // Do not set any value if the order has no media
  @Input('order-media') public orderMedia: Array<any>;
  // Do not set any value if the order has no media
  @Input('fullscreen-mode') public fullscreenMode: boolean = false;

  public currentMedia: number = 0;
  public media_fullscreen: boolean;

  constructor() { }

  public next(e?: Event): void {
    // Navigates to the next media
    if (e) e.stopImmediatePropagation();
    if (this.currentMedia < this.orderMedia.length - 1) this.currentMedia++;
    else this.currentMedia = 0;
  }

  public previous(e?: Event): void {
    // Navigates to the previous media
    if (e) e.stopImmediatePropagation();
    if (this.currentMedia > 0) this.currentMedia--;
    else this.currentMedia = this.orderMedia.length - 1;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.media_fullscreen) {
      event.stopImmediatePropagation();
      switch (event.key) {
        case 'Escape':
          this.toggleFullscreen();
          break;
        case 'ArrowLeft':
          this.previous();
          break;
        case 'ArrowUp':
          this.previous();
          break;
        case 'ArrowRight':
          this.next();
          break;
        case 'ArrowDown':
          this.next();
          break;
      };
    }
  }

  public toggleFullscreen(e?: Event): void {
    if (this.fullscreenMode) {
      if (e) e.stopImmediatePropagation();

      if (window.innerWidth < 992) {
        this.media_fullscreen = false;
        this.next();
      } else this.media_fullscreen = !this.media_fullscreen;
    }
  }
}
