import { Type } from "class-transformer";

export class CustomStatus {
  /** Agree's internal unique identifier. */
  readonly id: number;
  public name: string;
  public description?: string;

  @Type(() => Date)
  public created_at: Date;
  public color: string = "#90BE6D";

  constructor(data: Partial<CustomStatus> = {}) {
    Object.assign(this, data);
  }
}
