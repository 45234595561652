import { AfterViewInit, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import mapboxgl from 'mapbox-gl';

import { Order } from '../../../company/modules/commercial/models/order.model';
import { Company } from '../../../models/company.model';
import { GroupBy } from '../../../models/group-by.model';
import { Product } from '../../../models/product.model';

@Component({
  selector: 'mapbox-view',
  templateUrl: './mapbox-view.component.html',
  styleUrls: ['./mapbox-view.component.scss']
})
export class MapboxViewComponent implements AfterViewInit {

  @Input() set ordersByProduct(data: GroupBy<Product, Order>[]) {
    this.uniqueIds = {}; // Reset IDs map

    let uniqueId = new Date().getTime();

    data.forEach((group, gindex) => {
      group.values.forEach((order, oindex) => {
        this.uniqueIds[gindex + '_' + oindex] = uniqueId++; // This allows the UI to identify Order with no ID
      });
    });

    this._ordersByProduct = data;
  }
  get ordersByProduct(): GroupBy<Product, Order>[] {
    return this._ordersByProduct;
  }

  @Input() public companyId: number;
  @Input() public company: Company;

  public map;
  public uniqueIds: any;

  private _ordersByProduct: GroupBy<Product, Order>[];
  private coordinates: any[] = [];
  private locations_id;

  constructor(
    private router: Router
  ) { }

  ngAfterViewInit(): void {
    this.loadStyle('mapboxgl', 'https://api.tiles.mapbox.com/mapbox-gl-js/v1.0.0/mapbox-gl.css');
  }

  private initMap(): void {
    this.locations_id = {};

    mapboxgl.accessToken = 'pk.eyJ1Ijoic2ViYXN0aWFubXkiLCJhIjoiY2p3d2hiYzliMDIxYjQ0bnJ5Y3M2ODEyaiJ9.YVMwEuhY0PKACRrgNbFsAg';
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v9',
      // center: [-61, -34.2],
      zoom: 5
    });

    // Go through the orders
    for (let i = 0; i < this.ordersByProduct.length; i++) {
      const productOrders = this.ordersByProduct[i].values;
      for (let j = 0; j < productOrders.length; j++) {
        this.addMarker(productOrders[j], this.uniqueIds[i + '_' + j]);
      }
    }

    if (this.coordinates.length > 0) {
      const bounds = this.coordinates.reduce(function (bounds, coord) {
        return bounds.extend(coord);
      }, new mapboxgl.LngLatBounds(this.coordinates[0], this.coordinates[0]));

      this.map.fitBounds(bounds, {
        padding: { top: 50, bottom: 10, left: 5, right: 5 }
      });
    }

  }

  private addMarker(order: Order, uniqueId: number): void {
    if (!order.business_detail.delivery.geolocations) return;

    order.business_detail.delivery.geolocations.forEach((geolocation) => {
      // Apply offset to avoid overlapping
      if (this.locations_id[geolocation.place_id] != undefined) this.locations_id[geolocation.place_id] = this.locations_id[geolocation.place_id] + 0.0002;
      else this.locations_id[geolocation.place_id] = 0;

      let lng = geolocation.geometry.location.lng + this.locations_id[geolocation.place_id],
        lat = geolocation.geometry.location.lat + (Math.random() * 0.002);

      this.coordinates.push([lng, lat]);

      // https://docs.mapbox.com/mapbox-gl-js/api/#popup
      let elem = document.getElementById(uniqueId + '_' + geolocation.place_id).firstElementChild;
      if (!elem) return;

      const popup = new mapboxgl.Popup({ closeButton: false }).setDOMContent(elem);

      // https://docs.mapbox.com/mapbox-gl-js/api/#marker
      const marker = new mapboxgl.Marker().setLngLat([lng, lat]).setPopup(popup).addTo(this.map);
    });

  }

  public gotoOrder(id: number): void {
    if (id) this.router.navigateByUrl('/company/' + this.companyId + '/order/' + id);
    else if (!this.company) this.router.navigateByUrl('/login');
  }

  private loadStyle(id: string, styleName: string): void {
    const head = document.getElementsByTagName('head')[0];

    let themeLink = document.getElementById(id) as HTMLLinkElement;
    if (themeLink) {
      // themeLink.href = styleName;
      this.initMap();
    } else {
      const style = document.createElement('link');
      style.id = id;
      style.rel = 'stylesheet';
      style.href = styleName;

      if ('onload' in document.createElement('link')) {
        let scope = this;
        style.onload = function (): void {
          scope.initMap();
        };
      } else this.initMap(); // Older browsers
      head.appendChild(style);
    }
  }
}
