import { Component, forwardRef, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { Company } from '../../../models/company.model';
import { EntityBinding } from '../../../models/entity-binding.model';
import { Label } from '../../../models/label.model';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { LabelService } from '../../../services/label.service';

/**
 * [[Label]] manager. Allows you to create, edit and delete [[Company]] labels.
 * 
 * It also allows you to assign and remove [[Label|Labels]] to a
 * [[LabelManagerComponent.selected|specified collection]] of items.
 * 
 * ## Usage
 * ``` html
 * <ag-label-manager
 * [(ngModel)]="selection"
 * [company]="company"></ag-label-manager>
 * ```
 */
@Component({
  selector: 'ag-label-manager',
  templateUrl: './label-manager.component.html',
  styleUrls: ['./label-manager.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LabelManagerComponent),
    multi: true
  }]
})
export class LabelManagerComponent implements OnDestroy {

  @ViewChild('createModal', { static: true }) private readonly createModal: TemplateRef<any>;
  @ViewChild('manageModal', { static: true }) private readonly manageModal: TemplateRef<any>;
  @ViewChild('labelDropdown', { static: true }) private readonly labelDropdown: BsDropdownDirective;

  @Input() public company: Company;

  public label: Label;
  public labels: Label[];
  public palette: string[] = [
    '#F94144', // Red crayola
    '#F9C74F', // Maize crayola
    '#90BE6D', // Pistachio
    '#277DA1', // CG blue
    '#F3722C', // Orange red
    '#40A083', // Zomp
    '#F8961E', // Yellow orange
    '#4D908E', // Cadet blue
    '#F9844A', // Mango tango
    '#577590', // Queen blue
  ];
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public selectedLabel: Label;
  public dirty: boolean;

  private labelChanges: EntityBinding[];
  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];
  private subscribed: boolean;

  /** @ignore */
  constructor(
    private labelService: LabelService,
    private modalService: BsModalService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  public dropdownHandler(isOpen: boolean): void {
    if (isOpen) {
      // Reset values
      this.dirty = false;
      this.labelChanges = [];
      this.selectedLabel = undefined;
      // this.labels = undefined;
      this.loadData();
    }
  }

  private loadData(): void {
    if (!this.subscribed) { // Load labels only once
      this.subscribed = true;
      this.processing = true;

      this.subscriptions.push(this.labelService.watch(this.company.id).subscribe(labels => {
        this.dataLoaded(labels);
      }));
    } else this.processSelection();
  }

  private dataLoaded(labels: Label[] = []): void {
    this.labels = labels;
    this.processSelection();
    this.processing = false;
  }

  private getById(labels: Label[], labelId: number): Label {
    return labels ? labels.find(label => label.id === labelId) : undefined;
  }

  private processSelection(): void {
    if (this.labelDropdown.isOpen && this.labels && this.labels.length) {
      let summary = {};
      this.labels.forEach((label: Label) => {
        label.reset();
      });

      this.selected.forEach(item => {
        // TODO: Add labels id array to labeleable models
        // i.e.: Contract.labels: number[]
        if (item.labels && item.labels.length) {
          // Summarize labels in selection
          item.labels.forEach((label: Label) => {
            if (summary[label.id] == undefined) summary[label.id] = 0;
            summary[label.id]++;
          });
        }
      });

      for (let i = 0, keys = Object.keys(summary); i < keys.length; i++) {
        const labelId = keys[i];
        const numberOfLabels = summary[labelId];
        if (numberOfLabels === this.selected.length) {
          // All the elements of this selection have this label
          this.getById(this.labels, parseInt(labelId)).status = true;
        } else this.getById(this.labels, parseInt(labelId)).partial = true;
      }
    }
  }

  public createLabel(): void {
    this.label = new Label();
    this.label.color = this.palette[Math.floor(Math.random() * this.palette.length)];

    this.openModal(this.createModal);
    this.labelDropdown.hide();
  }

  private isUniqueName(name: string): boolean {
    const index = this.labels.findIndex(l => l.name.toLowerCase() === name.toLowerCase());
    return index === -1;
  }

  /** Creates a new [[Label]] and applies it to current selection. */
  public submit(): void {
    if (this.isUniqueName(this.label.name)) {
      this.processing = true;

      this.subscriptions.push(this.labelService.create(this.company.id, this.label).subscribe((label: Label) => {
        if (label) {
          this.labelChanges = [];
          this.labelChanges.push(new EntityBinding({
            id: label.id,
            value: true
          }));
          this.applyChanges();
        } else {
          this.closeModal();
          this.processing = false;
        }
      }));
    };
  }

  public manage(): void {
    this.openModal(this.manageModal);
    this.labelDropdown.hide();
  }

  public toggle(event, label: Label): void {
    this.dirty = true;

    const labelSet: EntityBinding = new EntityBinding({
      id: label.id,
      value: event.target.checked
    });

    const index = this.labelChanges.findIndex(value => value.id === label.id);

    if (index !== -1) this.labelChanges[index] = labelSet;
    else this.labelChanges.push(labelSet);
  }

  /** Adds and/or removes [[Label|Labels]] to current selection. */
  public applyChanges(): void {
    let payload: EntityBinding[] = [];

    // Labeleable entities must have entity property available
    if (this.selected[0].entity) this.selected.forEach(item => {
      this.labelChanges.forEach((labelChange: EntityBinding) => {
        let currentLabel: Label = this.getById(item.labels, labelChange.id);

        // Evaluate changes only
        if ((labelChange.value && !currentLabel) || // TRUE and there is no label
          (!labelChange.value && currentLabel)) { // FALSE and there is a label
          let itemLabel: EntityBinding = new EntityBinding(labelChange);

          itemLabel.entity = {
            id: item.id,
            type: item.entity
          };

          payload.push(itemLabel);
        }
      });
    });

    if (payload.length) {
      this.processing = true;

      this.subscriptions.push(this.labelService.bind(this.company.id, payload).subscribe({
        next: response => {
          // Once applied close dropdown
          this.selected = [];
          this.closeModal();
          this.labelDropdown.hide();
          this.processing = false;
        },
        error: error => {
          // Once applied close dropdown
          this.closeModal();
          this.labelDropdown.hide();
          this.processing = false;
          this.dataDogLoggerService.warn(error.message, error.error);
        }
      }));
    }
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  public update(key: string, event): void {
    if (event && event.target.value !== this.selectedLabel[key] &&
      (key !== 'name' || this.isUniqueName(event.target.value))) {
      this.processing = true;
      this.selectedLabel[key] = event.target.value;

      this.subscriptions.push(this.labelService.update(this.company.id, this.selectedLabel).subscribe({
        next: response => {
          this.processing = false;
          this.selectedLabel = undefined;
        },
        error: error => {
          this.processing = false;
          this.selectedLabel = undefined;
          this.dataDogLoggerService.warn(error.message, error.error);
        }
      }));
    };
  }

  public deleteLabel(label: Label): void {
    this.processing = true;

    this.subscriptions.push(this.labelService.delete(this.company.id, label).subscribe({
      next: response => {
        // Once submitted reset
        this.labels = this.labels.filter(item => item.id !== label.id);
        if (this.labels.length === 0) this.closeModal();
        this.processing = false;
      },
      error: error => {
        this.processing = false;
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  // ngModel
  private _value: any[] = [];
  public get selected(): any[] { return this._value }
  public set selected(v: any[]) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.selected = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}