/**
 * A Preset is a set of data associated to a [[Company]] for an specific key.
 * 
 * Presets can be private, that is, only visible to the [[User]] who created
 * them.
 */
export class Preset {
  /** Agree's internal unique identifier. */
  readonly id: string;

  companyId: number;
  userId: number; // Use [[User.id]]
  key: string;
  name: string;
  data: any;
  private: boolean;

  constructor(data: Partial<Preset> = {}) {
    Object.assign(this, data);
  }
}
