<div class="btn-group" dropdown #commercialZoneDropdown="bs-dropdown"
(onShown)="dropdownHandler(true)"
(onHidden)="dropdownHandler(false)"
[class.hidden]="selected?.length === 0" [insideClick]="true">
  <button id="button-commercial-zone" dropdownToggle
  [disabled]="processing" type="button"
  class="btn btn-link btn-sm dropdown-toggle" aria-controls="dropdown-commercial-zone"><i
      class="material-symbols-rounded">work</i></button>
  <ul id="dropdown-commercial-zone" *dropdownMenu class="dropdown-menu" role="menu"
    aria-labelledby="button-commercial-zone">
    <ng-container *ngIf="!commercialZones">
      <div class="loading loading-sm">
        <spinner></spinner>
      </div>
      <li class="divider"></li>
    </ng-container>

    <ng-container *ngIf="commercialZones?.length > 0">
      <li class="dropdown-header">{{ 'COMMERCIAL_ZONES.BIND_TO'|translate }}</li>
      <div class="labels-list">
        <li role="menuitem" *ngFor="let zone of commercialZones" [class.disabled]="processing">
          <label class="dropdown-item"
          *ngIf="commercialZoneDropdown.isOpen">
            <input type="checkbox" class="no-margin" name="label_{{ zone.id }}"
            [indeterminate]="zone.partial"
            [checked]="zone.status"
            (change)="toggle($event, zone)">&nbsp;&nbsp;{{ zone.name }}
          </label>
        </li>
      </div>
      <li class="divider"></li>
    </ng-container>
    <ng-container *ngIf="!dirty">
      <li role="menuitem" [class.disabled]="processing"><a class="dropdown-item" (click)="create()">{{
          'COMMERCIAL_ZONES.CREATE'|translate }}</a></li>
      <li role="menuitem" *ngIf="commercialZones?.length > 0" [class.disabled]="processing"><a class="dropdown-item"
          (click)="manage()">{{ 'COMMERCIAL_ZONES.MANAGE'|translate }}</a></li>
    </ng-container>
    <ng-container *ngIf="dirty">
      <li role="menuitem" [class.disabled]="processing"><a class="dropdown-item" (click)="applyChanges()">{{
          'QUALITY_INPUT.ACTION'|translate }}</a>
      <li role="menuitem" [class.disabled]="processing"><a class="dropdown-item"
          (click)="commercialZoneDropdown.hide()">{{ 'GLOBAL.CANCEL'|translate }}</a>
    </ng-container>
  </ul>
</div>

<ng-template #createModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ (commercialZone.id ? 'COMMERCIAL_ZONES.EDIT' : 'COMMERCIAL_ZONES.NEW')|translate }}</h4>
  </div>
  <div class="modal-body">
    <form id="label-form" #zoneForm="ngForm" (ngSubmit)="zoneForm.valid && submit()">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group"
          [class.has-error]="!isUnique">
            <label class="control-label">{{ 'GLOBAL.NAME'|translate }}</label>
            <input type="text" name="name" class="form-control" autocomplete="off"
            [disabled]="processing"
            maxlength="24"
            [modalFocus]="true" required [(ngModel)]="commercialZone.name">
            <p class="help-block small"
            [collapse]="isUnique" [isAnimated]="true">{{ 'COMMERCIAL_ZONES.NO_UNIQUE'|translate }}</p>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label class="control-label">
              {{ 'INVOICES.DESCRIPTION'|translate }}
              <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
            </label>
            <textarea class="form-control" name="description"
            [disabled]="processing"
            [(ngModel)]="commercialZone.description" maxlength="256" rows="3"></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" [disabled]="processing" class="btn btn-link" data-dismiss="modal">{{
      'GLOBAL.CANCEL'|translate }}</button>
    <button type="submit" form="label-form" class="btn btn-agree" [disabled]="processing || !zoneForm.valid">{{
      (commercialZone.id ? 'GLOBAL.SAVE_CHANGES' : 'COMMERCIAL_ZONES.CREATE')|translate }}</button>
  </div>
</ng-template>

<ng-template #manageModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'COMMERCIAL_ZONES.MANAGE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <table class="table table-condensed">
      <tbody>
        <tr *ngFor="let zone of commercialZones">
          <td class="sap">{{ zone.name }}</td>
          <td class="text-muted">{{ zone.description }}</td>
          <td class="sap">
            <a title="{{ 'GLOBAL.EDIT'|translate }}" (click)="create(zone)"
            class="show-onhover"
            [class.disabled]="processing"><i class="material-symbols-rounded">edit</i></a>
          </td>
          <td class="sap">
            <a title="{{ 'TEMPLATES_VIEW.DELETE'|translate }}"
            class="show-onhover"
            [class.disabled]="processing"
            (onConfirm)="delete(zone)"
            [confirm-options]="{
              action: 'GLOBAL.YES_DELETE'|translate
            }"><i class="material-symbols-rounded">delete</i></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>