import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { FilterSetComponent } from '../filter-set/filter-set.component';

@Component({
  selector: 'filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.css']
})
export class FilterSelectComponent implements OnDestroy {

  @Input() private key: string;

  public hide: boolean = true;
  public options: any[];
  public title: string;

  private subscriptions: Subscription[] = [];

  constructor(
    private filterset: FilterSetComponent
  ) {

    this.subscriptions.push(this.filterset.filters.subscribe(res => {
      this.options = this.filterset.filterList[this.key].options;

      this.title = this.filterset.filterList[this.key].label;

      if (res.find(element => element.key === this.key))
        this.hide = false;
      else
        this.hide = true;
    }));

    this.filterset.countSuscriberChilds();
  }

  selectFilter(event) {
    this.filterset.showFilters();
    this.filterset.addFilterData(this.key, event.target['value']);
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
