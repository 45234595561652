import { PrimitiveUnit, Unit } from "../../../../models/unit.model";

/**
 * A Market is a container of [[Company|Companies]] that share certain common
 * aspects.
 *
 * [[Company|Companies]] from different Markets will not be able to interact
 * with each other.
 *
 * These aspects can be:
 * * [[Product|products]] that are marketed,
 * * geography,
 * * [[Currency|currencies]],
 * * [[Unit|quantity units]],
 * * language,
 * * etc.
 */
export class Market extends PrimitiveUnit {
  /** Agree's internal unique identifier. */
  id: number;
  /** The image's URL of the Market's icon. */
  logo: string;
  description: string;
  /** For non-internationl Markets only. */
  country_id?: number;
  configuration: {
    [key: string]: any;
  };
  /**
   * Market's time zone. Expressed as an offset from Coordinated Universal Time
   * (UTC).
   *
   * e.g.: `"-03:00"`
   */
  offset: string;
  /** Market's operational hours. Expressed in local time. */
  operational_range: {
    from: string,
    to: string
  };
  dates: Array<string>;
  /** Market's default language. */
  language: {
    slug: string;
  };
  /** [[Unit|Quantity Units]] supported in this Market. */
  quantity_units: Unit[];

  type?: {
    description: string,
    id: number,
    logo: string,
    name: string,
    sort_number: number,
    subdescription: string
  };
}
