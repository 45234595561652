import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusIndicatorComponent {

  @Input() public status: "success" | "warning" | "error";

  constructor() { }

}
