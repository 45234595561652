import Theme from "../theme/models/theme.model";

export class Tenant {
  id: string;
  name: string;
  base_url: string;
  markets: number[];
  theme?: Theme

  constructor(data: Partial<Tenant>) {
    Object.assign(this, data);
  }
}