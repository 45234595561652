<div class="scroller">
	<div (click)="scroll(scrollPane, -1)" class="arrow no-select" *ngIf="orders.length > 4">
		<i class="material-symbols-rounded">keyboard_arrow_left</i>
	</div>

	<div class="related" #scrollPane>
		<div class="related_list">
			<div *ngFor="let order of orders" class="related_item">
				<div class="clickable" (click)="gotoOrder(order.id)">
					<media-browser *ngIf="company?.market.configuration.order.allow_media" [order-media]="order.media"></media-browser>
					<b>{{ order.product.name }}</b>
					<div>
						<div class="text-muted truncate">{{ order.product_detail.quality }}</div>
						<div class="pull-right">{{ order.business_detail.quantity.value }} <small class="text-muted">{{ order.business_detail.quantity.unit.abbrev }}</small></div>
						<price-display [price]="order.business_detail.price" [market]="company?.market" [small]="true"></price-display>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div (click)="scroll(scrollPane, 1)" class="arrow no-select" *ngIf="orders.length > 4">
		<i class="material-symbols-rounded">keyboard_arrow_right</i>
	</div>
</div>

