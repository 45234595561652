import { Directive, OnInit, Input } from '@angular/core';
import { Validator, AbstractControl, ValidatorFn, NG_VALIDATORS } from '@angular/forms';

export function ArrayMinlengthValidator(min: number): ValidatorFn {

  return (control: AbstractControl): { [key: string]: any } => {

    let length = (control.value || []).length;
    let isValid = length >= min;

    return isValid ? null : {
      'arrayMinlength': 'array has less than ' + min + ' elements'
    };
  };
}

@Directive({
  selector: '[arrayMinlength]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ArrayMinlengthDirective, multi: true }]
})
export class ArrayMinlengthDirective implements OnInit, Validator {

  @Input('arrayMinlength') private minlength: number;

  private validatorFn;

  constructor() { }

  ngOnInit(): void {
    this.validatorFn = ArrayMinlengthValidator(this.minlength);
  }

  validate(control: AbstractControl): { [key: string]: any } {
    this.validatorFn = ArrayMinlengthValidator(this.minlength);
    return this.validatorFn(control);
  }
}
