import { Directive, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AgMultiselectionDirective } from './ag-multiselection.directive';

@Directive({
  selector: '[AgSelectall]'
})
export class AgSelectallDirective implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  constructor(
    private multiselection: AgMultiselectionDirective,
    private elementRef: ElementRef
  ) { }

  @HostListener('click', ['$event'])
  click(event) {
    this.multiselection.toggleAll.emit(event.target.checked);
  }

  ngOnInit(): void {
    // Subscribe to changed event
    this.subscriptions.push(this.multiselection.changed.subscribe(response => {
      // Check if all set is checked
      this.elementRef.nativeElement.checked = (this.multiselection.set.length === this.multiselection.value.length);
    }));
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}