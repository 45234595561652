import { Currency } from './currency.model';
import { Unit } from "./unit.model";

export class Price {
  /**
   * ### Possible values
   * | value       | Description                                                                     |
   * |-------------|---------------------------------------------------------------------------------|
   * | flat        | Flat price                                                                      |
   * | to_be_fixed | It has no price, but fixing conditions                                          |
   * | consignment | It has no price, the product is granted on consignment under certain conditions |
   * | premiums    | The value is defined as a difference from a reference price                     |
   */
  type: 'flat' | 'to_be_fixed' | 'consignment' | 'premiums';

  unit: Currency;

  /**
   * If the price is in reference to a certain volume. For example, price per
   * ton.
   */
  quantity_unit?: Unit;

  value: number;
  /** Only used for premium prices. */
  year?: number;
  /** Only used for premium prices. */
  month?: number;

  constructor(data: Partial<Price> = {}) {
    this.unit = new Currency();
    this.quantity_unit = new Unit();
    this.type = "flat";

    Object.assign(this, data);
  }
}
