import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FiscalId } from '../../../models/fiscal-id.model';

/**
 * FiscalId is a component that displays [[Company|Companies]]
 * [[FiscalId|fiscal IDs]] in a normalized way.
 * 
 * ## Usage
 * ``` html
 * <fiscal-id
 * [value]="fiscal_id"
 * [label]="true"></fiscal-id>
 * ```
 * 
 * ### Related UI components:
 * - [[CompanyInfoComponent]]
 */
@Component({
  selector: 'fiscal-id',
  templateUrl: './fiscal-id.component.html',
  styleUrls: ['./fiscal-id.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiscalIdComponent {

  /** Only required input. */
  @Input('value') public fiscal_id: FiscalId;
  /**
   * Indicates whether [[FiscalId.label|fiscal ID label]] should be displayed or not.
   */
  @Input('label') public showLabel: boolean;

  /** @ignore */
  constructor() { }
}