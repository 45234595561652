import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'columnFilter'
})
export class ColumnFilterPipe implements PipeTransform {

  /**
   * Filters an array of objects based on a filter condition and a property.
   *
   * @param {any[]} value - The array of objects to be filtered.
   * @param {string} filter - The filter condition in the format "condition:searchTerm".
   * @param {string} property - The property to be checked within the objects.
   * @returns {any[]} - The filtered array of objects.
   */
  transform(value: any[], filter: string, property: string): any[] {
    if (!value.length) return value;

    const [condition, searchTerm] = filter.split(':');
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    switch (condition) {
      case 'contains':
        return value.filter(item => this.reduce(item, property).toLowerCase().includes(lowerCaseSearchTerm));

      case 'not':
        return value.filter(item => !this.reduce(item, property).toLowerCase().includes(lowerCaseSearchTerm));

      case 'starts':
        return value.filter(item => this.reduce(item, property).toLowerCase().startsWith(lowerCaseSearchTerm));

      case 'ends':
        return value.filter(item => this.reduce(item, property).toLowerCase().endsWith(lowerCaseSearchTerm));

      case 'is':
        return value.filter(item => this.reduce(item, property).toLowerCase() == lowerCaseSearchTerm);

      default:
        return value;
    }
  }

  /**
   * Reduces an object to a nested property value based on a dot-separated key string.
   *
   * @param {any} value - The object to be reduced.
   * @param {string} key - The dot-separated key string.
   * @returns {any} - The nested property value or undefined if not found.
   */
  private reduce(value: any, key: string): any {
    return key
      .split('.')
      .reduce((o, k) => o?.[k], value);
  }
}
