import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Company } from '../../../models/company.model';
import { validFiscalId } from '../../../models/fiscal-id.model';
import { CompanyService } from '../../../services/company.service';
// import { Account } from '../../../auth/models/account.model';

/**
 * CompanyInfo is a component that displays [[Company|Companies]] information
 * in a normalized way. You can customize the information detail.
 * 
 * ## Usage
 * ``` html
 * <company-info
 * [company]="company"></company-info>
 * ```
 */
@Component({
  selector: 'company-info[company]', // company is a required attribute
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CompanyInfoComponent implements OnInit, OnDestroy {

  /**
   * Indicates whether [[Company|Company's]] [[CompanyActivity|activity]]
   * should be displayed or not.
   */
  @Input() public activity: boolean = true;
  /**
   * Compact mode displays only the [[Company|Company's]] name.
   */
  @Input() public compact: boolean = false;
  /**
   * Indicates whether [[Company|Company's]] name could be truncated or not.
   */
  @Input() public truncate: boolean = true;
  @Input('commercial-zones') public commercial_zones: boolean;
  @Input('file-manager') public file_manager: boolean;
  /**
   * The [[Company]] to be displayed.
   */
  @Input() public set company(c: Company) {
    if (c && c.id !== this._company?.id) {
      this._company = c;
      this.checkCertificates(c);

      this.validFiscalId = validFiscalId(this._company.fiscal_id) != undefined;
    }
  };

  /** @ignore */
  public my_company: Company;
  public get company(): Company {
    return this._company;
  }
  /**
   * Indicates whether [[Company|Company's]] [[Market|market]] has
   * [[CompanyCertificate|certificates]] enabled or not.
   */
  public certificates: boolean;
  public validFiscalId: boolean;
  // private account: Account;
  private _company: Company;
  private subscriptions: Subscription[] = [];

  public get commercial_zones_enabled(): boolean {
    return this.commercial_zones &&
      this.my_company?.hasModule('my-network');
    //  &&
    // this.account &&
    // (this.account.is.commercial_with_users || this.account.is.counterpart_admin);
  };

  /** @ignore */
  constructor(
    private companyService: CompanyService
  ) { }

  /** @ignore */
  ngOnInit(): void {
    this.subscriptions.push(this.companyService.watch().subscribe(company => {
      if (!company) return;

      this.my_company = company;
      this.checkCertificates(company);
    }));

    // this.subscriptions.push(this.companyService.watchAccount().subscribe(account => {
    //   if (account) {
    //     this.account = account;
    //   }
    // }));
  }

  private checkCertificates(c: Company): void {
    if (c.market &&
      c.market.configuration &&
      c.market.configuration.certificates.enabled) {
      this.certificates = true;
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
