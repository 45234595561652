import { CountryCode } from "./country-code.model";

export class Phone {
  phone_country_code: CountryCode;
  phone_area_code: number;
  phone_number: number;

  constructor(data: Partial<Phone> = {}) {
    Object.assign(this, data);
  }
}
