import { Unit } from "./unit.model";

/**
 * A Product represents a real life good that can be traded in the context of a
 * [[Market]].
 * 
 * Products are defined by [[Market]]. In other words, each [[Market]] has
 * its own list of Products.
 */
export class Product {
  /** Agree's internal unique identifier. */
  readonly id: number;
  readonly name: string;
  /**
   * Relevant quality specifications that apply to this Product.
   * 
   * ## Sample value
   * 
   * ``` json
   * {
   *  "harvest": {
   *    "enabled": false
   *  },
   *  "quality": {
   *    "variety": {
   *      "name": "Variety",
   *      "empty": null,
   *      "order": 1,
   *      "abbrev": "",
   *      "status": 1,
   *      "values": [
   *        {
   *          "id": 1,
   *          "name": "Braeburn",
   *          "slug": "braeburn",
   *          "abbrev": "Braeburn",
   *          "status": 1
   *       },
   *       {
   *          "id": 6,
   *          "name": "Gala",
   *          "slug": "gala",
   *          "abbrev": "Gala",
   *          "status": 1
   *       },
   *       {
   *          "id": 7,
   *          "name": "Gold Rush",
   *          "slug": "gold_rush",
   *          "abbrev": "Gold Rush",
   *          "status": 1
   *       },
   *       {
   *          "id": 9,
   *          "name": "Granny Smith",
   *          "slug": "granny_smith",
   *          "abbrev": "Granny Smith",
   *          "status": 1
   *       }
   *     ]
   *    },
   *    "packaging": {
   *      "name": "Packaging",
   *      "value": "",
   *      "abbrev": "Pack.",
   *      "element": "textarea"
   *    },
   *    "quality_specs": {
   *      "name": "Quality specifications",
   *      "value": "",
   *      "abbrev": "Specs.",
   *      "element": "textarea"
   *    }
   *  },
   *  "order_quantity_label": {
   *    "venta": "Minimum order",
   *    "compra": "Quantity"
   *  }
   * ```
   */
  readonly attributes: {
    quality: any,
    payment_conditions: any;
    loading_rates: any;
    ports: any;
    order_quantity_label: Array<any>;
    harvest: any;
    country: any;
  }
  /**
   * If the [[Market]] allows the generation of Contracts, this property
   * includes the clauses of the standard Contract defined for this
   * [[Product]].
   * 
   * Any modification made by the [[Company|Companies]] will be considered a
   * customization.
   */
  readonly contract_clauses: any;
  /**
   * [[Unit|Quantity Units]] in which this Product can be traded in this
   * [[Market]].
   * 
   * ## Sample value
   * 
   * ``` json
   * [
   *   {
   *     "id": 2,
   *     "name": "Box",
   *     "name_plural": "Box(es)",
   *     "abbrev": "Box",
   *     "slug": "box"
   *   },
   *   {
   *     "id": 3,
   *     "name": "Pallet",
   *     "name_plural": "Pallet(s)",
   *     "abbrev": "Pallet",
   *     "slug": "pallet"
   *   },
   *   {
   *     "id": 4,
   *     "name": "Bin",
   *     "name_plural": "Bin(s)",
   *     "abbrev": "Bin",
   *     "slug": "bin"
   *   },
   *   {
   *     "id": 5,
   *     "name": "Container (FCL)",
   *     "name_plural": "Container(s)",
   *     "abbrev": "FCL",
   *     "slug": "fcl"
   *   }
   * ]
   * ```
   */
  quantity_units: Unit[];

  constructor(data: Partial<Product> = {}) {
    Object.assign(this, data);
  }
}
