<div #content class="content">
  <ng-content></ng-content>
</div>

<ng-template #confirmModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title" *ngIf="title">{{title}}</h4>
  </div>
  <div class="modal-body" *ngIf="body">
    <p [innerHTML]="body"></p>
  </div>
  <div class="modal-footer">
    <button *ngIf="cancel_text" (click)="closeModal()" [disabled]="processing" type="button" class="btn btn-link"
      data-dismiss="modal">{{cancel_text}}</button>
    <button *ngIf="confirm_text" (click)="action()" [disabled]="processing" type="button"
      class="btn btn-agree">{{confirm_text}}</button>
  </div>
</ng-template>