<ng-container *ngIf="ids.length">
  <div class="row">
    <div class="col-xs-12">
      <div class="flex-center-distributed">
        <h4>{{ 'TRACK_RECORD.TITLE'|translate }}
          <button type="button"
          class="btn btn-link btn-xs hidden-print" (click)="toggle()" [disabled]="processing"
          [innerHtml]="(show ? 'GLOBAL.TOGGLE_HIDE' : 'GLOBAL.TOGGLE_SHOW')|translate"></button>
        </h4>

        <div class="hidden-print">
          <button type="button"
          class="btn btn-link"
          (click)="preview()"
          title="{{ 'FILE_INPUT.OPEN_FILE'|translate }}"
          *ngIf="ids.length === 1"
          [disabled]="processing">
            <span class="material-symbols-rounded">history</span>
          </button>
          <button type="button"
          class="btn btn-link"
          (click)="comment()"
          title="{{ 'TRACK_RECORD.COMMENT'|translate }}"
          *ngIf="ids.length === 1"
          [disabled]="processing">
            <span class="material-symbols-rounded">post_add</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="show">
    <div class="col-xs-12">
      <div class="loading" *ngIf="loading; else loaded">
        <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
      </div>

      <ng-template #loaded>
        <div class="horizontal-scroller" *ngIf="trackrecord?.length > 0">
          <table class="table table-condensed table-hover table-stripped track-record"
          [sortable]="trackrecord" defaultSortBy="-date" #tableSortable="tableSortable">
            <!-- <caption></caption> -->
            <thead>
              <tr>
                <th class="sap" sortBy="date">{{ 'GLOBAL.DATE'|translate }}</th>
                <th *ngIf="ids.length > 1" class="sap" sortBy="entityId"></th>
                <th sortBy="company.name">{{ 'GLOBAL.COMPANY'|translate }}</th>
                <th class="sap"></th>
                <th sortBy="user.name">{{ 'TRACK_RECORD.USER_AND_ACTION'|translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let step of tableSortable.collection">
                <td class="sap">
                  <ag-timestamp [date]="step.date" [showSeconds]="step.action === 'update_auction'"></ag-timestamp>
                </td>
                <td *ngIf="ids.length > 1" class="sap small text-muted">
                  <a (click)="scrollToElement(step.entity + '_' + step.entityId)"><samp>#{{ step.entityId }}</samp></a>
                </td>
                <td>
                  <company-info  *ngIf="step.company; else none" [company]="step.company"></company-info>
                </td>
                <td class="sap text-muted">
                  <span *ngIf="step.private" class="material-symbols-rounded" tooltip="{{ 'TRACK_RECORD.PRIVATE'|translate }}">lock</span>
                </td>
                <td>
                  <div>
                    <b
                    *ngIf="step.user"
                    [class.highlighted-text]="user?.id === step.user.id" title="{{ step.user.email }}">{{ step.user.name }} {{ step.user.last_name}}</b>
                    {{ ('TRACK_RECORD.EVENTS.' + step.action.toUpperCase()) |translate }}
                    <b *ngIf="step.action === 'ENVELOPE.SIGNERS.SIGNED' ||
                    step.action.substr(0,10) === 'ATTACHMENT'">{{ step.changes[0].new }}</b>
                    <div class="well well-sm"
                    *ngIf="step.action === 'COMMENT.CREATED'"
                    [innerHtml]="step.changes[0].new|userMessage"></div>
                  </div>
                  <table class="mini-table" *ngIf="step.changes.length > 0 &&
                    step.action !== 'COMMENT.CREATED' &&
                    step.action !== 'ENVELOPE.SIGNERS.SIGNED' &&
                    step.action.substr(0,10) !== 'ATTACHMENT'">
                    <!-- <caption></caption> -->
                    <thead>
                      <tr>
                        <th>{{ 'TRACK_RECORD.FIELD'|translate }}</th>
                        <th>{{ 'TRACK_RECORD.ORIGINAL_VALUE'|translate }}</th>
                        <th class="sap"></th>
                        <th>{{ 'TRACK_RECORD.NEW_VALUE'|translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let change of step.changes">
                        <tr *ngIf="fields[change.field]">
                          <th>{{ fields[change.field]|translate }}</th>
                          <td class="text-muted">{{ change.old }}</td>
                          <td class="sap text-muted"><i class="material-symbols-rounded">arrow_right_alt</i></td>
                          <td>{{ change.new }}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                  <span class="small text-muted" *ngIf="step.tie_breaker == true">{{ 'TRACK_RECORD.TIE_BREAKER'|translate }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="no-data no-data-sm no-margin"
        *ngIf="trackrecord?.length === 0">
          <i class="material-symbols-rounded">not_interested</i>
          {{ 'GLOBAL.NO_DATA'|translate }}
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>

<ng-template #commentModal>
  <!-- modal -->
	<div class="modal-header">
		<button type="button" class="close" (click)="closeModal()" [disabled]="processing" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		</button>
		<h4 class="modal-title">{{ 'TRACK_RECORD.ADD_COMMENT'|translate }}</h4>
	</div>
	<div class="modal-body">
		<form id="comment-form" #commentForm="ngForm" (ngSubmit)="commentForm.valid && submit()">
			<div class="row">
				<div class="col-xs-12">
					<div class="form-group" hasError>
            <textarea class="form-control" name="text"
            [(ngModel)]="commentData.text"
            autocomplete="off"
            [modalFocus]="true"
            [disabled]="processing"
            minlength="3"
            maxlength="1024"
            rows="4"
            placeholder="{{ 'TRACK_RECORD.COMMENT_PLACEHOLDER'|translate }}"
            required></textarea>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-12">
          <div class="checkbox flex-center-distributed">
            <div>
              <input type="checkbox" class="ios"
              name="private" id="private_id"
              [disabled]="processing"
              [(ngModel)]="commentData.private">
            </div>
            <label for="private_id" class="grow"
            [class.text-muted]="!commentData.private">{{ 'TRACK_RECORD.PRIVATE'|translate }}</label>
          </div>
          <div *ngIf="commentData.private" class="help-block small">{{ 'TRACK_RECORD.PRIVATE_HELP'|translate }}</div>
				</div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" (click)="closeModal()" [disabled]="processing" class="btn btn-link" data-dismiss="modal">
			{{ 'GLOBAL.CANCEL'|translate }}
		</button>
		<button type="submit" form="comment-form" class="btn btn-agree" [disabled]="processing || commentForm.invalid">
			{{ 'GLOBAL.SEND'|translate }}
		</button>
	</div>
</ng-template>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>

<ag-file-preview #filePreviewer></ag-file-preview>
