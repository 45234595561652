<div #defaultDrop [class.not-valid]="!isValid">
  <input
    type="file"
    name="{{ name }}"
    id="{{ inputId }}"
    (change)="addFiles($event.target.files)"
    [disabled]="
      (files?.length + filesToUpload.length >= maxFiles || disabled) && isValid
    "
    [multiple]="maxFiles > 1"
    [accept]="accept"
  />
  <label
    for="{{ inputId }}"
    [class.disabled]="
      (files?.length + filesToUpload.length >= maxFiles || disabled) && isValid
    "
  >
    <ng-container
      *ngIf="
        (!hasFileSelected() &&
          files?.length + filesToUpload.length < maxFiles &&
          !hasAnomFile) ||
          !isValid;
        else fileSelected
      "
    >
      <i class="material-symbols-rounded">cloud_upload</i>
      {{
        (maxFiles === 1 ? "FILE_INPUT.SELECT_FILE" : "FILE_INPUT.SELECT_FILES")
          | translate
      }}
    </ng-container>
    <ng-template #fileSelected>
      <span class="text-success"
        ><i class="material-symbols-rounded">check_circle</i>
        {{
          (files.length === 1
            ? "FILE_INPUT.SELECTED"
            : "FILE_INPUT.MULIPLE_SELECTED"
          ) | translate
        }}</span
      >
    </ng-template>
  </label>
  <div class="text-right" *ngIf="filesToUpload?.length">
    <a class="micro" [class.disabled]="disabled" (click)="removeFiles()">{{
      "GLOBAL.CLEAR" | translate
    }}</a>
  </div>
  <div>
    <div *ngFor="let file of filesToUpload; let i = index" class="files">
      <div class="filename truncate" title="{{ file.name }}">
        {{ file.name }}
      </div>
      <div *ngIf="file.uri" class="thumb">
        <div [style.backgroundImage]="'url(' + file.uri + ')'"></div>
      </div>
      <button type="button" class="close" (click)="removeFile(i)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div *ngFor="let file of files" class="files">
      <div class="filename truncate" title="{{ file.name || file.uri }}">
        <a
          href="{{ file.uri }}"
          target="_blank"
          title="{{ 'FILE_INPUT.OPEN_FILE' | translate }}"
          >{{ file.name || file.uri }}</a
        >
      </div>
      <div>
        <a
          title="{{ 'FILE_INPUT.DELETE_CONFIRM.TITLE' | translate }}"
          (onConfirm)="deleteFile(inputId, file)"
          [confirm-options]="{
            title: 'FILE_INPUT.DELETE_CONFIRM.MODAL_TITLE' | translate,
            action: 'GLOBAL.YES_DELETE' | translate
          }"
          [confirm-close]="deletedFile"
        >
          <i class="material-symbols-rounded">delete</i>
        </a>
      </div>
    </div>
  </div>
</div>
