import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { Company } from '../../../models/company.model';

/**
 * This component shows a button that opens the modal that lists the
 * certificates of a [[Company]].
 * ## Usage
 * ``` html
 * <certificates-button
 * [company]="company"></certificates-button>
 * ```
 * ### Related UI components:
 * - [[CompanyInfoComponent]]
 */
@Component({
  selector: 'certificates-button[company]', // company is a required attribute
  templateUrl: './certificates-button.component.html',
  styleUrls: ['./certificates-button.component.scss']
})
export class CertificatesButtonComponent implements OnDestroy {

  @ViewChild('certificateTable', { static: true }) private readonly certificateTable: TemplateRef<any>;

  @Input() public company: Company;

  private modalRef: BsModalRef;

  /** @ignore */
  constructor(
    private modalService: BsModalService
  ) { }

  public show(): void {
    this.openModal(this.certificateTable, 'modal-lg');
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    // this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
    //   this.modalSub.unsubscribe();
    //   this.modalRef = undefined;
    //   // Reset all values
    //   this.processing = false;
    // });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.closeModal();
  }
}
