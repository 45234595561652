import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer } from '@angular/forms';

import { Currency } from '../../../models/currency.model';
import { Price } from '../../../models/price.model';
import { Unit } from '../../../models/unit.model';
import { CurrentDateService } from '../../../services/current-date.service';
// Ver:
// https://stackoverflow.com/questions/39242219/angular2-nested-template-driven-form/46748943#46748943

/**
 * Form to edit [[Price|prices]]. Supports multiple types (flat, to be fixed,
 * premiums) and [[Currency|currencies]].
 */
@Component({
  selector: 'price-form',
  templateUrl: './price-form.component.html',
  styleUrls: ['./price-form.component.scss'],
  viewProviders: [{
    provide: ControlContainer,// useExisting: NgForm
    useFactory: (container: ControlContainer) => container,
    deps: [[new SkipSelf(), ControlContainer]],
  }]
})
export class PriceFormComponent implements OnInit {

  @ViewChild('numberInput') private readonly numberInput: ElementRef;

  @Input() public units: Unit[];
  @Input() public isAuction: boolean;
  @Input() public types: any;
  @Input() public price: Price;
  @Input() public previousPrice: Price;
  /** The minimum value to accept for this entry. */
  @Input('min') public minPrice: number = 0;
  /** The maximum value to accept for this entry. */
  @Input('max') public maxPrice: number;
  /** Large input. */
  @Input() public lg: boolean;
  @Input() public currencies: Currency[];
  @Input() public prefix: string = '';
  @Input('help-block') public help_block: string;
  /** An example value to display inside the field when empty. */
  @Input() public placeholder: string;
  @Input() public disabled: boolean;
  @Input() public required: boolean = true;
  @Input() public showQuantityUnit = true;

  @Output() public readonly priceTypeChange = new EventEmitter();
  @Output() public readonly valueChange = new EventEmitter();

  public absValue: number;
  public months: number[] = [1, 3, 5, 7, 8, 9, 10, 11, 12];
  public positive: boolean = true;
  public previousAbsValue: number;
  public showInput: boolean;
  public years: number[];

  private sign: number = 1;

  constructor(
    private currentDate: CurrentDateService
  ) {
    let start = this.currentDate.get().getFullYear();
    this.years = [start, start + 1];
  }

  ngOnInit(): void {
    //this.checkType();
    this.checkValue();
  }

  private checkType(): void {
    if (this.price) {
      delete this.price.value;

      if (this.price.type !== 'premiums') {
        delete this.price.year;
        delete this.price.month;
        delete this.price.value;
        if (this.price.type === 'flat') {
          this.minPrice = 0.01;
        }
      } else {
        this.price.year = this.years[0];
        this.price.month = this.months[0];
        this.minPrice = 1;
      }
    }
  }

  private checkValue(): void {
    if (this.price) {
      if (this.price.value && typeof this.price.value === 'number') {
        if (this.price.value < 0) {
          this.sign = -1;
          this.positive = false;
          this.absValue = -this.price.value;
        } else {
          this.sign = 1;
          this.positive = true;
          this.absValue = this.price.value;
        }
      }
    }
    if (this.previousPrice) {
      if (this.previousPrice.value && typeof this.price.value === 'number') {
        if (this.previousPrice.value < 0) {
          this.previousAbsValue = -this.price.value;
        } else {
          this.previousAbsValue = this.price.value;
        }
      }
    }
  }

  public changeType(priceType: 'flat' | 'to_be_fixed' | 'consignment' | 'premiums'): void {
    this.price.type = priceType;
    this.priceTypeChange.emit();
    this.checkType();
  }

  public propagateValueChange(): void {
    this.valueChange.emit(this.price.value)
  }

  public changeAbsValue(absValue: number): void {
    this.absValue = absValue;
    this.updateValue();
  }

  public toggleSign(value: boolean): void {
    if (value) {
      this.sign = 1;
    } else {
      this.sign = -1;
    }
    this.updateValue();
  }

  private updateValue(): void {
    this.price.value = this.sign * this.absValue;
  }

  /** 
   * Customizes the default Angular option comparison algorithm.
   * @ignore */
  public compareId(a: { id: string | number }, b: { id: string | number }): boolean {
    return (!a && !b) || (a && b && a.id === b.id);
  }

  public displayName(a: any): string {
    return a.name;
  }

  public toggleInput(): void {
    this.showInput = !this.showInput;
    if (this.showInput) setTimeout(() => {
      this.numberInput.nativeElement.focus();
    });
  }
}
