import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from "class-transformer";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TrackrecordStep } from '../models/trackrecord-step.model';

@Injectable()
export class TrackRecordService {

  private baseUrl = '/:apiBase/companies/:companyId/trackrecords';
  private commentUrl = this.baseUrl + '/comment';
  private pdftUrl = this.baseUrl + '/pdf';

  constructor(
    private http: HttpClient
  ) { }

  /** Gets the track record of the specified entity. */
  public get(companyId: number, entity: string, entityId: string | number): Observable<TrackrecordStep[]> {
    const url = this.baseUrl
      .replace(':companyId', companyId + '');

    // Params Object
    const params = { entity, entityId };

    return this.http.get<TrackrecordStep[]>(url, { params: params }).pipe(map(trackrecord => {
      return plainToInstance(TrackrecordStep, trackrecord);
    }));
  }

  /** Gets the track record PDF of the specified entity. */
  public pdf(companyId: number, entity: string, entityId: string | number): Observable<any> {
    const url = this.pdftUrl
      .replace(':companyId', companyId + '');

    // Params Object
    const params = { entity, entityId };

    return this.http.get<any>(url, { params: params });
  }

  /** Posts a comment as part of the track record for the specified entity. */
  public comment(companyId: number, entity: string, entityId: string | number, comment: string, privateFlag: boolean = false): Observable<any> {
    const url = this.commentUrl
      .replace(':companyId', companyId + '');

    return this.http.post(url, {
      entity: entity,
      entityId: entityId,
      private: privateFlag,
      comment: comment
    });
  }
}
