import { Type } from "class-transformer";

import { Contract } from "../company/modules/contracts/models/contract.model";
import { Currency } from "./currency.model";
import { DeliveryDetail } from "./delivery-detail.model";
import { Quantity } from "./quantity.model";

/**
 * A price-to-be-fixed (PTBF) [[Contract]] separates the operational decision
 * to secure physical [[Product]], from the financial decision to fix the cost
 * of that Product.
 *
 * Those Contracts define conditions based on the quotation for an specified
 * delivery month of the futures market at the time of fixing, plus or minus an
 * optional differential.
 *
 * A [[Contract]] can have multiple PTBF conditions.
 */
export class PTBFConditions {
  contract: Contract; // Source: FIJAR_HEAD > NroContrato
  quantity: Quantity; // Source: FIJAR_DETA > Toneladas
  currency: Currency; // Source: FIJAR_HEAD > Moneda
  delivery: DeliveryDetail; // Source: FIJAR_DETA > Fecha Entrega Desde & Fecha Entrega Hasta

  @Type(() => Date)
  from: Date; // Source: FIJAR_DETA > Fecha Fijacion Desde

  @Type(() => Date)
  to: Date; // Source: FIJAR_DETA > Fecha Fijacion Hasta

  /** Fixing hour limit. Format: hh:mm */
  limit_hour?: string; // Source: FIJAR_DETA > Hs. Hasta Fijacion
  slate: string; // Source: FIJAR_DETA > Pizarra a Utilizar (Value defined in TablasValores.xls PIZARRAS tab)
  /** Bonus / Discount */
  bonus?: string; // Source: FIJAR_DETA > Cantidad Reb/Bonif + FIJAR_DETA > Unidad Reb/Bon
  order: number; // Source: FIJAR_DETA > Secuencia de Registro

  // Source: FIJAR_HEAD >
  // Minima Diaria
  // Maxima Diaria
  // Unidad Diaria
  // Minima Semanal
  // Maxima Semanal
  // Unidad Semanal
  // Minima Mensual
  // Maxima Mensual
  // Unidad Mensual
  // Dias Vto. Factura
  // Tipo de Dias
  // % Pago a Cta Final
  // Finales por Fijacion
  // Multa x Incumplimiento
  // Unidad Multa
  // Observaciones
  /**
   * ### Sample value
   *     Minima Diaria: {{ Minima Diaria }} {{ Unidad Diaria }};
   *     Dias Vto. Factura: {{ Dias Vto. Factura }} {{ Tipo de Dias }};
   *     Multa por incumplimiento: {{ Multa x Incumplimiento }} {{ Unidad Multa }};
   *     Observaciones: {{ Observaciones }}.
   */
  conditions?: string;

  constructor(
  ) {
    // this.contract = new Contract();
    // this.quantity = new Quantity();
    // this.currency = new Currency();
    // this.delivery = new DeliveryDetail();
  }
}
