import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Displays an inline square SVG country flag.
 * 
 * Based on [[https://www.npmjs.com/package/flag-icons|flag-icons]].
 * 
 * ## Usage
 * ``` html
 * <flag [country]="{iso: 'AR', name: 'Argentina'}"></flag>
 * ```
 */
@Component({
  selector: 'flag',
  templateUrl: './flag.component.html',
  styleUrls: ['./flag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlagComponent {

  @Input() public country: {
    /**
     * The [[https://www.iso.org/obp/ui/#search/code/|ISO 3166-1-alpha-2]] code
     * of the country.
     * */
    iso: string,
    name: string
  };

  /** @ignore */
  constructor() { }

}
