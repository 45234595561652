<ng-template #modalTemplate>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'VOLUME.CALCULATOR'|translate }}</h4>
  </div>
  <div class="modal-body">
    <form id="calculator-form"
    *ngIf="!loading; else loadingTemplate">
      <div class="row">
        <div class="col-xs-6">
          <div class="form-group">
            <label class="control-label">{{ 'VOLUME.AMOUNT'|translate }}</label>
            <div class="input-group">
              <!-- <span class="input-group-addon">{{ data.amount.unit?.name || '$' }}</span> -->
              <div dropdown agSelect name="currency" class="input-group-btn"
              [(ngModel)]="currency"
              [compareWith]="compareSlug">
                <button dropdownToggle type="button" class="btn btn-default dropdown-toggle"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ currency.name }}
                  <span class="caret"></span>
                </button>
                <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                  <li *ngFor="let c of currencies" [agOption]="c"><a>{{ c.name }}</a></li>
                </ul>
              </div><!-- /btn-group -->
              <input type="number" placeholder="0" step="0.01" class="form-control"
              name="amount"
              [(ngModel)]="amount"
              (ngModelChange)="update()"
              required
              [min]="0.01"
              [max]="9999999999">
            </div>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="form-group">
            <label class="control-label">{{ 'VOLUME.PRICE'|translate }}
              <span class="currency">/t</span>
            </label>
            <div class="input-group">
              <span class="input-group-addon">{{ currency.name }}</span>
              <input type="number" placeholder="0" step="0.01" class="form-control"
              name="price"
              [(ngModel)]="price"
              (ngModelChange)="update()"
              required
              [min]="0.01"
              [max]="9999999999">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="granting_date" class="control-label">{{ 'VOLUME.GRANTING_DATE'|translate }}</label>
            <div class="input-group">
              <span class="input-group-addon">
                <i class="material-symbols-rounded">today</i>
              </span>
              <input type="text"
              name="granting_date"
              class="form-control"
              [(ngModel)]="granting_date"
              (ngModelChange)="update()"
              bsDatepicker
              required
              container="#calculator-form"
              [bsConfig]="{showWeekNumbers: false, isAnimated: true, dateInputFormat: 'DD/MM/YYYY'}"
              placeholder="{{ 'GLOBAL.DATE_PLACEHOLDER'|translate }}" 
              readonly>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="due_date" class="control-label">{{ 'GLOBAL.DUE_DATE'|translate }}</label>
            <div class="input-group">
              <span class="input-group-addon">
                <i class="material-symbols-rounded">today</i>
              </span>
              <input type="text"
              name="due_date"
              class="form-control"
              [(ngModel)]="due_date"
              (ngModelChange)="update()"
              bsDatepicker
              required
              container="#calculator-form"
              [minDate]="granting_date"
              [bsConfig]="{showWeekNumbers: false, isAnimated: true, dateInputFormat: 'DD/MM/YYYY'}"
              placeholder="{{ 'GLOBAL.DATE_PLACEHOLDER'|translate }}" 
              readonly>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label class="control-label">{{ 'VOLUME.WARRANTY'|translate }}</label>
            <div class="input-group">
              <input type="number" placeholder="0" step="0.01" class="form-control" name="warranty"
              [(ngModel)]="warranty"
              (ngModelChange)="update()"
              [min]="0"
              [max]="100">
              <span class="input-group-addon">%</span>
            </div>
          </div>
        </div>
      </div>

      <table *ngIf="results"
      class="table table table-condensed">
        <tbody>
          <tr>
            <th>{{ 'VOLUME.DAYS'|translate }}</th>
            <td class="text-right">{{ results.days|number: '1.0-0' }}</td>
          </tr>
          <tr>
            <th>{{ 'FINTECH.INTEREST'|translate }}
              <span class="small text-muted">({{ rate }}%)</span>
            </th>
            <td class="text-right">
              <price [unit]="currency.name" [value]="results.interest"></price>
            </td>
          </tr>
          <tr *ngIf="tax_rate > 0">
            <th>{{ 'FINTECH.TAXES'|translate }}
              <span class="small text-muted">({{ tax_rate }}%)</span>
            </th>
            <td class="text-right">
              <price [unit]="currency.name" [value]="results.taxes"></price>
            </td>
          </tr>
          <ng-container *ngIf="warranty > 0">
            <tr>
              <th>{{ 'VOLUME.SUBTOTAL'|translate }}</th>
              <td class="text-right">
                <price [unit]="currency.name" [value]="results.subtotal"></price>
              </td>
            </tr>
            <tr>
              <th>{{ 'VOLUME.WARRANTY'|translate }}
                <span class="small text-muted">({{ warranty }}%)</span>
              </th>
              <td class="text-right">
                <price [unit]="currency.name" [value]="results.warranty"></price>
              </td>
            </tr>
          </ng-container>
          <tr class="active">
            <th>{{ 'GLOBAL.TOTAL'|translate }}</th>
            <td class="text-right">
              <price [unit]="currency.name" [value]="results.total"></price>
            </td>
          </tr>
          <tr>
            <th>{{ 'FUTURES.VOLUME'|translate }}</th>
            <td class="text-right">
              {{ results.volume|number:'1.0-3' }} <span class="currency">t</span>
            </td>
          </tr>
          <tr class="active tr-align-center">
            <th>{{ 'VOLUME.MULTIPLE'|translate: { multiple: 30 } }}</th>
            <td class="text-right bigger">
              {{ results.volume30 }} <span class="currency">t</span>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>