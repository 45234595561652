import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { JSend } from '../../models/jsend.model';
import { Preset } from '../../models/preset.model';
import { simpleHash } from '../../utilities/json-tools';

/**
 * Microservice that stores different [[Preset|Presets]] generated by
 * [[User|Users]] for forms or application views.
 * 
 * https://bitbucket.org/agreemarket/agree-presets
 */
@Injectable({
  providedIn: 'root'
})
export class PresetService {

  private baseUrl: string = '/:apiBase/presets';
  private presetById: string = this.baseUrl + '/:presetId';

  private _cache: {
    [key: string]: {
      created_at: Date;
      presets: Preset[];
    }
  } = {};
  private readonly _cacheTime: number = 10 * 60 * 1000;

  public presetFilter$ = new BehaviorSubject<Preset>(new Preset());
  public originalFilters$ = new BehaviorSubject<string>(null);

  constructor(
    private http: HttpClient
  ) { }

  public setPresetFilter(preset: Preset, originalFilters?: string): void {
    this.presetFilter$.next(preset);

    if (originalFilters) {
      this.originalFilters$.next(originalFilters);
    }
  }

  /** Create a new [[Preset]]. */
  public create(preset: Preset): Observable<string> {
    return this.http.post<JSend<{
      id: string
    }>>(this.baseUrl, preset).pipe(map(response => {
      return response.data.id;
    }));
  }

  /** Returns a collection of [[Preset|Presets]]. */
  public get(companyId: number, userId: number, key: string, sortResponse: boolean = true): Observable<Preset[]> {
    const eventKey = simpleHash(arguments);
    const now = new Date();

    if (this._cache[eventKey] &&
      now.getTime() - this._cache[eventKey].created_at.getTime() < this._cacheTime) {
      return of(this._cache[eventKey].presets);
    } else {
      return this.http.get<JSend<{
        presets: Preset[]
      }>>(this.baseUrl, {
        params: {
          companyId: companyId.toString(),
          userId: userId.toString(),
          key: key
        }
      }).pipe(map(response => {
        this._cache[eventKey] = {
          created_at: now,
          presets: plainToInstance(Preset, sortResponse ? response.data.presets.sort((a, b) => a.name.localeCompare(b.name)) : response.data.presets)
        };

        return this._cache[eventKey].presets;
      }));
    }
  }

  /** Deletes the specified [[Preset]]. */
  public delete(preset: Preset): Observable<JSend> {
    const url = this.presetById.replace(':presetId', preset.id + '');

    return this.http.delete<JSend>(url);
  }
}
