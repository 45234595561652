import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { AppliedFilter } from '../../models/filter.model';
import { FilterSetComponent } from '../filter-set/filter-set.component';

@Component({
  selector: 'filter-multiple-list',
  templateUrl: './filter-multiple-list.component.html',
  styleUrls: ['./filter-multiple-list.component.css']
})
export class FilterMultipleListComponent implements OnDestroy {

  @ViewChild('moreOptions', { static: true }) private readonly moreOptions: TemplateRef<any>;

  @Input() private sortKey: string;
  @Input() private key: string;
  /**
   * Maximum number of options to list.
   * The rest will be accessible through a modal.
   */
  @Input() public maxItems = 20;

  public optionsSelected: any[] = [];
  public options: any[];
  public title: string;

  private modalRef: BsModalRef;
  // private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  /** @ignore */
  constructor(
    private filterset: FilterSetComponent,
    private modalService: BsModalService
  ) {
    this.subscriptions.push(this.filterset.filters.subscribe(selectedOptions => {
      this.options = this.filterset.filterList[this.key].options;
      if (typeof this.sortKey !== "undefined" && this.options && this.options.length &&
        typeof this.options[0][this.sortKey] !== "undefined") {
        // Sort alphabetically
        this.options.sort((a, b) => a[this.sortKey].toString().localeCompare(b[this.sortKey]));
      }

      this.hideSelectedOptions(selectedOptions);

      this.title = this.filterset.filterList[this.key].label;

    }));
    this.filterset.countSuscriberChilds();
  }

  private hideSelectedOptions(selectedOptions: AppliedFilter[]): void {
    this.options.forEach((option, index) => {
      const existInSelectedOption = selectedOptions.find(filter => (filter.key === this.key && parseInt(filter.id) === option.id));
      if (existInSelectedOption) {
        this.options[index].disabled = true;
      } else {
        this.options[index].disabled = false;
      }
    });
  }

  public selectFilter(option): void {
    this.closeModal();
    this.filterset.showFilters();
    this.filterset.addFilterData(this.key, option.id, true);
  }

  get optionsAvailbles(): any[] {
    const optionsAvailable = this.options.filter(x => !this.optionsSelected.includes(x));
    return optionsAvailable;
  }

  public showAllOptions(): void {
    this.openModal(this.moreOptions, 'modal-md');
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
