import { Type } from "class-transformer";

import { CommercialZone } from "../company/models/commercial-zone.model";
import { Company } from "./company.model";

/**
 * An extended [[Company]] model to provide additional information from the
 * perspective of a specific Counterpart.
 * 
 * ### Related UI components:
 * - [[NetworkComponent]]
 */
export class Counterpart extends Company {
  /**
   * Date the Counterpart was verified by Agree
   */
  @Type(() => Date)
  readonly verification_date: Date;

  readonly invited_emails?: string[];

  /**
   * Indicates if current [[Company]] has blocked or approved interactions with
   * this Counterpart.
   */
  approved: boolean;
  network_relation: {
    /**
     * Agree's internal unique identifier.
     */
    id: number,
    /**
     * Counterpart that submitted the initial request
     */
    from_company_id: number,
    to_company_id: number,
    /**
     * ### Possible values
     * | id | name    |
     * |---:|---------|
     * |  1 | Pending |
     * |  2 | Active  |
     */
    status: {
      id: number,
      name: string
    }
  }

  readonly commercial_zones: CommercialZone[];
}
