<input autocomplete="off" autocorrect="off" autocapitalize="none" type="text"
maxlength="256"
[disabled]="disabled"
[(ngModel)]="search_string" name="search_string" placeholder="{{ 'GOOGLE_PLACES.PLACEHOLDER'|translate }}" class="form-control" #search [class.hidden]="hasMaxItems" [required]="required">

<ul class="locations" *ngIf="value?.length > 0" [class.margin-top]="!hasMaxItems">
  <li class="location" *ngFor="let location of value">
    <span class="location-text">{{ location.formatted_address }}</span>
    <span class="location-remove" (click)="removeGeolocation(location.place_id)">&times;</span>
  </li>
</ul>