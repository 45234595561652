import { AfterContentInit, ContentChildren, Directive, ElementRef, EventEmitter, HostBinding, Input, IterableDiffers, OnChanges, OnDestroy, Optional, Output, QueryList, Self, SimpleChanges, forwardRef } from '@angular/core';
import { NgForm, NgModel, NgModelGroup } from '@angular/forms';
import { Observable, Subscription, debounceTime } from 'rxjs';

@Directive({
  selector: '[hasError]'
})
export class HasErrorDirective implements AfterContentInit, OnChanges, OnDestroy {

  @Input('hasErrorOn') private hasErrorOn: 'submit' | 'dirty' | 'always' = 'submit';

  @Output() public readonly modelsChange = new EventEmitter();

  @HostBinding('class.has-error')
  private hasError: boolean;

  @HostBinding('class.has-feedback')
  private hasFeedback: boolean;

  @ContentChildren(forwardRef(() => NgModel), { descendants: true })
  private models: QueryList<NgModel>;

  private readonly debouceTime: number = 100;
  private valueChanges: Observable<any>;
  private differ: any;
  private subscriptions: Subscription[] = [];
  private feedbackSpan: any;

  constructor(
    private el: ElementRef,
    private differs: IterableDiffers,
    private form: NgForm,
    @Self() @Optional() private modelGroup: NgModelGroup
  ) {
    this.differ = this.differs.find([]).create(null);

    this.subscriptions.push(this.form.ngSubmit.subscribe(() => {
      this.validate();
    }));

    this.subscriptions.push(this.form.statusChanges.pipe(
      debounceTime(this.debouceTime)
    ).subscribe(() => {
      // Validate after submitted set to false in resetForm().
      this.validate();
    }));
  }

  ngAfterContentInit(): void {
    // Check if a feedback span exists
    this.feedbackSpan = this.el.nativeElement.querySelector('span.form-control-feedback');

    if (this.modelGroup) {
      this.initGroupTracking();
    } else {
      this.initModelTracking();
    }
  }

  private initGroupTracking(): void {
    // Sometimes statusChanges is null
    setTimeout(() => {
      if (this.modelGroup.statusChanges) {
        this.subscriptions.push(this.modelGroup.statusChanges.pipe(
          debounceTime(this.debouceTime)
        ).subscribe(() => {
          this.validate();
        }));
        this.modelsChange.emit();
      } else {
        this.initGroupTracking();
      }
    }, 100);
  }

  private initModelTracking(): void {
    this.models.map((model) => {
      this.initListener(model);
    });

    this.subscriptions.push(this.models.changes.pipe(
      debounceTime(this.debouceTime)
    ).subscribe((changes) => {
      let changeDiff = this.differ.diff(changes);
      if (changeDiff) {
        changeDiff.forEachAddedItem((model) => {
          // added model
          this.initListener(model);
        });
        changeDiff.forEachRemovedItem((model) => { // removed item
          // removed model
          // unsubscribe()
        });
      }
    }));

    this.modelsChange.emit();
  }

  private initListener(model: NgModel): void {

    if (model.valueChanges) {
      this.subscriptions.push(model.valueChanges.pipe(
        debounceTime(this.debouceTime)
      ).subscribe(() => {
        this.validate();
      }));
    }

    if (model.statusChanges) {
      this.subscriptions.push(model.statusChanges.pipe(
        debounceTime(this.debouceTime)
      ).subscribe(() => {
        this.validate();
      }));
    }

    this.validate();
  }

  private validate(): void {
    // If it's a group, don't validate indvidual models, just the group
    if (this.modelGroup) {
      this.hasError = this.modelGroup.invalid &&
        (this.hasErrorOn !== 'submit' || this.form.submitted);
    } else if (this.models) {
      let hasError = false;
      this.models.map((model) => {
        hasError = hasError || (
          model.invalid &&
          (this.hasErrorOn !== 'submit' || this.form.submitted) &&
          (this.hasErrorOn !== 'dirty' || model.dirty)
        );
      });
      this.hasError = hasError;
    }

    this.hasFeedback = this.hasError && this.feedbackSpan;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.models || changes.modelGroup) {
      this.modelsChange.emit();
    }
  }

  public getModels(): QueryList<NgModel> {
    return this.models;
  }

  public getModelGroup(): NgModelGroup {
    return this.modelGroup;
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
