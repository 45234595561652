<div class="btn-group" dropdown
#labelDropdown="bs-dropdown"
(onShown)="dropdownHandler(true)"
(onHidden)="dropdownHandler(false)"
[class.hidden]="selected?.length === 0"
container=".app-content"
[insideClick]="true">
  <button id="button-label" dropdownToggle
  [disabled]="processing"
  type="button" class="btn btn-link btn-sm dropdown-toggle"
  aria-controls="dropdown-label"><i class="material-symbols-rounded">label</i></button>
  <ul id="dropdown-label" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-label">
    <ng-container *ngIf="!labels">
      <div class="loading loading-sm">
        <spinner></spinner>
      </div>
      <li class="divider"></li>
    </ng-container>

    <ng-container *ngIf="labels?.length > 0">
      <li class="dropdown-header">{{ 'LABELS.LABEL_AS'|translate }}</li>
      <div class="labels-list">
        <li role="menuitem" *ngFor="let label of labels"
        [class.disabled]="processing">
          <label class="dropdown-item"
          *ngIf="labelDropdown.isOpen">
            <input type="checkbox" class="no-margin"
            name="label_{{ label.id }}"
            [indeterminate]="label.partial"
            [checked]="label.status"
            (change)="toggle($event, label)">&nbsp;
            <span class="ag-label ag-label-lg" [style.backgroundColor]="label.color">{{ label.name }}</span>
          </label>
        </li>
      </div>
      <li class="divider"></li>
    </ng-container>
    <ng-container *ngIf="!dirty">
      <li role="menuitem"
      [class.disabled]="processing"><a class="dropdown-item" (click)="createLabel()">{{ 'LABELS.CREATE'|translate }}</a></li>
      <li role="menuitem"
      *ngIf="labels?.length > 0"
      [class.disabled]="processing"><a class="dropdown-item" (click)="manage()">{{ 'LABELS.MANAGE'|translate }}</a></li>
    </ng-container>
    <ng-container *ngIf="dirty">
      <li role="menuitem"
      [class.disabled]="processing"><a class="dropdown-item" (click)="applyChanges()">{{ 'QUALITY_INPUT.ACTION'|translate }}</a>
      <li role="menuitem"
      [class.disabled]="processing"><a class="dropdown-item" (click)="labelDropdown.hide()">{{ 'GLOBAL.CANCEL'|translate }}</a>
    </ng-container>
  </ul>
</div>

<ng-template #createModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'LABELS.NEW'|translate }}</h4>
  </div>
  <div class="modal-body">
    <form id="label-form" #labelForm="ngForm" (ngSubmit)="labelForm.valid && submit()">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group" hasError>
            <label class="control-label">{{ 'GLOBAL.NAME'|translate }}</label>
            <input type="text" name="name" class="form-control"
            autocomplete="off"
            [disabled]="processing"
            maxlength="24"
            [modalFocus]="true"
            required
            [(ngModel)]="label.name">
            <error-list></error-list>
            <p class="help-block">{{ 'LABELS.SAMPLE'|translate }} <span class="ag-label ag-label-lg" [style.backgroundColor]="label.color">{{ label.name || 'GLOBAL.NAME'|translate }}</span></p>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label class="control-label">{{ 'LABELS.COLOR'|translate }}</label>
            <div class="color-radio">
              <div class="radio-inline" *ngFor="let color of palette">
                <label>
                  <input type="radio" name="color"
                  [disabled]="processing"
                  value="{{ color }}"
                  [(ngModel)]="label.color">
                  <span class="ag-label" [style.backgroundColor]="color">Aa</span>
                </label>
              </div>
              <div>
                <label class="color-picker">
                  <a [class.disabled]="processing" class="btn btn-link">{{ 'CERTIFICATES.MODAL.OPTION_OTHER'|translate }}</a>
                  <input type="color"
                  [disabled]="processing"
                  name="customColor" [(ngModel)]="label.color">
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="closeModal()" [disabled]="processing" class="btn btn-link" data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
    <button type="submit" form="label-form" class="btn btn-agree" [disabled]="processing || !labelForm.valid">{{ 'LABELS.CREATE'|translate }}</button>
  </div>
</ng-template>

<ng-template #manageModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'LABELS.MANAGE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <table class="table table-condensed">
      <tbody>
        <tr *ngFor="let label of labels">
          <td class="sap">
            <label class="color-picker"
            (click)="selectedLabel = label">
              <i class="material-symbols-rounded" [style.color]="label.color">label</i>
              <input type="color"
              (change)="update('color', $event)"
              [disabled]="processing"
              name="customColor" [value]="label.color">
            </label>
          </td>
          <td>
            <div class="flex-center-distributed" *ngIf="selectedLabel?.id !== label.id">
              {{ label.name }}
              <!-- Edit name button -->
              <div class="grow">
                <button type="button"
                class="show-onhover btn btn-link btn-xs" title="{{ 'GLOBAL.EDIT'|translate }}"
                (click)="selectedLabel = label" [disabled]="processing"><i class="material-symbols-rounded">edit</i></button>
              </div>
            </div>
            <input *ngIf="selectedLabel?.id === label.id"
            class="form-control input-inline" type="text"
            (keyup.enter)="update('name', $event)"
            (blur)="selectedLabel = undefined"
            autocomplete="off"
            autofocus
            [disabled]="processing"
            maxlength="24"
            [value]="label.name">
          </td>
          <td class="sap">
            <a
            title="{{ 'TEMPLATES_VIEW.DELETE'|translate }}"
            (onConfirm)="deleteLabel(label)"
            [confirm-options]="{
              action: 'GLOBAL.YES_DELETE'|translate
            }"
            [class.disabled]="processing"><i class="material-symbols-rounded">delete</i></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>