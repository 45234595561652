import { Component, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { instanceToInstance } from 'class-transformer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { BusinessDetail, QuanityCalculator } from '../../../company/modules/commercial/models/business-detail.model';
import { Price } from '../../../models/price.model';

@Component({
  selector: 'ag-volume-calculator',
  templateUrl: './volume-calculator.component.html',
  styleUrls: ['./volume-calculator.component.scss']
})
export class VolumeCalculatorComponent implements OnDestroy {

  @ViewChild('modalTemplate', { static: true }) private readonly modalTemplate: TemplateRef<any>;

  @Input('business-detail')
  @Output('business-detail')
  public business_detail: BusinessDetail;

  /** Amount to settle. */
  @Input() public amount: Price;
  @Input() private price: number;
  @Input() public readonly: boolean;

  public calculatedQuantity: number;
  public data: QuanityCalculator;
  public priceWithVat: number;
  public suggested: number;
  public total: number;

  private modalRef: BsModalRef;
  private modalSub: Subscription;

  constructor(
    private modalService: BsModalService
  ) { }

  /** Opens modal. */
  public show(): void {
    if (this.business_detail.quantity_calculator) {
      this.data = instanceToInstance(this.business_detail.quantity_calculator);
    } else {
      this.data = new QuanityCalculator({
        price: this.price,
        amount: this.amount,
        vat: 10.5
      });
    }

    this.openModal(this.modalTemplate);
    this.calculate();
  }

  public done(): void {
    this.business_detail.quantity.value = parseFloat(this.suggested.toFixed(3));
    this.business_detail.quantity_calculator = instanceToInstance(this.data);
    this.closeModal();
  }

  public calculate(form?: NgForm): void {
    if (!form || form.valid) {
      this.total = this.data.amount.value + (this.data.additionals || 0);
      this.priceWithVat = this.data.price + (this.data.vat * (this.data.price / 100));
      this.calculatedQuantity = this.total / this.priceWithVat;
      this.suggested = this.data.warranty > 0 ? this.calculatedQuantity + ((this.data.warranty * this.calculatedQuantity) / 100) : this.calculatedQuantity;
    }
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      // if (!this.succesfullySubmitted) this.onCancel.emit();
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.closeModal();
  }
}
