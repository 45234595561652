import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject, Subscription } from 'rxjs';

/**
 * This component displays a dialog window with an optional message and two
 * buttons, Accept and Cancel.
 * 
 * It captures the click event applied to the content and asks the user for
 * confirmation, similar to
 * [[https://developer.mozilla.org/es/docs/Web/API/Window/confirm|Window.confirm()]].
 * 
 * ## Usage
 * ``` html
 * <agree-confirm
 * title="Are you sure?"
 * body="This action cannot be undone."
 * confirm-button="Yes, proceed"
 * cancel-button="Cancel">
 *   <button class="btn btn-link" (click)="doSomething()">Action</button>
 * </agree-confirm>
 * ```
 */
@Component({
  selector: 'agree-confirm',
  exportAs: 'agreeConfirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit, OnDestroy {

  @ViewChild('confirmModal', { static: true }) private readonly confirmModal: TemplateRef<any>;
  @ViewChild('content', { static: true }) private readonly content;

  /** Close confirmation modal based on an external event. */
  @Input() public closeOnEvent: Subject<any>;
  /** Optional modal title. */
  @Input() public title: string;
  /** Optional modal body text (supports HTML). */
  @Input() public body: string;
  /** Confirm button text. */
  @Input('confirm-button') public confirm_text: string;
  /** Cancel button text. */
  @Input('cancel-button') public cancel_text: string;
  /** Disables the component. */
  @Input() public disabled: boolean = false;

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;

  private modalRef: BsModalRef;
  // private modalSub: Subscription;
  private result: Subject<boolean> = new Subject<boolean>();
  private event: MouseEvent;
  private stopPropagation: boolean = true;
  private subs: Subscription;

  /** @ignore */
  constructor(
    private modalService: BsModalService
  ) { }

  /** @ignore */
  ngOnInit(): void {
    this.content.nativeElement.addEventListener('click', (event: MouseEvent) => {
      if (this.stopPropagation && !this.disabled) {
        this.event = event;
        event.stopImmediatePropagation();
        this.askConfirmation();

        // si es un checkbox, prevengo el cambio de checked hasta que confirmo
        if (event.target['type'] === 'checkbox') event.target['checked'] = !event.target['checked'];
      }
    }, true);
  }

  /** Executes the captured event. */
  public action(): void {
    this.processing = true;

    // If a content event was captured, then dispatch it
    if (this.event) {
      this.stopPropagation = false;
      this.event.target.dispatchEvent(this.event);
      this.stopPropagation = true;

      // si es un checkbox, lo checkeo una vez que se ejecuta la accion
      if (this.event.target['type'] === 'checkbox') {
        this.event.target['checked'] = !this.event.target['checked'];
      }
    }

    // Should the modal wait for an external event to close?
    if (this.closeOnEvent) {
      this.subs = this.closeOnEvent.subscribe(event => {
        this.onSuccess();
      });
    } else {
      this.onSuccess();
    }
  }

  /** Cancels the executed event. */
  public cancel(): void {
    if (this.processing) return;

    this.result.next(false);
    this.result.complete();
    this.result = null;

    this.closeModal();
  }

  private onSuccess(): void {
    this.processing = false;

    this.result.next(true);
    this.result.complete();
    this.result = null;

    this.closeModal();
  }

  /** Opens the confirmation modal. */
  public askConfirmation(): Observable<boolean> {
    this.result = new Subject<boolean>();
    this.openModal(this.confirmModal);

    return this.result;
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    // this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
    //   this.modalSub.unsubscribe();
    //   this.modalRef = undefined;
    //   // Reset all values
    //   this.processing = false;
    // });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.closeModal();

    if (this.subs) this.subs.unsubscribe();
  }
}
