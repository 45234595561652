import { Account } from './account.model';
import { Phone } from '../../models/phone.model';

/**
 * A User respresents a real-life person in Agree. It can belong to one or
 * multiple [[Company|Companies]].
 * 
 * Users are linked to [[Company|Companies]] through [[Account|Accounts]].
 */
export class User {
  /** Agree's internal unique identifier. */
  readonly id: number;
  name: string;
  last_name: string;
  email: string;
  /**
   * The image's URL of the User's avatar.
   */
  avatar: string;
  /**
   * 1 for true.
   */
  active: number;
  /**
   * Wheter the User's email has been verified or not. 1 for true.
   */
  mail_verified: number;
  /**
   * Agree platform SUPER-ADMIN.
   */
  readonly admin: boolean;
  readonly readOnly: boolean;
  readonly writeOnly: boolean;
  readonly created_at: string;
  readonly updated_at: string;
  readonly deleted_at: string;
  accounts: Account[];
  phone: Phone;
  /**
   * User's preferred language
   * [[https://es.wikipedia.org/wiki/ISO_639-1|ISO 639-1]] code.
   */
  language: string;
  /**
   * Wheter the User's phone number has been verified or not. 1 for true.
   */
  phone_verified: number;
  phone_code: number;
  allows_whatsapp: boolean;
  /**
   * Wheter the User's has set a password or not (e.g.: Users who registered
   * using Google Sign-In).
   */
  readonly has_password: boolean;

  constructor(data: Partial<User> = {}) {
    Object.assign(this, data);
  }
}

export class NotificationChannel {
  /** Agree's internal unique identifier. */
  readonly id: number;
  name: string;
  status: boolean;
}
