import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ag-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.scss']
})
export class GaugeComponent implements OnInit, OnChanges {

  @Input() public value: number;
  @Input() public max: number = 100;

  @Input() public label: string;
  @Input() public colors: string[] = [
    '#ff4d4d',
    '#ff7416',
    '#27ae60',
    '#27ae60'
  ];

  public color: string;
  /** The language currently used. */
  public currentLang: string;
  public rotation: number = 0;
  public percentage: number = 0;

  constructor(
    private translateService: TranslateService
  ) {
    this.color = this.colors[0];
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.max > 0) {
      // Calcular el porcentaje asegurándose de que esté entre 0 y 1
      this.percentage = Math.min(1, Math.max(0, this.value / this.max));

      // Calcular el índice del color basado en el porcentaje
      const index = Math.min(Math.floor(this.colors.length * this.percentage), this.colors.length - 1);

      this.color = this.colors[index];

      setTimeout(() => {
        this.rotation = this.percentage * 180;
      });
    }
  }
}
