export class EntityBinding {
  /** Agree's internal unique identifier. */
  readonly id?: number;
  /** Whether to bind or not. */
  value: boolean;

  /** Reference to the entity and its id. */
  entity?: {
    id: number | string;
    /** Bindeable entities must have an entity() get method. */
    type: string;
  };

  constructor(data: Partial<EntityBinding> = {}) {
    Object.assign(this, data);
  }
}
