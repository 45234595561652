<div class="loading" *ngIf="loading">
  <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
</div>

<div class="viewport">
  <div class="child list" [class.open]="selectedNode">
    <table class="table table-hover table-fixed" *ngIf="!loading && nodes.length > 0">
      <thead>
        <tr>
          <th class="s t g" [style.width]="(settings.padding * 2) + (branches * settings.points.margin) + 'px'"></th>
          <th class="s t g">{{ 'TRACKER.EVENT'|translate }}</th>
          <th class="s t g text-right">{{ 'GLOBAL.DATE'|translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let node of nodes; let i = index" class="clickable"
          (click)="setNode(node)"
          class="tr-align-center"
          [class.selected]="selectedNode?.id === node.id">
          <td class="td-empty" [style.width]="(settings.padding * 2) + (branches * settings.points.margin) + 'px'">
            <div *ngIf="i === 0" class="tracer">
              <svg
                [attr.width]="(settings.padding * 2) + (branches * settings.points.margin)"
                [attr.height]="nodes.length * settings.row_height">
                <svg:g [attr.transform]="'translate('+settings.padding+',0)'"><!-- Paths -->
                  <ng-container *ngFor="let n of nodes">
                    <ng-container *ngFor="let p of n.parents">
                      <ng-container *ngTemplateOutlet="path;context:{
                        id: n.id,
                        p: p,
                        x: nodesMap[p].x * settings.points.margin,
                        y: (nodesMap[p].y * settings.row_height) - (settings.row_height / 2),
                        dx: nodesMap[n.id].x * settings.points.margin,
                        dy: (nodesMap[n.id].y * settings.row_height) - (settings.row_height / 2)
                      }"></ng-container>
                    </ng-container>
                    <ng-template #path let-x="x" let-y="y" let-dx="dx" let-dy="dy" let-id="id" let-p="p">
                      <!-- Draws a line from x,y to dx,dy -->
                      <!-- No tocar, es delicado -->
                      <!-- <svg:line
                      [attr.x1]="x" [attr.y1]="y"
                      [attr.x2]="dx" [attr.y2]="dy"
                      stroke="#00000033" stroke-width="1" stroke-dasharray="2 1"></svg:line> -->
                      <svg:path *ngIf="x <= dx"
                        [class.selected]="selectedNode &&
                          (selectedNode.id === id || p === selectedNode.id)"
                        [attr.d]="
                        'M' + x + ',' + y +
                        ' C' + x + ',' + (y - (settings.paths.bezier * settings.row_height)) +' '+ dx + ',' + ((y - settings.row_height) + (settings.paths.bezier * settings.row_height)) + ' ' + dx + ',' + (y - settings.row_height) +
                        ' L'+ dx + ',' + dy"></svg:path>
                      <svg:path *ngIf="x > dx"
                        [class.selected]="selectedNode &&
                          (selectedNode.id === id || p === selectedNode.id)"
                        [attr.d]="
                        'M' + x + ',' + y +
                        ' L' + x + ',' + (dy + settings.row_height) +
                        ' C' + x + ',' + (dy + (settings.paths.bezier * settings.row_height)) + ' ' + dx + ',' + ((dy + settings.row_height) - (settings.paths.bezier * settings.row_height)) + ' ' + dx + ',' + dy"></svg:path>
                    </ng-template>
                  </ng-container>
                </svg:g>
                <svg:g><!-- Circles -->
                  <svg:circle *ngFor="let n of nodes"
                  [class.selected]="selectedNode &&
                    selectedNode.id === n.id"
                  [class.related]="selectedNode &&
                    (selectedNode.parents?.includes(n.id) || nodesMap[selectedNode.id].childs?.includes(n.id))"
                  [attr.cx]="settings.padding + (nodesMap[n.id].x * settings.points.margin)"
                  [attr.cy]="(nodesMap[n.id].y * settings.row_height) - (settings.row_height / 2)" r="4"></svg:circle>
                </svg:g>
              </svg>
            </div>
          </td>
          <td class="truncate">{{ node.event|translate }}</td>
          <td class="truncate text-right">
            <ag-timestamp [date]="node.date"></ag-timestamp>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div #dataView class="child data" [class.hidden]="!selectedNode">
    <div class="container-fluid">
      <div class="row" *ngIf="selectedNode">
        <div class="col-xs-12">
          <div class="btn-group pull-right" role="group">
            <button type="button" class="btn btn-default" title="Anterior" (click)="previous(true)"><i class="material-symbols-rounded">keyboard_arrow_left</i></button>
            <button type="button" class="btn btn-default" title="Siguiente" (click)="next(true)"><i class="material-symbols-rounded">keyboard_arrow_right</i></button>
          </div>
          <h4>{{ selectedNode.event|translate }}</h4>
          <p class="small text-muted">{{ selectedNode.date|date: ('COUNTERORDER_VIEW.BOOKING_DATE_FORMAT'|translate):undefined:currentLang }}</p>
        </div>
      </div>
      <div class="row" [class.hidden]="!map || !selectedNode?.locations">
        <div class="col-xs-12">
          <!-- <h5>Map</h5> -->
          <div id="map" class="rounded-border"></div>
        </div>
      </div>
      <ng-container *ngIf="selectedNode">
        <div class="row">
          <div class="col-sm-4" *ngIf="selectedNode.media">
            <h5>{{ 'TRACKER.GALLERY'|translate }}</h5>
            <div class="rounded-border">
              <media-browser [order-media]="selectedNode.media" [fullscreen-mode]="true"></media-browser>
            </div>
          </div>
          <div [class.col-sm-12]="!selectedNode.media" [class.col-sm-8]="selectedNode.media">
            <h5>{{ 'TRACKER.INFORMATION'|translate }}</h5>
            <p *ngIf="selectedNode.batch">
              <small>{{ 'TRACKER.BATCH_ID'|translate }}: </small><code>{{ selectedNode.batch.in }}</code>
              <span *ngIf="selectedNode.batch.in !== selectedNode.batch.out">
                &nbsp;<i class="material-symbols-rounded text-muted">arrow_right_alt</i>&nbsp;
                <small>{{ 'TRACKER.BATCH_ID'|translate }}: </small><code>{{ selectedNode.batch.out }}</code>
              </span>
            </p>
            <!-- <h5>Company</h5> -->
            <div class="f" *ngIf="selectedNode.companies">
              <div class="fi co"
              *ngFor="let c of selectedNode.companies">
                <company-info [company]="c"></company-info>
              </div>
            </div>

            <p *ngIf="selectedNode.description" class="mt">{{ selectedNode.description }}</p>

            <div *ngIf="selectedNode.parents || nodesMap[selectedNode.id].childs" class="rounded-border mt">
              <table class="table table-condensed table-hover">
                <thead>
                  <tr>
                    <th class="sap"></th>
                    <th>{{ 'TRACKER.RELATED_EVENTS'|translate }}</th>
                    <th class="sap text-right">{{ 'GLOBAL.DATE'|translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let nodeId of nodesMap[selectedNode.id].childs" (click)="setNode(getNodeById(nodeId))" class="clickable tr-align-center">
                    <td class="sap text-faded"><i class="material-symbols-rounded">arrow_forward</i></td>
                    <td class="truncate">{{ getNodeById(nodeId).event|translate }}</td>
                    <td class="sap text-right">
                      <ag-timestamp [date]="getNodeById(nodeId).date"></ag-timestamp>
                    </td>
                  </tr>
                  <tr *ngFor="let nodeId of selectedNode.parents" (click)="setNode(getNodeById(nodeId))" class="clickable tr-align-center">
                    <td class="sap text-faded"><i class="material-symbols-rounded">arrow_back</i></td>
                    <td class="truncate">{{ getNodeById(nodeId).event|translate }}</td>
                    <td class="sap text-right">
                      <ag-timestamp [date]="getNodeById(nodeId).date"></ag-timestamp>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12" *ngIf="selectedNode.attachments">
            <h5>{{ 'ATTACHMENTS.HEADER'|translate }}</h5>
            <div class="list-group">
              <a href="#" *ngFor="let file of selectedNode.attachments" class="list-group-item"><i class="material-symbols-rounded pull-right">cloud_download</i>{{ file.name }}</a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12" *ngIf="selectedNode.table">
            <h5>{{ 'WORKING_ORDERS.VIEWMODE.TABLE'|translate }}</h5>
            <div class="table-responsive rounded-border table-mh50">
              <table class="table table-condensed table-hover table-micro" sortable #tableSortable="tableSortable">
                <thead>
                  <tr>
                    <th *ngFor="let column of selectedNode.table[0]| keyvalue: originalOrder" class="s t g" [sortBy]="column.key">{{ column.key }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of selectedNode.table|orderBy: tableSortable.sortBy">
                    <!-- <td *ngFor="let cell of row| keyvalue: originalOrder" [innerHtml]="parseValue(cell.value)"></td> -->
                    <ng-container *ngFor="let cell of row| keyvalue: originalOrder">
                      <td *ngIf="typeof(cell.value) === 'number'; else defaultValue" class="text-right"><samp>{{ cell.value }}</samp></td>
                      <ng-template #defaultValue>
                        <td>{{ cell.value }}</td>
                      </ng-template>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
