import { Type } from "class-transformer";
import { forwardRef } from '@angular/core';
import { DeliveryDetail } from '../../../../models/delivery-detail.model';
import { Price } from '../../../../models/price.model';
import { Quantity } from '../../../../models/quantity.model';
import { Company } from '../../../../models/company.model';
import { Location } from '../../../../models/location.model';
import { LoadingRate } from '../../../../models/loading_rate.model';
import { Port } from '../../../../models/port.model';
import { Berth } from '../../../../models/berth.model';
import { PrimitiveUnit } from "../../../../models/unit.model";

export class QuanityCalculator {
  /** Commissions, freight, etc. */
  additionals?: number;
  /** Amount of money to settle. */
  amount?: Price;
  /**
   * Reference price per unit (in general, tons).
   * In the same currency as the amount.
   */
  price?: number;
  warranty?: number;
  vat: number = 0;

  constructor(data: Partial<QuanityCalculator> = {}) {
    this.amount = new Price();

    Object.assign(this, data);
  }
};

export class BusinessDetail {

  @Type(forwardRef(() => Price) as any)
  price: Price;

  @Type(forwardRef(() => Location) as any)
  origin: Location;

  geo_origin: any[];

  harvest: string;
  payment_terms: PrimitiveUnit;
  loading_rate: LoadingRate;
  port: Port;
  berth: Berth;
  other_berth: string;

  @Type(forwardRef(() => DeliveryDetail) as any)
  delivery: DeliveryDetail;

  @Type(forwardRef(() => Quantity) as any)
  quantity: Quantity;

  @Type(() => QuanityCalculator)
  quantity_calculator: QuanityCalculator;

  authorized_buyers: Company[];
  sustainable?: boolean;

  constructor(
  ) {
    this.price = new Price();
    this.origin = null;
    this.harvest = null;
    this.quantity = new Quantity();
    this.authorized_buyers = [];
    this.delivery = new DeliveryDetail();
    this.loading_rate = new LoadingRate();
  }
}
