import { Injectable } from '@angular/core';

@Injectable()
export class CurrentDateService {
  /*
   * Use this service to set and retrieve current Date
   */

  private lastDate: Date; // Last date received
  private updatedAt: Date; // When lastDate was updated - Date based
  private updatedAtMs: number; // When lastDate was updated - performance based

  constructor() {
    this.set(new Date()); // Sets a default Date based on users browser
  }

  public set(d: Date): void {
    this.lastDate = d;

    if (performance) this.updatedAtMs = performance.now(); // Updates updatedAtMs milliseconds
    else this.updatedAt = new Date(); // Updates updatedAt date
  }

  public get(): Date {
    if (this.updatedAtMs) {
      return new Date(this.lastDate.getTime() + performance.now() - this.updatedAtMs); // Adds difference to lastDate
    } else {
      const now = new Date();

      return new Date(this.lastDate.getTime() + now.getTime() - this.updatedAt.getTime()); // Adds difference to lastDate
    }
  }
}
