import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userMessage'
})
export class UserMessagePipe implements PipeTransform {
  /**
   * Transforms a user-created message into safe HTML content by escaping HTML, linkifying URLs,
   * detecting line breaks, and enlarging emojis if applicable.
   * 
   * @param {string} message - The user-created message to transform.
   * @returns {string} - The transformed message as safe HTML content.
   */
  transform(message: string): string {
    let response = message.trim();
    response = this.escapeHtml(response);
    response = this.linkify(response);
    response = this.lineBreaks(response);
    response = this.emoji(response);

    return response;
  }

  /**
   * Replaces potentially unsafe HTML characters with safe equivalents.
   * 
   * @param {string} unsafe - The string containing potentially unsafe HTML characters.
   * @returns {string} - The escaped string with safe HTML characters.
   * @private
   */
  private escapeHtml(unsafe: string): string {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }

  /**
   * Replaces newline characters with HTML line break tags.
   * 
   * @param {string} text - The string in which to replace newline characters.
   * @returns {string} - The string with newline characters replaced by HTML line break tags.
   * @private
   */
  private lineBreaks(text: string): string {
    return text.replace(/\n/g, "<br>");
  }

  /**
   * Transforms URLs in the text into clickable links.
   * 
   * @param {string} text - The string containing URLs to transform.
   * @returns {string} - The string with URLs transformed into clickable links.
   * @private
   */
  private linkify(text: string): string {
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    });
  }

  /**
   * Enlarges emojis in the text if the text contains only emojis and is shorter than 30 characters.
   * 
   * @param {string} text - The string containing emojis to enlarge.
   * @returns {string} - The string with enlarged emojis if applicable.
   * @private
   */
  private emoji(text: string): string {
    if (text.length < 30 && this.containsOnlyEmojis(text)) {
      text = "<span class='biggest'>" + text + "</span>";
    }
    return text;
  }

  /**
   * Checks if a string contains only emojis.
   * 
   * @param {string} text - The string to check.
   * @returns {boolean} - True if the string contains only emojis, false otherwise.
   * @private
   */
  private containsOnlyEmojis(text: string): boolean {
    const onlyEmojis = text.replace(new RegExp('[\u0000-\u1eeff]', 'g'), '');
    const visibleChars = text.replace(new RegExp('[\n\r\s]+|( )+', 'g'), '');
    return onlyEmojis.length === visibleChars.length;
  }
}
