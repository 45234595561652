import { Component, EventEmitter, forwardRef, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { instanceToInstance } from 'class-transformer';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { CommercialZone } from '../../../company/models/commercial-zone.model';
import { CommercialZonesService } from '../../../company/services/commercial-zones.service';
import { Company } from '../../../models/company.model';
import { EntityBinding } from '../../../models/entity-binding.model';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';

/**
 * [[CommercialZone|Commercial zones]] manager. Allows you to create, edit and
 * delete [[Company]] Commercial zones.
 * 
 * It also allows you to assign and remove [[CommercialZone|Commercial zones]]
 * to a [[CommercialZonesManagerComponent.selected|specified collection]] of
 * items.
 * 
 * ## Usage
 * ``` html
 * <ag-commercial-zones-manager
 * [(ngModel)]="selection"
 * [company]="company"></ag-commercial-zones-manager>
 * ```
 */
@Component({
  selector: 'ag-commercial-zones-manager',
  templateUrl: './commercial-zones-manager.component.html',
  styleUrls: ['./commercial-zones-manager.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CommercialZonesManagerComponent),
    multi: true
  }]
})
export class CommercialZonesManagerComponent implements OnDestroy {

  @ViewChild('createModal', { static: true }) private readonly createModal: TemplateRef<any>;
  @ViewChild('manageModal', { static: true }) private readonly manageModal: TemplateRef<any>;
  @ViewChild('commercialZoneDropdown', { static: true }) private readonly commercialZoneDropdown: BsDropdownDirective;

  @Input() public company: Company;

  @Output() readonly submitted = new EventEmitter<CommercialZone>();

  public commercialZone: CommercialZone;
  public commercialZones: CommercialZone[];
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public dirty: boolean;
  public isUnique: boolean;

  private commercialZoneChanges: EntityBinding[];
  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];
  private subscribed: boolean;

  constructor(
    private commercialZonesService: CommercialZonesService,
    private modalService: BsModalService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  public dropdownHandler(isOpen: boolean): void {
    if (isOpen) {
      // Reset values
      this.dirty = false;
      this.commercialZoneChanges = [];
      this.loadData();
    }
  }

  private loadData(): void {
    if (!this.subscribed) { // Load only once
      this.subscribed = true;
      this.processing = true;

      this.subscriptions.push(this.commercialZonesService.watch(this.company.id).subscribe(commercialZones => {
        if (!commercialZones) return;

        this.dataLoaded(commercialZones);
      }));
    } else this.processSelection();
  }

  private dataLoaded(commercialZones: CommercialZone[] = []): void {
    this.commercialZones = commercialZones;
    this.processSelection();
    this.processing = false;
  }

  private getById(commercialZones: CommercialZone[] = [], commercialZoneId: number | string): CommercialZone {
    return commercialZones.find(commercialZone => commercialZone.id == commercialZoneId);
  }

  private processSelection(): void {
    if (this.commercialZoneDropdown.isOpen && this.commercialZones && this.commercialZones.length) {
      let summary = {};
      this.commercialZones.forEach((commercialZone: CommercialZone) => {
        commercialZone.reset();
      });

      this.selected.forEach(item => {
        if (item.commercial_zones && item.commercial_zones.length) {
          // Summarize commercial_zones in selection
          item.commercial_zones.forEach((zone: CommercialZone) => {
            if (summary[zone.id] == undefined) summary[zone.id] = 0;
            summary[zone.id]++;
          });
        }
      });

      for (let i = 0, keys = Object.keys(summary); i < keys.length; i++) {
        const zoneId = keys[i];
        const numberOfZones = summary[zoneId];
        if (numberOfZones === this.selected.length) {
          // All the elements of this selection have this label
          this.getById(this.commercialZones, zoneId).status = true;
        } else this.getById(this.commercialZones, zoneId).partial = true;
      }
    }
  }

  public create(commercialZone: CommercialZone = new CommercialZone()) {
    this.loadData();
    this.closeModal(() => {
      this.isUnique = true;
      this.commercialZone = instanceToInstance(commercialZone);
      this.openModal(this.createModal);
    });
    this.commercialZoneDropdown.hide();
  }

  public manage(): void {
    this.openModal(this.manageModal);
    this.commercialZoneDropdown.hide();
  }

  public delete(commercialZone: CommercialZone): void {
    this.processing = true;

    this.subscriptions.push(this.commercialZonesService.delete(this.company.id, commercialZone).subscribe({
      next: response => {
        // Once submitted reset
        this.commercialZones = this.commercialZones.filter(item => item.id !== commercialZone.id);
        if (this.commercialZones.length === 0) this.closeModal();
        this.processing = false;
      },
      error: error => {
        this.processing = false;
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  public toggle(event, zone: CommercialZone): void {
    this.dirty = true;

    const labelSet: EntityBinding = new EntityBinding({
      id: zone.id,
      value: event.target.checked
    });

    const index = this.commercialZoneChanges.findIndex(value => value.id === zone.id);

    if (index !== -1) this.commercialZoneChanges[index] = labelSet;
    else this.commercialZoneChanges.push(labelSet);
  }

  /**
   * Creates/Edit a [[CommercialZone|Commercial zone]] and applies it to
   * current selection.
   */
  public submit(): void {
    if (this.isUniqueName(this.commercialZone.name)) {
      this.processing = true;

      if (this.commercialZone.id) {
        this.subscriptions.push(this.commercialZonesService.update(this.company.id, this.commercialZone).subscribe({
          next: commercialZone => {
            this.closeModal();
            this.processing = false;
            this.submitted.emit(commercialZone);
          },
          error: error => {
            this.closeModal();
            this.processing = false;
            this.dataDogLoggerService.warn(error.message, error.error);
          }
        }));
      } else {
        this.subscriptions.push(this.commercialZonesService.create(this.company.id, this.commercialZone).subscribe((commercialZone: CommercialZone) => {
          if (commercialZone) {
            this.commercialZones.push(commercialZone);
            this.commercialZoneChanges = [];
            this.commercialZoneChanges.push(new EntityBinding({
              id: commercialZone.id,
              value: true
            }));
            this.applyChanges();
            this.submitted.emit(commercialZone);
          } else {
            this.closeModal();
            this.processing = false;
          }
        }));
      }
    }
  }

  private isUniqueName(name: string): boolean {
    const index = this.commercialZones.findIndex(l => l.name.toLowerCase() === name.toLowerCase());
    this.isUnique = index === -1;
    return this.isUnique;
  }

  /**
   * Adds and/or removes [[CommercialZone|Commercial zone]] to current
   * selection.
   */
  public applyChanges(): void {
    let payload: EntityBinding[] = [];

    // Zoneable entities must have entity property available
    if (this.selected?.length && this.selected[0].entity) this.selected.forEach(item => {
      this.commercialZoneChanges.forEach((zoneChange: EntityBinding) => {
        let currentZone: CommercialZone = this.getById(item.commercial_zones, zoneChange.id);

        // Evaluate changes only
        if ((zoneChange.value && !currentZone) || // TRUE and there is no zone
          (!zoneChange.value && currentZone)) { // FALSE and there is a zone
          let itemLabel: EntityBinding = new EntityBinding(zoneChange);

          itemLabel.entity = {
            id: item.id,
            type: item.entity
          };

          payload.push(itemLabel);
        }
      });
    });

    if (payload.length) {
      this.processing = true;

      this.subscriptions.push(this.commercialZonesService.bind(this.company.id, payload).subscribe({
        next: response => {
          // Once applied close dropdown
          this.updateModel();
          this.selected = [];
          this.closeModal();
          this.commercialZoneDropdown.hide();
          this.processing = false;
        },
        error: error => {
          // Once applied close dropdown
          this.closeModal();
          this.commercialZoneDropdown.hide();
          this.processing = false;
          this.dataDogLoggerService.warn(error.message, error.error);
        }
      }));
    } else this.closeModal();
  }

  /**
   * Updates the model with the new relationships so there is no need to reload
   * information.
   */
  private updateModel(): void {
    this.selected.forEach(item => {
      if (!item.commercial_zones) item.commercial_zones = [];

      this.commercialZoneChanges.forEach((zoneChange: EntityBinding) => {
        if (zoneChange.value) {
          // Add
          const currentZone: CommercialZone = this.getById(item.commercial_zones, zoneChange.id);
          if (!currentZone) {
            // Only if is not there yet
            item.commercial_zones.push(this.getById(this.commercialZones, zoneChange.id));
          }
        } else {
          // Remove
          item.commercial_zones = item.commercial_zones.filter((zone: CommercialZone) => {
            return zone.id !== zoneChange.id;
          });
        }
      });
    });
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  // ngModel
  private _value: any[] = [];
  public get selected(): any[] { return this._value }
  public set selected(v: any[]) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.selected = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
