import { Directive, Input, HostListener, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AgMultiselectionDirective } from './ag-multiselection.directive';

@Directive({
  selector: '[AgCheckbox]'
})
export class AgCheckboxDirective implements OnInit, OnDestroy {

  @Input('AgCheckbox') private value: any;
  @Input() set disabled(v: boolean) {
    this._disabled = v;
    this.elementRef.nativeElement.disabled = v;
  };

  private _disabled: boolean;
  private subscription: Subscription;

  constructor(
    private multiselection: AgMultiselectionDirective,
    private elementRef: ElementRef
  ) { }

  @HostListener('click', ['$event'])
  click(event) {
    if (!this._disabled) this.toggle();
  }

  ngOnInit(): void {
    // I add the value to the set
    this.multiselection.add(this.value);

    // Subscribe to toggleAll event
    this.subscription = this.multiselection.toggleAll.subscribe(response => {
      if (!this._disabled) {
        this.elementRef.nativeElement.checked = response;
        this.toggle();
      }
    });

    setTimeout(() => this.checkValue());
  }

  public toggle(): void {
    const checked = this.elementRef.nativeElement.checked;
    const myKey = this.multiselection.key;
    const exists = this.multiselection.value?.findIndex(item => item[myKey] === this.value[myKey]) !== -1;
    let change: boolean;

    if (checked) {
      if (!exists) {
        this.multiselection.value.push(this.value);
        change = true;
      }
    } else {
      if (exists) {
        this.multiselection.value = this.multiselection.value.filter(item => item[myKey] !== this.value[myKey]);
        change = true;
      }
    }

    // If neccessary notify the change
    if (change) this.multiselection.changed.emit();
  }

  private checkValue(): void {
    const myKey = this.multiselection.key;
    const exists = this.multiselection.value?.findIndex(item => item[myKey] === this.value[myKey]) !== -1;

    this.elementRef.nativeElement.checked = exists;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
