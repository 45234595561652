import { Directive, OnInit, Input } from '@angular/core';
import { Validator, AbstractControl, ValidatorFn, NG_VALIDATORS } from '@angular/forms';

export function FutureTimeValidator(date: string): ValidatorFn {
  return (control: AbstractControl): {} => {
    if (!date) return null;

    let valueTime = (control.value || '').split(":");
    let valueDate = new Date();
    let actualDate = new Date();

    let givenDate = date.split("-");
    valueDate.setDate(parseInt(givenDate[2]));
    valueDate.setMonth(parseInt(givenDate[1]) - 1);
    valueDate.setFullYear(parseInt(givenDate[0]));

    valueDate.setHours(parseInt(valueTime[0]), parseInt(valueTime[1]));

    let isValid = valueDate > actualDate;
    return isValid ? null : {
      "futureTime": false
    };
  }
}

@Directive({
  selector: '[futureTime]',
  providers: [{ provide: NG_VALIDATORS, useExisting: FutureTimeDirective, multi: true }]
})
export class FutureTimeDirective implements OnInit, Validator {

  @Input('futureTime') private date: string;

  private validatorFn;

  constructor() { }

  ngOnInit(): void {
    this.validatorFn = FutureTimeValidator(this.date);
  }

  validate(control: AbstractControl): {} {
    this.validatorFn = FutureTimeValidator(this.date);
    return this.validatorFn(control);
  }
}
