import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '.ag-container'
})
export class AgContainerDirective {

  @HostBinding('class.ag-col-expanded-only')
  private hasExpandedChildren = false;

  constructor(
  ) { }

  childExpanded(isExpanded) {
    this.hasExpandedChildren = isExpanded;
  }
}
