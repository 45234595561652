<table class="table table-detail table-condensed {{ classes }}">
  <thead>
    <tr
    [class.clickable]="collapsible"
    (click)="toggle()">
      <th>
        <div class="flex-center-distributed">
          <div class="grow truncate"
          title="{{ headerTitle || header|stripHtml }}"
          [class.text-right]="right" [innerHtml]="header"></div>
          <a *ngIf="collapsible"
          class="hidden-print"
          [innerHtml]="(expanded ? 'GLOBAL.TOGGLE_HIDE' : 'GLOBAL.TOGGLE_SHOW')|translate"></a>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td [class.text-right]="right">
        <div [collapse]="!expanded" [isAnimated]="true">
          <ng-content></ng-content>
        </div>
      </td>
    </tr>
  </tbody>
</table>