import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize'
})
/** Convert byte sizes to human readable sizes. */
export class FilesizePipe implements PipeTransform {

  /**
   * Converts a number of bytes into a human-readable string with appropriate units.
   *
   * @param {number} bytes - The number of bytes to be converted.
   * @param {Object} [options] - Optional parameters.
   * @param {number} [options.decimals=0] - The number of decimal places to display.
   * @returns {string} - The human-readable string representation of the bytes.
   */
  transform(bytes: number, options: { decimals?: number } = {}): string {
    if (typeof bytes !== 'number' || bytes < 0) {
      return 'Invalid input';
    }

    if (bytes === 0) return '0 B';

    const decimals = options.decimals ?? 0;
    const k = 1024;
    const dm = Math.max(0, decimals); // Ensure non-negative decimals
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const sizeIndex = Math.floor(Math.log(bytes) / Math.log(k));
    const result = (bytes / Math.pow(k, sizeIndex)).toFixed(dm);

    return `${result} ${sizes[sizeIndex]}`;
  }
}
