import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MapsAPILoader } from '@ng-maps/core';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';
// import * as MarkerClusterer from '@google/markerclusterer';

import { TrackerEvent } from '../../../models/tracker-event.model';
declare var google;

@Component({
  selector: 'visual-tracker',
  templateUrl: './visual-tracker.component.html',
  styleUrls: ['./visual-tracker.component.scss']
})
export class VisualTrackerComponent implements OnInit, AfterViewInit {

  @ViewChild('dataView') private readonly dataView;

  @Input() public set batch(v: any) {
    this._batch = v;
    if (v) this.loadData();
    else {
      this.selectedNode = undefined;
      this.nodes = [];
    }
  };

  public get batch(): any {
    return this._batch;
  }

  public branches: number;
  /** The language currently used. */
  public currentLang: string;
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean = true;
  public map;
  public nodes: Array<any> = [];
  public nodesMap = {};
  public selectedNode;
  public settings = {
    padding: 12,
    row_height: 45,
    map: {
      zoom: 9,
      strokeColor: '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 3
    },
    paths: {
      bezier: .66
    },
    points: {
      margin: 9
    }
  };

  private UUID: string;
  private _batch: any;
  private marker;
  private marker_from;
  private route;

  // Demo data
  private data = {
    supplier: { "id": 15, "is_new": false, "name": "Insumos Agro S.A.", "logo": "https:\/\/agreemarket-fas-dev.s3.us-east-2.amazonaws.com\/fas\/companies\/5b510b3be929f\/642617_2018-07-19.png", "fiscal_id": { "type": "cuit", "value": "20123456780" }, "activity": { "id": 3, "name": "Exportador" }, "verification_date": "2018-07-26 21:48:37", "approved": true, "address": { "country": { "id": 10, "iso": "ar", "name": "Argentina" } } },
    farmer: { "id": 30, "is_new": false, "name": "Los Ombúes", "logo": "https:\/\/agreemarket-fas-dev.s3.us-east-2.amazonaws.com\/fas\/companies\/5ba7e7ccafa9c\/932832_2018-09-23.jpeg", "fiscal_id": { "type": "cuit", "value": "20123456780" }, "activity": { "id": 1, "name": "Productor" }, "verification_date": "2018-09-24 12:08:55", "approved": true, "address": { "country": { "id": 10, "iso": "ar", "name": "Argentina" } } },
    exporter: { "id": 31, "is_new": false, "name": "South Grains Corp.", "logo": "https:\/\/agreemarket-fas-dev.s3.us-east-2.amazonaws.com\/fas\/companies\/5ba7e897984ca\/372420_2018-09-23.png", "fiscal_id": { "type": "cuit", "value": "20123456780" }, "activity": { "id": 3, "name": "Exportador" }, "verification_date": "2018-09-24 12:09:03", "approved": true, "address": { "country": { "id": 10, "iso": "ar", "name": "Argentina" } } },
    transporter: { "id": 37, "is_new": false, "name": "Del Valle S.A.", "logo": "https:\/\/agreemarket-fas-dev.s3.us-east-2.amazonaws.com\/fas\/default-thumbs\/prod-05.png", "fiscal_id": { "type": "cuit", "value": "20123456780" }, "activity": { "id": 1, "name": "Transportista" }, "verification_date": "2018-10-22 19:44:39", "approved": true, "address": { "country": { "id": 10, "iso": "ar", "name": "Argentina" } } },
    grain_elevator: { "id": 38, "is_new": false, "name": "Silos Norte S.A.", "logo": "https:\/\/agreemarket-fas-dev.s3.us-east-2.amazonaws.com\/fas\/default-thumbs\/acop-05.png", "fiscal_id": { "type": "cuit", "value": "20123456780" }, "activity": { "id": 2, "name": "Acopiador / Consignatario" }, "verification_date": "2018-11-08 18:28:49", "approved": true, "address": { "country": { "id": 10, "iso": "ar", "name": "Argentina" } } },
    laboratory: { "id": 23, "is_new": false, "name": "Zoom Agri", "logo": "https:\/\/agreemarket-fas-dev.s3.us-east-2.amazonaws.com\/fas\/companies\/5b7c6cd607734\/75877_2018-08-21.jpeg", "fiscal_id": { "type": "cuit", "value": "20123456780" }, "activity": { "id": 1, "name": "Control de calidad" }, "verification_date": "2018-08-21 19:50:20", "approved": true, "address": { "country": { "id": 10, "iso": "ar", "name": "Argentina" } } },
    port: { "id": 61, "is_new": false, "name": "Gestión de Puerto Quequén", "logo": "https:\/\/agreemarket-fas-dev.s3.us-east-2.amazonaws.com\/fas\/default-thumbs\/prod-05.png", "fiscal_id": { "type": "cuit", "value": "20123456780" }, "activity": { "id": 7, "name": "Puerto" }, "verification_date": "2019-01-30 20:49:04", "approved": true, "address": { "country": { "id": 10, "iso": "ar", "name": "Argentina" } } },
    shipper: { "id": 39, "is_new": false, "name": "Antares International S.A.", "logo": "https:\/\/agreemarket-fas-dev.s3.us-east-2.amazonaws.com\/fas\/default-thumbs\/prod-02.png", "fiscal_id": { "type": "cuit", "value": "20123456780" }, "activity": { "id": 1, "name": "Naviera" }, "verification_date": "2018-11-08 18:28:44", "approved": true, "address": { "country": { "id": 10, "iso": "ar", "name": "Argentina" } } }
  };

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private translateService: TranslateService
  ) {
    this.UUID = 'map-' + uuidv4();
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;
    // this.loadData();
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  private initMap(): void {
    this.mapsAPILoader.load().then(() => {
      this.map = new google.maps.Map(document.getElementById('map'), {
        // center: {lat: 0, lng: 0},
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        clickableIcons: false,
        zoom: this.settings.map.zoom,
        maxZoom: 16,
        minZoom: 3,
        mapId: this.UUID
      });

      this.marker = new google.maps.marker.AdvancedMarkerElement({
        map: this.map
      });

      this.marker_from = new google.maps.marker.AdvancedMarkerElement({
        map: this.map,
        opacity: .3
      });

      this.route = new google.maps.Polyline({
        map: this.map,
        geodesic: true,
        strokeColor: this.settings.map.strokeColor,
        strokeOpacity: this.settings.map.strokeOpacity,
        strokeWeight: this.settings.map.strokeWeight
      });
    });
  }

  private loadData(): void {
    this.loading = true;

    // Emulates loading
    setTimeout(() => {
      let genNode = function (id: number, date, event: string, parents?: number[], locations?, media?, attachments?, table?, companies?: any[], description?: string, batch_in?: string, batch_out?: string) {
        let response = new TrackerEvent();

        response.id = id;
        response.event = event;
        response.date = new Date(date);
        if (batch_in) response.batch = {
          in: batch_in,
          out: (batch_out || batch_in)
        };
        if (description) response.description = description;

        if (companies && companies.length) response.companies = companies;
        if (parents && parents.length) response.parents = parents;
        if (locations && locations.length) response.locations = locations;
        if (media && media.length) response.media = media;
        if (attachments && attachments.length) response.attachments = attachments;
        if (table && table.length) response.table = table;

        return response;
      };

      this.dataLoaded([
        genNode(0, '06/20/19', 'TRACKER.EVENTS.SUPPLIES_SALE', null, [{ lat: -37.315756, lng: -59.1085404 }], null, [
          { name: 'recibo_JUN.pdf' },
          { name: 'factura_23322-32233223.pdf' }
        ], null, [this.batch.company, this.data.supplier], null, 'N122001'),
        genNode(14, '06/21/19 12:56', 'TRACKER.EVENTS.TRADE', null, null, null, [
          { name: 'contrato_junio19.pdf' }
        ], null, [this.batch.company, this.data.exporter], null, null),
        genNode(2, '06/22/19', 'TRACKER.EVENTS.SUPPLIES_SALE', null, [{ lat: -37.315756, lng: -59.1085404 }], null, null, null, [this.batch.company, this.data.supplier], null, 'N122031'),
        genNode(1, '06/25/19', 'TRACKER.EVENTS.SOWING', [0], [{ lat: -37.415464, lng: -59.1699047 }], null, null, null, [this.batch.company], null, this.batch.name),
        genNode(3, '07/04/19', 'TRACKER.EVENTS.APPLICATION', [1, 2], [{ lat: -37.415464, lng: -59.1699047 }], null, null, null, [this.batch.company], 'Herbicida XXX-FFF', this.batch.name),
        genNode(4, '07/10/19', 'TRACKER.EVENTS.IRRIGATION', [3], [{ lat: -37.415464, lng: -59.1699047 }], null, null, null, [this.batch.company], null, this.batch.name),
        genNode(5, '07/12/19', 'TRACKER.EVENTS.WEED_CONTROL', [4], [{ lat: -37.415464, lng: -59.1699047 }], null, null, null, [this.batch.company], 'Weed Management Procedure OEUP-1000-PRO-ENV-005', this.batch.name),
        genNode(6, '07/20/19', 'TRACKER.EVENTS.APPLICATION', [5, 2], [{ lat: -37.415464, lng: -59.1699047 }], null, null, null, [this.batch.company], null, this.batch.name),
        genNode(7, '11/15/19 08:30', 'TRACKER.EVENTS.HARVEST', [6], [{ lat: -37.415464, lng: -59.1699047 }], [
          { "id": "5e2b18cb2d774", "uri": "https://www.agrolatam.com/files/image/27/27633/5bf9500768925_907_510!.jpg" },
          { "id": "5e2b18cb2d774", "uri": "https://i.ytimg.com/vi/Duycghad0DM/maxresdefault.jpg" }
        ], null, null, [this.batch.company], null, this.batch.name),
        genNode(8, '11/15/19 10:15', 'TRACKER.EVENTS.LOAD', [7], [{ lat: -37.415464, lng: -59.1699047 }], null, null, null, [this.batch.company, this.data.transporter], null, this.batch.name, 'CP546727'),
        genNode(9, '11/15/19 11:15', 'TRACKER.EVENTS.TRANSPORTATION', [8], [
          { lat: -37.415464, lng: -59.1699047 },
          { lat: -37.4222585, lng: -59.1838573 },
          { lat: -37.3871347, lng: -59.193746 },
          { lat: -37.3689678, lng: -59.212381 },
          { lat: -37.3463959, lng: -59.1977918 },
          { lat: -37.297883, lng: -59.1841949 },
          { lat: -37.2903496, lng: -59.2008927 }
        ], null, null, null, [this.data.transporter], null, 'CP546727'),
        genNode(10, '11/15/19 17:15', 'TRACKER.EVENTS.UNLOAD', [9], [{ lat: -37.2903496, lng: -59.2008927 }], [
          { "uri": "https://coopagric-eltrebol.com.ar/wp-content/uploads/2018/06/35433322_2120461798239409_622377045074116608_n.jpg" }
        ], null, null, [this.data.transporter, this.data.grain_elevator], null, 'CP546727', 'SILO_4'),
        genNode(11, '11/15/19 12:08', 'TRACKER.EVENTS.LOAD', [7], [{ lat: -37.415464, lng: -59.1699047 }], null, null, null, [this.batch.company, this.data.transporter], null, this.batch.name, 'CP546728'),
        genNode(12, '11/15/19 12:56', 'TRACKER.EVENTS.TRANSPORTATION', [11], [
          { lat: -37.415464, lng: -59.1699047 },
          { lat: -37.4222585, lng: -59.1838573 },
          { lat: -37.3871347, lng: -59.193746 },
          { lat: -37.3689678, lng: -59.212381 },
          { lat: -37.3463959, lng: -59.1977918 },
          { lat: -37.297883, lng: -59.1841949 },
          { lat: -37.2903496, lng: -59.2008927 }
        ], null, null, null, [this.data.transporter], null, 'CP546728'),
        genNode(13, '11/15/19 19:56', 'TRACKER.EVENTS.UNLOAD', [12], [{ lat: -37.2903496, lng: -59.2008927 }], null, null, null, [this.data.transporter, this.data.grain_elevator], null, 'CP546728', 'SILO_4'),
        genNode(20, '11/16/19 21:17', 'TRACKER.EVENTS.QUALITY_CONTROL', [13], [{ lat: -37.2903496, lng: -59.2008927 }], null, [
          { name: 'resultados_muestra_1234.pdf' }
        ], null, [this.data.laboratory], 'One set of samples was sent to the Laboratory where it was well mixed and reduced to constitute one average sample of the total shipped quantity for determination of the quality.', 'CP546728', 'QTEST_66566'),
        genNode(15, '11/16/19 09:28', 'TRACKER.EVENTS.STORAGE', [10, 13], [{ lat: -37.2903496, lng: -59.2008927 }], null, null, null, [this.data.grain_elevator], 'Stowed into holds number: 2, 3 and 4', 'SILO_4'),
        genNode(16, '12/02/19 09:28', 'TRACKER.EVENTS.LOAD', [15], [{ lat: -37.2903496, lng: -59.2008927 }], null, null, null, [this.data.grain_elevator, this.data.transporter], null, 'SILO_4', 'CP546921'),
        genNode(17, '12/02/19 10:28', 'TRACKER.EVENTS.TRANSPORTATION', [16], [
          { lat: -37.2903496, lng: -59.2008927 },
          { lat: -37.2898559, lng: -59.1501706 },
          { lat: -37.3605135, lng: -59.0162105 },
          { lat: -37.4423606, lng: -58.9463497 },
          { lat: -37.5938027, lng: -58.7517167 },
          { lat: -37.8060634, lng: -58.6976626 },
          { lat: -37.9729485, lng: -58.6878998 },
          { lat: -38.1754176, lng: -58.7652571 },
          { lat: -38.1980267, lng: -58.7383354 },
          { lat: -38.4357503, lng: -58.6604998 },
          { lat: -38.5730241, lng: -58.7115444 }
        ], null, null, null, [this.data.transporter], null, 'CP546921'),
        genNode(18, '12/02/19 18:28', 'TRACKER.EVENTS.UNLOAD', [17, 14], [{ lat: -38.5730241, lng: -58.7115444 }], [
          { "uri": "https://bichosdecampo.com/wp-content/uploads/2017/12/descarga-de-granos.jpg" },
          { "uri": "https://www.elciudadanoweb.com/wp-content/uploads/2017/05/camion.jpg" }
        ], null, [
          {
            "C.PORTE": 581260421,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 28820,
            "Kg.Tara Proc.": 16160,
            "Kg.Bruto Proc.": 44980,
            "Kg.Bruto Desc.": 45080,
            "Kg.Tara Desc.": 16160,
            "Kg.Neto Desc.": 28920,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 29,
            "Kg.Netos": 28891,
            "TITULAR CARTA PORTE": "SCORZIELLO Y GALLELA S.A.",
            "CUIT": 33561059409,
            "Nº PLANTA ONCCA": 20036,
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "CASALS GUSTAVO",
            "CUIT__4": 20129912422,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "EMO129",
            "Pat. Acoplado": "AA002RX",
            "CUIT Transp.": 20222824177,
            "Trasportista": "VALLEJOS JOSE LUIS",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20222824177,
            "Chofer": "VALLEJO JOSE LUIS",
            "Nro CAC": 89499337443309,
            "Vencim.CP": "2/2/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 90,
            "Tarifa": 575.72,
            "CTG": 19959077,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581260421.TIF"
          },
          {
            "C.PORTE": 581829676,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29060,
            "Kg.Tara Proc.": 15840,
            "Kg.Bruto Proc.": 44900,
            "Kg.Bruto Desc.": 44840,
            "Kg.Tara Desc.": 15980,
            "Kg.Neto Desc.": 28860,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 29,
            "Kg.Netos": 28831,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "FSR526",
            "Pat. Acoplado": "AUD912",
            "CUIT Transp.": 20168061995,
            "Trasportista": "BERRUET JULIO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20168061995,
            "Chofer": "BERRUET JULIO",
            "Nro CAC": 90011408793313,
            "Vencim.CP": "3/3/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 91228982,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581829676.TIF"
          },
          {
            "C.PORTE": 581829677,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 30260,
            "Kg.Tara Proc.": 13220,
            "Kg.Bruto Proc.": 43480,
            "Kg.Bruto Desc.": 43500,
            "Kg.Tara Desc.": 13240,
            "Kg.Neto Desc.": 30260,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 30,
            "Kg.Netos": 30230,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "AGE348",
            "Pat. Acoplado": "VCC101",
            "CUIT Transp.": 20163968267,
            "Trasportista": "NUCCIARONE DOMINGO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20148321737,
            "Chofer": "MACHADO VICTOR",
            "Nro CAC": 90011408793313,
            "Vencim.CP": "3/3/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 51127551,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581829677.TIF"
          },
          {
            "C.PORTE": 581829678,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 28840,
            "Kg.Tara Proc.": 14520,
            "Kg.Bruto Proc.": 43360,
            "Kg.Bruto Desc.": 43680,
            "Kg.Tara Desc.": 14520,
            "Kg.Neto Desc.": 29160,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 29,
            "Kg.Netos": 29131,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "BPJ975",
            "Pat. Acoplado": "WWW211",
            "CUIT Transp.": 20087034748,
            "Trasportista": "DE DIOS RAUL",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20087034748,
            "Chofer": "DE DIOS RAUL",
            "Nro CAC": 90011408793313,
            "Vencim.CP": "3/3/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 51140246,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581829678.TIF"
          },
          {
            "C.PORTE": 581829679,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 43660,
            "Kg.Tara Proc.": 1340,
            "Kg.Bruto Proc.": 45000,
            "Kg.Bruto Desc.": 45160,
            "Kg.Tara Desc.": 13520,
            "Kg.Neto Desc.": 31640,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 32,
            "Kg.Netos": 31608,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "AC154BA",
            "Pat. Acoplado": "AA253MG",
            "CUIT Transp.": 20383204500,
            "Trasportista": "GARCIA PEDRO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20383204500,
            "Chofer": "GARCIA PEDRO",
            "Nro CAC": 90011408793313,
            "Vencim.CP": "2/25/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.625,
            "CTG": 61242218,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581829679.TIF"
          },
          {
            "C.PORTE": 581829680,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29180,
            "Kg.Tara Proc.": 13760,
            "Kg.Bruto Proc.": 42940,
            "Kg.Bruto Desc.": 43040,
            "Kg.Tara Desc.": 13840,
            "Kg.Neto Desc.": 29200,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 29,
            "Kg.Netos": 29171,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "WIL420",
            "Pat. Acoplado": "MID782",
            "CUIT Transp.": 30714326305,
            "Trasportista": "LOS AMIGOS SH",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20293462713,
            "Chofer": "AGUILAR SALVADOR",
            "Nro CAC": 90011408793313,
            "Vencim.CP": "3/3/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 51594985,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581829680.TIF"
          },
          {
            "C.PORTE": 581829681,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 30440,
            "Kg.Tara Proc.": 14260,
            "Kg.Bruto Proc.": 44700,
            "Kg.Bruto Desc.": 44560,
            "Kg.Tara Desc.": 14160,
            "Kg.Neto Desc.": 30400,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 30,
            "Kg.Netos": 30370,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "HVL658",
            "Pat. Acoplado": "WIF647",
            "CUIT Transp.": 20273499777,
            "Trasportista": "CRESCITELLI WALTER",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20273499777,
            "Chofer": "CRESCITELLI WALTER",
            "Nro CAC": 90011408793313,
            "Vencim.CP": "3/3/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 51705862,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581829681.TIF"
          },
          {
            "C.PORTE": 581829682,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29100,
            "Kg.Tara Proc.": 15700,
            "Kg.Bruto Proc.": 44800,
            "Kg.Bruto Desc.": 44960,
            "Kg.Tara Desc.": 15780,
            "Kg.Neto Desc.": 29180,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 29,
            "Kg.Netos": 29151,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "RNO874",
            "Pat. Acoplado": "FBG742",
            "CUIT Transp.": 23161518549,
            "Trasportista": "GIMENEZ JULIO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 23161518549,
            "Chofer": "GIMENEZ JULIO",
            "Nro CAC": 90011408793313,
            "Vencim.CP": "3/3/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 41475825,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581829682.TIF"
          },
          {
            "C.PORTE": 581829683,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 28780,
            "Kg.Tara Proc.": 15940,
            "Kg.Bruto Proc.": 44720,
            "Kg.Bruto Desc.": 44820,
            "Kg.Tara Desc.": 15960,
            "Kg.Neto Desc.": 28860,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 29,
            "Kg.Netos": 28831,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "EDF946",
            "Pat. Acoplado": "FKJ396",
            "CUIT Transp.": 20238480923,
            "Trasportista": "BRIS LUIS",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20238480923,
            "Chofer": "BRIS ALFREDO",
            "Nro CAC": 90011408793313,
            "Vencim.CP": "3/3/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 51088775,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581829683.TIF"
          },
          {
            "C.PORTE": 581829684,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29900,
            "Kg.Tara Proc.": 15000,
            "Kg.Bruto Proc.": 44900,
            "Kg.Bruto Desc.": 44980,
            "Kg.Tara Desc.": 15040,
            "Kg.Neto Desc.": 29940,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 30,
            "Kg.Netos": 29910,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "TKE364",
            "Pat. Acoplado": "SNW968",
            "CUIT Transp.": 20238480923,
            "Trasportista": "BRIS LUIS",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20265513159,
            "Chofer": "BRIS NICOLAS",
            "Nro CAC": 90011408793313,
            "Vencim.CP": "3/3/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 91784246,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581829684.TIF"
          },
          {
            "C.PORTE": 581829685,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 31080,
            "Kg.Tara Proc.": 13660,
            "Kg.Bruto Proc.": 44740,
            "Kg.Bruto Desc.": 44820,
            "Kg.Tara Desc.": 13740,
            "Kg.Neto Desc.": 31080,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 31,
            "Kg.Netos": 31049,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "HLH350",
            "Pat. Acoplado": "FKJ396",
            "CUIT Transp.": 20238480923,
            "Trasportista": "BRIS LUIS",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20373985873,
            "Chofer": "ZARRIELLO EXEQUIEL",
            "Nro CAC": 90011408793313,
            "Vencim.CP": "3/3/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 91537150,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581829685.TIF"
          },
          {
            "C.PORTE": 581829686,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 30440,
            "Kg.Tara Proc.": 14320,
            "Kg.Bruto Proc.": 44760,
            "Kg.Bruto Desc.": 44860,
            "Kg.Tara Desc.": 14480,
            "Kg.Neto Desc.": 30380,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 30,
            "Kg.Netos": 30350,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "AYR464",
            "Pat. Acoplado": "AA933OA",
            "CUIT Transp.": 20128450824,
            "Trasportista": "JUAREZ HECTOR",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20128450824,
            "Chofer": "JUAREZ HECTOR",
            "Nro CAC": 90011408793313,
            "Vencim.CP": "3/3/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 105,
            "Tarifa": 634.64,
            "CTG": 91272693,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581829686.TIF"
          },
          {
            "C.PORTE": 581938547,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 27880,
            "Kg.Tara Proc.": 17020,
            "Kg.Bruto Proc.": 44900,
            "Kg.Bruto Desc.": 45000,
            "Kg.Tara Desc.": 17060,
            "Kg.Neto Desc.": 27940,
            "Kg.Dif.": "",
            "H.%": 12.8,
            "Mer.Kg.": 310,
            "Volatil": 28,
            "Kg.Netos": 27602,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "DYW397",
            "Pat. Acoplado": "DRO460",
            "CUIT Transp.": 27207370598,
            "Trasportista": "DARUICH VIVIANA VIRGINIA",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CHDAD",
            "CUIT chofer": 20135842398,
            "Chofer": "GARRIDO LUIS",
            "Nro CAC": 90021687604195,
            "Vencim.CP": "3/9/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 11677358,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581938547.TIF"
          },
          {
            "C.PORTE": 581938548,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29660,
            "Kg.Tara Proc.": 15320,
            "Kg.Bruto Proc.": 44980,
            "Kg.Bruto Desc.": 45200,
            "Kg.Tara Desc.": 15500,
            "Kg.Neto Desc.": 29700,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 30,
            "Kg.Netos": 29670,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "TNT847",
            "Pat. Acoplado": "JRR652",
            "CUIT Transp.": 20227717921,
            "Trasportista": "LUNA DANIEL",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20227717921,
            "Chofer": "LUNA DANIEL",
            "Nro CAC": 90021687604195,
            "Vencim.CP": "3/9/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.95,
            "CTG": 51359075,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581938548.TIF"
          },
          {
            "C.PORTE": 581938549,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 28280,
            "Kg.Tara Proc.": 16160,
            "Kg.Bruto Proc.": 44440,
            "Kg.Bruto Desc.": 44560,
            "Kg.Tara Desc.": 16280,
            "Kg.Neto Desc.": 28280,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 28,
            "Kg.Netos": 28252,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "GJY945",
            "Pat. Acoplado": "LXQ259",
            "CUIT Transp.": 27205225868,
            "Trasportista": "ALTAMIRANO MARIA C",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20297694988,
            "Chofer": "MARIONE FERNANDO",
            "Nro CAC": 90021687604195,
            "Vencim.CP": "3/9/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 51792846,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581938549.TIF"
          },
          {
            "C.PORTE": 581938550,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29300,
            "Kg.Tara Proc.": 15520,
            "Kg.Bruto Proc.": 44820,
            "Kg.Bruto Desc.": 44860,
            "Kg.Tara Desc.": 15620,
            "Kg.Neto Desc.": 29240,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 29,
            "Kg.Netos": 29211,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "FED332",
            "Pat. Acoplado": "JEF972",
            "CUIT Transp.": 20231222627,
            "Trasportista": "FRANCHINO CESAR GABRIEL",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20339120413,
            "Chofer": "TARTAGLIO PABLO",
            "Nro CAC": 90021687604195,
            "Vencim.CP": "3/9/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 31955084,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581938550.TIF"
          },
          {
            "C.PORTE": 581938551,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 30400,
            "Kg.Tara Proc.": 14240,
            "Kg.Bruto Proc.": 44640,
            "Kg.Bruto Desc.": 44700,
            "Kg.Tara Desc.": 14280,
            "Kg.Neto Desc.": 30420,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 30,
            "Kg.Netos": 30390,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "MRE887",
            "Pat. Acoplado": "VQZ668",
            "CUIT Transp.": 30710618883,
            "Trasportista": "TRANSPORTE ARDANS SRL",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20217934282,
            "Chofer": "KUCZORA DIEGO",
            "Nro CAC": 90021687604195,
            "Vencim.CP": "2/25/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 31421746,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581938551.TIF"
          },
          {
            "C.PORTE": 581938552,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 28480,
            "Kg.Tara Proc.": 16340,
            "Kg.Bruto Proc.": 44820,
            "Kg.Bruto Desc.": 44940,
            "Kg.Tara Desc.": 16400,
            "Kg.Neto Desc.": 28540,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 29,
            "Kg.Netos": 28511,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "MOM546",
            "Pat. Acoplado": "OSW535",
            "CUIT Transp.": 27205225868,
            "Trasportista": "ALTAMIRANO MARIA C",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 23163962209,
            "Chofer": "FUNES HECTOR",
            "Nro CAC": 90021687604195,
            "Vencim.CP": "3/9/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.95,
            "CTG": 31507682,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581938552.TIF"
          },
          {
            "C.PORTE": 581938553,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 31140,
            "Kg.Tara Proc.": 13800,
            "Kg.Bruto Proc.": 44940,
            "Kg.Bruto Desc.": 45020,
            "Kg.Tara Desc.": 13680,
            "Kg.Neto Desc.": 31340,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 31,
            "Kg.Netos": 31309,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "AB015YM",
            "Pat. Acoplado": "AA253MG",
            "CUIT Transp.": 20241600263,
            "Trasportista": "GARCIA JUAN ANTONIO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20241600263,
            "Chofer": "GARCIA JUAN A",
            "Nro CAC": 90011408793313,
            "Vencim.CP": "2/25/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 41465016,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581938553.TIF"
          },
          {
            "C.PORTE": 581938555,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 31800,
            "Kg.Tara Proc.": 13100,
            "Kg.Bruto Proc.": 44900,
            "Kg.Bruto Desc.": 44920,
            "Kg.Tara Desc.": 13100,
            "Kg.Neto Desc.": 31820,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 32,
            "Kg.Netos": 31788,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "SZZ220",
            "Pat. Acoplado": "WXK772",
            "CUIT Transp.": 20163968267,
            "Trasportista": "NUCCIARONE DOMINGO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20163968267,
            "Chofer": "NUCCIANORE DOMINGO",
            "Nro CAC": 90021687604195,
            "Vencim.CP": "3/9/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 61751823,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581938555.TIF"
          },
          {
            "C.PORTE": 581938556,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 30900,
            "Kg.Tara Proc.": 14100,
            "Kg.Bruto Proc.": 45000,
            "Kg.Bruto Desc.": 45020,
            "Kg.Tara Desc.": 14040,
            "Kg.Neto Desc.": 30980,
            "Kg.Dif.": "",
            "H.%": 12.4,
            "Mer.Kg.": 0,
            "Volatil": 31,
            "Kg.Netos": 30949,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "LXN980",
            "Pat. Acoplado": "KNX438",
            "CUIT Transp.": 20163968267,
            "Trasportista": "NUCCIARONE DOMINGO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20342537287,
            "Chofer": "NUCCIARONE DANIEL",
            "Nro CAC": 90021687604195,
            "Vencim.CP": "3/9/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 41108738,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581938556.TIF"
          },
          {
            "C.PORTE": 581938557,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 30220,
            "Kg.Tara Proc.": 14760,
            "Kg.Bruto Proc.": 44980,
            "Kg.Bruto Desc.": 45100,
            "Kg.Tara Desc.": 14920,
            "Kg.Neto Desc.": 30180,
            "Kg.Dif.": "",
            "H.%": 12.5,
            "Mer.Kg.": 0,
            "Volatil": 30,
            "Kg.Netos": 30150,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "HFT800",
            "Pat. Acoplado": "BGR503",
            "CUIT Transp.": 23345902244,
            "Trasportista": "MONJE MARIA JOSE",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20145517681,
            "Chofer": "IBARGUENGOITIA ENRIQUE",
            "Nro CAC": 90021687604195,
            "Vencim.CP": "3/9/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 61653990,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581938557.TIF"
          },
          {
            "C.PORTE": 581938558,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29520,
            "Kg.Tara Proc.": 13920,
            "Kg.Bruto Proc.": 43440,
            "Kg.Bruto Desc.": 43620,
            "Kg.Tara Desc.": 13980,
            "Kg.Neto Desc.": 29640,
            "Kg.Dif.": "",
            "H.%": 12.8,
            "Mer.Kg.": 329,
            "Volatil": 30,
            "Kg.Netos": 29281,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": 21666,
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "BYN852",
            "Pat. Acoplado": "TSA286",
            "CUIT Transp.": 23303177809,
            "Trasportista": "DUARTE JUAN",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CHDAD",
            "CUIT chofer": 23303177809,
            "Chofer": "DUARTE JUAN",
            "Nro CAC": 90021687604195,
            "Vencim.CP": "1/10/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 11923448,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581938558.TIF"
          },
          {
            "C.PORTE": 581938559,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29680,
            "Kg.Tara Proc.": 15200,
            "Kg.Bruto Proc.": 44880,
            "Kg.Bruto Desc.": 45000,
            "Kg.Tara Desc.": 15280,
            "Kg.Neto Desc.": 29720,
            "Kg.Dif.": "",
            "H.%": 12.8,
            "Mer.Kg.": 330,
            "Volatil": 30,
            "Kg.Netos": 29360,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "EGI986",
            "Pat. Acoplado": "RAZ257",
            "CUIT Transp.": 20307812275,
            "Trasportista": "RODRIGUEZ DAMIAN",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CHDAD",
            "CUIT chofer": 20307812275,
            "Chofer": "RODRIGUEZ DAMIAN",
            "Nro CAC": 90021687604195,
            "Vencim.CP": "3/9/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 61648125,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581938559.TIF"
          },
          {
            "C.PORTE": 581938560,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29520,
            "Kg.Tara Proc.": 14180,
            "Kg.Bruto Proc.": 43700,
            "Kg.Bruto Desc.": 43800,
            "Kg.Tara Desc.": 14260,
            "Kg.Neto Desc.": 29540,
            "Kg.Dif.": "",
            "H.%": 12.8,
            "Mer.Kg.": 328,
            "Volatil": 30,
            "Kg.Netos": 29182,
            "TITULAR CARTA PORTE": "RURAL CERES SA",
            "CUIT": 30567668661,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "RIS382",
            "Pat. Acoplado": "SYH206",
            "CUIT Transp.": 20253669250,
            "Trasportista": "CHERENISCO MARCELO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BALCARCE",
            "Nº LOCALIDAD ONCCA PROCED.": 1056,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7620,
            "Cal.-Observaciones": "CHDAD",
            "CUIT chofer": 20253669250,
            "Chofer": "CHERENISCO MARCELO",
            "Nro CAC": 90021687604195,
            "Vencim.CP": "3/9/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 95,
            "Tarifa": 595.65,
            "CTG": 71753123,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581938560.TIF"
          },
          {
            "C.PORTE": 581206219,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 31320,
            "Kg.Tara Proc.": 13540,
            "Kg.Bruto Proc.": 44860,
            "Kg.Bruto Desc.": 44800,
            "Kg.Tara Desc.": 13520,
            "Kg.Neto Desc.": 31280,
            "Kg.Dif.": "",
            "H.%": 12.9,
            "Mer.Kg.": 382,
            "Volatil": 31,
            "Kg.Netos": 30867,
            "TITULAR CARTA PORTE": "COOP. AGROP. E IND. DE OLAVARRIA",
            "CUIT": 30534002226,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "ENTREGAS DEL SUR S.A.",
            "CUIT__4": 30666351254,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "AC748MX",
            "Pat. Acoplado": "LPF173",
            "CUIT Transp.": 20260501063,
            "Trasportista": "ROSSI JULIO DARIO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "OLAVARRIA",
            "Nº LOCALIDAD ONCCA PROCED.": 10361,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CHDAD",
            "CUIT chofer": 20260501063,
            "Chofer": "ROSSI JULIO DARIO",
            "Nro CAC": 89486825529297,
            "Vencim.CP": "1/31/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 240,
            "Tarifa": 1021,
            "CTG": 96606955,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581206219.TIF"
          },
          {
            "C.PORTE": 581939785,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 30640,
            "Kg.Tara Proc.": 13260,
            "Kg.Bruto Proc.": 43900,
            "Kg.Bruto Desc.": 43780,
            "Kg.Tara Desc.": 13280,
            "Kg.Neto Desc.": 30500,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 30,
            "Kg.Netos": 30470,
            "TITULAR CARTA PORTE": "AGRONOMIA EL CERRO SA",
            "CUIT": 33709913099,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "ARAYA JUAN A.",
            "CUIT__4": 20131614439,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "VIU112",
            "Pat. Acoplado": "XLM778",
            "CUIT Transp.": 20271059702,
            "Trasportista": "FERNANDEZ LEANDRO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "LOBERIA",
            "Nº LOCALIDAD ONCCA PROCED.": 8840,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7635,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20271059702,
            "Chofer": "FERNANDEZ LEANDRO",
            "Nro CAC": 90029196500184,
            "Vencim.CP": "1/31/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 50,
            "Tarifa": 424,
            "CTG": 69145881,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581939785.TIF"
          },
          {
            "C.PORTE": 581939786,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 30440,
            "Kg.Tara Proc.": 13880,
            "Kg.Bruto Proc.": 44320,
            "Kg.Bruto Desc.": 44220,
            "Kg.Tara Desc.": 13900,
            "Kg.Neto Desc.": 30320,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 30,
            "Kg.Netos": 30290,
            "TITULAR CARTA PORTE": "AGRONOMIA EL CERRO SA",
            "CUIT": 33709913099,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "ARAYA JUAN A.",
            "CUIT__4": 20131614439,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "CMG962",
            "Pat. Acoplado": "TYG934",
            "CUIT Transp.": 20276246071,
            "Trasportista": "TORRES MARTIN",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "LOBERIA",
            "Nº LOCALIDAD ONCCA PROCED.": 8840,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7635,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20276246071,
            "Chofer": "TORRES MARTIN",
            "Nro CAC": 90029196500184,
            "Vencim.CP": "3/9/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 50,
            "Tarifa": 424.82,
            "CTG": 39402008,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581939786.TIF"
          },
          {
            "C.PORTE": 581939787,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29560,
            "Kg.Tara Proc.": 15100,
            "Kg.Bruto Proc.": 44660,
            "Kg.Bruto Desc.": 44580,
            "Kg.Tara Desc.": 15080,
            "Kg.Neto Desc.": 29500,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 30,
            "Kg.Netos": 29470,
            "TITULAR CARTA PORTE": "AGRONOMIA EL CERRO SA",
            "CUIT": 33709913099,
            "Nº PLANTA ONCCA": 23244,
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "ARAYA JUAN A.",
            "CUIT__4": 20131614439,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "FHR229",
            "Pat. Acoplado": "RVH337",
            "CUIT Transp.": 33709913099,
            "Trasportista": "AGRONOMIA EL CERRO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "LOBERIA",
            "Nº LOCALIDAD ONCCA PROCED.": 8840,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7635,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20183562097,
            "Chofer": "MARQUEZ PEDRO",
            "Nro CAC": 90029196500184,
            "Vencim.CP": "3/9/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 50,
            "Tarifa": 424.82,
            "CTG": 19338003,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581939787.TIF"
          },
          {
            "C.PORTE": 581939788,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29820,
            "Kg.Tara Proc.": 11520,
            "Kg.Bruto Proc.": 41340,
            "Kg.Bruto Desc.": 41340,
            "Kg.Tara Desc.": 11660,
            "Kg.Neto Desc.": 29680,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 30,
            "Kg.Netos": 29650,
            "TITULAR CARTA PORTE": "AGRONOMIA EL CERRO SA",
            "CUIT": 33709913099,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "ARAYA JUAN A.",
            "CUIT__4": 20131614439,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "WVW 808",
            "Pat. Acoplado": "WYL126",
            "CUIT Transp.": 20214484294,
            "Trasportista": "EULOGIO JUAN",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "LOBERIA",
            "Nº LOCALIDAD ONCCA PROCED.": 8840,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7635,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20214484294,
            "Chofer": "EULOGIO JUAN",
            "Nro CAC": 90029196500184,
            "Vencim.CP": "3/9/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 50,
            "Tarifa": 424.82,
            "CTG": 69884988,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581939788.TIF"
          },
          {
            "C.PORTE": 581404204,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 30100,
            "Kg.Tara Proc.": 14180,
            "Kg.Bruto Proc.": 44280,
            "Kg.Bruto Desc.": 44360,
            "Kg.Tara Desc.": 14460,
            "Kg.Neto Desc.": 29900,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 30,
            "Kg.Netos": 29870,
            "TITULAR CARTA PORTE": "JUAREZ ALBERTO ALEJANDRO",
            "CUIT": 20183808673,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "OBANDO JUAN",
            "CUIT__4": 20219131810,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "AA471XC",
            "Pat. Acoplado": "AA641XC",
            "CUIT Transp.": 20183808673,
            "Trasportista": "JUAREZ ALBERTO ALEJANDRO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BOLIVAR",
            "Nº LOCALIDAD ONCCA PROCED.": "",
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20217672962,
            "Chofer": "GOMEZ MARTIN",
            "Nro CAC": 89493812631203,
            "Vencim.CP": "3/3/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 330,
            "Tarifa": 1230,
            "CTG": 43475071,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581404204.TIF"
          },
          {
            "C.PORTE": 581404205,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 30580,
            "Kg.Tara Proc.": 13420,
            "Kg.Bruto Proc.": 44000,
            "Kg.Bruto Desc.": 44140,
            "Kg.Tara Desc.": 13760,
            "Kg.Neto Desc.": 30380,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 30,
            "Kg.Netos": 30350,
            "TITULAR CARTA PORTE": "JUAREZ ALBERTO ALEJANDRO",
            "CUIT": 20183808673,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "OBANDO JUAN",
            "CUIT__4": 20219131810,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "EHP980",
            "Pat. Acoplado": "WYI668",
            "CUIT Transp.": 20183808673,
            "Trasportista": "JUAREZ ALBERTO ALEJANDRO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BOLIVAR",
            "Nº LOCALIDAD ONCCA PROCED.": "",
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20371974777,
            "Chofer": "DOMINGUEZ MATIAS",
            "Nro CAC": 89493812631203,
            "Vencim.CP": "2/7/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 330,
            "Tarifa": 1230,
            "CTG": 63108984,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581404205.TIF"
          },
          {
            "C.PORTE": 581751332,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 28540,
            "Kg.Tara Proc.": 14100,
            "Kg.Bruto Proc.": 42640,
            "Kg.Bruto Desc.": 42500,
            "Kg.Tara Desc.": 13900,
            "Kg.Neto Desc.": 28600,
            "Kg.Dif.": "",
            "H.%": 13,
            "Mer.Kg.": 383,
            "Volatil": 29,
            "Kg.Netos": 28188,
            "TITULAR CARTA PORTE": "ESPARTINA SA",
            "CUIT": 30635322140,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "OBANDO JUAN",
            "CUIT__4": 20219131810,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "GCX972",
            "Pat. Acoplado": "GLX084",
            "CUIT Transp.": 20264578931,
            "Trasportista": "TRANSPORTE VALLEJO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "ORENSE",
            "Nº LOCALIDAD ONCCA PROCED.": "",
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CHDAD",
            "CUIT chofer": 20101014844,
            "Chofer": "OYHAMBURO AMILCAR",
            "Nro CAC": 89520169345496,
            "Vencim.CP": "2/25/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 118,
            "Tarifa": 639.26,
            "CTG": 40886534,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581751332.TIF"
          },
          {
            "C.PORTE": 581751333,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29320,
            "Kg.Tara Proc.": 14870,
            "Kg.Bruto Proc.": 44190,
            "Kg.Bruto Desc.": 44300,
            "Kg.Tara Desc.": 14880,
            "Kg.Neto Desc.": 29420,
            "Kg.Dif.": "",
            "H.%": 13,
            "Mer.Kg.": 394,
            "Volatil": 29,
            "Kg.Netos": 28997,
            "TITULAR CARTA PORTE": "ESPARTINA SA",
            "CUIT": 30635322140,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "OBANDO JUAN",
            "CUIT__4": 20219131810,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "DIX083",
            "Pat. Acoplado": "AB359UQ",
            "CUIT Transp.": 20264578931,
            "Trasportista": "TRANSPORTE VALLEJO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "ORENSE",
            "Nº LOCALIDAD ONCCA PROCED.": "",
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CHDAD",
            "CUIT chofer": 20287949546,
            "Chofer": "COMBA GERARDO",
            "Nro CAC": 89520169345496,
            "Vencim.CP": "2/25/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 118,
            "Tarifa": 639.62,
            "CTG": 50101439,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581751333.TIF"
          },
          {
            "C.PORTE": 581751334,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 28310,
            "Kg.Tara Proc.": 15080,
            "Kg.Bruto Proc.": 43390,
            "Kg.Bruto Desc.": 43360,
            "Kg.Tara Desc.": 15040,
            "Kg.Neto Desc.": 28320,
            "Kg.Dif.": "",
            "H.%": 12.8,
            "Mer.Kg.": 314,
            "Volatil": 28,
            "Kg.Netos": 27978,
            "TITULAR CARTA PORTE": "ESPARTINA SA",
            "CUIT": 30635322140,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "OBANDO JUAN",
            "CUIT__4": 20219131810,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "RPD448",
            "Pat. Acoplado": "AA906ZH",
            "CUIT Transp.": 20264578931,
            "Trasportista": "TRANSPORTE VALLEJO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "ORENSE",
            "Nº LOCALIDAD ONCCA PROCED.": "",
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CHDAD",
            "CUIT chofer": 20314139667,
            "Chofer": "COMBA EMILIANO",
            "Nro CAC": 89520169345496,
            "Vencim.CP": "2/25/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 118,
            "Tarifa": 639.62,
            "CTG": 90099146,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581751334.TIF"
          },
          {
            "C.PORTE": 581751335,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29340,
            "Kg.Tara Proc.": 14560,
            "Kg.Bruto Proc.": 43900,
            "Kg.Bruto Desc.": 43820,
            "Kg.Tara Desc.": 14520,
            "Kg.Neto Desc.": 29300,
            "Kg.Dif.": "",
            "H.%": 13,
            "Mer.Kg.": 393,
            "Volatil": 29,
            "Kg.Netos": 28878,
            "TITULAR CARTA PORTE": "ESPARTINA SA",
            "CUIT": 30635322140,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "OBANDO JUAN",
            "CUIT__4": 20219131810,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "CGD595",
            "Pat. Acoplado": "CLN147",
            "CUIT Transp.": 20264578931,
            "Trasportista": "TRANSPORTE VALLEJO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "ORENSE",
            "Nº LOCALIDAD ONCCA PROCED.": "",
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CHDAD",
            "CUIT chofer": 20323879312,
            "Chofer": "SUAREZ MAURO",
            "Nro CAC": 89520169345496,
            "Vencim.CP": "2/25/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 118,
            "Tarifa": 639.62,
            "CTG": 50968703,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581751335.TIF"
          },
          {
            "C.PORTE": 581751336,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 28940,
            "Kg.Tara Proc.": 15530,
            "Kg.Bruto Proc.": 44470,
            "Kg.Bruto Desc.": 44520,
            "Kg.Tara Desc.": 15500,
            "Kg.Neto Desc.": 29020,
            "Kg.Dif.": "",
            "H.%": 13,
            "Mer.Kg.": 389,
            "Volatil": 29,
            "Kg.Netos": 28602,
            "TITULAR CARTA PORTE": "ESPARTINA SA",
            "CUIT": 30635322140,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "OBANDO JUAN",
            "CUIT__4": 20219131810,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "CRW288",
            "Pat. Acoplado": "STT557",
            "CUIT Transp.": 20264578931,
            "Trasportista": "TRANSPORTE VALLEJO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "ORENSE",
            "Nº LOCALIDAD ONCCA PROCED.": "",
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CHDAD",
            "CUIT chofer": 20053724435,
            "Chofer": "PEREYRA M.",
            "Nro CAC": 89520169345496,
            "Vencim.CP": "2/25/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 118,
            "Tarifa": 639.62,
            "CTG": 50275625,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581751336.TIF"
          },
          {
            "C.PORTE": 581751337,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29040,
            "Kg.Tara Proc.": 15680,
            "Kg.Bruto Proc.": 44720,
            "Kg.Bruto Desc.": 44540,
            "Kg.Tara Desc.": 15660,
            "Kg.Neto Desc.": 28880,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 29,
            "Kg.Netos": 28851,
            "TITULAR CARTA PORTE": "ESPARTINA SA",
            "CUIT": 30635322140,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "OBANDO JUAN",
            "CUIT__4": 20219131810,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "CPR619",
            "Pat. Acoplado": "UYJ201",
            "CUIT Transp.": 20264578931,
            "Trasportista": "VALLEJO PEDRO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "ORENSE",
            "Nº LOCALIDAD ONCCA PROCED.": "",
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20234069544,
            "Chofer": "CARBAJAL LUIS CARLOS",
            "Nro CAC": 89520169345496,
            "Vencim.CP": "2/15/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 118,
            "Tarifa": 639.62,
            "CTG": 20106708,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581751337.TIF"
          },
          {
            "C.PORTE": 581751338,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 30120,
            "Kg.Tara Proc.": 14210,
            "Kg.Bruto Proc.": 44330,
            "Kg.Bruto Desc.": 44420,
            "Kg.Tara Desc.": 14240,
            "Kg.Neto Desc.": 30180,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 30,
            "Kg.Netos": 30150,
            "TITULAR CARTA PORTE": "ESPARTINA SA",
            "CUIT": 30635322140,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "OBANDO JUAN",
            "CUIT__4": 20219131810,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "CEV393",
            "Pat. Acoplado": "RUL025",
            "CUIT Transp.": 20264578931,
            "Trasportista": "VALLEJO PEDRO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "ORENSE",
            "Nº LOCALIDAD ONCCA PROCED.": "",
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20369060296,
            "Chofer": "ALONSO PABLO",
            "Nro CAC": 89520169345496,
            "Vencim.CP": "2/15/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 118,
            "Tarifa": 639.62,
            "CTG": 70424986,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581751338.TIF"
          },
          {
            "C.PORTE": 581751339,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 28080,
            "Kg.Tara Proc.": 14340,
            "Kg.Bruto Proc.": 42420,
            "Kg.Bruto Desc.": 42360,
            "Kg.Tara Desc.": 14080,
            "Kg.Neto Desc.": 28280,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 28,
            "Kg.Netos": 28252,
            "TITULAR CARTA PORTE": "ESPARTINA SA",
            "CUIT": 30635322140,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "OBANDO JUAN",
            "CUIT__4": 20219131810,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "ENB655",
            "Pat. Acoplado": "FRC571",
            "CUIT Transp.": 20264578931,
            "Trasportista": "TRANSPORTE VALLEJO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "ORENSE",
            "Nº LOCALIDAD ONCCA PROCED.": "",
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20219132582,
            "Chofer": "CAMPAGNIOLI ALFREDO",
            "Nro CAC": 89520169345496,
            "Vencim.CP": "2/25/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 118,
            "Tarifa": 639.29,
            "CTG": 50352438,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581751339.TIF"
          },
          {
            "C.PORTE": 581751342,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 27320,
            "Kg.Tara Proc.": 14430,
            "Kg.Bruto Proc.": 41750,
            "Kg.Bruto Desc.": 43860,
            "Kg.Tara Desc.": 14520,
            "Kg.Neto Desc.": 29340,
            "Kg.Dif.": "",
            "H.%": 11.6,
            "Mer.Kg.": 0,
            "Volatil": 29,
            "Kg.Netos": 29311,
            "TITULAR CARTA PORTE": "ESPARTINA SA",
            "CUIT": 30635322140,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "OBANDO JUAN",
            "CUIT__4": 20219131810,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "AAT188",
            "Pat. Acoplado": "RLA537",
            "CUIT Transp.": 20264578931,
            "Trasportista": "VALLEJO PEDRO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "ORENSE",
            "Nº LOCALIDAD ONCCA PROCED.": "",
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20233491897,
            "Chofer": "ANGLADETE MARCOS",
            "Nro CAC": 89520169345496,
            "Vencim.CP": "1/11/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 118,
            "Tarifa": 639.62,
            "CTG": 20961632,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581751342.TIF"
          },
          {
            "C.PORTE": 581751343,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29450,
            "Kg.Tara Proc.": 14150,
            "Kg.Bruto Proc.": 43600,
            "Kg.Bruto Desc.": 43520,
            "Kg.Tara Desc.": 14220,
            "Kg.Neto Desc.": 29300,
            "Kg.Dif.": "",
            "H.%": 16.2,
            "Mer.Kg.": 1456,
            "Volatil": 29,
            "Kg.Netos": 27815,
            "TITULAR CARTA PORTE": "ESPARTINA SA",
            "CUIT": 30635322140,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "OBANDO JUAN",
            "CUIT__4": 20219131810,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "EUM636",
            "Pat. Acoplado": "VFV448",
            "CUIT Transp.": 20264578931,
            "Trasportista": "VALLEJO PEDRO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "ORENSE",
            "Nº LOCALIDAD ONCCA PROCED.": "",
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 23103520959,
            "Chofer": "BETANZO MIGUEL",
            "Nro CAC": 89520169345496,
            "Vencim.CP": "1/11/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 118,
            "Tarifa": 639.62,
            "CTG": 60569807,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581751343.TIF"
          },
          {
            "C.PORTE": 581751345,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 28620,
            "Kg.Tara Proc.": 14100,
            "Kg.Bruto Proc.": 42720,
            "Kg.Bruto Desc.": 42660,
            "Kg.Tara Desc.": 14060,
            "Kg.Neto Desc.": 28600,
            "Kg.Dif.": "",
            "H.%": 12.5,
            "Mer.Kg.": 0,
            "Volatil": 29,
            "Kg.Netos": 28571,
            "TITULAR CARTA PORTE": "ESPARTINA SA",
            "CUIT": 30635322140,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "",
            "CUIT__2": "",
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "OBANDO JUAN",
            "CUIT__4": 20219131810,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "CQU354",
            "Pat. Acoplado": "SND103",
            "CUIT Transp.": 20264578931,
            "Trasportista": "VALLEJO PEDRO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "ORENSE",
            "Nº LOCALIDAD ONCCA PROCED.": "",
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20264578931,
            "Chofer": "DORADO MAURO",
            "Nro CAC": 89520169345496,
            "Vencim.CP": "1/11/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 118,
            "Tarifa": 639.62,
            "CTG": 50392411,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581751345.TIF"
          },
          {
            "C.PORTE": 581837848,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29320,
            "Kg.Tara Proc.": 15390,
            "Kg.Bruto Proc.": 44710,
            "Kg.Bruto Desc.": 44960,
            "Kg.Tara Desc.": 15520,
            "Kg.Neto Desc.": 29440,
            "Kg.Dif.": "",
            "H.%": 11,
            "Mer.Kg.": 0,
            "Volatil": 29,
            "Kg.Netos": 29411,
            "TITULAR CARTA PORTE": "LA LEGUA SCA",
            "CUIT": 33619778559,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "AGRIFORUM SA",
            "CUIT__2": 30714229571,
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "CEDREZ RUBEN Y OTROS S.H.",
            "CUIT__4": 30715896423,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "FTP696",
            "Pat. Acoplado": "HHQ701",
            "CUIT Transp.": 20182275760,
            "Trasportista": "PIETRALUNGA OMAR ARIEL",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "A.G.CHAVEZ",
            "Nº LOCALIDAD ONCCA PROCED.": 101,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20344171425,
            "Chofer": "GUZMAN LUCIO",
            "Nro CAC": 90019727796374,
            "Vencim.CP": "1/11/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 140,
            "Tarifa": 632.5,
            "CTG": 99410857,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581837848.TIF"
          },
          {
            "C.PORTE": 581837849,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29720,
            "Kg.Tara Proc.": 15050,
            "Kg.Bruto Proc.": 44770,
            "Kg.Bruto Desc.": 44980,
            "Kg.Tara Desc.": 15200,
            "Kg.Neto Desc.": 29780,
            "Kg.Dif.": "",
            "H.%": 10.8,
            "Mer.Kg.": 0,
            "Volatil": 30,
            "Kg.Netos": 29750,
            "TITULAR CARTA PORTE": "LA LEGUA SCA",
            "CUIT": 33619778559,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "AGRIFORUM SA",
            "CUIT__2": 30714229571,
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "CEDREZ RUBEN Y OTROS S.H.",
            "CUIT__4": 30715896423,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "KBU007",
            "Pat. Acoplado": "HLE099",
            "CUIT Transp.": 20182275760,
            "Trasportista": "PIETRALUNGA OMAR ARIEL",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "A.G.CHAVEZ",
            "Nº LOCALIDAD ONCCA PROCED.": 101,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20306651170,
            "Chofer": "PERALTA ROQUE",
            "Nro CAC": 90019727796374,
            "Vencim.CP": "1/11/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 140,
            "Tarifa": 632.5,
            "CTG": 59554521,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581837849.TIF"
          },
          {
            "C.PORTE": 581837850,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29800,
            "Kg.Tara Proc.": 14650,
            "Kg.Bruto Proc.": 44450,
            "Kg.Bruto Desc.": 44580,
            "Kg.Tara Desc.": 14820,
            "Kg.Neto Desc.": 29760,
            "Kg.Dif.": "",
            "H.%": 10.8,
            "Mer.Kg.": 0,
            "Volatil": 30,
            "Kg.Netos": 29730,
            "TITULAR CARTA PORTE": "LA LEGUA SCA",
            "CUIT": 33619778559,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "AGRIFORUM SA",
            "CUIT__2": 30714229571,
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "CEDREZ RUBEN Y OTROS S.H.",
            "CUIT__4": 30715896423,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "SQX934",
            "Pat. Acoplado": "SGU273",
            "CUIT Transp.": 20245148403,
            "Trasportista": "TAVIERES ALEJANDRO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "A.G.CHAVEZ",
            "Nº LOCALIDAD ONCCA PROCED.": 101,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20245148403,
            "Chofer": "TAVIERES ALEJANDRO",
            "Nro CAC": 90019727796374,
            "Vencim.CP": "1/11/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 140,
            "Tarifa": 632.5,
            "CTG": 99569270,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581837850.TIF"
          },
          {
            "C.PORTE": 581837851,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29810,
            "Kg.Tara Proc.": 14840,
            "Kg.Bruto Proc.": 44650,
            "Kg.Bruto Desc.": 44720,
            "Kg.Tara Desc.": 14840,
            "Kg.Neto Desc.": 29880,
            "Kg.Dif.": "",
            "H.%": 10.7,
            "Mer.Kg.": 0,
            "Volatil": 30,
            "Kg.Netos": 29850,
            "TITULAR CARTA PORTE": "LA LEGUA SCA",
            "CUIT": 33619778559,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "AGRIFORUM SA",
            "CUIT__2": 30714229571,
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "CEDREZ RUBEN Y OTROS S.H.",
            "CUIT__4": 30715896423,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "ASS664",
            "Pat. Acoplado": "SII916",
            "CUIT Transp.": 20327587065,
            "Trasportista": "CABRERA CLAUDIO",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "A.G.CHAVEZ",
            "Nº LOCALIDAD ONCCA PROCED.": 101,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20335374208,
            "Chofer": "CARRERA MAXIMILIANO",
            "Nro CAC": 90019727796374,
            "Vencim.CP": "1/11/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 140,
            "Tarifa": 632.5,
            "CTG": 99422146,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581837851.TIF"
          },
          {
            "C.PORTE": 581788361,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29040,
            "Kg.Tara Proc.": 14860,
            "Kg.Bruto Proc.": 43900,
            "Kg.Bruto Desc.": 43900,
            "Kg.Tara Desc.": 14860,
            "Kg.Neto Desc.": 29040,
            "Kg.Dif.": "",
            "H.%": 12.2,
            "Mer.Kg.": 0,
            "Volatil": 29,
            "Kg.Netos": 29011,
            "TITULAR CARTA PORTE": "EL NOGAL DEL SUDESTE S.A",
            "CUIT": 30710553307,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "SCORZIELLO Y GALLELA S.A.",
            "CUIT__2": 33561059409,
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "CASALS GUSTAVO",
            "CUIT__4": 20129912422,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "EMR369",
            "Pat. Acoplado": "AD803KK",
            "CUIT Transp.": 30712205071,
            "Trasportista": "MARTIN MARTINEZ SA",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "LOBERIA",
            "Nº LOCALIDAD ONCCA PROCED.": 8840,
            "Desc.": "10/01/20",
            "CODIGO POSTAL": 7635,
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20289896911,
            "Chofer": "ALTUNA MARIANO",
            "Nro CAC": 90017362904712,
            "Vencim.CP": "3/9/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 90,
            "Tarifa": 575.72,
            "CTG": 67234170,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581788361.TIF"
          },
          {
            "C.PORTE": 581918532,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 28860,
            "Kg.Tara Proc.": 14000,
            "Kg.Bruto Proc.": 42860,
            "Kg.Bruto Desc.": 42900,
            "Kg.Tara Desc.": 14040,
            "Kg.Neto Desc.": 28860,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 29,
            "Kg.Netos": 28831,
            "TITULAR CARTA PORTE": "STANCOVICH ROBERTO DANILO",
            "CUIT": 20053933840,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "GUIRA GUAZU SA",
            "CUIT__2": 30708848154,
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "CEDREZ RUBEN Y OTROS S.H.",
            "CUIT__4": 30715896423,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "CQL704",
            "Pat. Acoplado": "TNR404",
            "CUIT Transp.": 20313362451,
            "Trasportista": "VILLALBA NAHUEL",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "ORENSE",
            "Nº LOCALIDAD ONCCA PROCED.": "",
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20178962990,
            "Chofer": "SMIDTH DARIO",
            "Nro CAC": 90020066264516,
            "Vencim.CP": "3/8/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 110,
            "Tarifa": 647.7,
            "CTG": 70977221,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581918532.TIF"
          },
          {
            "C.PORTE": 581918533,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 28840,
            "Kg.Tara Proc.": 14000,
            "Kg.Bruto Proc.": 42840,
            "Kg.Bruto Desc.": 42780,
            "Kg.Tara Desc.": 13980,
            "Kg.Neto Desc.": 28800,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 29,
            "Kg.Netos": 28771,
            "TITULAR CARTA PORTE": "STANCOVICH ROBERTO DANILO",
            "CUIT": 20053933840,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "GUIRA GUAZU SA",
            "CUIT__2": 30708848154,
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "CEDREZ RUBEN Y OTROS S.H.",
            "CUIT__4": 30715896423,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "VXU562",
            "Pat. Acoplado": "TNR404",
            "CUIT Transp.": 20313362451,
            "Trasportista": "VILLALBA NAHUEL",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "ORENSE",
            "Nº LOCALIDAD ONCCA PROCED.": "",
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20386071900,
            "Chofer": "SMIDTH EZEQUIEL",
            "Nro CAC": 90020066264516,
            "Vencim.CP": "3/8/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 110,
            "Tarifa": 647.7,
            "CTG": 30368674,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581918533.TIF"
          },
          {
            "C.PORTE": 581946072,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 28470,
            "Kg.Tara Proc.": 16470,
            "Kg.Bruto Proc.": 44940,
            "Kg.Bruto Desc.": 44920,
            "Kg.Tara Desc.": 16480,
            "Kg.Neto Desc.": 28440,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 28,
            "Kg.Netos": 28412,
            "TITULAR CARTA PORTE": "GARBUGLIA ARMANDO RAUL",
            "CUIT": 20125068120,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "RURAL CERES SA",
            "CUIT__2": 30567668661,
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "AA032MG",
            "Pat. Acoplado": "AA493HH",
            "CUIT Transp.": 30713711302,
            "Trasportista": "GEDIMAR SRL",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BOLIVAR",
            "Nº LOCALIDAD ONCCA PROCED.": "",
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20246322601,
            "Chofer": "ALFANO EUGENIO",
            "Nro CAC": 90020556050688,
            "Vencim.CP": "3/9/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 370,
            "Tarifa": 1323,
            "CTG": 70909881,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581946072.TIF"
          },
          {
            "C.PORTE": 581946073,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29770,
            "Kg.Tara Proc.": 15200,
            "Kg.Bruto Proc.": 44970,
            "Kg.Bruto Desc.": 44880,
            "Kg.Tara Desc.": 15100,
            "Kg.Neto Desc.": 29780,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 30,
            "Kg.Netos": 29750,
            "TITULAR CARTA PORTE": "GARBUGLIA ARMANDO RAUL",
            "CUIT": 20125068120,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "RURAL CERES SA",
            "CUIT__2": 30567668661,
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "BMB268",
            "Pat. Acoplado": "SSJ407",
            "CUIT Transp.": 30713711302,
            "Trasportista": "GEDIMAR SRL",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BOLIVAR",
            "Nº LOCALIDAD ONCCA PROCED.": "",
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20144038518,
            "Chofer": "GUALDIERI JORGE",
            "Nro CAC": 90020556050688,
            "Vencim.CP": "3/9/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 370,
            "Tarifa": 1323,
            "CTG": 80007432,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581946073.TIF"
          },
          {
            "C.PORTE": 581946074,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 30190,
            "Kg.Tara Proc.": 14810,
            "Kg.Bruto Proc.": 45000,
            "Kg.Bruto Desc.": 44800,
            "Kg.Tara Desc.": 14540,
            "Kg.Neto Desc.": 30260,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 30,
            "Kg.Netos": 30230,
            "TITULAR CARTA PORTE": "GARBUGLIA ARMANDO RAUL",
            "CUIT": 20125068120,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "RURAL CERES SA",
            "CUIT__2": 30567668661,
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "AC744GD",
            "Pat. Acoplado": "ANO260",
            "CUIT Transp.": 30713711302,
            "Trasportista": "GEDIMAR SRL",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BOLIVAR",
            "Nº LOCALIDAD ONCCA PROCED.": "",
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20223347119,
            "Chofer": "GUERRAZ WALTER",
            "Nro CAC": 90020556050688,
            "Vencim.CP": "3/9/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 370,
            "Tarifa": 1323,
            "CTG": 50783578,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581946074.TIF"
          },
          {
            "C.PORTE": 581946075,
            "Arribo": "10/01/20",
            "COSECHA": 1920,
            "Producto": "CEBADA",
            "TIPO": "ANDREIA",
            "Contrato": "",
            "Kg.Proc.": 29280,
            "Kg.Tara Proc.": 15710,
            "Kg.Bruto Proc.": 44990,
            "Kg.Bruto Desc.": 44960,
            "Kg.Tara Desc.": 15680,
            "Kg.Neto Desc.": 29280,
            "Kg.Dif.": "",
            "H.%": "",
            "Mer.Kg.": 0,
            "Volatil": 29,
            "Kg.Netos": 29251,
            "TITULAR CARTA PORTE": "GARBUGLIA ARMANDO RAUL",
            "CUIT": 20125068120,
            "Nº PLANTA ONCCA": "",
            "INTERMEDIARIO": "",
            "CUIT__1": "",
            "REMITENTE COMERCIAL": "RURAL CERES SA",
            "CUIT__2": 30567668661,
            "Corredor": "NEGOCIO DE GRANOS S.A.",
            "CUIT__3": 30663380865,
            "REPRESENT / ENTREG": "TRANSP. Y ENTREGAS  DE CEREA.",
            "CUIT__4": 30708448474,
            "DESTINATARIO": "OPORTUNIDADES COMERCIALES AGROP",
            "CUIT__5": 30707640029,
            "DESTINO": "SITIO 0 DE QUEQUEN",
            "CUIT__6": 30713667508,
            "Pat. Chasis": "HRR318",
            "Pat. Acoplado": "HWL449",
            "CUIT Transp.": 30713711302,
            "Trasportista": "GEDIMAR SRL",
            "COD.LOC. ONCCA DESTINO": 12217,
            "Destino": "QUEQUEN",
            "Nro. Planta Onca": "",
            "Procedcia.": "BOLIVAR",
            "Nº LOCALIDAD ONCCA PROCED.": "",
            "Desc.": "10/01/20",
            "CODIGO POSTAL": "",
            "Cal.-Observaciones": "CONF.",
            "CUIT chofer": 20266713321,
            "Chofer": "VILLA DARIO",
            "Nro CAC": 90020556050688,
            "Vencim.CP": "3/9/20",
            "Emision CP": "1/10/20",
            "Sucursal": "",
            "Km": 370,
            "Tarifa": 1470.64,
            "CTG": 30558976,
            "LINK": "http://www.entregastorres.com.ar/cporte/2020/581946075.TIF"
          }
        ], [this.data.transporter, this.data.port], null, 'CP546921', 'PORT_S_125'),
        genNode(19, '12/02/19 20:01', 'TRACKER.EVENTS.STORAGE', [18], [{ lat: -38.5730241, lng: -58.7115444 }], null, null, null, [this.data.port], 'Stowed into holds number: 2, 3 and 4', 'PORT_S_125'),
        genNode(21, '12/02/20 05:28', 'TRACKER.EVENTS.LOAD', [19], [{ lat: -38.5730241, lng: -58.7115444 }], [
          { "uri": "https://i0.wp.com/efarmnewsar.com/wp-content/uploads/2019/04/barco-granos.jpg" }
        ], null, [
          {
            "Ship": 'Weco Josefine',
            "IMO": "9779020",
            "Vessel Type": "Bulk Carrier",
            "MMSI": "565043500",
            "Flag": "Singapore [SG]"
          }
        ], [this.data.port, this.data.shipper], null, 'PORT_S_125', 'WJ_BL_20200413'),
      ]);
    }, 500);
  }

  public next(sameBranch: boolean = false): void {
    let _index;

    if (sameBranch) {
      if (this.nodesMap[this.selectedNode.id].childs) {
        _index = this.getNodeIndexById(this.nodesMap[this.selectedNode.id].childs[0]);
      }
    } else {
      let _index = this.getNodeIndexById(this.selectedNode.id);
      _index--;
      if (_index < 0) _index = this.nodes.length - 1;
    }

    if (_index != undefined) this.setNode(this.nodes[_index]);
  }

  public previous(sameBranch: boolean = false): void {
    let _index;

    if (sameBranch) {
      if (this.selectedNode.parents) {
        _index = this.getNodeIndexById(this.selectedNode.parents[0]);
      }
    } else {
      _index = this.getNodeIndexById(this.selectedNode.id);
      _index++;
      if (_index >= this.nodes.length) _index = 0;
    }

    if (_index != undefined) this.setNode(this.nodes[_index]);
  }

  public getNodeById(id): void {
    return this.nodes[this.getNodeIndexById(id)];
  }

  private getNodeIndexById(id): number {
    return this.nodes.findIndex((element) => element.id === id);
  }

  private dataLoaded(data): void {
    // sort based on timestamp
    data.sort((a, b) => (a.date < b.date) ? 1 : -1);

    this.nodes = data;

    this.buildMap();

    this.loading = false;
  }

  private buildMap(): void {
    // Ojo con tocar aca -- Muy complicado
    // Requiere mejoras, no creo que dibuje bien todos los escenarios

    this.branches = 0;
    // map coordinates
    let map = this.nodesMap,
      data: any[] = JSON.parse(JSON.stringify(this.nodes)),
      row = this.nodes.length,
      goThruMap = function (func) {
        for (const node_id in map) {
          if (map.hasOwnProperty(node_id)) {
            func(map[node_id]);
          }
        }
      };

    data.reverse(); // Sort from oldest to newest to build map

    data.forEach((node, i) => {
      map[node.id] = { x: 0, y: row, childs: [] };

      if (node.parents) { // Has parent node(s)
        node.parents.sort(function (a, b) {
          return (map[a].x < map[b].x) ? -1 : 1;
        }); // Sort parents nodes by id

        let firstParent = node.parents[0];

        if (!map[firstParent]) throw new Error("The node " + node.id + " doesn't have any parents.");

        if (map[firstParent].childs.length) {
          // New branch
          this.branches++;
          map[node.id].x = this.branches;
        } else {
          // First child of its parent
          map[node.id].x = map[firstParent].x;
        }

        node.parents.forEach(parent_id => {
          let l = map[parent_id].childs.push(node.id);
          if (l > 1) map[parent_id].lastChildIndex = i;
        });
      } else if (i !== 0) { // Has no parents
        // New branch
        this.branches++;
        map[node.id].x = this.branches;
      }

      row--; // new line
    });

    let lastChildIndexes = []; // Clean empty spaces based on the last child position
    goThruMap(node => {
      if (node.lastChildIndex) {
        // Populate an array with last child positions only
        lastChildIndexes.push(node.lastChildIndex);
      }
    });

    // Sort array with last child positions only from last to first
    lastChildIndexes.sort((a, b) => {
      a = parseInt(a);
      b = parseInt(b);
      if (a > b) {
        return -1;
      }
      if (a < b) {
        return 1;
      }
      return 0;
    });

    this.branches = 0; // Reset branches

    let minX = 0; // Position offset based on last childs
    lastChildIndexes.forEach(lastIndex => {
      for (let index = lastIndex; index < data.length; index++) {
        const element = map[data[index].id];
        if (element.x > minX) element.x--;
        this.branches = Math.max(element.x, this.branches);
      }
      minX++;
    });

    this.setNode(this.nodes[0]);
  }

  public setNode(node): void {
    if (!node) return;
    if (this.selectedNode && this.selectedNode.id === node.id) this.selectedNode = undefined;
    else {
      this.selectedNode = node;

      let _location = this.selectedNode.locations;

      if (_location) {
        this.marker.setPosition(_location[_location.length - 1]);

        if (_location.length > 1) {
          // Route
          this.marker_from.setPosition(_location[0]);
          this.route.setPath(_location);

          let bounds = new google.maps.LatLngBounds();

          _location.forEach(element => {
            bounds.extend(element);
          });

          this.route.setVisible(true);
          this.marker_from.setVisible(true);

          this.map.fitBounds(bounds, 40);
          this.map.panToBounds(bounds, 40);
        } else {
          this.route.setVisible(false);
          this.marker_from.setVisible(false);
          // Smoothly move the map
          this.map.setZoom(this.settings.map.zoom);
          this.map.panTo(_location[_location.length - 1]);
        }
      }

      this.dataView.nativeElement.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  /**
   * Compare function for
   * [[https://angular.io/api/common/KeyValuePipe|keyvalue]] pipe to preserve
   * original array order.
   */
  public originalOrder(): number {
    return 0;
  }

  public typeof(v): string {
    return typeof v;
  }
}
