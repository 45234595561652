import { Type } from "class-transformer";

export class CompanyCertificate {
  id: number;
  holder: string;
  certificate_type: {
    id: number;
    name: string;
  }
  certificate: {
    id: number;
    name: string;
    other?: string;
  }
  category: string;
  number: string;
  entity: {
    id: number;
    name: string;
    other?: string;
  }

  @Type(() => Date)
  valid_from: Date;

  @Type(() => Date)
  valid_to: Date;

  scope: string;
  image: string;
  verified: boolean;
  expired: boolean;
}