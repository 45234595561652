import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { SlotsBatch } from '../../../models/slots-batch.model';

@Component({
  selector: 'ag-slots-details',
  templateUrl: './slots-details.component.html',
  styleUrls: ['./slots-details.component.scss']
})
export class SlotsDetailsComponent implements OnChanges {

  @Input() public batches: SlotsBatch[];

  public details: {
    [key: string]: number;
  };

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.details = {};

    this.batches.forEach(batch => {
      const statusMap = {
        1: 'SLOTS.STATUS.RECEIVED',
        2: 'SLOTS.STATUS.ACCEPTED',
        3: 'SLOTS.STATUS.REJECTED',
        4: 'SLOTS.STATUS.WITHDRAWN',
        5: 'SLOTS.STATUS.RETURNED'
      };

      const key = statusMap[batch.status.id];
      if (key) {
        this.details[key] = (this.details[key] || 0) + batch.slots_total;
      }
    });
  }
}
