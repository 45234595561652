import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm, UntypedFormGroup } from '@angular/forms';

import { InvitationAbstract } from '../../../company/models/invitation-abstract';
import { CounterpartsService } from '../../../company/services/counterparts.service';
import { Company } from '../../../models/company.model';
import { Country } from '../../../models/country.model';
import { FiscalId } from '../../../models/fiscal-id.model';
import { CompanyService } from '../../../services/company.service';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { LocationService } from '../../../services/location.service';

@Component({
  selector: 'single-invite',
  templateUrl: './single-invite.component.html',
  styleUrls: ['./single-invite.component.scss']
})
export class SingleInviteComponent extends InvitationAbstract implements OnInit, OnDestroy, OnChanges {

  @ViewChild('invitationForm') private readonly invitationForm: NgForm;

  public companiesFromSelectedFiscalId: Company[];
  public selectedCompany: Company = null;
  public selectedCountry: Country = null;
  public singleNewCompany: Company = null;

  /** @ignore */
  constructor(
    private counterpartsService: CounterpartsService,
    private companyService: CompanyService,
    private locationService: LocationService,
    private dataDogLoggerService: DataDogLoggerService
  ) {
    super();
  }

  /** @ignore */
  ngOnInit(): void {
    this.subscribeEventListeners()
    this.resetData();
    this.fetchCountries();
  }

  /** @ignore */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.invitationForm)
      this.formOnChangeEmitter.emit(this.invitationForm);
  }

  private subscribeEventListeners(): void {
    // Listening submitEvent from parent component
    this.subscriptions.push(this.submitEvent.subscribe(() => {
      this.invitationForm.ngSubmit.emit();
    }));
  }

  private fetchCountries(): void {
    if (!this.countries) this.subscriptions.push(this.locationService.getCountryCodes().subscribe(countries => {
      this.countries = countries;

      if (this.company.market.country_id) {
        this.selectedCountry = countries.filter(country => {
          return country.id === this.company.market.country_id;
        })[0];
      }
    }));
  }

  private resetData(fg?: UntypedFormGroup): void {
    fg?.reset();
    this.companiesFromSelectedFiscalId = [];
    this.emitSubmitError(null);

    delete this.selectedCompany;
    delete this.singleNewCompany;
  }

  /**
   * Check the fiscalId after input change
   */
  public onFiscalIdInputChange(fg: UntypedFormGroup): void {
    this.clearInviteForm(fg)
    const isAnInvalidFiscalId = !fg.controls.fiscal_id.valid
    if (isAnInvalidFiscalId) return

    const fiscalId = fg.value.fiscal_id;
    if (!fiscalId) return

    const companiesFromCache = this.getCompanyFromCache(fiscalId);
    if (companiesFromCache) {
      this.companiesFromSelectedFiscalId = companiesFromCache;
      this.setFirstCompanie(fiscalId, fg);
      return
    }
    this.fetchCompaniesByFiscalId(fiscalId, fg);
  }

  private fetchCompaniesByFiscalId(fiscalId: string, fg?: UntypedFormGroup): void {
    /* Fetch companies with this fiscalId */
    this.processing = true;
    this.subscriptions.push(this.companyService.getCompaniesByFiscalValue(this.company.id, this.selectedCountry.id, fiscalId).subscribe(companies => {
      this.companiesFromSelectedFiscalId = companies;
      this.addNewFiscalIdToCache(fiscalId, companies);
      if (fg) this.setFirstCompanie(fiscalId, fg);

      this.processing = false;
    }));
  }

  /**
   * Set the information of the company after the dropdown list selection
   */
  public onSelectRazonSocial(companyId, fg: UntypedFormGroup): void {
    this.selectedCompany = this.companiesFromSelectedFiscalId.find(company => company.id === parseInt(companyId))
    this.setFormWithCompanySelector(this.selectedCompany, fg);
  }

  private setFormWithCompanySelector(c: Company, fg: UntypedFormGroup): void {
    this.singleNewCompany = c;
    this.selectedCompany = c;
    if (fg.controls.name) fg.controls.name.setValue(c.id);
    if (c.activity) fg.controls.activity.setValue(c.activity.id);
    if (fg.controls.email) fg.controls.email.reset();
  }

  private setFormWithCompanyInput(c: Company, fg: UntypedFormGroup): void {
    const firstCompany = c[0];
    this.singleNewCompany = firstCompany;

    if (fg.controls.name) fg.controls.name.setValue(firstCompany.name);
    if (firstCompany.activity) fg.controls.activity.setValue(firstCompany.activity.id);
    if (fg.controls.email) fg.controls.email.reset();
  }

  private setFirstCompanie(fiscalId: string, fg: UntypedFormGroup): void {
    const companies = this.getCompanyFromCache(fiscalId);

    const fiscalIdHasOneCompany = companies.length === 1
    if (fiscalIdHasOneCompany) {
      this.setFormWithCompanyInput(companies, fg);
      return;
    }
  }

  /**
   * Submit form to invite a company
   */
  public submit(form: NgForm): void {
    this.emitSubmitError(null);

    this.processing = true;
    let payload = new Array<Company>();
    const group_values = form.control.value[0];
    let newCompany: Company = this.singleNewCompany;

    if (newCompany?.id) {
      newCompany = this.overrideNewCompany(newCompany, group_values)
    } else {
      newCompany = this.newCompany(group_values['name'], group_values['fiscal_id'], group_values['activity'], group_values['email']);
    }

    payload.push(newCompany);
    if (payload.length)
      this.sendInvitation(payload);
    else
      this.closeModalEventEmit();
  }

  private overrideNewCompany(newCompany: Company, formRowValues: any): Company {
    const isAnImportedCompany = (newCompany.activation_level && newCompany.activation_level.id === 3);

    if (isAnImportedCompany) {
      newCompany.name = this.selectedCompany ? this.selectedCompany.name : formRowValues['name'];
      newCompany.fiscal_id.value = formRowValues['fiscal_id'];
      newCompany.activity = this.activities.find(activity => activity.id == formRowValues['activity']);
      newCompany.admin_email = formRowValues['email'];
    }
    return newCompany;
  }

  private newCompany(name: string, fiscal_id: string, activity_id: number, email: string): Company {
    let c = new Company();
    c.fiscal_id = new FiscalId();
    c.name = name;
    c.fiscal_id.value = fiscal_id;
    c.activity = this.activities.find(activity => activity.id === Number(activity_id));
    c.admin_email = email;

    if (this.selectedCountry) {
      c.address.country = this.selectedCountry;
      // Hardcoded fiscal_id type
      if (this.selectedCountry.fiscalIds.length) c.fiscal_id.type = this.selectedCountry.fiscalIds[0].type;
    }
    return c;
  }

  private sendInvitation(companies: Company[]): void {
    this.subscriptions.push(
      this.counterpartsService.invite(this.company.id, companies).subscribe({
        next: response => {
          this.closeModalEventEmit();
          this.processing = false;
        },
        error: error => {
          this.processing = false;
          const errorMessage = error.error.message.ext_invalid_action || error.error.info.message;
          this.emitSubmitError(errorMessage);
          this.dataDogLoggerService.warn(error.message, error.error);
        }
      }));
  }

  /**
   * Resets the Company and FormGroup
   */
  public resetInvitationForm(formgroup: UntypedFormGroup): void {
    formgroup.reset();
    this.clearInviteForm(formgroup);
    formgroup.markAsPristine();
    formgroup.markAsUntouched();
  }

  private clearInviteForm(fg: UntypedFormGroup): void {
    this.companiesFromSelectedFiscalId = [];
    delete this.selectedCompany;
    delete this.singleNewCompany;

    fg.controls.name?.setValue('');
    fg.controls.activity?.setValue('');
    if (fg.controls.email) fg.controls.email.setValue('');
  }

  /**
   * Customizes the default Angular option comparison algorithm
   * @ignore */
  public compareId(a: { id: string | number }, b: { id: string | number }): boolean {
    return (!a && !b) || (a && b && a.id === b.id);
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    super.ngOnDestroy()
  }
}
