import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cmCode'
})
export class CmCodePipe implements PipeTransform {

  /**
   * Transforms a number into a corresponding label based on predefined mappings.
   *
   * @param {number} value - The number to be transformed.
   * @param {any} [args] - Additional arguments (not used in this function).
   * @returns {string} - The corresponding label for the given number, or the number as a string if no mapping is found.
   */
  transform(value: number, args?: any): string {
    const labelMap = new Map<number, string>([
      [1, 'F'],
      [2, 'G'],
      [3, 'H'],
      [4, 'J'],
      [5, 'K'],
      [6, 'M'],
      [7, 'N'],
      [8, 'Q'],
      [9, 'U'],
      [10, 'V'],
      [11, 'X'],
      [12, 'Z']
    ]);

    // Return the corresponding label or the number as a string if no mapping is found
    return labelMap.get(value) || value + '';
  }
}
