import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FlashService {

  private messages: { [key: string]: BehaviorSubject<boolean> } = {};

  constructor() { }

  /**
   * Reports a flash message by setting its state to true.
   * 
   * @param {string} key - The key identifying the flash message.
   */
  public report(key: string): void {
    this.message(key).next(true);
  }

  /**
   * Dismisses a flash message by setting its state to false.
   * 
   * @param {string} key - The key identifying the flash message.
   */
  public dismiss(key: string): void {
    this.message(key).next(false);
  }

  /**
   * Returns the BehaviorSubject associated with the given key.
   * Creates a new BehaviorSubject if one does not already exist for the key.
   * 
   * @param {string} key - The key identifying the flash message.
   * @returns {BehaviorSubject<boolean>} - The BehaviorSubject associated with the key.
   */
  public message(key: string): BehaviorSubject<boolean> {
    if (!this.messages[key]) {
      this.messages[key] = new BehaviorSubject<boolean>(null);
    }
    return this.messages[key];
  }

  /**
   * Dismisses all flash messages by setting their state to false.
   */
  public dismissAll(): void {
    Object.values(this.messages).forEach(subject => subject.next(false));
  }
}
