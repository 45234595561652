import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { JSONPath } from 'jsonpath-plus';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { downloadAsJSON } from '../../../utilities/json-tools';
import { HTMLToClipboardService } from '../../services/html-to-clipboard.service';

@Component({
  selector: 'ag-json-modal',
  templateUrl: './json-modal.component.html',
  styleUrls: ['./json-modal.component.scss']
})
export class JsonModalComponent implements OnInit, OnDestroy {

  @ViewChild('modal', { static: true }) private readonly modal: TemplateRef<any>;

  @Input() private table: boolean;
  @Input() private string: boolean = true;
  @Input() private source: boolean = true;

  /** The language currently used. */
  public currentLang: string;
  public json: JSON;
  public jsonString: string;
  public path: { title: string; path: string, value: JSON }[];
  public sections: any[];
  public selectedSection: any;
  public title: string;

  private modalRef: BsModalRef;
  // private modalSub: Subscription;

  constructor(
    private http: HttpClient,
    public htmlClipboardService: HTMLToClipboardService,
    private modalService: BsModalService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;

    this.sections = [];

    if (this.table) this.sections.push({ heading: 'WORKING_ORDERS.VIEWMODE.TABLE', slug: 'table' });
    if (this.string) this.sections.push({ heading: 'String', slug: 'string' });
    if (this.source) this.sections.push({ heading: 'Source', slug: 'source' });
  }

  public navigateTo(section: any = this.sections[0]): void {
    this.selectedSection = section;
  }

  public show(title: string = '', json?: JSON, url?: string): void {
    this.title = title;
    this.path = undefined;

    const execute = (data: JSON) => {
      this.json = data || JSON.parse('{}');
      this.jsonString = JSON.stringify(data, undefined, 2);
      this.goto('$', 'Inicio');
      this.navigateTo();
    };

    if (json || !url) {
      execute(json);
    } else if (url) {
      this.http.get<JSON>(url).subscribe(response => {
        this.title = url.substring(url.lastIndexOf('/') + 1);
        execute(response);
      });
    }

    this.openModal(this.modal);
  }

  public goto(to: string | number, title?: string): void {
    let jsonPath: string;
    if (typeof to === 'number') {
      jsonPath = this.path.splice(to)[0].path;
    } else jsonPath = to;

    const value: JSON = JSONPath({ path: jsonPath, json: this.json, wrap: false });

    this.path = [...(this.path || []), {
      title: title || jsonPath,
      path: jsonPath,
      value: value
    }];
  }

  public export(): void {
    downloadAsJSON(this.json, "JSON_" +
      (this.title || "no_name"));
  }

  /**
   * Compare function for
   * [[https://angular.io/api/common/KeyValuePipe|keyvalue]] pipe to preserve
   * original array order.
   */
  public originalOrder(): number {
    return 0;
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    // this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
    //   this.modalSub.unsubscribe();
    //   this.modalRef = undefined;
    //   // Reset all values
    //   // this.processing = false;
    // });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.closeModal();
  }
}
