/**
 * Checks if a file matches any of the specified file types.
 *
 * @param {File} file - The file to be validated.
 * @param {string[]} specifiers - An array of file type specifiers (extensions or MIME types).
 * @returns {boolean} - Returns true if the file matches any of the specifiers, otherwise false.
 */
function checkFile(file: File, specifiers: string[]): boolean {
  return specifiers.some(specifier => {
    if (specifier.startsWith('.')) {
      // Check by file extension (case insensitive)
      return file.name.toLowerCase().endsWith(specifier);
    } else {
      // Check by MIME type string
      const matcher = new RegExp(specifier, "g");
      return matcher.test(file.type);
    }
  });
}

/**
 * Checks if all files in the provided FileList match the accepted file types specified by the 'accept' attribute.
 *
 * @param {FileList} files - The list of files to be validated.
 * @param {string} accept - The accept attribute.
 * @returns {boolean} - Returns true if all files match the accepted types, otherwise false.
 */
export function checkAccept(files: FileList, accept: string = ''): boolean {
  if (accept === "") return true; // Empty accept accepts all

  const specifiers = accept.split(',').map((specifier: string) => specifier.trim().toLowerCase());

  for (let i = 0; i < files.length; i++) {
    // If any file is invalid, return false
    if (!checkFile(files[i], specifiers)) return false;
  }

  // All files are valid
  return true;
}

/**
 * Checks if the provided file match the accepted file types specified by the 'accept' attribute.
 *
 * @param {File} file - The file to be validated.
 * @param {string} accept - The accept attribute.
 * @returns {boolean} - Returns true if all files match the accepted types, otherwise false.
 */
export function checkAcceptFile(file: File, accept: string = ''): boolean {
  if (accept === "") return true; // Empty accept accepts all

  const specifiers = accept.split(',').map((specifier: string) => specifier.trim().toLowerCase());

  return checkFile(file, specifiers);
}