import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

/**
 * Esta directiva permite hacer 2 columnas puedan cambiar su tamaño
 * [Col1] || [Col2]
 */

@Directive({
  selector: '[agResize]'
})
export class ResizeDirective implements OnInit {

  @Input('leftResize') private leftElement: HTMLElement;
  @Input('rightResize') private rightElement: HTMLElement;
  @Input() private parentElement: HTMLElement;

  private grabber: boolean = false;
  private width: number;

  constructor(private el: ElementRef<HTMLElement>) { }

  ngOnInit(): void {
    this.width = this.parentElement.offsetWidth || window.screen.availWidth;
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.width = this.parentElement.offsetWidth || window.screen.availWidth;
  }

  @HostListener('mousedown') onMouseDown(): void {
    this.grabber = true;
    this.el.nativeElement.classList.add('side-panel');
    document.body.style.cursor = 'e-resize';
  }

  @HostListener('window:mouseup') onMouseUp(): void {
    this.grabber = false;
    this.el.nativeElement.classList.remove('side-panel');
    document.body.style.cursor = 'default';
  }

  @HostListener('window:mousemove', ['$event']) onMouseMove(event: MouseEvent) {
    const gap = ((window.screen.availWidth - this.width) / 2) - 12;
    const clientX = event.clientX - gap;

    if (this.grabber) {
      event.preventDefault();
      if (event.movementX > 0) {
        this.rightElement.style.flex = `0 5 ${this.pxToPercenteage((this.width || window.screen.availWidth) - clientX)}%`;
        this.leftElement.style.flex = `1 5 ${this.pxToPercenteage(clientX)}%`;
      } else {
        this.leftElement.style.flex = `0 5 ${this.pxToPercenteage(clientX)}%`;
        this.rightElement.style.flex = `1 5 ${this.pxToPercenteage((this.width || window.screen.availWidth) - clientX)}%`;
      }
    }
  }

  private pxToPercenteage(value: number): number {
    return value * 100 / this.width;
  }
}
