import { Country } from './country.model';
import { Location } from './location.model';

export class Address {
  city?: string;
  colony?: string;
  municipality?: string;
  postal_code?: string;
  state: string;
  street: string;

  country: Country;

  localidad: Location;

  constructor(data: Partial<Address> = {}) {
    this.country = null;

    Object.assign(this, data);
  }
}
