import { Market } from "../company/modules/commercial/models/market.model";

/**
 * The Activity of a [[Company]] defines what role it plays in the
 * supply-chain and determines the type of actions it can carry out in the
 * different modules of the platform.
 *
 * Activities are defined by [[Market]]. In other words, each [[Market]] has
 * its own list of Company Activities.
 *
 * ## Sample values
 * | id | market_id | name                   | slug                 | broker | buyer | seller |
 * |---:|----------:|------------------------|----------------------|:------:|:-----:|:------:|
 * |  1 |         2 | Farmer                 | farmer               |    0   |   0   |    1   |
 * |  2 |         2 | Copier / Consignee     | copier-consignee     |    0   |   1   |    1   |
 * |  3 |         2 | Exporter               | exporter             |    0   |   1   |    1   |
 * |  4 |         2 | Mill                   | mill                 |    0   |   1   |    1   |
 * |  5 |         2 | Feed / Industry        | feed-industry        |    0   |   1   |    0   |
 * |  6 |         2 | Broker                 | broker               |    1   |   1   |    1   |
 * |  7 |         2 | Farmer / Feed Industry | farmer-feed-industry |    0   |   1   |    1   |
 * | 37 |         2 | Input supplier         | input-supplier       |    0   |   0   |    0   |
 * | 38 |         2 | Input distributor      | input-distributor    |    0   |   0   |    0   |
 * | 40 |         2 | Insurance produce      | insurance-produce    |    0   |   0   |    0   |
 * | 41 |         2 | Institución financiera | futures-exchange     |    0   |   1   |    1   |
 * | 42 |         2 | Domestic Surveyor      | domestic-surveyor    |    0   |   0   |    0   |
 */
export class CompanyActivity {
  /** Agree's internal unique identifier. */
  readonly id: number;
  name: string;
  label?: string;
  /**
   * Determines if the [[Company]] can perform purchase operations.
   */
  buyer: boolean;
  /**
   * Determines if the [[Company]] can perform sales operations.
   */
  seller: boolean;
  /**
   * Determines if the [[Company]] can perform operations on behalf of (or
   * representing) another [[Company]].
   */
  broker: boolean;

  market: Market;

  /**
   * Deprecated: We should use `market.id` instead
   * @ignore
   */
  market_id: number;
}

