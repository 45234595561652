import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { FilterSetComponent } from '../filter-set/filter-set.component';

/**
 * TODO: This component requires refactoring.
 */
@Component({
  selector: 'filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.css']
})
export class FilterListComponent implements OnDestroy {

  @ViewChild('moreOptions', { static: true }) private readonly moreOptions: TemplateRef<any>;

  @Input() private sortKey: string;
  @Input() private key: string;
  /**
   * Maximum number of options to list.
   * The rest will be accessible through a modal.
   */
  @Input() public maxItems: number = 20;

  public hide: boolean = true;
  public options: any[];
  public title: string;

  private modalRef: BsModalRef;
  // private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  /** @ignore */
  constructor(
    private filterset: FilterSetComponent,
    private modalService: BsModalService
  ) {
    this.subscriptions.push(this.filterset.filters.subscribe(res => {

      this.options = this.filterset.filterList[this.key].options;
      if (typeof this.sortKey !== "undefined" && this.options && this.options.length &&
        typeof this.options[0][this.sortKey] !== "undefined") {
        // Sort alphabetically
        this.options.sort((a, b) => a[this.sortKey].toString().localeCompare(b[this.sortKey]));
      }

      this.title = this.filterset.filterList[this.key].label;

      this.hide = !res.find(element => element.key === this.key);
    }));

    this.filterset.countSuscriberChilds();
  }

  public selectFilter(id): void {
    this.closeModal();
    this.filterset.showFilters();
    this.filterset.addFilterData(this.key, id);
  }

  public showAllOptions(): void {
    this.openModal(this.moreOptions, 'modal-lg');
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    // this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
    //   this.modalSub.unsubscribe();
    //   this.modalRef = undefined;
    //   // Reset all values
    //   this.processing = false;
    // });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
