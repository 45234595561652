import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';

import { FileManagerFile } from '../../../models/file-manager-file.model';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';

@Component({
  selector: 'ag-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnDestroy {

  @ViewChild('previewModal', { static: true }) private readonly previewModal: TemplateRef<any>;

  public filename: string;
  public filesize: number;
  public filePreview: {
    viewer: 'office' | 'google' | 'image' | 'video' | 'mammoth' | 'pdf',
    // safeUrl?: SafeResourceUrl,
    url?: string
  };
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public observable: Observable<FileManagerFile>;
  public title: string;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(
    private modalService: BsModalService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  /** Try to preview the file located at the specified index in the browser. */
  public preview(observable: Observable<FileManagerFile>, filename: string = '', filesize?: number, url?: string, title?: string): void {
    this.filename = filename;
    this.filesize = filesize;
    this.title = title;

    if (observable) {
      this.filePreview = {
        viewer: 'google'
      };
      this.openModal(this.previewModal, 'modal-xl modal-lg');

      if (filename) {
        // (?:         # begin non-capturing group
        //   \.        #   a dot
        //   (         #   begin capturing group (captures the actual extension)
        //     [^.]+   #     anything except a dot, multiple times
        //   )         #   end capturing group
        // )?          # end non-capturing group, make it optional
        // $           # anchor to the end of the string
        const regx = /(?:\.([^.]+))?$/;
        const ext = regx.exec(filename)[1].toLocaleLowerCase();
        // office viewer support: .ppt, .pptx, .doc, .docx, .xls and .xlsx
        if (["ppt", "pptx", "doc", "docx", "xls", "xlsx"].includes(ext)) this.filePreview.viewer = 'office';
        if (["jpg", "jpeg", "png", "gif", "bmp", "svg"].includes(ext)) this.filePreview.viewer = 'image';
        if (["mov", "mp4", "webm", "ogg"].includes(ext)) this.filePreview.viewer = 'video';
        // if (["json"].includes(ext)) this.filePreview.viewer = 'json';
        // if (["pdf"].includes(ext)) this.filePreview.viewer = 'pdf';
      }

      if (url) {
        this.setData(observable, url);
      } else {
        this.processing = true;
        this.subscriptions.push(observable.subscribe({
          next: response => {
            if (response.url) {
              // this.filePreview.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.url);
              this.setData(observable, response.url);
            }

            this.processing = false;
          },
          error: error => {
            this.processing = false;
            this.dataDogLoggerService.warn(error.message, error.error);
          }
        }));
      }
    }
  }

  private setData(observable: Observable<any>, url: string): void {
    this.filePreview.url = url;
    this.observable = observable;
  }

  /**
   * Downloads the envelope's file based on [[EnvelopeFile.id|id]].
   */
  public download(): void {
    this.processing = true;

    // Safari blocks any call to window.open() which is made inside an async call.
    const windowReference = window.open();
    this.subscriptions.push(this.observable.subscribe({
      next: response => {
        if (response.url) {
          windowReference.location = response.url;
        } else {
          windowReference.close();
        }
        this.processing = false;
      },
      error: error => {
        windowReference.close();
        this.processing = false;
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.observable = undefined;
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
