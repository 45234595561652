import { Type } from "class-transformer";

import { User } from "../auth/models/user.model";
import { Company } from "../models/company.model";

export class TrackerEvent {
  @Type(() => Date)
  date: Date;

  companies?: Company[];
  reporter?: User;

  attachments?: {
    id: number,
    uri: string
  }[];
  locations?: {
    lat: number,
    lng: number
  }[];
  media?: {
    id: number,
    uri: string
  }[];
  batch: {
    in: string,
    out: string
  };

  description?: string;
  event: string;
  id: number;
  parents?: number[]; // Parents IDs
  table?: any[];
}
