<ng-template #modal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title" *ngIf="title">
      {{ title }}
    </h4>
  </div>
  <div class="modal-body">
    <button type="button"
    *ngIf="json"
    tooltip="{{ 'GLOBAL.DOWNLOAD'|translate }}"
    (click)="export()" [disabled]="processing" class="btn btn-link pull-right">
      <span class="material-symbols-rounded">save_alt</span>
    </button>
    
    <tabset *ngIf="json; else loading">
      <tab *ngFor="let section of sections"
      heading="{{ section.heading|translate }}"
      (selectTab)="navigateTo(section)"
      [active]="section.slug === selectedSection?.slug">
        <ng-container [ngSwitch]="selectedSection?.slug">
          <ng-container *ngSwitchCase="'table'">
            <ol class="breadcrumb" *ngIf="path.length > 1">
              <li *ngFor="let step of path; let stepIndex = index; let last = last"
              [class.active]="last">
                <a (click)="goto(stepIndex, step.title)" *ngIf="!last">{{ step.title }}</a>
                <span *ngIf="last">{{ step.title }}</span>
              </li>
            </ol>
    
            <ng-container *ngIf="path[path.length - 1]; let current">
              <ng-container *ngIf="current.value | isArray; else jsonObject">
                <ng-continer *ngFor="let item of current.value; let itemIndex = index">
                  <code class="number">{{ current.title + '[' + itemIndex + ']' }}</code>
                  <ng-container [ngSwitch]="item|isObject">
                    <ng-container *ngSwitchCase="true">
                      <ng-container *ngTemplateOutlet="jsonTable; context: {
                        source: item,
                        position: current.path + '[' + itemIndex + ']'
                      }"></ng-container>
                    </ng-container>
                    <p *ngSwitchDefault>
                      <ng-container *ngTemplateOutlet="showValue; context: {
                        value: item
                      }"></ng-container>
                    </p>
                  </ng-container>
                </ng-continer>
              </ng-container>
    
              <ng-template #jsonObject>
                <ng-container *ngTemplateOutlet="jsonTable; context: {
                  source: current.value,
                  position: current.path
                }"></ng-container>
              </ng-template>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'string'">
            <ng-container *ngIf="jsonString">
              <div class="flex-center-distributed">
                <div class="grow">
                  <form>
                    <div class="form-group">
                      <!-- <label for="filterKey"><span class="material-symbols-rounded">search</span>&nbsp;</label> -->
                      <input [(ngModel)]="filterKey" name="filterKey"
                      autocomplete="off"
                      type="text" class="form-control" id="filterKey" placeholder="{{ 'MESSENGER_CENTER.SEARCH_PLACEHOLDER'|translate }}">
                    </div>
                  </form>
                </div>
                <label
                class="btn btn-link btn-sm"
                title="{{ 'GLOBAL.COPY_CLIPBOARD'|translate }}"
                (click)="htmlClipboardService.copy('span#json')">
                  <!-- <span class="material-symbols-rounded">content_copy</span> -->
                  {{ 'GLOBAL.COPY_CLIPBOARD'|translate }}
                </label>
              </div>
      
              <span class="value"
              id="json"
              [innerHtml]="jsonString|highlighter:filterKey:true"></span>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'source'">
            <div class="well well-sm no-margin">
              <ngx-json-viewer [json]="json" [depth]="2"></ngx-json-viewer>
            </div>
          </ng-container>
        </ng-container>
      </tab>
    </tabset>
  </div>
</ng-template>

<ng-template #jsonTable
let-source="source"
let-position="position">
  <table class="table table-bordered table-hover no-margin">
    <thead>
      <tr>
        <th>{{ 'FINTECH.CREDIT.VARIABLE'|translate }}</th>
        <th>{{ 'FINTECH.CREDIT.VALUE'|translate }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let value of source|keyvalue: originalOrder">
        <tr class="tr-align-center">
          <th class="sap">{{ value.key | replace : '_' : ' ' }}</th>
          <td [class.text-right]="value.value | isNumber">
            <ng-container *ngTemplateOutlet="showValue; context: {
              key: value.key,
              value: value.value,
              position: position
            }"></ng-container>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-template>

<ng-template #showValue
let-value="value"
let-key="key"
let-position="position">
  <samp *ngIf="value | isNumber"
  [class.text-danger]="value < 0"
  [class.text-muted]="value === 0">{{ value | number:'1.0-2':currentLang }}</samp>
  <span *ngIf="value | isString">{{ value }}</span>
  <!-- <code *ngIf="value === true || value === false">{{ value }}</code> -->
  <span *ngIf="value === true || value === false"
  class="material-symbols-rounded"
  [class.text-success]="value"
  [class.text-danger]="!value">{{ value ? 'done' : 'close' }}</span>
  <var *ngIf="value === null || value === undefined"
  class="faded">{{ value | json }}</var>
  <a *ngIf="value | isArray" (click)="goto(position + '.' + key, key)"><span [innerHtml]="'GLOBAL.COLLECTION'|translate: { length: value.length }"></span>&nbsp;<span class="material-symbols-rounded">launch</span></a>
  <a *ngIf="(value | isObject) && !(value | isArray)" (click)="goto(position + '.' + key, key)"><span [innerHtml]="'GLOBAL.TABLE'|translate: { length: (value | keys).length }"></span>&nbsp;<span class="material-symbols-rounded">launch</span></a>
</ng-template>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>