import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { Price } from '../../models/price.model';
import { CmCodePipe } from './cm-code.pipe';

@Pipe({
  name: 'price',
  pure: false
})
export class PricePipe implements PipeTransform {

  /**
   * Transforms a Price object into a formatted string based on its type.
   *
   * @param {Price} price - The Price object to transform.
   * @param {boolean} [withoutYear=false] - Flag indicating whether to exclude the year in the formatted string.
   * @returns {string | null} - The formatted price string or null if the price type is not recognized.
   */
  transform(price: Price, withoutYear: boolean = false): string {
    // TODO: Provide globally

    if (price.type === 'flat') {
      const decimalPipe = new DecimalPipe('es-Ar');
      return price.unit.name + ' ' + decimalPipe.transform(price.value, '1.2-2');
    } else if (price.type === 'to_be_fixed' || price.type === 'consignment') {
      return price.value.toString();
    } else if (price.type === 'premiums') {
      const cmCodePipe = new CmCodePipe();
      let premiumPrice = (price.value >= 0 ? '+' : '') + price.value + ' ' + cmCodePipe.transform(price.month);
      if (!withoutYear) premiumPrice += ' ' + price.year;
      return premiumPrice;
    }

    return null;
  }
}
