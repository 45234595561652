import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
declare var $: any;

const REGEX_EMAIL = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectizeEmailsComponent),
  multi: true
};

@Component({
  selector: 'selectize-emails',
  exportAs: 'selectize-emails',
  templateUrl: './selectize-emails.component.html',
  styleUrls: ['./selectize-emails.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class SelectizeEmailsComponent implements OnInit {

  @Input() set disabled(v: boolean) {
    if (v !== this.isDisabled) {
      this.isDisabled = v;
      if (this.selectObject) {
        if (this.isDisabled) this.selectObject[0].selectize.disable();
        else this.selectObject[0].selectize.enable();
      }
    }
  }
  @Input() public maxItems: Number;

  public id: string;

  private selectObject: any;

  constructor() { }

  public isDisabled: boolean;
  get disabled(): boolean {
    return this.isDisabled;
  }

  ngOnInit(): void {
    this.id = 'slctzml_' + uuidv4();

    // el settimeout se utiliza como hack para que pueda instanciarse jquery en un elemento con nombre dinamico originado en angular
    setTimeout(() => {
      this.selectObject = $('#' + this.id).selectize({
        persist: false,
        maxItems: this.maxItems,
        addPrecedence: false,
        valueField: 'email',
        labelField: 'email',
        searchField: ['email'],
        options: [],
        render: {
          item: function (item, escape) {
            const string = '<div class="item"><span class="item-text">' + escape(item.email) + '</span></div>';

            return string;

          },
          option: function (item, escape) {
            const label = item.email;
            const caption = item.email;

            return '<div>' +
              '<span class="label">' + escape(label) + '</span>' +
              (caption ? '<span class="caption">' + escape(caption) + '</span>' : '') +
              '</div>';
          },
          option_create: function (data, escape) {
            return '<div class="create" style="padding:10px;">+ <strong>' + escape(data.input) + '</strong>&hellip;</div>';
          }
        },
        createFilter: function (input) {
          const match = input.match(REGEX_EMAIL);
          if (match) return !this.options.hasOwnProperty(match[0]);

          return false;
        },
        create: function (input) {
          if (REGEX_EMAIL.test(input)) {
            return { email: input.toLowerCase() };
          }

          //alert('Invalid email address.');
          return false;
        },
        onChange: values => this.setValues(values)
      });
    });
  }

  writeValue(value: any) { }

  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(): void { }

  setValues(values: any) {
    this.propagateChange(values);
  }
}

