import { Filter } from "../../models/filter.model";

const filterList = {};

filterList['commercial_zone'] = new Filter({
  key: 'commercial_zone',
  label: 'FILTERS.COMMERCIAL_ZONE'
});

filterList['market_id'] = new Filter({
  key: 'market_id',
  label: 'GLOBAL.MARKET',
  admin: true,
});

filterList['product_id'] = new Filter({
  key: 'product_id',
  label: 'GLOBAL.PRODUCT'
});

filterList['order_type'] = new Filter({
  key: 'order_type',
  label: 'FILTERS.ORDER_TYPE',
  options: [
    {
      'id': 4,
      'name': 'GLOBAL.AUCTION'
    },
    {
      'id': 1,
      'name': 'GLOBAL.ORDER'
    }
  ]
});

filterList['operation_type'] = new Filter({
  key: 'operation_type',
  label: 'FILTERS.OPERATION_TYPE',
  options: [
    {
      'id': 'compra',
      'name': 'GLOBAL.BID'
    },
    {
      'id': 'venta',
      'name': 'GLOBAL.SELL'
    }
  ]
});

filterList['zone_id'] = new Filter({
  key: 'operation_type',
  label: 'FILTERS.DELIVERY_ZONE'
});

filterList['zones_id'] = new Filter({
  key: 'operation_type',
  label: 'FILTERS.DELIVERY_ZONE',
  type: 'multiple'
});

filterList['envelope_status'] = new Filter({
  key: 'envelope_status',
  label: 'FILTERS.ENVELOPE_STATUS',
  options: [
    {
      'id': 10,
      'name': 'SIGNATURES.STATUS.NOT_SENT'
    },
    {
      'id': 20,
      'name': 'SIGNATURES.STATUS.PENDING'
    },
    {
      'id': 30,
      'name': 'SIGNATURES.STATUS.COMPLETED'
    },
    {
      'id': 40,
      'name': 'SIGNATURES.STATUS.CANCELED'
    }
  ]
});

filterList['status_group_orders'] = new Filter({
  key: 'status_group_orders',
  label: 'GLOBAL.STATUS',
  options: [
    {
      'id': '1',
      'name': 'FILTERS.OPEN'
    },
    {
      'id': '2',
      'name': 'FILTERS.CLOSED'
    }
  ]
});

filterList['status_barter_proposals'] = new Filter({
  key: 'status_barter_proposals', // name or key reference
  label: 'GLOBAL.STATUS',
  options: [
    {
      'id': '1',
      'name': 'FILTERS.OPEN_BARTER'
    },
    {
      'id': '2',
      'name': 'FILTERS.CLOSED_BARTER'
    }
  ]
});

filterList['delivery_range'] = new Filter({
  key: 'delivery_range',
  label: 'FILTERS.DELIVERY_RANGE',
  type: 'date_range'
  // options: [{
  //     'id': 'true',
  //     'name': 'FILTERS.DELIVERY_RANGE'
  // }],
});

filterList['fixation_period'] = new Filter({
  key: 'fixation_period',
  label: 'FILTERS.FIXATION_PERIOD',
  type: 'date_range'
  // options: [{
  //     'id': 'true',
  //     'name': 'FILTERS.FIXATION_PERIOD'
  // }]
});

filterList['date_issued'] = new Filter({
  key: 'date_issued',
  label: 'FILTERS.ASSIGNMENT_RANGE',
  type: 'date_range'
  // options: [{
  //     'id': 'true',
  //     'name': 'FILTERS.ASSIGNMENT_RANGE'
  // }]
});

filterList['payment_condition'] = new Filter({
  key: 'payment_condition',
  label: 'GLOBAL.PAYMENT_CONDITION',
  options: [
    {
      'id': 1,
      'name': 'FILTERS.CASH',
      'iconHtml': '<i class="image-icons cash-payment"></i>',
      'class': 'highlighted'
    }
  ]
});

filterList['validity'] = new Filter({
  key: 'validity',
  label: 'FILTERS.VALIDITY',
  options: [
    // {
    //   'id': 'on_date',
    //   'name': 'FILTERS.ON_DATE'
    // },
    {
      'id': 'valid',
      'name': 'FILTERS.VALID' //vigente
    },
    {
      'id': 'archived',
      'name': 'FILTERS.ARCHIVED'
    }
  ]
});

filterList['price'] = new Filter({
  key: 'price',
  label: 'FILTERS.PRICE',
});

filterList['fintech_status'] = new Filter({
  key: 'fintech_status',
  label: 'GLOBAL.STATUS',
  options: [
    {
      'id': 'active',
      'name': 'FILTERS.FINTECH.ACTIVE'
    },
    {
      'id': 'granted',
      'name': 'FILTERS.FINTECH.GRANTED'
    },
    {
      'id': 'rejected',
      'name': 'FILTERS.FINTECH.REJECTED'
    },
    {
      'id': 'withdrawed',
      'name': 'FILTERS.FINTECH.WITHDRAWN'
    },
    {
      'id': 'expired',
      'name': 'FILTERS.FINTECH.EXPIRED'
    }
  ]
});

filterList['status'] = new Filter({
  key: 'price',
  label: 'GLOBAL.STATUS',
  options: [
    {
      'id': 0,
      'name': 'FILTERS.FINTECH.ACTIVE'
    },
    {
      'id': 1,
      'name': 'FILTERS.FINTECH.REJECTED'
    },
    {
      'id': 2,
      'name': 'FILTERS.FINTECH.GRANTED'
    },
    // {
    //   'id': 3,
    //   'name': 'FILTERS.FINTECH.WITHDRAWN'
    // },
    {
      'id': 4,
      'name': 'FILTERS.FINTECH.EXPIRED'
    },
    {
      'id': 6,
      'name': 'FILTERS.FINTECH.WITHDRAWN'
    }
  ]
});

filterList['has_contract'] = new Filter({
  key: 'has_contract',
  label: 'GLOBAL.CONTRACT',
  options: [
    {
      'id': 'true',
      'name': 'FILTERS.WITH_CONTRACT'
    },
    {
      'id': 'false',
      'name': 'FILTERS.WITHOUT_CONTRACT'
    }
  ]
});

filterList['active'] = new Filter({
  key: 'active',
  label: 'GLOBAL.STATUS',
  admin: true,
  options: [
    {
      'id': 'true',
      'name': 'FILTERS.ACTIVE'
    },
    {
      'id': 'false',
      'name': 'FILTERS.INACTIVE'
    }
  ]
});

filterList['label_id'] = new Filter({
  key: 'label_id',
  label: "FILTERS.LABELS",
});

filterList['commercial_zone'] = new Filter({
  key: 'commercial_zone',
  label: "FILTERS.COMMERCIAL_ZONE",
});

filterList['activity_id'] = new Filter({
  key: 'activity_id',
  label: 'GLOBAL.MAIN_ACTIVITY',
});

filterList['sustainable'] = new Filter({
  key: 'sustainable',
  label: 'ORDER.PRODUCTION',
  options: [
    {
      'id': 'true',
      'name': 'ORDER_FORM.PRODUCT.SUSTAINABLE',
      'iconHtml': '<i class="glyphicon glyphicon-leaf"></i>',
      'class': 'highlighted'
    }
  ]
});

filterList['differences'] = new Filter({
  key: 'differences',
  label: 'CONTRACTS.DIFF.TITLE',
  options: [
    {
      'id': 'true',
      'name': 'CONTRACTS.DIFF.YES',
      'iconHtml': '<span class="material-symbols-rounded">swap_horizontal_circle</span>',
      'class': 'warning'
    },
    {
      'id': 'false',
      'name': 'CONTRACTS.DIFF.NO'
    }
  ]
});

filterList['warnings'] = new Filter({
  key: 'warnings',
  label: 'IMPORTER.WARNING.TITLE',
  options: [
    {
      'id': 'true',
      'name': 'IMPORTER.WARNING.YES',
      'iconHtml': '<span class="material-symbols-rounded">warning</span>',
      'class': 'warning'
    },
    {
      'id': 'false',
      'name': 'IMPORTER.WARNING.NO'
    }
  ]
});

filterList['created_at'] = new Filter({
  key: 'created_at',
  label: 'GLOBAL.DATE',
  type: 'date_range'
});

filterList['delivery_type'] = new Filter({
  key: 'delivery_type',
  label: 'GLOBAL.DELIVERY_TYPE'
});

filterList['range'] = new Filter({
  key: 'range',
  label: 'GLOBAL.PERIOD',
});

filterList['past_range'] = new Filter({
  key: 'past_range',
  label: 'GLOBAL.PERIOD',
});

filterList['crop'] = new Filter({
  key: 'crop',
  label: 'GLOBAL.CROP_YEAR',
});

filterList['page'] = new Filter({
  key: 'page',
  value: 1
});

filterList['unassigned'] = new Filter({
  key: 'unassigned',
  label: 'GLOBAL.STATE',
  options: [
    {
      'id': 'false',
      'name': 'SLOTS.TABLE_HEADER.ASSIGNED_SLOTS',
    },
    {
      'id': 'true',
      'name': 'SLOTS.TABLE_HEADER.PENDING_SLOTS'
    }
  ]
});

export default filterList;
