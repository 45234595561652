import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map } from 'rxjs';

import { ColumnFilter, ColumnFiltersService } from '../../services/column-filters.service';

@Component({
  selector: 'ag-selected-column-filters',
  templateUrl: './selected-column-filters.component.html',
  styleUrls: ['./selected-column-filters.component.scss']
})
export class SelectedColumnFiltersComponent {
  @Output() onChange = new EventEmitter();

  public selectedFilters: any[];

  constructor(
    public columnFiltersService: ColumnFiltersService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  get getAppliedFilters$(): Observable<any[]> {
    return this.columnFiltersService.columnFilters$
      .pipe(
        map(filters => {
          const queryParams = this.route.snapshot.queryParams;

          return filters.filter(filter => filter.key in queryParams);
        })
      )
  }

  public removeFilter(filter: ColumnFilter): void {
    if (!filter.canDelete) return;

    this.columnFiltersService.delete(filter);

    // Create a shallow copy to avoid mutation
    const queryParamsUpdated = { ...this.route.snapshot.queryParams };

    delete queryParamsUpdated[filter.key];

    this.router.navigate([], {
      queryParams: queryParamsUpdated,
      relativeTo: this.route
    });
  }
}
