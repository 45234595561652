import { Directive, ElementRef, Optional, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Directive({
  selector: '[modalFocus]'
})
export class ModalFocusDirective implements AfterViewInit, OnDestroy {

  @Input() set modalFocus(b: boolean) {
    if (typeof (b) === 'boolean') {
      this.enabled = b;
    }
  };

  private enabled: boolean = true;
  private subscriptions: Subscription[] = [];

  constructor(
    private element: ElementRef,
    @Optional() private modal: ModalDirective
  ) {
    if (modal && this.enabled) {
      // With ModalDirective
      this.subscriptions.push(modal.onShown.subscribe(() => {
        this.element.nativeElement.focus();
      }));
    }
  }

  ngAfterViewInit(): void {
    if (!this.modal && this.enabled) {
      setTimeout(() => {
        this.element.nativeElement.focus();
      });
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
