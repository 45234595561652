import { Directive, Input, HostListener } from '@angular/core';

import { AgSelectDirective } from './ag-select.directive';

@Directive({
  selector: '[agOption]'
})
export class AgOptionDirective {

  @Input('agOption') private value: any;

  constructor(
    private agSelect: AgSelectDirective
  ) { }

  @HostListener('click')
  select(): void {
    this.agSelect.value = this.value;
  }
}
