<ng-template #popTemplate>
  {{ label }}
  <div *ngIf="show_percentage">{{ (progress * 100)|number:'1.2-2' }}%</div>
</ng-template>
<div class="progress-circle" [tooltip]="popTemplate">
  <svg [attr.height]="size" [attr.width]="size" viewBox="-1 -1 21 21">
    <svg:circle class="faded" r="9.5" cx="10" cy="10" fill="transparent"
      *ngIf="progress > 0"
      stroke-width="1"
      [attr.stroke]="color" />
    <svg:circle r="9.5" cx="10" cy="10" fill="transparent"
      *ngIf="progress === 0"
      stroke-width="1"
      stroke="#a94442" />
    <svg:circle r="5" cx="10" cy="10" fill="transparent"
      stroke-width="10"
      [attr.stroke]="color"
      [attr.stroke-dasharray]="((progress * 100) * 31.4 / 100) + ' 31.4'"
      transform="rotate(-90) translate(-20)" />
    <path *ngIf="progress === 1" d="M4.5 10 L8.5 14 L15 6" stroke="white" stroke-width="2" fill="none" />
  </svg>
  <div *ngIf="value" class="small">
    {{ value|number:'1.0-2':currentLang }}
    <span *ngIf="unit" class="currency">{{ unit }}</span>
  </div>
</div>