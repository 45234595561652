import { Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';

import { environment } from '../../environments/environment';
import { LoginService } from '../auth/services/login.service';
import { ThemeService } from '../theme/services/theme.service';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root'
})
export class DataDogLoggerService {

  /** @ignore */
  constructor(
    private loginService: LoginService,
    private companyService: CompanyService,
    private themeService: ThemeService
  ) {
    if (environment.datadog?.CLIENT_TOKEN) {
      const { datadog } = environment;

      datadogLogs.init({
        clientToken: datadog.CLIENT_TOKEN,
        // site,
        service: datadog.service,
        env: environment.env,
        version: environment.version,
        forwardErrorsToLogs: true
      });

      this.loginService.getCurrentUser().subscribe(user => {
        const copy = { ...user };
        copy.accounts = user.accounts.filter(account => account.role_id < 10);

        datadogLogs.setGlobalContextProperty('user', copy);
      });

      this.companyService.watch().subscribe(company => {
        datadogLogs.setGlobalContextProperty('company', company);
      });
    }
  }

  /**
   * Logs a message with a given status to Datadog if the CLIENT_TOKEN is configured,
   * otherwise logs to the console using the appropriate method based on the status.
   *
   * @param {'debug' | 'info' | 'warn' | 'error'} status - The severity level of the log message.
   * @param {string} message - The message to be logged.
   * @param {any} error - The error object or additional data to log.
   */
  private call(status: 'debug' | 'info' | 'warn' | 'error', message: string, error: any): void {
    if (environment.datadog?.CLIENT_TOKEN) {
      datadogLogs.logger.log(message, undefined, status, error);
      return;
    }

    const logMessage = `${this.themeService.theme.title}\r\n${message}\r\n`;
    const logMethod = {
      error: console.error,
      warn: console.warn,
      debug: console.debug,
      info: console.info
    }[status] || console.info;

    logMethod(logMessage, error);
  }

  /** Log a custom error in dataDog */
  public error(message: string, error?: any): void {
    this.call('error', message, error);
  }

  public warn(message: string, error: any): void {
    this.call('warn', message, error);
  }

  public info(message: string, error: any): void {
    this.call('info', message, error);
  }

  public debug(message: string, error: any): void {
    this.call('debug', message, error);
  }
}