<div #defaultDrop>
  <ng-container *ngIf="documents.length || uploading || failed || isDragging; else placeholder">
    <h4>
      {{ 'ATTACHMENTS.HEADER'|translate }}
      <small>
        <ng-container *ngTemplateOutlet="attachButton"></ng-container>
      </small>
    </h4>
    <div class="files">
      <div *ngFor="let document of documents; let i = index" class="file" (click)="open(i)">
        <div class="img-wrapper"></div><!-- For file preview thumbnails -->
        <div class="overlay">
          <div>
            <span *ngIf="document.private" class="material-symbols-rounded text-muted pull-right"
            tooltip="{{ 'ATTACHMENTS.PRIVATE.PRIVATE'|translate }}">lock</span>
            <div class="filename clamp-2"
            title="{{ document.filename }}">{{ document.filename }}</div>
            <div class="flex-center-distributed">
              <ag-timestamp [date]="document.created_at"></ag-timestamp>
              <a class="icon-link"
              [class.disabled]="disabled || loading"
              clickStop
              tooltip="{{ 'ORDER_FORM.PREVIEW'|translate }}"
              (click)="preview(i)"><span class="material-symbols-rounded">remove_red_eye</span></a>
            </div>
          </div>
          <div class="filesize" *ngIf="document.size">
            <small class="text-muted">
              <!-- <span class="material-symbols-rounded hidden-print">save_alt</span> -->
              {{ document.size|filesize }}
            </small>
            <a *ngIf="!disabled && !loading"
            class="icon-link"
            clickStop
            title="{{ 'ATTACHMENTS.TITLE'|translate }}"
            (onConfirm)="delete(i)"
            [confirm-options]="{
              title: 'ATTACHMENTS.MODAL.TITLE'|translate,
              action: 'GLOBAL.YES_DELETE'|translate
            }"><span class="material-symbols-rounded">clear</span></a>
          </div>
        </div>
      </div>
      <div *ngFor="let document of uploading; let i = index" class="file uploading hidden-print">
        <div class="img-wrapper"></div><!-- For file preview thumbnails -->
        <div class="overlay">
          <div class="filename clamp-2 text-muted">{{ document.name }}</div>
          <div class="filesize" *ngIf="document.size">
            <small class="text-muted">
              <!-- <span class="material-symbols-rounded hidden-print">save_alt</span> -->
              {{ document.size|filesize }}
            </small>
            <bouncer [opacity]="1"></bouncer>
          </div>
        </div>
      </div>
      <div *ngFor="let document of failed; let i = index" class="file failed hidden-print">
        <div class="img-wrapper"></div><!-- For file preview thumbnails -->
        <div class="overlay">
          <div class="filename clamp-2 text-muted">{{ document.name }}</div>
          <div class="flex-center-distributed text-danger">
            <span>{{ 'ATTACHMENTS.ERROR'|translate }}</span>
            <span class="material-symbols-rounded">error</span>
          </div>
          <div class="filesize" *ngIf="document.size">
            <small class="text-muted">
              <!-- <span class="material-symbols-rounded hidden-print">save_alt</span> -->
              {{ document.size|filesize }}
            </small>
          </div>
        </div>
      </div>
      <div class="file drop fadeIn" *ngIf="isDragging && !disabled && !loading">
        <div class="img-wrapper"></div><!-- For file preview thumbnails -->
        <div class="overlay">{{ 'MESSENGER.DROP_HERE'|translate }}</div>
      </div>
    </div>
  </ng-container>
  <ng-template #placeholder>
    <p class="small text-muted hidden-print" *ngIf="!disabled">
      <ng-container *ngTemplateOutlet="attachButton"></ng-container>
      <!-- <span class="material-symbols-rounded">cloud_upload</span> -->
      {{ 'ATTACHMENTS.PLACEHOLDER'|translate }}
    </p>
  </ng-template>
</div>

<ng-template #privateModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" (click)="closeModal()" aria-label="Close"
    [disabled]="disabled">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'ATTACHMENTS.PRIVATE.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-xs-6 text-center"><p [innerHtml]="'ATTACHMENTS.PRIVATE.PRIVATE_HELP'|translate"></p></div>
      <div class="col-xs-6 text-center"><p [innerHtml]="'ATTACHMENTS.PRIVATE.PUBLIC_HELP'|translate"></p></div>
    </div>
    <div class="row">
      <div class="col-xs-6 biggest text-center"><p class="material-symbols-rounded text-muted">arrow_downward</p></div>
      <div class="col-xs-6 biggest text-center"><p class="material-symbols-rounded text-muted">arrow_downward</p></div>
    </div>
    <div class="row">
      <div class="col-xs-6">        
        <button type="button" class="btn btn-default btn-block"
        (click)="upload(true)">
          <span class="material-symbols-rounded">lock</span> {{ 'ATTACHMENTS.PRIVATE.PRIVATE'|translate }}
        </button>
      </div>
      <div class="col-xs-6">        
        <button type="button" class="btn btn-agree btn-block"
        (click)="upload(false)">{{ 'ATTACHMENTS.PRIVATE.PUBLIC'|translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #attachButton>
  <span class="attach-icon" *ngIf="!disabled">
    <input type="file" name="attach" id="{{ UUID }}" (change)="attach = $event.target.files" [accept]="accept" multiple="true">
    <label class="btn-link no-margin" for="{{ UUID }}" title="{{ 'MESSENGER.ATTACH'|translate }}"><i class="material-symbols-rounded">attach_file</i></label>
  </span>
</ng-template>

<ag-file-preview #filePreviewer></ag-file-preview>