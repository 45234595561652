import { Barter } from '../../../../models/barter.model';
import { forwardRef } from '@angular/core';
import { Proposal } from './proposal.model';
import { Company } from '../../../../models/company.model';
import { Product } from '../../../../models/product.model';
import { Negotiation } from './negotiation.model';
import { AuctionData } from '../../../../models/auctionData.model';
import { Type } from "class-transformer";
import { Media } from '../../../../models/media.model';
import { Label } from '../../../../models/label.model';

/**
 * An Order is a [[Company|Company's]] buying or selling intention of an
 * specific [[Product]], this can be a publication, an auction, a
 * [[Barter|barter]] or even a template.
 *
 * ### Related UI components:
 * - [[ViewOrderComponent|app-view-order]]
 * - [[TradingOrdersComponent|app-trading-orders]]
 * - [[PreordersComponent|app-preorders]]
 */
export class Order extends Proposal {
  /** Agree's internal unique identifier. */
  id: number;
  // account_id: number;

  @Type(forwardRef(() => Company) as any)
  broker_company: Company;

  @Type(forwardRef(() => Product) as any)
  product: Product;

  /**
   * ## Possible values
   * | id | name           | status_group |
   * |---:|----------------|-------------:|
   * |  1 | Open           |            1 |
   * |  2 | Pending review |            1 |
   * |  3 | Rejected       |            2 |
   * |  4 | Withdrawn      |            2 |
   * |  5 | Published      |            2 |
   * |  6 | Canceled       |            2 |
   * |  7 | Closed         |            2 |
   */
  order_status: {
    id: number,
    name: string,
    group: {
      /**
       * ### Possible values
       * | id | name   |
       * |---:|--------|
       * |  1 | Open   |
       * |  2 | Closed |
       */
      id: number
    }
  };

  /**
   * When the Order will be closed by system.
   */
  @Type(() => Date)
  expiration_datetime: Date;

  /**
   * Owner [[Company]].
   */
  @Type(forwardRef(() => Company) as any)
  company: Company;

  /**
   * Deprecated
   * @ignore
   */
  company_id: number;

  /**
   * ## Possible values
   * | id | name                   | slug                   |
   * |---:|------------------------|------------------------|
   * |  1 | Order                  | order                  |
   * |  2 | Pre-order              | pre_order              |
   * |  3 | Template               | template               |
   * |  4 | Auction                | auction_order          |
   * |  5 | Auction Pre-order      | auction_pre_order      |
   * |  6 | Order Barter Proposal  | order_barter_proposal  |
   * |  7 | Order Barter           | order_barter           |
   * |  8 | Certificate of Deposit | certificate_of_deposit |
   */
  type: {
    id: number,
    name: string,
    slug?: string
  };

  /**
   * If the Order is a template, this is the title.
   */
  template: {
    title: string;
  }

  barter?: Barter;

  /**
   * There are two possible values: `compra` (bid) y `venta` (offer).
   */
  operation_type: 'compra' | 'venta';

  /**
   * The scope determines the visibility of an [[Order]] with respect to the
   * rest of the [[Market]].
   *
   * | slug    | Description                                                                                      |
   * |---------|--------------------------------------------------------------------------------------------------|
   * | abierto | Visible to all Companies with the 'market' module enabled. Requires the 'market' module enabled. |
   * | red     | Visible to all the Companies in my network. Requires the 'my-network' module enabled.            |
   * | privado | Visible only to the specified Companies. Requires market setup for private orders.               |
   * | offline | Visible only to the specified Company. Requires market setup for offline orders.                 |
   */
  scope: 'abierto' | 'red' | 'privado' | 'offline';

  /**
   * The Order is paused, for example, when editing. 1 for true.
   */
  on_hold: number;
  has_contract_change: boolean;
  // document_language:string;
  product_clauses: any;

  @Type(() => Company)
  represented: Company[];

  /**
   * All the [[Negotiation|Negotiations]] arising from this Order.
   */
  @Type(forwardRef(() => Negotiation) as any)
  negotiations: Negotiation[];

  /** Deprecated */
  // pre_orders:Order[];
  parent_order: Order;

  @Type(() => AuctionData)
  auction: AuctionData;

  /**
   * For UI purposes (TODO: remove)
   * @ignore */
  props: {
    name: string,
    value: string,
    class?: string
  }[] = [];

  media: Array<Media> = [];
  new_media: FileList;

  negotiability = {
    payment_detail: {},
    product_detail: {},
    business_detail: {
      price: false,
      delivery: {},
      quantity: false
    },
    contract_detail: {},
    general_observations: false
  };

  constructor(
  ) {
    super();

    this.negotiations = [];
  }

  /** Used for sorting purposes. */
  get companyName(): string {
    if (this.company) {
      return this.company.name;
    } else {
      return ' ';
    }
  }

  get totalBooked(): number {
    return (this.negotiations || []).reduce((total, negotiation) => {
      return total + (negotiation.status.id === 7 ? negotiation.proposal.business_detail.quantity.value : 0);
    }, 0);
  }

  /** Used for sorting purposes. */
  get priority(): number {
    let priority = 0;

    if (this.order_status && this.order_status.id === 1 && this.on_hold === 0) {
      priority += 200;
    }

    if (this.on_hold === 1) {
      priority += 100;
    }

    // TODO: priorizar las negociaciones pendientes para la compañia actual
    if (this.negotiations) {

      this.negotiations.forEach(negotiation => {
        let active =
          negotiation.status.id === 1 ||
          negotiation.status.id === 2 ||
          negotiation.status.id === 3;

        if (active) {
          priority += 10;
        }
      });
    }
    return priority;
  }

  /** For labeleable entities. */
  get entity(): string { return 'order'; }
  readonly labels?: Label[];
}

export class OrderCreation extends Order {
  private_companies: Company[];
  represented_counterpart?: Company[];
}

