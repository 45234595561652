<form id="batch-finder" #form="ngForm" (ngSubmit)="form.valid">
  <div *ngIf="batch?.level === 4; else selector">
    <h3>
      <button (click)="batch = undefined" type="button" class="btn btn-link">
        <span class="glyphicon glyphicon-menu-left"></span>
      </button>
      {{ 'TRACKER.BATCH_ID'|translate }}: <code>{{ batch.name }}</code>
    </h3>
  </div>
  <ng-template #selector>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label class="control-label">Buscador de Lotes</label>
          <selectize *ngIf="!batch" [maxItems]="1" [options]="batches" [(ngModel)]="batch" name="batches"
            placeholder="Ingrese el código de Lote" [disabled]="processing">
          </selectize><!-- No actualiza el ngModel -->
          <div *ngIf="batch" class="editable-pill">
            <button type="button" class="close"
            [disabled]="processing" (click)="batch = undefined"><span aria-hidden="true">&times;</span></button>
            Lote seleccionado: <code>{{ batch.name }}</code>
            <div><company-info [company]="batch.company"></company-info></div>
          </div>
          <span *ngIf="!batch" class="help-block">Puede buscar por código de lote en barcos, silos, puertos, campos.</span>
          <!-- <error-list></error-list> -->
        </div>
      </div>
    </div>
    <div *ngIf="related_batches">
      <p>Se encontraron <b>{{ related_batches.length }}</b> lotes relacionados. Seleccione uno:</p>
      <table class="table table-hover table-condensed" sortable defaultSortBy="name" #tableSortable="tableSortable">
        <thead>
          <tr>
            <th sortBy="date">Fecha</th>
            <th sortBy="name">Reference</th>
            <th sortBy="company.name">Productor</th>
            <th sortBy="product.name">Producto</th>
            <th sortBy="crop">Crop</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let rb of related_batches|orderBy: tableSortable.sortBy;" class="clickable"
            (click)="batch = rb"
            class="tr-align-center">
            <td class="small"><date-md [date]="rb.date"></date-md></td>
            <td><code>{{ rb.name }}</code></td>
            <td><company-info [company]="rb.company"></company-info></td>
            <td>{{ rb.product.name }}</td>
            <td>{{ rb.crop }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>
</form>