import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlighter'
})
export class HighlighterPipe implements PipeTransform {

  constructor(
    private domSanitizer: DomSanitizer
  ) { }

  /**
   * Transforms a string by highlighting occurrences of a filter string.
   * Optionally, only the lines containing the filter string are returned.
   * 
   * @param {string} value - The input string to transform.
   * @param {string} filter - The string to highlight in the input string.
   * @param {boolean} linesFilteredOnly - If true, only lines containing the filter string are returned.
   * @returns {SafeHtml} - The transformed string as safe HTML.
   */
  transform(value: string, filter: string, linesFilteredOnly: boolean): SafeHtml {
    if (!filter || filter === '') return value;

    const re = new RegExp(filter, 'igm');
    const replaced = (value + '').replace(re, '<span class="highlighted-text">$&</span>');

    if (linesFilteredOnly) {
      let filteredLines = replaced.split('\n').filter(line => {
        return line.includes('<span class="highlighted-text">');
      }).join('\n');

      return this.domSanitizer.bypassSecurityTrustHtml(filteredLines);
    } else {
      return this.domSanitizer.bypassSecurityTrustHtml(replaced);
    }
  }
}
