import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Order } from '../../../company/modules/commercial/models/order.model';
import { BarterProposal } from '../../../models/barter-proposal.model';

@Component({
  selector: 'scope-icon',
  templateUrl: './scope-icon.component.html',
  styleUrls: ['./scope-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScopeIconComponent {

  @Input() public entity: Order | BarterProposal;

  constructor() { }

}
