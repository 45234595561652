import { Directive, OnInit, Input } from '@angular/core';
import { Validator, AbstractControl, ValidatorFn, NG_VALIDATORS } from '@angular/forms';
import { validateCuit } from '../../models/fiscal-id.model';

/**
 * Validates that the field value is a correct Argentine fiscal identifier.
 * 
 * ## Usage
 * ``` html
 * <input type="text" name="fiscalid"
 * [validCuit]="true">
 * ```
 * 
 * ### Related UI components:
 * - [[RegisterCompanyComponent]]
 * - [[NetworkComponent]]
 */
@Directive({
  selector: '[validCuit]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidCuitDirective, multi: true }]
})
export class ValidCuitDirective implements OnInit, Validator {

  @Input('validCuit') private condition: boolean;

  private validatorFn: ValidatorFn;

  /** @ignore */
  constructor() { }

  /** @ignore */
  ngOnInit(): void {
    this.validatorFn = this.validator(this.condition);
  }

  /** @ignore */
  validate(control: AbstractControl): {} {
    this.validatorFn = this.validator(this.condition);
    return this.validatorFn(control);
  }

  private validator(condition: boolean): ValidatorFn {
    return (control: AbstractControl): {} => {
      const cuit: string = control.value;
      if (!condition || !cuit) return true;

      return validateCuit(cuit) ? null : {
        "validFiscalId": false
      };
    }
  }
}
