import { Component, ContentChild, Input, TemplateRef, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiSelectComponent),
  multi: true
};

@Component({
  selector: 'multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class MultiSelectComponent {

  @Input() public source: any[];
  @Input() private withKey: (a: any) => any;

  @ContentChild(TemplateRef, { static: true }) itemTemplate: TemplateRef<any>;

  public keyMap: any = new Map();

  private innerValue: any[];

  constructor() { }

  get value(): any[] {
    return this.innerValue;
  };

  private onTouchedCallback: () => void = () => { };
  private onChangeCallback: (_: any) => void = () => { };

  set value(v: any[]) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  onBlur(): void {
    this.onTouchedCallback();
  }

  writeValue(value: any[]) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.updateSelection();
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  updateSelection(): void {
    this.keyMap = new Map();
    if (this.value) {
      this.value.forEach(item => {
        this.keyMap[this.getKey(item)] = true;
      });
    }
  };

  optionChange(): void {
    const selection = [];
    if (this.source) {
      this.source.forEach(item => {
        if (this.keyMap[this.getKey(item)]) {
          selection.push(item);
        }
      });
    }
    this.value = selection;
  }

  getKey(o: any) {
    if (this.withKey) {
      return this.withKey(o);
    } else {
      return o;
    }
  }
}
