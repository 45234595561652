<ng-container *ngIf="envelope">
  <p *ngIf="envelope.message" [innerHtml]="envelope.message|lineBreak"></p>
  <table-detail [header]="'SIGNATURES.SIGNERS'|translate"
  *ngIf="envelope.signers?.length">
    <table class="table-signatures">
      <tbody>
        <tr *ngFor="let signer of envelope.signers" class="tr-align-center">
          <td>
            <div><b [class.highlighted-text]="user?.email === signer.email">{{ signer.name }} {{ signer.last_name }}</b></div>
            <samp class="text-muted micro"
              *ngIf="signer.fiscal_id">{{ signer.fiscal_id.mask ? (signer.fiscal_id.value| mask: signer.fiscal_id.mask) : signer.fiscal_id.value }}</samp>
          </td>
          <td class="small text-muted">{{ signer.email }}</td>
          <td class="small text-muted hidden-xs">{{ signer.phone }}</td>
          <td class="sap">
            <button (click)="getSignerFile(signer)"
            type="button"
            *ngIf="signer.id && signer.file"
            [disabled]="processing"
            class="btn btn-link btn-sm"
            title="{{ 'SIGNATURES.SIGNER_AUTHORIZATION'|translate }}">
              <i class="material-symbols-rounded">save_alt</i>
              <span class="hidden-xs">&nbsp;{{ 'SIGNATURES.SIGNER_AUTHORIZATION_VIEW'|translate }}</span>
            </button>
          </td>
          <ng-container *ngIf="envelope.status.id !== 10">
            <td class="text-center sap">
              <span *ngIf="signer_status[signer.id].status === 2" class="text-success material-symbols-rounded"
              title="{{ 'SIGNATURES.SIGNED'|translate }}">done</span>
              <span *ngIf="signer_status[signer.id].status === 1" class="text-muted material-symbols-rounded live" title="{{ 'SIGNATURES.PARTIALLY_SIGNED'|translate }}">priority_high</span>
            </td>
            <td class="sap">
              <ag-timestamp *ngIf="signer_status[signer.id].status === 2; else signPending" [date]="signer_status[signer.id].date"></ag-timestamp>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </table-detail>
  
  <table-detail [header]="'SIGNATURES.DOCUMENTS'|translate"
  *ngIf="envelope.files?.length; else envelopeError">
    <table class="table-signatures" sortable #docsTable="tableSortable">
      <tbody>
        <tr *ngFor="let file of envelope.files | orderBy: docsTable.sortBy; let i = index" class="tr-align-center">
          <td>
            <span class="micro text-muted">{{ i + 1 }}.</span>&nbsp;
            <samp class="small" title="{{ 'ORDER_FORM.PREVIEW'|translate }}"><a (click)="filePreviewer.preview(getObservable(file.id), file.name)" [class.disabled]="processing">{{ file.name }}</a></samp>
          </td>
          <ng-container *ngIf="envelope.signers?.length">
            <td class="text-center sap">
              <span *ngIf="file.signatures?.length === envelope.signers.length" class="text-success material-symbols-rounded"
              title="{{ 'SIGNATURES.SIGNED'|translate }}">done</span>
              <span *ngIf="file.signatures?.length > 0 && file.signatures?.length !== envelope.signers.length" class="text-muted material-symbols-rounded live" title="{{ 'SIGNATURES.PARTIALLY_SIGNED'|translate }}">priority_high</span>
            </td>
            <ng-container *ngIf="envelope.status.id !== 10">
              <td>
                <div class="pg"
                [title]="(((file.signatures?.length || 0) * 100) / envelope.signers.length) + '%'">
                  <div class="pg-bar"
                  [style.width]="(((file.signatures?.length || 0) * 100) / envelope.signers.length) + '%'"></div>
                </div>
              </td>
              <td class="sap text-right micro text-muted"
              [tooltip]="'SIGNATURES.SIGNS_PENDING'|translate: {quantity: envelope.signers.length - (file.signatures?.length || 0)}"
              container="body"
              placement="left">{{ file.signatures?.length || 0 }}/{{ envelope.signers.length }}</td>
            </ng-container>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </table-detail>
</ng-container>

<!-- <ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template> -->
<ng-template #envelopeError>
  <span class="text-danger" [innerHtml]="'IMPORTER.ERROR'|translate"></span>
</ng-template>
<ng-template #signPending>
  <span class="text-muted small"
  *ngIf="envelope.status.id !== 40">{{ 'SIGNATURES.NOT_SIGNED'|translate }}</span>
</ng-template>

<ag-file-preview #filePreviewer></ag-file-preview>