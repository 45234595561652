import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'bouncer',
  templateUrl: './bouncer.component.html',
  styleUrls: ['./bouncer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BouncerComponent {

  @Input() public size: number = 8;
  @Input() public opacity: number = .6;
  @Input() public dark: boolean;

  constructor() { }

}
