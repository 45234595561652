import { instanceToPlain } from "class-transformer";

/**
 * Constructs a FormData object from the given object.
 * Special handling is done for 'new_media', 'image', and 'files' attributes to prevent them from being destroyed 
 * when passed through instanceToPlain().
 *
 * @param {Object} object - The object to be converted to FormData.
 * @returns {FormData} - The constructed FormData object.
 */
export function constructFormData(object: any): FormData {
  let data = instanceToPlain(object);
  let new_media, image, files;

  // se hace un workaround con el atributo media, new_media y files para que no se destruyan los archivos al pasar el objeto por instanceToPlain()
  if (object.new_media) {
    new_media = object.new_media;
    data = instanceToPlain(object);
    data['new_media'] = new_media;
  }
  if (object.image) {
    image = object.image;
    data = instanceToPlain(object);
    data['image'] = image;
  }
  if (object.files) {
    files = object.files;
    data = instanceToPlain(object);
    data['files'] = files;
  }

  let form_data = new FormData(), json = {};
  Object.keys(data).reduce((object, key) => {
    if (object[key] instanceof FileList) {
      for (let i = 0; i < object[key].length; i++) {
        form_data.append(key + '[]', object[key][i]);
      }
    } else {
      json[key] = object[key];
    }
    return object;
  }, data);

  form_data.append('json', JSON.stringify(json));

  return form_data;
}