import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ColumnFilter {
  key: string;
  label?: string;
  canDelete?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ColumnFiltersService {
  private _columnFilters: BehaviorSubject<ColumnFilter[]> = new BehaviorSubject<ColumnFilter[]>([]);
  public columnFilters$: Observable<ColumnFilter[]> = this._columnFilters.asObservable();

  constructor() {}

  public add(filter: ColumnFilter): void {
    const currentFilters = this._columnFilters.value;
    currentFilters.push(filter);
    this._columnFilters.next(currentFilters);
  }

  public delete(filterToDelete: ColumnFilter): void {
    const currentFilters = this._columnFilters.value;
    const updatedFilters = currentFilters.filter((filter) => filter.key !== filterToDelete.key);
    this._columnFilters.next(updatedFilters); 
  }
}

