import { Directive, OnInit, Input } from '@angular/core';
import { Validator, AbstractControl, ValidatorFn, NG_VALIDATORS } from '@angular/forms';

export function RangeValueValidator(range: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let hours = (range || '').split("-");
    let startTime = hours[0].split(":");
    let endTime = hours[1].split(":");
    let valueTime = (control.value || '').split(":");

    let valueDate = new Date();
    valueDate.setHours(parseInt(valueTime[0]), parseInt(valueTime[1]));

    let startDate = new Date();
    startDate.setHours(parseInt(startTime[0]), parseInt(startTime[1]));

    let endDate = new Date();
    endDate.setHours(parseInt(endTime[0]), parseInt(endTime[1]));

    let isValid = valueDate >= startDate && valueDate <= endDate;

    return isValid ? null : {
      'timeRange': {
        range: range
      }
    }
  }
}

@Directive({
  selector: '[timeRange]',
  providers: [{ provide: NG_VALIDATORS, useExisting: TimeRangeValueDirective, multi: true }]
})
export class TimeRangeValueDirective implements OnInit, Validator {

  @Input('timeRange') private range: string;

  private validatorFn;

  constructor() { }

  ngOnInit(): void {
    this.validatorFn = RangeValueValidator(this.range);
  }

  validate(control: AbstractControl): { [key: string]: any } {
    this.validatorFn = RangeValueValidator(this.range);
    return this.validatorFn(control);
  }
}
