import { Directive, Input, HostBinding } from '@angular/core';
import { Validator, AbstractControl, Validators, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[min]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinDirective, multi: true }]
})
export class MinDirective implements Validator {

  @HostBinding('min')
  @Input() private min: number;

  constructor() { }

  validate(control: AbstractControl): { [key: string]: any } {
    return this.min != null ? Validators.min(this.min)(control) : null;
  }
}
