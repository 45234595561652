import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Show a price with it's currency in a standardized way.
 * 
 * ## Usage
 * ``` html
 * <price
 * [unit]="invoice.currency.name"
 * [value]="invoice.amount"></price>
 * ```
 */
@Component({
  selector: 'price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceComponent implements OnInit {

  /** The price currency. */
  @Input() public unit: string;
  @Input() set value(v: number) {
    // Warning: isNaN(null) == false
    this.isNaN = typeof v !== 'number';
    this._value = v;

    if (!this.isNaN) {
      // Math.floor do not works with negative numbers
      const fixed: string[] = v.toFixed(2).split('.');
      this.integer = parseInt(fixed[0]);
      this.decimals = fixed[1];
    }
  }
  get value(): number {
    return this._value;
  }

  /** Whether the [[value]] is NaN or not. */
  public isNaN: boolean;
  /** The language currently used. */
  public currentLang: string;
  /** The integer portion of the [[value]]. */
  public integer: number;
  /** The two decimal places of the [[value]], for example: "00". */
  public decimals: string;

  private _value: number;

  /** @ignore */
  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;
  }
}
