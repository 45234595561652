import { forwardRef } from '@angular/core';
import { Type } from "class-transformer";

import { PrimitiveUnit, Unit } from './unit.model';

export class Quantity {

  @Type(forwardRef(() => Unit) as any)
  unit: Unit;

  /**
   * ### Possible values
   * | id | slug    |
   * |---:|---------|
   * |  1 | fixed   |
   * |  2 | maximum |
   */
  type: PrimitiveUnit;
  value: number;

  /** Used for sorting purposes */
  get valueString(): string {
    return (this.value) ? this.value.toString() : ' ';
  }

  constructor(data: Partial<Quantity> = {}) {
    this.unit = new Unit();
    this.type = new PrimitiveUnit({
      name: '',
      id: 1,
      slug: 'fixed'
    });
    Object.assign(this, data);
  }
}
