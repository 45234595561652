<ng-container *ngIf="!modelGroup">
  <ng-container *ngFor="let model of models">
    <div class="elist">
      <div *ngIf="model.errors?.pattern">{{ 'ERROR_LIST.PATTERN'|translate }}</div>
      <div *ngIf="model.errors?.min">{{ 'ERROR_LIST.MIN'|translate: { min: model.errors.min.min } }}</div>
      <div *ngIf="model.errors?.max">{{ 'ERROR_LIST.MAX'|translate: { max: model.errors.max.max } }}</div>
      <div *ngIf="model.errors?.required">{{ 'ERROR_LIST.REQUIRED'|translate }}</div>
      <div *ngIf="model.errors?.email">{{ 'ERROR_LIST.EMAIL'|translate }}</div>
      <div *ngIf="model.errors?.minlength">{{ 'ERROR_LIST.MIN_LENGTH'|translate: { min: model.errors.minlength.requiredLength } }}</div>
      <div *ngIf="model.errors?.maxlength">{{ 'ERROR_LIST.MAX_LENGTH'|translate: { max: model.errors.maxlength.requiredLength } }}</div>
      <div *ngIf="model.errors?.unique">{{ 'ERROR_LIST.UNIQUE'|translate }}</div>
      <div *ngIf="model.errors?.fileMaxSize">{{ 'ERROR_LIST.FILE_MAX_SIZE'|translate: { max: model.errors.fileMaxSize.max/1024/1024 } }} MB</div>
      <div *ngIf="model.errors?.maxFiles != null">{{ 'ERROR_LIST.MAX_FILES'|translate: { max: model.errors.maxFiles } }}</div>
      <div *ngIf="model.errors?.fileType">{{ 'ERROR_LIST.FILE_TYPE'|translate }} {{ model.errors.fileType.types }}</div>
      <div *ngIf="model.errors?.timeRange">{{ 'ERROR_LIST.TIME_RANGE'|translate }} {{ model.errors.timeRange.range }}</div>
      <div *ngIf="model.errors?.futureTime != null">{{ 'ERROR_LIST.FUTURE_TIME'|translate }}</div>
      <div *ngIf="model.errors?.validFiscalId != null">{{ 'ERROR_LIST.VALID_CUIT'|translate }}</div>
      <div *ngIf="model.errors?.validIdentityId != null">{{ 'ERROR_LIST.VALID_ID'|translate }}</div>
      <div *ngIf="model.errors?.companyExists != null" [innerHtml]="'ERROR_LIST.COMPANY_EXISTS'|translate"></div>
      <div *ngIf="model.errors?.bsDate">{{ 'ERROR_LIST.BSDATE'|translate }}</div>
      <div *ngIf="model.errors?.companyNotActive">{{ 'ERROR_LIST.COMPANY_NOT_ACTIVE'|translate }}</div>
      <div *ngIf="model.errors?.waybillExists">{{ 'ERROR_LIST.WAYBILL_EXISTS'|translate }}</div>
      <div *ngIf="model.errors?.exists">{{ 'ERROR_LIST.EMAIL_OT_FOUND'|translate }}</div>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="modelGroup">
  <div class="elist">
    <div *ngIf="modelGroup.errors?.matches">{{ 'ERROR_LIST.MATCHES'|translate }}</div>
  </div>
</ng-container>
