<div class="text-uppercase timestamp"
*ngIf="!inline; else inlineText"
title="{{ date|date: 'EEEE':undefined:currentLang }}">
  <div class="hidden-xs">{{ date|date: ('GLOBAL.DATE_FORMAT.MD'|translate):undefined:currentLang }}</div>
  <div class="visible-xs">
    {{ date|date: ('GLOBAL.DATE_FORMAT.XS'|translate):undefined:currentLang }}<br>
    {{ date|date: 'yyyy' }}
  </div>
  <div class="text-muted time" *ngIf="showTime">
    {{ date|date: 'HH:mm' }}<ng-container *ngIf="showSeconds">.{{ date|date: 'ss' }}</ng-container>
  </div>
</div>

<ng-template #inlineText>
  <span class="text-uppercase" title="{{ date|date: 'EEEE':undefined:currentLang }}">
    {{ date|date: ('GLOBAL.DATE_FORMAT.MD'|translate):undefined:currentLang }}
    <ng-container *ngIf="showTime">
      - {{ date|date: 'HH:mm' }}
      <ng-container *ngIf="showSeconds">.{{ date|date: 'ss' }}</ng-container>
    </ng-container>
  </span>
</ng-template>