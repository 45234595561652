<button type="button" class="btn btn-link"
(click)="open()"
[disabled]="processing">
  <span class="material-symbols-rounded">auto_fix_high</span>
  <span class="hidden-xs">{{ 'FORM_REUSE.TITLE'|translate }}</span>
</button>

<ng-template #modal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'FORM_REUSE.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="!loading; else showLoading">
      <ng-container  *ngIf="lastSubmittedData; else noData">
        <p class="text-muted">{{ 'FORM_REUSE.HELP'|translate }}</p>
        
        <div class="row">
          <form id="reuse-form" #reuseForm="ngForm" (ngSubmit)="submit(reuseForm)">
            <ng-container *ngFor="let field of formdata|keyvalue">
              <div class="col-sm-6"
              *ngIf="lastSubmittedData[field.key] && !map[field.key]?.skip">
                <div class="checkbox"
                [class.disabled]="processing">
                  <label>
                    <input type="checkbox"
                    name="{{ field.key }}"
                    [disabled]="processing"
                    [(ngModel)]="lastSelection[field.key]">
                    <div class="truncate">{{ (map[field.key]?.label || field.key)|translate }}</div>
                    <div class="truncate small text-muted">{{ lastSubmittedData[field.key].name || (lastSubmittedData[field.key]|json) }}</div>
                  </label>
                </div>
              </div>
            </ng-container>
          </form>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="modal-footer" *ngIf="lastSubmittedData">
    <button type="button" (click)="closeModal()" [disabled]="processing" class="btn btn-link" data-dismiss="modal">
      {{ 'GLOBAL.CANCEL'|translate }}
    </button>
    <button type="submit" form="reuse-form" class="btn btn-agree" [disabled]="processing">
      {{ 'FORM_REUSE.REUSE'|translate }}
    </button>
  </div>
</ng-template>

<ng-template #showLoading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>

<ng-template #noData>
  <!-- No data -->
  <div class="no-data">
    <i class="material-symbols-rounded">not_interested</i>
    {{ 'GLOBAL.NO_DATA'|translate }}
  </div>
</ng-template>