import { Type } from "class-transformer";
import { Invoice } from "./invoice.model";
import { Contract } from "../../contracts/models/contract.model";
import { Unload } from "./unload.model";
import { Price } from "../../../../models/price.model";
import { Quantity } from "../../../../models/quantity.model";

/**
 * A [[Contract]] can have multiple Price Fixations, based on its
 * [[PTBFConditions|PTBF conditions]].
 */
export class Fixation {
  /** Agree's internal unique identifier. */
  readonly id: number;

  /**
   * Source channel used to import.
   *
   * | ID | Channel        |
   * |---:|----------------|
   * |  1 | API            |
   * |  2 | Imported files |
   * |  3 | UI             |
   * |  4 | AFIP WS        |
   * |  5 | File           |
   */
  readonly import_channel_id: number;

  contract?: Contract; // Source: FIJACION_HEADER > NroContrato
  invoices?: Invoice[]; // Source: FIJACIONFACTURA > Numero_Factura
  unloads?: Unload[]; // Source: FIJACIONDETALLE > Carta de Porte & FIJACIONDETALLE > Kilos Fijados de la Descarga
  price: Price; // Source: Value: FIJACION_HEADER > Precio Real - Currency: FIJACION_HEADER > Moneda (Default PESOS)
  slate_price?: Price; // Source: Value: FIJACION_HEADER > Precio Pizarra - Currency: FIJACION_HEADER > Moneda (Default PESOS)
  exchange_rate: Price; // Source: Value: FIJACION_HEADER > Tipo Cambio Aplicado - Currency: Hardcoded $AR
  volume: Quantity; // Source: FIJACION_HEADER > Kilos Fijados (in Tons, not rounded)

  @Type(() => Date)
  date: Date; // Source: FIJACION_HEADER > Fecha Fijación

  @Type(() => Date)
  issued: Date; // Source: FIJACION_HEADER > Fecha de Emision

  @Type(() => Date)
  due_date: Date; // Source: FIJACION_HEADER > Fecha Vencimiento

  order: number; // Source: FIJACION_HEADER > Secuencia de Fijaciones
  slate: string; // Source: FIJACION_HEADER > PIZARRA (Value defined in TablasValores.xls PIZARRAS tab)
  observations?: string; // Source: FIJACION_HEADER > Observaciones
  /** Invoicing date */
  invoicing?: {
    type: number; // Source: FIJACION_HEADER > Dias Motivo Dias facturacion (1: Habiles / 2: Corridos / 3: Fecha Cierta)
    days?: number; // Source: FIJACION_HEADER > Dias Facturacion
    date?: Date; // Source: FIJACION_HEADER > Fecha Cierta
  }

  /**
   * Potential inconsistencies in imported data.
   */
  readonly warnings?: string[];

  constructor(
  ) {
    // this.contract = new Contract();
    this.price = new Price();
    this.slate_price = new Price();
    this.exchange_rate = new Price();
    this.volume = new Quantity();
  }
}
