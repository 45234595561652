import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';

import { User } from '../../../auth/models/user.model';
import { LoginService } from '../../../auth/services/login.service';
import { TrackrecordStep } from '../../../models/trackrecord-step.model';
import { TrackRecordService } from '../../../services/trackrecord.service';
import { FilePreviewComponent } from '../file-preview/file-preview.component';

@Component({
  selector: 'trackrecord',
  exportAs: 'trackRecord',
  templateUrl: './trackrecord.component.html',
  styleUrls: ['./trackrecord.component.scss']
})
export class TrackrecordComponent implements OnInit, OnDestroy {

  @ViewChild('commentModal', { static: true }) private readonly commentModal: TemplateRef<any>;
  @ViewChild('filePreviewer', { static: true }) private readonly filePreviewer: FilePreviewComponent;

  @Input() private refreshOnEvent: Subject<any>;
  @Input() public show: boolean;
  @Input() public entity: string;
  @Input() public ids: (string | number)[];

  public commentData = {
    text: undefined,
    private: false
  };
  public fields: object = {};
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public trackrecord: TrackrecordStep[];
  /** Current [[User]]. */
  public user: User;

  private companyId: number;
  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private refreshRequested: boolean;
  private pending: number;
  private subscriptions: Subscription[] = [];
  private trackrecordById: {
    entityId: number | string,
    trackrecord: TrackrecordStep[]
  }[];

  constructor(
    private loginService: LoginService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private trackrecordService: TrackRecordService
  ) {
    // Refactor to obtain the first parameter, which is always companyId
    this.route.pathFromRoot[1].params.subscribe(params => {
      this.companyId = parseInt(params['companyId'])
    })

    this.fields = {
      // -- estos keys ya no se utilizan, solo se conservan para que no pinchen las negociaciones viejas y se puedan seguir viendo bien.
      'business_detail.price.value': 'GLOBAL.PRICE',
      'business_detail.price.unit.name': 'TRACK_RECORD.FIELDS.PRICE_UNIT',
      'business_detail.price.year': 'TRACK_RECORD.FIELDS.PRICE_YEAR',
      'business_detail.price.month': 'TRACK_RECORD.FIELDS.PRICE_MONTH',
      // ------------------------
      'business_detail.price': 'GLOBAL.PRICE',
      'business_detail.quantity.value': 'GLOBAL.QUANTITY',
      'business_detail.harvest': 'GLOBAL.CROP_YEAR',
      'arbitration_chamber.name': 'TRACK_RECORD.FIELDS.ARBITRATION_CHAMBER',
      'general_observations': 'GLOBAL.OBSERVATIONS',
      'business_detail.delivery.date_from': 'TRACK_RECORD.FIELDS.DATE_FROM',
      'business_detail.delivery.date_to': 'TRACK_RECORD.FIELDS.DATE_TO',
      'payment_detail.payment_condition.name': 'GLOBAL.PAYMENT_CONDITION',
      'product_detail.quality.name': 'GLOBAL.QUALITY',
      'contract_detail.clauses': 'TRACK_RECORD.FIELDS.CLAUSES',
      'data_external.reference.id': 'TRACK_RECORD.FIELDS.REFERENCE',
      'represented': 'GLOBAL.COUNTERPARTS',
      'signers': 'SIGNATURES.SIGNERS',
      'version': 'GLOBAL.VERSION',
      'original_booking_date': 'GLOBAL.DATE',
      'company': 'GLOBAL.COMPANY',
      'price_value': 'Precio',
      'quantity_value': 'Volumen',
      'observations': 'Observaciones'
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    }));

    this.getTrackRecord();

    if (this.refreshOnEvent) {
      this.subscriptions.push(this.refreshOnEvent.subscribe(event => {
        this.refresh();
      }));
    }
  }

  private refresh(): void {
    this.refreshRequested = true;
    this.getTrackRecord();
  }

  public toggle(): void {
    this.show = !this.show;

    if (!this.trackrecord || this.refreshRequested) this.getTrackRecord();
  }

  private getTrackRecord(): void {
    if (!this.loading && this.companyId && this.ids.length && this.show) {
      this.loading = true;
      this.trackrecordById = [];
      this.pending = this.ids.length - 1;
      this.loadData();
    }
  }

  private loadData(): void {
    this.subscriptions.push(this.trackrecordService.get(this.companyId, this.entity, this.ids[this.pending]).subscribe(trackrecord => {
      this.trackrecordById.push({
        entityId: this.ids[this.pending],
        trackrecord: trackrecord
      });

      this.pending--;

      if (this.pending < 0) {
        // All negotiations loaded
        this.parse();
      } else this.loadData();
    }));
  }

  private parse(): void {
    this.trackrecord = [];
    this.trackrecordById.forEach(tr => {
      tr.trackrecord.forEach(element => {
        element.entityId = tr.entityId;
        this.trackrecord.push(element);
      });
    });
    this.refreshRequested = false;
    this.loading = false;
  }

  public scrollToElement(element_id: string): void {
    const d = document,
      elem = d.getElementById(element_id),
      scrollablePane = d.querySelector('.app-content'),
      header = d.querySelector('.ag-header');

    let y = elem.offsetTop - 4;

    if (elem && scrollablePane) {
      elem.classList.remove("flash-panel");
      if (header) y -= header.getBoundingClientRect().height;
      scrollablePane.scrollTo({
        top: y,
        behavior: 'smooth',
      });
      setTimeout(() => {
        elem.classList.add("flash-panel");
      }, 250);
    }
  }

  public comment(): void {
    this.openModal(this.commentModal);
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  public submit(): void {
    this.commentData.text = this.commentData.text.trim();
    if (this.commentData.text !== "") {
      this.processing = true;
      this.subscriptions.push(this.trackrecordService.comment(this.companyId, this.entity, this.ids[0], this.commentData.text, this.commentData.private).subscribe(response => {
        // Wait for API response
        this.commentData.text = undefined; // Reset value
        this.refresh(); // Refresh data
        this.closeModal();
      }));
    }
  }

  /** Try to preview the track record PDF in the browser. */
  public preview(): void {
    if (this.ids[0]) {
      this.filePreviewer.preview(this.trackrecordService.pdf(this.companyId, this.entity, this.ids[0]));
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
