import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lineBreak'
})
export class LineBreakPipe implements PipeTransform {

  /**
   * Transforms a string by replacing newline characters with HTML line break tags.
   *
   * @param {any} value - The input string to transform.
   * @param {...any[]} args - Additional arguments (unused).
   * @returns {any} - The transformed string with newline characters replaced by '<br/>'.
   */
  transform(value: any, ...args: any[]): any {
    return value.replace(/(?:\r\n|\r|\n)/g, '<br/>');
  }
}
