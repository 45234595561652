<form id="invitationForm" *ngIf="activities ; else loading" #invitationForm="ngForm"
  (ngSubmit)="invitationForm.valid && submit(invitationForm)">

  <fieldset ngModelGroup="0">
    <ng-container *ngIf="countries; else loading">
      <div class="row" *ngIf="company.market?.configuration?.company.address.country?.enabled">
        <div class="col-xs-12">
          <div class="form-group" hasError>
            <label for="comCountry" class="control-label">{{ 'REGISTER_COMPANY.COUNTRY'|translate }}</label>
            <select id="comCountry" class="form-control input-lg has-placeholder" name="country"
              [(ngModel)]="selectedCountry" required [compareWith]="compareId"
              #country="ngModel">
              <option [ngValue]="null" [disabled]="true">{{ 'REGISTER_COMPANY.COUNTRY'|translate }}</option>
              <option *ngFor="let country of countries"
                [ngValue]="{id: country.id, name: country.name, iso: country.iso, fiscalIds: country.fiscalIds}">
                {{ country.name }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <label for="form_fi" class="control-label">{{ selectedCountry?.fiscalIds[0]?.label ?
            selectedCountry?.fiscalIds[0]?.label : 'REGISTER_COMPANY.FISCAL_IDENTIFICATION' |translate
            }}</label>
          <div class="form-group has-button" hasError hasErrorOn="dirty">
            <input id="form_fi" type="text" class="form-control input-lg" name="fiscal_id" autocomplete="off" ngModel
            #fiscalIdInput="ngModel"
            required [disabled]="processing || !selectedCountry"
            mask="{{ selectedCountry?.fiscalIds[0]?.mask ? selectedCountry?.fiscalIds[0]?.mask  : '' }}"
            placeholder="{{ selectedCountry?.fiscalIds[0]?.mask || '' }}"
            [modalFocus]="true"
            [validCuit]="company.market.configuration.company.fiscal_id?.type === 'cuit'"
            [validRfc]="company.market.configuration.company.fiscal_id?.type === 'rfc'"
            [validCURP]="company.market.configuration.company.fiscal_id?.type === 'curp'"
            (input)="onFiscalIdInputChange(invitationForm.controls[0])">
            <span *ngIf="!processing" (click)="resetData(invitationForm.controls[0])"
              class="glyphicon glyphicon-remove-sign form-control-button" aria-hidden="true"></span>
            <error-list></error-list>
            <p class="help-block small">{{ 'COUNTERPARTIES.NETWORK.MODAL.FISCAL_ID_HELP'|translate }}</p>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!fiscalIdInput.invalid">
        <!-- RAZON SOCIAL INPUT/SELECTOR-->
        <ng-container
          *ngIf="!companiesFromSelectedFiscalId || companiesFromSelectedFiscalId.length <= 1; else companySelector">
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group" hasError>
                <label for="form_ne" class="control-label">{{ 'REGISTER_COMPANY.NAME'|translate }}</label>
                <input id="form_ne" type="text" class="form-control input-lg" maxlength="256" name="name" ngModel required
                  autocomplete="off" [readonly]="singleNewCompany != null && singleNewCompany?.activation_level.id !== 3"
                  [disabled]="processing">
                <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
                <error-list></error-list>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #companySelector>
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group" hasError>
                <label for="form_ni" class="control-label">{{ 'REGISTER_COMPANY.NAME'|translate }}</label>
                <select id="form_ni" class="form-control input-lg" name="name" ngModel required
                  (input)="onSelectRazonSocial($event.target.value, invitationForm.controls[0])"
                  [class.readonly]="processing">
                  <option value="" [disabled]="true">{{ 'GLOBAL.SELECT'|translate }}</option>
                  <option *ngFor="let company of companiesFromSelectedFiscalId" value="{{ company.id }}">
                    {{ company.name }}
                  </option>
                </select>
                <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
                <error-list></error-list>
              </div>
            </div>
          </div>
        </ng-template>
  
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group" hasError>
              <label for="form_ac" class="control-label">{{ 'GLOBAL.MAIN_ACTIVITY'|translate }}</label>
              <select id="form_ac" class="form-control input-lg" name="activity" ngModel required
                [class.readonly]="processing || (singleNewCompany?.id && singleNewCompany?.active)">
                <option value="" [disabled]="true">{{ 'GLOBAL.SELECT'|translate }}</option>
                <option *ngFor="let act of activities" [value]="act.id">{{ act.name }}</option>
              </select>
              <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
              <error-list></error-list>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!singleNewCompany?.id || !singleNewCompany?.active">
          <div class="col-xs-12">
            <div class="form-group" hasError>
              <label for="form_em" class="control-label">{{ 'COUNTERPARTIES.NETWORK.MODAL.EMAILS'|translate
                }}</label>
              <selectize-emails name="email" ngModel
              [disabled]="processing" required maxItems="5"></selectize-emails>
              <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
              <error-list></error-list>
              <p class="help-block small">{{ 'COUNTERPARTIES.NETWORK.MODAL.EMAILS_HELP'|translate }}</p>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </fieldset>
</form>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>
