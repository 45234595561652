import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MapsAPILoader } from '@ng-maps/core';
import { v4 as uuidv4 } from 'uuid';
declare var google;

/** Displays a square map. */
@Component({
  selector: 'map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit {

  @ViewChild('mapElement', { static: true }) private readonly mapElement: ElementRef;

  /** Displays a map with rounded corners. */
  @Input() public rounded: boolean;
  @Input() public maxZoom: number = 16;
  @Input() public minZoom: number = 3;
  /** An Array of addresses which you want to geocode and mark on the map. */
  @Input() set addresses(data: string[]) {
    if (data && data.length) {
      this._addresses = data;

      this.addAddresses();
    }
  }

  private UUID: string;
  private _addresses: string[];
  private latlngbounds;
  private map;
  private geocoder;
  private markers: any[] = [];

  /** @ignore */
  constructor(
    private mapsAPILoader: MapsAPILoader
  ) {
    this.UUID = 'map-' + uuidv4();
  }

  /** @ignore */
  ngAfterViewInit(): void {
    this.initMap();
  }

  private initMap(): void {
    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
      this.map = new google.maps.Map(this.mapElement.nativeElement, {
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        clickableIcons: false,
        maxZoom: this.maxZoom,
        minZoom: this.minZoom,
        mapId: this.UUID
      });

      this.latlngbounds = new google.maps.LatLngBounds();

      this.addAddresses();
    });
  }

  /** Adds a marker to the map and updates the viewport. */
  private addMarker(location: any): void {
    const marker = new google.maps.marker.AdvancedMarkerElement({
      position: location,
      map: this.map
    });

    this.markers.push(marker);

    // Extend each marker's position in LatLngBounds object.
    this.latlngbounds.extend(marker.position);

    // Center map and adjust Zoom based on the position of all markers.
    this.map.setCenter(this.latlngbounds.getCenter());
    this.map.fitBounds(this.latlngbounds);
  }

  private addAddresses(): void {
    if (this.geocoder && this._addresses) this._addresses.forEach(address => {
      // https://developers.google.com/maps/documentation/javascript/geocoding?hl=es#GeocodingRequests
      this.geocoder.geocode({
        'address': address
      }, (results, status) => {
        if (status === 'OK') {
          this.addMarker(results[0].geometry.location);
          // } else {
          // console.log('Geocode was not successful for the following reason: ' + status);
        }
      });
    });
  }

  /** Removes all the in the map. */
  // private resetMarkers(): void {
  //   this.markers.forEach(marker => {
  //     // https://developers.google.com/maps/documentation/javascript/markers?hl=es#remove
  //     marker.setMap(null);
  //   })

  //   this.markers = [];
  // }
}
