import { Pipe, PipeTransform } from '@angular/core';

import { GeoSelection } from '../../models/geo-selection.model';

/**
 * Converts an array of GeoSelection objects into a formatted string.
 * Each location and zone name are concatenated and separated by spaces, and each pair is separated by commas.
 * 
 * @param {GeoSelection[]} [locations] - The array of GeoSelection objects to format.
 * @returns {string} - The formatted location string.
 */
export function locationString(locations?: GeoSelection[]) {
  if (locations) {
    const loc = [];

    locations.forEach(location => {
      const locstr = [];

      if (location.location) locstr.push(location.location.name);
      if (location.zone) locstr.push(location.zone.name);

      loc.push(locstr.join(" "));
    });

    return loc.join(", ");
  } else return ' ';
}

/**
 * Converts an array of geolocation objects into a formatted string based on a specified field.
 * The field values are concatenated and separated by commas or semicolons if any field value contains a comma.
 * 
 * @param {any[]} [geolocations] - The array of geolocation objects to format.
 * @param {string} [field='name'] - The field to use for extracting values from the geolocation objects.
 * @returns {string} - The formatted geolocation string.
 */
export function geolocationString(geolocations?: any[], field: string = 'name') {
  if (geolocations) {
    const loc = [];

    geolocations.forEach(geolocation => {
      loc.push(geolocation[field]);
    });

    let separator = ', ';
    for (let i = 0; i < loc.length; i++) {
      if (loc[i].indexOf(',') !== -1) {
        separator = '; ';
        break;
      }
    }

    return loc.join(separator);
  } else return ' ';
}

@Pipe({
  name: 'locationString'
})
export class LocationStringPipe implements PipeTransform {

  transform(locations: any[], isGeoLocation: boolean = false, field: string = 'formatted_address'): string {
    return isGeoLocation ? geolocationString(locations, field) : locationString(locations);
  }
}
