import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription, combineLatest, map, of } from 'rxjs';

import { User } from '../../../auth/models/user.model';
import { CommercialZone } from '../../../company/models/commercial-zone.model';
import { Market } from '../../../company/modules/commercial/models/market.model';
import { OrderService } from '../../../company/modules/commercial/services/order.service';
import { CommercialZonesService } from '../../../company/services/commercial-zones.service';
import { CompanyActivity } from '../../../models/company-activity.model';
import { Currency } from '../../../models/currency.model';
import { Label } from '../../../models/label.model';
import { Location } from '../../../models/location.model';
import { Product } from '../../../models/product.model';
import { Zone } from '../../../models/zone.model';
import { CurrentDateService } from '../../../services/current-date.service';
import { LabelService } from '../../../services/label.service';
import { LocationService } from '../../../services/location.service';
import { MarketService } from '../../../services/market.service';
import { AgColumnDirective } from '../../directives/ag-column.directive';
import { AppliedFilter, Filter, FilterOption, FiltersData, MarketAndParams, MonthStruct } from '../../models/filter.model';
import { FilterMultiple } from '../filter-multiple-list/filter-multiple';
import filterList from './filter-list';

@Component({
  selector: 'filter-set',
  exportAs: 'filterSet',
  templateUrl: './filter-set.component.html',
  styleUrls: ['./filter-set.component.scss']
})
export class FilterSetComponent implements OnInit, OnDestroy {

  @ViewChild('filtersContent') private readonly content: ElementRef;

  @Input() public user: User;
  @Input() public presets: boolean = false;
  @Input() set marketId(value: number) {
    this._marketId = value;

    setTimeout(() => {
      if (!this.marketId) this.setupAdmin();
      else this.setup();
    });
  }

  @Output() readonly filters = new Subject<AppliedFilter[]>();

  get marketId(): number {
    return this._marketId;
  }

  public allApplied: boolean;
  public filterList: { [key: string]: Filter };
  public market: Market;
  public show: boolean;

  private _marketId: number;
  private appliedFilters: AppliedFilter[];
  private countSuscriber = 0;
  private currentLang: string;
  private subscriptions: Subscription[] = [];
  private today: Date = this.currentDate.get();

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private agColumn: AgColumnDirective,
    private currentDate: CurrentDateService,
    private datePipe: DatePipe,
    private locationService: LocationService,
    private marketService: MarketService,
    private orderService: OrderService,
    private translateService: TranslateService,
    private labelService: LabelService,
    private commercialZonesService: CommercialZonesService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;

    this.subscriptions.push(this.translateService.onLangChange.subscribe(() => {
      this.filtersPreLoading();
    }));
  }

  /**
    Importing filter declarations from an external file
  */
  private filtersPreLoading(settings?: { admin: boolean }): void {
    const reducedFilters = {};
    // Import and reduces the filters that do or do not apply to admin sections
    if (settings) Object.keys(filterList).forEach(key => {
      if (filterList[key].admin === settings.admin) {
        reducedFilters[key] = filterList[key];
      }
    });
    this.filterList = reducedFilters;

    // Set options for some filters
    if (!settings.admin) {
      this.filterList['range'].options = this.periodInitialization();
      this.filterList['past_range'].options = this.periodInitialization(24);
      this.filterList['crop'].options = this.genCropList();
    }
  }

  private setup(): void {
    this.filtersPreLoading({ admin: false });
    this.subscriptions.push(combineLatest({
      queryParams: this.route.parent.queryParams,
      market: this.marketService.get(this.marketId),
      params: this.route.parent.params
    }).subscribe((marketAndParams: MarketAndParams) => {

      if (!this.isEmpty(marketAndParams.market)) {
        this.market = marketAndParams.market;

        // Load API filters options
        this.subscriptions.push(combineLatest({
          products: this.getProductsOptions(),
          zones: this.getZonesOptions(marketAndParams),
          locations: this.getLocationsOptions(marketAndParams),
          delivery_types: this.getDeliveryTypesOptions(),
          currencies: this.getCurrenciesOptions(),
          labels: this.getLabelOptions(marketAndParams),
          commercial_zones: this.getCommercialZonesOptions(marketAndParams),
          activities: this.getCompanyActivitiesOptions()
        })
          .subscribe(filtersOptionsByAPI => {
            this.parseFiltersData(new FiltersData({
              marketAndParams,
              ...filtersOptionsByAPI,
            }));
          }));
      }
    }));
  }

  private setupAdmin(): void {
    this.filtersPreLoading({ admin: true });

    if (this.user) {
      this.subscriptions.push(combineLatest({
        params: this.route.parent.queryParams, // Filters
        market: this.marketService.getMarketsByUser(this.user.id) // Markets
      })
        .subscribe((filterParams: { params: Params; market: Market[] }) => {
          this.appliedFilters = [];

          this.filterList['market_id'].options = filterParams.market;

          if (!this.isEmpty(filterParams.params)) {
            this.applyFilters(filterParams.params);
          }

          this.filters.next(this.appliedFilters);
          this.allApplied = (this.appliedFilters.length === this.countSuscriber);
        }));
    }
  }

  private parseFiltersData(filtersData: FiltersData): void {
    this.appliedFilters = [];

    this.filterList.product_id.options = filtersData.products;
    this.filterList.zone_id.options = filtersData.zones;
    this.filterList.zones_id.options = filtersData.zones;
    this.filterList.label_id.options = filtersData.labels;
    this.filterList.commercial_zone.options = filtersData.commercial_zones;
    this.filterList.activity_id.options = filtersData.activities;
    // this.filterList.location_id.options = filtersData.locations

    if (filtersData.delivery_types?.length) {
      const options = [];

      filtersData.delivery_types.forEach(group => {
        group.values.forEach(({ id, name }) => {
          options.push(new FilterOption({ id, name }));
        });
      });

      this.filterList['delivery_type'].options = options;
    }

    if (filtersData.marketAndParams) {
      if (filtersData.currencies) {
        const priceTypes = filtersData.marketAndParams.market.configuration.order.business_detail.price.types
          .filter(t => t.enabled)
          .map(({ id, label }) => new FilterOption({ id, name: label }));

        const currencyTypes = filtersData.currencies
          .map(({ id, name }) => new FilterOption({ id, name }));

        const priceFilters = [
          ...priceTypes,
          ...currencyTypes
        ];

        this.filterList['price'].options = priceFilters;
      }

      this.applyFilters(filtersData.marketAndParams.queryParams);
    }

    this.filters.next(this.appliedFilters);
    this.allApplied = (this.appliedFilters.length === this.countSuscriber);
  }

  private applyFilters(params: Params): void {
    for (const key in params) {
      const keyFilter = this.getKeyFromMultipleFilter(key);

      if (this.multipleFilterEnable(keyFilter)) {
        const filter = this.filterList[keyFilter];
        const filterValue = this.handleValueByFilterType(filter, params, key);

        if (filterValue) {
          const appliedFilter = new AppliedFilter({
            key: keyFilter,
            id: params[key],
            title: filter.label,
            type: filter.type,
            value: filterValue
          });

          this.appliedFilters.push(appliedFilter);
        }
      }
    }
  }

  private multipleFilterEnable(key: string): boolean {
    const keyParsed = this.getKeyFromMultipleFilter(key);
    return this.filterEnable(keyParsed);
  }

  private getKeyFromMultipleFilter(multipleKey: string): string {
    const isMultipleFilter = multipleKey.includes('filters[');
    if (isMultipleFilter) {
      const key = multipleKey.split('[')[1].split(']')[0];
      return key;
    }
    return multipleKey;
  }

  /**
   *
   * Returns the converted value if the filter has a defined type
   *
   * EX: date_range => range:[isoDate],[isoDate] -> dd/mm/yy - dd/mm/yy
  **/
  private handleValueByFilterType(filter: Filter, params: Params, key: string): string {
    switch (filter.type) {
      case 'date_range':
        return this.dateRangeValueHandler(params[key]);
      case 'list':
        return this.getSelectedOption(params, key)?.name;
      default:
        return this.getSelectedOption(params, key)?.name;
    }
  }

  // Filter options obtained by API
  private getProductsOptions(): Observable<Product[]> {
    return this.filterEnable('product_id') ? this.marketService.watchProducts() : of([]);
  }

  private getZonesOptions(filterParams: MarketAndParams): Observable<Zone[]> {
    const locationFlag = Boolean(filterParams.queryParams.location_id);
    const zonesEnabled = this.market.configuration.location.zone.enabled;
    return ((locationFlag || !zonesEnabled) && (this.filterEnable('zone_id') || this.filterEnable('zones_id'))) ? of([]) : this.locationService.getZones();
  }

  private getLocationsOptions(filterParams: MarketAndParams): Observable<Location[]> {
    const locationFlag = Boolean(filterParams.queryParams.location_id);

    return (locationFlag && this.filterEnable('location_id')) ? this.locationService.getLocationsById(this.market.id, filterParams.queryParams.location_id) : of([]);
  }

  private getDeliveryTypesOptions(): Observable<any[]> {
    return (this.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists && this.filterEnable('delivery_type')) ? this.orderService.getDeliveryTypes(this.market.id) : of([]);
  }

  private getCurrenciesOptions(): Observable<Currency[]> {
    return this.filterEnable('price') ? this.marketService.watchCurrencies() : of([]);
  }

  private getLabelOptions(filterParams: MarketAndParams): Observable<Label[]> {
    const companyId = filterParams.params['companyId'];

    return (companyId && this.filterEnable('label_id')) ? this.labelService.watch(parseInt(companyId)) : of([]);
  }

  private getCommercialZonesOptions(filterParams: MarketAndParams): Observable<CommercialZone[]> {
    const companyId = filterParams.params['companyId'];

    return (companyId && this.filterEnable('commercial_zone')) ? this.commercialZonesService.watch(parseInt(companyId)) : of([]);
  }

  private getCompanyActivitiesOptions(): Observable<CompanyActivity[]> {
    return this.filterEnable('activity_id') ? this.marketService.watchActivities() : of([]);
  }

  // Methods to manage filter states
  public clearAllFilters(): void {
    const queryParams = JSON.parse(JSON.stringify(this.route.parent.snapshot.queryParams));

    if (this.appliedFilters?.length) {
      this.appliedFilters.forEach(filter => {
        if (filter.type === 'multiple') {
          const multipleFilter = new FilterMultiple({ key: filter.key, value: filter.id });
          delete queryParams[multipleFilter.queryParamKey];
        } else {
          delete queryParams[filter.key];
        }

      });

      this.router.navigate([], {
        queryParams: queryParams,
        relativeTo: this.route
      });
    }
  }

  public removeFilter(key: string): void {
    const queryParams = JSON.parse(JSON.stringify(this.route.parent.snapshot.queryParams));
    delete queryParams[key];

    this.router.navigate([], {
      queryParams: queryParams,
      relativeTo: this.route
    });
  }

  public addFilterData(key, id, isMultiple = false): void {
    if (isMultiple) {
      this.addMultipleFilter(key, id);
    } else {
      this.addSimpleFilter(key, id);
    }
  }

  private addSimpleFilter(key: string, id: string | number): void {
    const queryParams = JSON.parse(JSON.stringify(this.route.parent.snapshot.queryParams));
    let mergeFilter = [];

    this.appliedFilters.forEach(filter => {
      if (filter.key !== 'page' && filter.type !== 'multiple') {
        mergeFilter.push(filter);
      }
    });
    mergeFilter = [...mergeFilter, { key, id }];

    mergeFilter.forEach(obj => {
      queryParams[obj.key] = obj.id;
    });

    this.router.navigate([], {
      queryParams: queryParams,
      relativeTo: this.route
    });
  }

  private addMultipleFilter(key: string, id: string | number): void {
    const queryParams = JSON.parse(JSON.stringify(this.route.parent.snapshot.queryParams));
    let mergeFilter = [];
    const filterMultiple = new FilterMultiple({ key: key, value: id });

    const keyFilter = filterMultiple.queryParamKey;
    mergeFilter = [...mergeFilter, { keyFilter, id }];

    mergeFilter.forEach(obj => {
      queryParams[obj.keyFilter] = obj.id;
    });

    this.router.navigate([], {
      queryParams: queryParams,
      relativeTo: this.route
    });
  }

  public getFilters(): Observable<AppliedFilter[]> {
    return this.filters.pipe(
      map(filters => {
        const arrayFilters = [];

        filters.forEach(filter => {
          arrayFilters[filter.key] = filter.id;
        });

        return arrayFilters;
      })
    );
  }

  public showFilters(): void {
    this.show = !this.show;
    this.agColumn.expand(this.show);
  }

  private filterEnable(key: string): boolean {
    return this.checkKeys().includes(key);
  };

  private getSelectedOption(params: Params, key: string): FilterOption {
    const parsedFilter = this.getKeyFromMultipleFilter(key);
    return this.filterList[parsedFilter].options.find(opt => opt.id == params[key]);
  }

  /**
   * Retrieves a list of enabled keys from the native element's children.
   *
   * @returns {string[]} - An array of enabled keys.
   */
  public checkKeys(): string[] {
    const enabledKeys: string[] = [];
    const ne = this.content?.nativeElement;

    if (ne) {
      const children = Array.from(ne.children) as HTMLElement[];

      children.forEach(node => {
        const key = node.getAttribute('key');
        if (key) enabledKeys.push(key);
      });
    }

    return enabledKeys;
  }

  public countSuscriberChilds(): void {
    this.countSuscriber++;
  }

  // Utils
  private isEmpty(obj: Object): boolean {
    return Object.keys(obj).length === 0;
  }

  private capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  private periodInitialization(months_before: number = 0): MonthStruct[] {
    const monthsArray: MonthStruct[] =
      [], startingDate = new Date(this.today.getTime());

    startingDate.setMonth(startingDate.getMonth() - months_before);

    for (let i = 0; i < months_before + 12; i++) {
      monthsArray.push(this.addMonthRange(startingDate));
      startingDate.setMonth(startingDate.getMonth() + 1);
    }

    return monthsArray;
  }

  private genCropList(yearsBack: number = 3, yearsForward: number = 1): any[] {
    const cropList = [];
    const currentYear: number = this.currentDate.get().getFullYear();

    for (let i = -yearsBack; i <= yearsForward; i++) {
      const cropYear = `${(currentYear + i) % 100}/${((currentYear + i + 1) % 100).toString().padStart(2, '0')}`;
      cropList.push({
        id: cropYear.replace('/', ''),
        name: cropYear
      });
    }
    return cropList;
  }

  private addMonthRange(d: Date): {
    id: string;
    name: string;
  } {
    return {
      id: this.datePipe.transform(d, 'MM-yyyy'),
      name: this.capitalizeFirstLetter(this.datePipe.transform(d, 'MMMM yyyy', undefined, this.currentLang))
    };
  }
  /**
  * Convierte las fechas a un string legible para el usuario,
  * "range:[isoDate],[isoDate]" -> dd/mm/yy - dd/mm/yy
  */
  private dateRangeValueHandler(range: string): string {
    const dates: string[] = range.split('range:')[1].split(',');
    const start: Date = new Date(dates[0]);
    const end: Date = new Date(dates[1]);

    return `${this.datePipe.transform(start, this.translateService.instant('GLOBAL.DATE_FORMAT.SM'))} -
      ${this.datePipe.transform(end, this.translateService.instant('GLOBAL.DATE_FORMAT.SM'))}`;
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
