<ng-template #verificationModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" (click)="closeModal()" aria-label="Close"
    [disabled]="processing || loading">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'CELLPHONE_VERIFICATION.MODAL.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="loading" *ngIf="loading; else modalBody">
      <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div>
  </div>
  <div class="modal-footer" *ngIf="!loading">
    <button class="btn btn-link"
    type="button"
    (click)="closeModal()"
    [disabled]="processing || loading"
    data-dismiss="modal">{{ 'GLOBAL.CANCEL'|translate }}</button>
    <button class="btn btn-agree"
    type="button"
    (click)="nextStep()"
    [disabled]="processing || loading ||
    !phone.phone_area_code ||!phone.phone_country_code ||!phone.phone_number ||
    !differentAsOriginal"
    *ngIf="step === 1">{{ 'CELLPHONE_VERIFICATION.MODAL.NEXT'|translate }}</button>
    <button class="btn btn-agree"
    type="button"
    (click)="nextStep()"
    [disabled]="processing || loading || !validationCode"
    *ngIf="step === 2">{{ 'GLOBAL.CONFIRM'|translate }}</button>
  </div>
</ng-template>

<ng-template #modalBody>
  <ng-container *ngIf="step === 1">
    <div class="form-group">
      <label class="control-label"
      for="cc_{{ UUID }}">{{ 'CELLPHONE_VERIFICATION.MODAL.COUNTRY_CODE'|translate }}</label>
      <select class="form-control has-placeholder input-lg"
      name="countryCode"
      id="cc_{{ UUID }}"
      [modalFocus]="true"
      [compareWith]="compareId"
      [(ngModel)]="phone.phone_country_code"
      [disabled]="loading || processing"
      (ngModelChange)="phoneChange()">
        <option [ngValue]="null" [disabled]="true">{{ 'CELLPHONE_VERIFICATION.MODAL.SELECT_CODE'|translate }}</option>
        <option *ngFor="let code of countryCodes" [ngValue]="code">{{ code.name }} +{{ code.phone_code }}</option>
      </select>
    </div>
    <div class="row">
      <div class="col-xs-4">
        <div class="form-group">
          <label class="control-label"
          for="pac_{{ UUID }}">{{ 'CELLPHONE_VERIFICATION.MODAL.AREA_CODE'|translate }}</label>
          <input class="form-control input-lg"
          name="phone_area_code"
          id="pac_{{ UUID }}"
          type="number"
          [max]="16"
          [(ngModel)]="phone.phone_area_code"
          [disabled]="loading || processing"
          (ngModelChange)="phoneChange()"
          placeholder="{{ 'CELLPHONE_VERIFICATION.MODAL.AREA_CODE'|translate }}">
        </div>
      </div>
      <div class="col-xs-8">
        <div class="form-group">
          <label class="control-label"
          for="pn_{{ UUID }}">{{ 'USER_PROFILE.PHONE'|translate }}</label>
          <input class="form-control input-lg"
          name="phone_number"
          id="pn_{{ UUID }}"
          type="number"
          [max]="64"
          [(ngModel)]="phone.phone_number"
          [disabled]="loading || processing"
          (ngModelChange)="phoneChange()"
          placeholder="{{ 'USER_PROFILE.PHONE'|translate }}">
        </div>
      </div>
    </div>
    <span class="help-block">{{ 'CELLPHONE_VERIFICATION.MODAL.NUMBER_HELP'|translate }}</span>
  
    <div class="panel panel-default">
      <div class="panel-body">
        <div class="checkbox flex-center-distributed no-margin">
          <div>
            <input type="checkbox" class="ios"
            name="allow_whatsapp"
            id="aw_{{ UUID }}"
            [disabled]="loading || processing"
            [(ngModel)]="allowsWhastapp">
          </div>
          <label for="aw_{{ UUID }}" class="grow" [innerHtml]="'CELLPHONE_VERIFICATION.MODAL.ALLOW_WHATSAPP'|translate"></label>
        </div>
        <span class="help-block small" *ngIf="allowsWhastapp" [innerHtml]="'CELLPHONE_VERIFICATION.MODAL.ALLOW_WHATSAPP_HELP'|translate"></span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="step === 2">
    <p>{{ 'CELLPHONE_VERIFICATION.MODAL.VERIFICATION_DESCRIPTION'|translate }} <a (click)="sendVerificationCode()">{{
      'CELLPHONE_VERIFICATION.MODAL.SEND_AGAIN'|translate }}</a>.</p>
    <div class="form-group">
      <label class="control-label"
      for="pc_{{ UUID }}">{{ 'CELLPHONE_VERIFICATION.MODAL.CODE_FROM_TEXT_MESSAGE'|translate }}</label>
      <input class="form-control input-lg"
      name="phone_code"
      id="pc_{{ UUID }}"
      type="number"
      [disabled]="loading || processing"
      [(ngModel)]="validationCode"
      (keyup)="handleKeyUp($event)"
      [maxlength]="4">
    </div>

    <alert type="danger" [dismissible]="true" *ngIf="validationCodeError">{{ 'CELLPHONE_VERIFICATION.MODAL.ERROR'|translate }}</alert>
  </ng-container>

  <ng-container *ngIf="step === 3">
    <spinner message="{{ 'CELLPHONE_VERIFICATION.MODAL.VALIDATING'|translate }}"></spinner>
  </ng-container>
</ng-template>