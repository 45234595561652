<div class="btn-group btn-group-justified" *ngIf="types && types.length > 1 && !isAuction">
  <ng-container *ngFor="let type of types">
    <label class="btn btn-default"
    [class.active]="price.type === type.id"
    [class.disabled]="disabled">
      <input type="radio" name="{{ prefix }}orderType"
      [disabled]="disabled"
      [ngModel]="price.type" (ngModelChange)="changeType($event)"
      [value]="type.id">
      <span>{{ type.label }}</span>
    </label>
  </ng-container>
</div>
<ng-container *ngIf="price && price.type === 'to_be_fixed'">
  <div *ngIf="!types" class="text-right">{{ 'PRICE_FORM.TO_BE_FIXED.LABEL'|translate }}</div>
  <div class="text-left small conditions">
    {{ 'PRICE_FORM.TO_BE_FIXED.CONDITIONS'|translate }}
    <textarea class="form-control lighten" rows="3" placeholder="{{ 'PRICE_FORM.TO_BE_FIXED.PLACEHOLDER'|translate }}"
    name="{{ prefix }}price" [(ngModel)]="price.value" [required]="required" trackedControl
    (ngModelChange)="propagateValueChange()"
    maxlength="512"
    [previousValue]="previousPrice?.value" autocomplete="off" [disabled]="disabled"></textarea>
  </div>
</ng-container>
<ng-container *ngIf="price && price.type === 'consignment'">
  <div *ngIf="!types" class="text-right">{{ 'PRICE_FORM.CONSIGNMENT.LABEL'|translate }}</div>
  <div class="text-left small conditions">
    {{ 'PRICE_FORM.CONSIGNMENT.CONDITIONS'|translate }}
    <textarea class="form-control lighten" rows="3" placeholder="{{ 'PRICE_FORM.CONSIGNMENT.PLACEHOLDER'|translate }}"
    maxlength="1024"
    name="{{ prefix }}price" [(ngModel)]="price.value" [required]="required" trackedControl
    (ngModelChange)="propagateValueChange()"
    [previousValue]="previousPrice?.value" autocomplete="off" [disabled]="disabled"></textarea>
  </div>
</ng-container>
<div class="input-group" *ngIf="price && (price.type === 'flat' || price.type === 'premiums')" hasError>
  <div class="input-group-btn" *ngIf="price.type === 'premiums'">
    <label class="btn btn-default">
      <input type="checkbox" name="{{ prefix }}positive" [(ngModel)]="positive" (ngModelChange)="toggleSign($event)"
        [disabled]="disabled" [previousValue]="previousPrice?.value < 0 ? false : true" trackedControl>
      <span>{{ positive ? '+' : '-' }}</span>
    </label>
  </div><!-- /input-group-btn -->
  <ng-container *ngIf="price.type === 'flat'">
    <input *ngIf="!showInput" [class.input-lg]="lg" type="text" [placeholder]="placeholder" class="form-control"
    maxlength="64"
    (focus)="toggleInput()"
    [disabled]="disabled"
    (ngModelChange)="propagateValueChange()"
    name="{{ prefix }}pricea" [ngModel]="price.value|number:'1.2-2'">
    <input [class.hidden]="!showInput" [class.input-lg]="lg" type="number" [placeholder]="placeholder" step="0.01"
    #numberInput
    (blur)="toggleInput()"
    (ngModelChange)="propagateValueChange()"
    class="form-control" name="{{ prefix }}price" [(ngModel)]="price.value" [required]="required" number
    [min]="minPrice" [max]="maxPrice" trackedControl [previousValue]="previousPrice?.value" autocomplete="off"
    [disabled]="disabled">
  </ng-container>
  <input [class.input-lg]="lg" type="number" [placeholder]="placeholder" step="1" class="form-control"
    *ngIf="price.type === 'premiums'" name="{{ prefix }}price" [ngModel]="absValue"
    (ngModelChange)="changeAbsValue($event)" [required]="required" number [min]="minPrice" [max]="maxPrice"
    trackedControl [previousValue]="previousAbsValue" autocomplete="off" [disabled]="disabled">
  <div class="input-group-btn">
    <div dropdown agSelect name="{{ prefix }}priceMonth" class="btn-group" [class.hidden]="price.type !== 'premiums'"
      [(ngModel)]="price.month" trackedControl [previousValue]="previousPrice?.month" [isDisabled]="disabled">
      <button [class.btn-lg]="lg" dropdownToggle type="button" class="btn btn-default dropdown-toggle"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{ price.month|cmCode }}
        <span class="caret"></span>
      </button>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
        <li *ngFor="let month of months" [agOption]="month">
          <a>{{ month|cmCode }}</a>
        </li>
      </ul>
    </div><!-- /btn-group -->
    <div dropdown agSelect name="{{ prefix }}priceYear" class="btn-group" [class.hidden]="price.type !== 'premiums'"
      [(ngModel)]="price.year" trackedControl [previousValue]="previousPrice?.year" [isDisabled]="disabled">
      <button [class.btn-lg]="lg" dropdownToggle type="button" class="btn btn-default dropdown-toggle"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{ price.year }}
        <span class="caret"></span>
      </button>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
        <li *ngFor="let year of years" [agOption]="year">
          <a>{{ year }}</a>
        </li>
      </ul>
    </div><!-- /btn-group -->
    <ng-container *ngIf="price.type === 'flat'">
      <div dropdown agSelect name="{{ prefix }}priceUnit" class="btn-group"
        [class.single-unit]="currencies?.length <= 1" [(ngModel)]="price.unit" trackedControl
        [previousValue]="previousPrice?.unit" [isDisabled]="disabled" [compareWith]="compareId"
        [displayAs]="displayName">
        <button [class.btn-lg]="lg" dropdownToggle type="button" class="btn btn-default dropdown-toggle"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{ price.unit?.name }}
          <span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
          <li *ngFor="let c of currencies" [agOption]="c"><a>{{ c.name }}</a></li>
        </ul>
      </div><!-- /btn-group -->

      <div dropdown agSelect name="{{ prefix }}priceQuantityUnit" class="btn-group" *ngIf="showQuantityUnit"
        [class.single-unit]="units?.length <= 1" [(ngModel)]="price.quantity_unit" trackedControl
        [previousValue]="previousPrice?.quantity_unit" [isDisabled]="disabled" [compareWith]="compareId"
        [displayAs]="displayName">
        <button [class.btn-lg]="lg" dropdownToggle type="button" class="btn btn-default dropdown-toggle"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          /{{ price.quantity_unit?.abbrev || price.quantity_unit?.name }}
          <span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
          <li *ngFor="let u of units" [agOption]="u"><a>{{ u.name }}</a></li>
        </ul>
      </div><!-- /btn-group -->
    </ng-container>
  </div><!-- /input-group-btn -->
</div><!-- /input-group -->
<p *ngIf="help_block" class="help-block small" [innerHtml]="help_block"></p>