import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

declare var Intl: any;

@Pipe({
  name: 'listFormatter'
})
export class ListNamesPipe implements PipeTransform {

  private names: string[];

  constructor(private translateService: TranslateService) { }

  /**
   * Transforms an array of entities into a formatted string, returns a string with a language-specific representation of the list.
   * Supports excluding specific IDs, truncating text, and adding prefixes.
   * 
   * @param {any[]} entities - The array of entities to transform.
   * @param {Object} [options={}] - The options for transformation.
   * @param {number} [options.excludeId] - An ID to exclude from the transformation.
   * @param {number} [options.truncate] - The maximum length of each text item before truncation.
   * @param {string} [options.key="name"] - The key to use for extracting text from entities.
   * @param {string} [options.prefix=""] - A prefix to add to each text item.
   * @returns {string} - The formatted string.
   */
  transform(entities: any[], options: {
    excludeId?: number,
    truncate?: number,
    key?: string,
    prefix?: string
  } = {}): string {
    if (!entities) return;

    this.names = [];

    const key = options.key || 'name';
    const prefix = options.prefix || '';
    const truncate = options.truncate || 0;

    entities.forEach(entity => {
      const isString = typeof entity === 'string';
      let text = isString ? entity : entity[key];

      if (text) {
        if (!isString && options.excludeId) {
          if (entity.id !== options.excludeId) this.add(prefix + text, truncate);
        } else this.add(prefix + text, truncate);
      }
    });

    if (Intl) {
      return new Intl.ListFormat(this.translateService.currentLang, { style: 'long', type: 'conjunction' }).format(this.names);
    } else {
      return this.names.join(', ');
    }
  }

  /**
   * Adds a value to the names array, truncating it if necessary.
   * 
   * @param {any} value - The value to add.
   * @param {number} truncateAt - The maximum length of the value before truncation.
   * @private
   */
  private add(value: any, truncateAt: number): void {
    let text = String(value);

    if (truncateAt > 0 && text.length > truncateAt) {
      text = text.substring(0, truncateAt) + '...';
    }

    this.names.push(text);
  }
}
