import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, combineLatest } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { User } from '../../../auth/models/user.model';
import { LoginService } from '../../../auth/services/login.service';
import { CountryCode } from '../../../models/country-code.model';
import { Phone } from '../../../models/phone.model';
import { IntercomService } from '../../../services/intercom.service';
import { LocationService } from '../../../services/location.service';

/**
 * CellphoneVerification allows you to edit the
 * [[User.phone|User's cell phone number]]. A verification code is sent by SMS
 * to authenticate the number.
 *
 * ## Usage
 * ``` html
 * <ag-cellphone-verification
 * #cellVerification></ag-cellphone-verification>
 * ```
 * 
 * Then you can open the modal as follows:
 * 
 * ``` html
 * <a (click)="cellVerification.addPhone()">Modify phone</a>
 * ```
 */
@Component({
  selector: 'ag-cellphone-verification',
  templateUrl: './cellphone-verification.component.html',
  styleUrls: ['./cellphone-verification.component.css']
})

export class CellphoneVerificationComponent implements OnDestroy {

  @ViewChild('verificationModal', { static: true }) private readonly verificationModal: TemplateRef<any>;

  public UUID: string;
  public allowsWhastapp: boolean = true;
  public countryCodes: CountryCode[];
  public differentAsOriginal: boolean;
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean;
  public phone: Phone;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public step: number;
  /** Current [[User]]. */
  public user: User;
  public validationCode: number;
  public validationCodeError: boolean;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];

  /** @ignore */
  constructor(
    private modalService: BsModalService,
    private loginService: LoginService,
    private locationService: LocationService,
    private intercomService: IntercomService
  ) { }

  public addPhone(): void {
    this.UUID = uuidv4();
    this.resetValues();
    this.loadData();
    this.openModal(this.verificationModal);
  }

  private loadData(): void {
    if (this.countryCodes) return;

    this.loading = true;

    this.subscriptions.push(combineLatest([
      this.loginService.getCurrentUser(),
      this.locationService.getCountryCodes()
    ]).subscribe(values => {
      // Destructures the values ​​received from the observables, for readability
      const [
        user,
        countryCodes
      ] = values;

      this.user = user;
      this.countryCodes = countryCodes;

      this.phone = new Phone(this.user.phone);
      this.user.phone_code = undefined;

      this.loading = false;
    }));
  }

  /**
   * Evaluates if the entered [[Phone]] is different from current
   * [[User.phone|User's phone]].
   */
  public phoneChange(): void {
    const original: Phone = this.user.phone;
    this.differentAsOriginal = Boolean(!original ||
      !original.phone_country_code ||
      original.phone_area_code !== this.phone.phone_area_code ||
      original.phone_country_code.id !== this.phone.phone_country_code.id ||
      original.phone_number !== this.phone.phone_number);
  }

  /** 
   * Customizes the default Angular option comparison algorithm
   * @ignore */
  public compareId(a: { id: string | number }, b: { id: string | number }): boolean {
    return (!a && !b) || (a && b && a.id === b.id);
  }

  public nextStep(): void {
    if (this.step === 1) this.sendVerificationCode();
    else if (this.step === 2) this.validateCode();

    this.step++;
  }

  public sendVerificationCode(): void {
    this.processing = true;

    // Create a temp user to post
    let tempUser: User = new User(this.user);
    tempUser.phone = new Phone(this.phone);
    delete tempUser.accounts;

    this.subscriptions.push(this.loginService.updatePhone(tempUser).subscribe(response => {
      this.processing = false;
      // Focus hack
      setTimeout(() => {
        const input = document.getElementById('pc_' + this.UUID);
        if (input) input.focus();
      });
    }));
  }

  public handleKeyUp(event: any): void {
    if (event.key === "Enter" && this.validationCode) {
      this.nextStep();
    }
  }

  private resetValues(): void {
    this.step = 1;
    this.differentAsOriginal = false;
    this.validationCode = undefined;
    this.processing = false;
    this.loading = false;
  }

  private validateCode(): void {
    if (this.validationCode) {
      this.processing = true;

      let tempUser: User = new User(this.user);
      tempUser.phone = this.phone;
      tempUser.phone_code = this.validationCode;

      this.subscriptions.push(this.loginService.verificatePhoneCode(tempUser).subscribe(response => {
        if (response.response === 'ok') {
          this.user.phone = this.phone;
          this.updateWhastappSetting();

          const obj = JSON.parse(localStorage.getItem("login"));
          obj.user.phone = this.phone;
          obj.user.phone_verified = this.user.phone_verified = 1;
          localStorage.setItem("login", JSON.stringify(obj));

          this.intercomService.track('phone-verified', {
            user_id: obj.user.id,
            user_name: obj.user.name + ' ' + obj.user.last_name
          });

          this.closeModal();
        }
        else {
          this.validationCodeError = true;
          this.step = 2;
        }
        this.processing = false;
      }));
    }
  }

  private updateWhastappSetting(): void {
    if (this.user.allows_whatsapp !== this.allowsWhastapp) {
      this.user.allows_whatsapp = this.allowsWhastapp;
      this.subscriptions.push(this.loginService.updateUser(this.user).subscribe());
    }
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.resetValues();
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
