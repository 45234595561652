import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class HTMLToClipboardService {

  private clipboardClass: string = 'clipboard-ready';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) { }

  public copy(selectors: string): void {
    const htmlElement: Element = this.document.querySelector(selectors);
    const done = () => {
      this.toastrService.success(this.translateService.instant('GLOBAL.COPIED_CLIPBOARD'));
    };

    if (htmlElement) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(htmlElement);
        done();
      }
      else {
        // Clean HTML selection
        const tempBODY = document.createElement("body");
        tempBODY.innerHTML = htmlElement.innerHTML;
        this.removeTags(tempBODY, 'container-element');
        this.removeTags(tempBODY, 'button');
        this.removeTags(tempBODY, 'map');
        this.removeTags(tempBODY, '.material-symbols-rounded');
        this.removeTags(tempBODY, '.show-onhover');

        const tempHTML = document.createElement("html");
        const tempHEAD = document.createElement("head");
        tempHEAD.innerHTML = this.document.querySelector('head').innerHTML;
        this.removeTags(tempHEAD, 'link');
        this.removeTags(tempHEAD, 'meta');
        this.removeTags(tempHEAD, 'script');

        tempHTML.append(tempHEAD);
        tempHTML.append(tempBODY);

        const tempText = tempBODY.innerHTML
          .replace(/&[^;]*;/gi, ' ')
          .replace(/<\/td>/gi, '\t')
          .replace(/<\/th>/gi, '\t')
          .replace(/<br>/gi, '\r\n')
          .replace(/<\/p>/gi, '\r\n\r\n')
          .replace(/<div[^>]*>/gi, '\r\n')
          .replace(/<tr[^>]*>/gi, '\r\n')
          .replace(/<table[^>]*>/gi, '\r\n')
          .replace(/<h[1-5][^>]*>/gi, '\r\n\r\n')
          .replace(/<\/h[1-5]>/gi, '\r\n')
          .replace(/<[^>]*>/gi, "")
          .replace(/\r\n\r\n\r\n\r\n/gi, '\r\n\r\n')
          .replace(/\r\n\r\n\r\n/gi, '\r\n\r\n');

        navigator.clipboard.write([new ClipboardItem({
          'text/plain': new Blob([tempText], { type: 'text/plain' }),
          // 'text/plain': new Blob([tempBODY.textContent], { type: 'text/plain' }),
          'text/html': new Blob([tempHTML.innerHTML], { type: 'text/html' })
        })]).then((v) => {
          done();
        });
      }
    }
  }

  private removeTags(htmlElement: Element, query: string): void {
    const elements = htmlElement.querySelectorAll(query);
    for (let index = 0; index < elements.length; index++) {
      elements[index].remove();
    }
  }

  private fallbackCopyTextToClipboard(htmlElement: Element): void {
    htmlElement.classList.add(this.clipboardClass);
    // create a Range object
    const range: Range = document.createRange();
    // set the Node to select the "range"
    range.selectNode(htmlElement);
    // add the Range to the set of window selections
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);

    // execute 'copy', can't 'cut' in this case
    this.document.execCommand('copy');

    window.getSelection().removeRange(range);
    htmlElement.classList.remove(this.clipboardClass);
  }
}
