import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Displays an explanatory step-divided guide, optionally numbered, applicable
 * to sections of the platform.
 * 
 * ## Usage
 * ``` html
 * <easy-steps [steps]="[
 *   {
 *     title: 'FINTECH.EASYSTEPS.1.TITLE',
 *     bullets: [
 *       'FINTECH.EASYSTEPS.1.BULLETS.1',
 *       'FINTECH.EASYSTEPS.1.BULLETS.2',
 *       'FINTECH.EASYSTEPS.1.BULLETS.3'
 *     ]
 *   }
 * ]" [action]="{
 *   title: 'FINTECH.EASYSTEPS.ACTION',
 *   button: 'FINTECH.APPLY.NEW',
 *   icon: 'attach_money'
 * }" (actionClick)="apply()" [numbered]="true"></easy-steps>
 * ```
 */
@Component({
  selector: 'easy-steps',
  templateUrl: './easy-steps.component.html',
  styleUrls: ['./easy-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EasyStepsComponent {

  @Input() public steps: EasyStep[];
  @Input() public action: EasyStep;
  @Input() public footer: string;
  /** Whether to show the step number or not. */
  @Input() public numbered: boolean;

  @Output() public readonly actionClick = new EventEmitter();

  /** @ignore */
  constructor() { }

}

/**
 * ### Related UI components:
 * - [[EasyStepsComponent]]
 */
class EasyStep {
  title?: string;
  bullets?: string[];
  /** Button legend. */
  button?: string;
  /** Icon on the button. Uses [[https://material.io/resources/icons/|Material Symbols Rounded]]. */
  icon?: string;
}
