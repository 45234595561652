import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { FileManagerService } from '../../../company/services/file-manager.service';
import { Company, companyCan } from '../../../models/company.model';
import { FileManagerFile } from '../../../models/file-manager-file.model';
import { CompanyService } from '../../../services/company.service';
import { CurrentDateService } from '../../../services/current-date.service';
import { SubscriptionManager } from '../../../utilities/subscription-manager';
import { FilePreviewComponent } from '../file-preview/file-preview.component';

@Component({
  selector: 'ag-file-manager-modal',
  templateUrl: './file-manager-modal.component.html',
  styleUrls: ['./file-manager-modal.component.scss']
})
export class FileManagerModalComponent extends SubscriptionManager implements OnDestroy {

  @ViewChild('modal', { static: true }) private readonly modal: TemplateRef<any>;
  @ViewChild('filePreviewer', { static: true }) private readonly filePreviewer: FilePreviewComponent;

  @Input() public company: Company;

  public editable: boolean;
  public files: FileManagerFile[];
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
  */
  public processing: boolean;
  public sharingCompany: Company;
  public today: Date;

  private modalRef: BsModalRef;
  private modalSub: Subscription;

  constructor(
    private currentDate: CurrentDateService,
    public companyService: CompanyService,
    private fileManagerService: FileManagerService,
    private modalService: BsModalService
  ) {
    super();
  }

  public open(company: Company = undefined): void {
    this.today = this.currentDate.get();
    this.editable = companyCan(this.company).beFunder;

    this.reset();

    if (company) {
      this.setCompany(company);
    }
    this.openModal(this.modal);
  }

  public reset(): void {
    this.files = undefined;
    this.sharingCompany = undefined;
  }

  public setCompany(company: Company): void {
    this.sharingCompany = company;
    this.loadData();
  }

  private loadData(): void {
    this.processing = true;

    this.subscriptions.push(this.fileManagerService.get(this.company.id, {
      // type: 'lastBalance',
      shared: [this.sharingCompany.id]
    }, false).subscribe(response => {
      this.files = response.body;
      this.processing = false;
    }));
  }

  /** Try to preview the file located at the specified index in the browser. */
  public preview(file: FileManagerFile): void {
    if (file && !this.processing) {
      this.filePreviewer.preview(this.fileManagerService.getFile(this.company.id, file.id), file.name + file.extension, file.size);
    }
  }

  public editDueDate(file: FileManagerFile, date: Date): void {
    if (date && date.getTime() != file.dueDate?.getTime()) {
      this.processing = true;
      file.dueDate = date;

      this.subscriptions.push(this.fileManagerService.edit(this.company.id, file).subscribe(response => {
        // this.files = response.body;
        this.processing = false;
      }));
    }
  }

  public iconClass(mime: string): string {
    let cl = "draft", // Default
      has = function (str) {
        return (mime.indexOf(str) !== -1);
      };

    if (has('image/')) cl = "photo";
    else if (has('video/')) cl = "movie";
    else if (has('audio/')) cl = "play_circle";
    else if (has('/zip') || has('-compressed')) cl = "folder_zip";
    else if (has('excel') || has('xls')) cl = "table_chart";

    return cl;
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.closeModal();
    super.ngOnDestroy();
  }
}
