import { Type } from "class-transformer";

import { Company } from "./company.model";
import { EntityBinding } from "./entity-binding.model";
import { S3UploadedFile } from "./fileS3.model";
import { FiscalId } from "./fiscal-id.model";

/**
 * An Envelope is a container for documents sent to one or multipe
 * [[Envelope.signers|recipients]] to sign. An Envelope can have one
 * [[Envelope.files|document]] or many documents.
 * 
 * Envelopes have [[Envelope.status|statuses]] (i.e. created, pending,
 * completed, cancelled) and also contain information about the sender and
 * timestamps that indicate the progress of the delivery procedure.
 */
export class Envelope {

  @Type(() => Date)
  readonly created_at: Date;

  @Type(() => Date)
  readonly updated_at: Date;

  // @Type(forwardRef(() => Date) as any)
  // @Transform(dateToString, { toPlainOnly: true })
  // @Transform(stringToDateTime, { toClassOnly: true })
  // due_date?: Date;

  readonly id: number;
  subject: string;
  message?: string;

  /** [[Company]] that created the Envelope. */
  readonly company: Company;
  /** [[Company|Companies]] that must sign the Envelope. */
  companies: Company[];

  /** Electronic or digital signature provider used. */
  readonly provider: {
    id: number,
    name: string
  };

  /**
   * | id | Description |
   * |---:|-------------|
   * | 10 | Created     |
   * | 20 | Pending     |
   * | 30 | Completed   |
   * | 40 | Cancelled   |
   */
  readonly status: {
    id: number,
    name: string
  };

  /** Signers, may or may not be a [[User]] of the platform. */
  @Type(() => EnvelopeSigner)
  signers: EnvelopeSigner[];

  /** 
   * S3UploadFile type is used when creating the envelope and 
   * inserting data from attachments previously uploaded to S3. 
   * */
  @Type(() => EnvelopeFile)
  files: S3UploadedFile[] | EnvelopeFile[];

  cancel_reason?: string;

  readonly binded_entities: EntityBinding[];

  /** For labeleable entities. */
  get entity(): string { return 'envelope'; }

  private _signerByCompany: any;
  /**
   * Determines if the specified [[Company]] has to set
   * [[EnvelopeSigner|Signers]] for this Envelope.
   */
  public hasToSetSigners(companyId: number): boolean {
    if (!this._signerByCompany) this._signerByCompany = {};

    // Check if the response is cached or not
    if (this._signerByCompany[companyId] == undefined) {
      // Check if the Company is among the signers
      const isSigner = Boolean(this.companies.find(c => companyId === c.id));
      // Check if the Company has defined any signer
      const hasSigners = Boolean(this.signers.find(s => companyId === s.company_id));

      // It's signer and haven't define one?
      this._signerByCompany[companyId] = isSigner && !hasSigners;
    }

    return this._signerByCompany[companyId];
  }

  constructor(data: Partial<Envelope> = {}) {
    this.signers = [];
    this.files = [];
    this.companies = [];
    Object.assign(this, data);
  }
}

export class EnvelopeSigner {
  @Type(() => Date)
  created_at: Date;

  readonly id: number;
  email: string;

  name: string;
  last_name: string;
  phone?: string;

  fiscal_id: FiscalId;
  identity_number: string;
  company_id?: number;

  file?: number;

  constructor(data: Partial<EnvelopeSigner> = {}) {
    this.fiscal_id = new FiscalId();

    Object.assign(this, data);
  }
}

class EnvelopeFile {
  @Type(() => Date)
  readonly created_at: Date;

  readonly id: number;
  readonly name: string;

  @Type(() => EnvelopeFileSignature)
  readonly signatures: EnvelopeFileSignature[];
}

class EnvelopeFileSignature {
  @Type(() => Date)
  readonly created_at: Date;

  @Type(() => Date)
  readonly signed_at: Date;

  readonly id: number;
  readonly signer: EnvelopeSigner;
}