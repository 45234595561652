import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Quantity } from '../../../models/quantity.model';

/**
 * This component displays [[Quantity|volumes]] in a standardized way.
 * 
 * ## Usage
 * ``` html
 * <quantity-control
 * [quantity]="c.quantity"
 * [compact]="true"></quantity-control>
 * ```
 */
@Component({
  selector: 'quantity-control',
  templateUrl: './quantity-control.component.html',
  styleUrls: ['./quantity-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuantityControlComponent implements OnInit, OnChanges {

  @Input() public quantity: Quantity;

  /** Compact mode abbreviates the [[Quantity.unit|volume unit]]. */
  @Input() public compact: boolean;

  /** The language currently used. */
  public currentLang: string;
  /** The integer part of the [[Quantity|volume]]. */
  public integer: number;
  /** The decimals of the [[Quantity|volume]]. For example: 0.123 */
  public decimals: number = 0;

  constructor(
    private translateService: TranslateService
  ) { }

  /** @ignore */
  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.quantity?.value) {
      this.integer = Math.trunc(this.quantity.value);
      this.decimals = this.quantity.value - this.integer;
    } else {
      this.integer = undefined;
      this.decimals = 0;
    }
  }
}
