<div class="product-image no-select" [style.backgroundImage]="'url(' + (orderMedia?.length ? orderMedia[currentMedia].uri : '/assets/img/sample/no-photo.png') + ')'" (click)="toggleFullscreen($event)">
  <div class="media-browser" *ngIf="orderMedia.length > 1">
    <div class="arrow back" (click)="previous($event)">
      <i class="material-symbols-rounded">keyboard_arrow_left</i>
    </div>
    <div class="arrow fwrd" (click)="next($event)">
      <i class="material-symbols-rounded">keyboard_arrow_right</i>
    </div>
  </div>
  <img src="/assets/img/t.png">
</div>

<div class="fullscreen" *ngIf="fullscreenMode && media_fullscreen">
  <div class="media-browser">
    <div class="arrow back" (click)="previous($event)" *ngIf="orderMedia.length > 1">
      <i class="material-symbols-rounded">keyboard_arrow_left</i>
    </div>
    <div class="arrow fwrd" (click)="next($event)" *ngIf="orderMedia.length > 1">
      <i class="material-symbols-rounded">keyboard_arrow_right</i>
    </div>
    <div class="arrow close" (click)="toggleFullscreen($event)">
      <i class="material-symbols-rounded">close</i>
    </div>
  </div>
  <div class="margin">
    <div class="img" [style.backgroundImage]="'url(' + orderMedia[currentMedia].uri + ')'"></div>        
  </div>
</div>
