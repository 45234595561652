<ng-template #defItemTemplate let-item="item">
  {{ item.name }}
</ng-template>

<div class="form-group" [class.has-feedback]="searching" [class.hide-selector]="!showSelector">
  <input type="text" class="form-control {{ classes }}" [(ngModel)]="query" (ngModelChange)="queryChange($event)"
  maxlength="128"
  (blur)="onBlurMethod()" autocomplete="nope" [disabled]="disabled" [placeholder]="placeholder || ''"
  #queryInput>
  <span class="form-control-feedback" aria-hidden="true">
    <spinner [skip-zoomin]="true" class="absolute-center"></spinner>
  </span>
  <div class="dropdown" dropdown>
    <ul class="dropdown-menu dropdown-menu-left" *dropdownMenu (clickOutside)="cancel()">
      <ng-container *ngIf="groupBy">
        <ng-container *ngFor="let group of results|groupBy:groupBy|pairs; let last = last">
          <li class="dropdown-header"> {{ group[0] }} </li>
          <li *ngFor="let item of group[1]">
            <a (click)="select(item)">
              <template [ngTemplateOutlet]="itemTemplate || defItemTemplate" [ngTemplateOutletContext]="{item: item}">
              </template>
            </a>
          </li>
          <li *ngIf="!last" role="separator" class="divider"></li>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!groupBy">
        <li *ngFor="let item of results">
          <a (click)="select(item)">
            <template [ngTemplateOutlet]="itemTemplate || defItemTemplate" [ngTemplateOutletContext]="{item: item}">
            </template>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
  <ul class="results {{ classes }}" *ngIf="selection.length > 0">
    <li class="result" [class.disabled]="disabled" *ngFor="let item of selection">
      <span class="result-text"><template [ngTemplateOutlet]="itemTemplate || defItemTemplate"
          [ngTemplateOutletContext]="{item: item}">
        </template></span>
      <span *ngIf="!disabled" class="result-remove" (click)="remove(item)">&times;</span>
    </li>
  </ul>
</div>
