import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Price } from '../../../models/price.model';

/**
 * PriceDisplay is a component that allows to edit and render prices in a
 * normalized style.
 * 
 * ## Usage
 * ``` html
 * <price-display
 * [price]="contract.price"
 * [market]="company.market"
 * [withoutYear]="true"></price-display>
 * ```
 */
@Component({
  selector: 'price-display',
  templateUrl: './price-display.component.html',
  styleUrls: ['./price-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceDisplayComponent implements OnInit {

  @Input() public market: any;
  @Input() public price: Price;
  /** Smaller units. */
  @Input() public small: boolean = false;
  /** Only for Premiums. */
  @Input() public withoutYear: boolean = false;
  /** Enables form mode. */
  @Input() public editable: boolean = false;
  /** Whether to display price fixing conditions or not. */
  @Input() public showConditions: boolean = false;
  // @Input('fixing-conditions')
  // public set fixing_conditions(c: PTBFConditions[]) {
  //   if (c && c.length) {
  //     this._fixing_conditions = c;
  //     this.setFixRange();
  //   }
  // }

  /** The language currently used. */
  public currentLang: string;
  // public fixing_range: {
  //   from: Date,
  //   to: Date
  // };
  // private _fixing_conditions: PTBFConditions[];

  public get integer(): number {
    return Math.floor(this.price.value);
  }

  public get decimals(): string {
    return this.price.value.toFixed(2).split('.')[1];
  }

  /** @ignore */
  constructor(private translateService: TranslateService) { }

  /** @ignore */
  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;
  }
}
