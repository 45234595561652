<a clickStop tooltip="{{ 'CERTIFICATES.BUTTON.TITLE'|translate }}" (click)="show()"
class="small hidden-print">
  <i class="glyphicon glyphicon-certificate"></i>
</a>

<!-- modal -->
<ng-template #certificateTable>
  <div class="modal-header">
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'CERTIFICATES.BUTTON.MODAL'|translate:{company: company.name} }}</h4>
  </div>
  <div class="modal-body">
    <certificates-table [company]="company" [adminMode]="false"></certificates-table>
  </div>
</ng-template>