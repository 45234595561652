import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * This component shows a small pie chart.
 * 
 * ## Usage
 * ``` html
 * <progress-circle
 * label="Progress"
 * [progress]="0.33"
 * size="16"></progress-circle>
 * ```
 */
@Component({
  selector: 'progress-circle[progress]',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    // https://css-tricks.com/svg-line-animation-works/
    trigger('dash', [
      state('*',
        style({
          strokeDasharray: 566,
          strokeDashoffset: '{{ progress }}'
        }),
        { params: { progress: 0 } }
      ),
      transition(':enter', [
        style({
          strokeDasharray: 566,
          strokeDashoffset: 566
        }),
        animate('800ms 500ms ease-out', style({
          strokeDashoffset: '{{ progress }}'
        }))
      ], { params: { progress: 0 } })
    ])
  ]
})
export class ProgressCircleComponent implements OnInit {

  /** Chart size. */
  @Input() public size: number = 20;
  /** Chart color. */
  @Input() public color: string = '#3c763d';
  /** Label to show in the tooltip (optional). */
  @Input() public label: string = '';
  /** Indicates if the tooltip should include the percentage. */
  @Input('show-percentage') public show_percentage: boolean = true;
  @Input() public value: number;
  @Input() public unit: string;
  /** Percentage to represent from 0 to 1. */
  @Input() set progress(value: number) {
    value = Math.max(0, Math.min(1, value)); // Accepted range 0-1
    this._progress = value;
  }
  get progress(): number {
    return this._progress;
  }

  /** The language currently used. */
  public currentLang: string;

  private _progress: number;

  /** @ignore */
  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;
  }
}
