import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CurrentDateService } from '../../../services/current-date.service';
import { startOfDay } from '../../../utilities/date';

/**
 * DateMd is a component that displays dates in a normalized way.
 * 
 * ## Usage
 * ``` html
 * <date-md
 * [date]="date"></date-md>
 * ```
 *
 * ### Related UI components:
 * - [[DateRangeComponent|date-range]]
 */
@Component({
  selector: 'date-md[date]',
  templateUrl: './date-md.component.html',
  styleUrls: ['./date-md.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateMdComponent implements OnInit {

  @Input() set date(d: Date) {
    if (typeof d?.getMonth === 'function') {
      this._date = d;
      this.isAboutToExpire();
    }
  };
  @Input() public timezone: string = undefined;
  /** 
   * If true, the component will highlight if the date is expired or about to
   * expire.
   */
  @Input() public style: boolean;

  get date(): Date {
    return this._date;
  }

  /** The language currently used. */
  public currentLang: string;
  /** @ignore */
  public nextWeek: Date;
  /** @ignore */
  public today_start: Date;

  private today: Date = this.currentDate.get();
  private _date: Date;

  /** @ignore */
  constructor(
    private currentDate: CurrentDateService,
    private translateService: TranslateService
  ) {
    this.today_start = startOfDay(this.today);
  }

  private isAboutToExpire(): void {
    this.nextWeek = new Date(this.today.getTime());
    this.nextWeek.setDate(this.today.getDate() + 7);
  }

  /** @ignore */
  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;
  }
}
