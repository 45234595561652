<ul class="list-group no-margin" *ngIf="map">
  <li *ngFor="let participant of map"
  class="list-group-item">
    <div class="flex-center-distributed">
      <div class="grow checkbox flex-center-distributed no-margin">
        <div>
          <input type="checkbox" class="ios"
          id="p-{{ participant.name }}"
          [disabled]="disabled"
          [(ngModel)]="participant.checked"
          (change)="update(participant)">
        </div>
        <label for="p-{{ participant.name }}" class="grow">{{ participant.name }}</label>
        <div AgMultiselection [(ngModel)]="participant.roles"
        (change)="update(participant)"
        *ngIf="participant.checked">
          <div class="checkbox-inline"
          [class.disabled]="disabled"
          *ngFor="let role of roles">
            <label>
              <input type="checkbox"
              [disabled]="disabled"
              [AgCheckbox]="role"> {{ role.value }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </li>
</ul>