import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'connector',
  templateUrl: './connector.component.html',
  styleUrls: ['./connector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectorComponent {

  @Input() public linked: boolean;
  @Input() public on: string = 'link';
  @Input() public off: string = 'link_off';

  constructor() { }

}
