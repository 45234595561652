/**
 * A commercial zone is an arbitrary designation of each [[Company]] to group
 * its Clients or [[Counterpart|Counterparties]].
 * 
 * They can correspond to geographic areas, service tiers, types, etc.
 * 
 * These designations are used to be able to filter different types of
 * operations and to be able to assign users of your [[Company]] to certain
 * Clients.
 */
export class CommercialZone {
  name: string;
  description: string;
  /** Agree's internal unique identifier. */
  readonly id: number;

  /** Used for UI purposes. */
  status?: boolean;
  /**
   * Represents a label partially assign to current selection.
   * Used for UI purposes.
   */
  partial?: boolean;

  constructor(data: Partial<CommercialZone> = {}) {
    Object.assign(this, data);
  }

  public reset(): void {
    this.status = false;
    this.partial = false;
  }
}
