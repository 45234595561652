import { Type } from "class-transformer";

import { Company } from './company.model';
import { Price } from './price.model';

export class AuctionBid {
  price: Price;
  company?: Company;

  @Type(() => Date)
  date: Date;

  order: number;
  account_id: number;
  auction_id: number;

  tie_braker: boolean;
}