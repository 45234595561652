import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { instanceToInstance, instanceToPlain } from 'class-transformer';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { User } from '../../../auth/models/user.model';
import { LoginService } from '../../../auth/services/login.service';
import { Company } from '../../../models/company.model';
import { Preset } from '../../../models/preset.model';
import { PresetService } from '../../services/preset.service';

@Component({
  selector: 'ag-presets',
  templateUrl: './presets.component.html',
  styleUrls: ['./presets.component.scss']
})
export class PresetsComponent implements OnInit, OnDestroy {

  @ViewChild('createModal', { static: true }) private readonly createModal: TemplateRef<any>;
  @ViewChild('presetDropdown', { static: true }) private readonly presetDropdown: BsDropdownDirective;

  /**
   * By default, a dropdown menu is automatically positioned 100% from the top
   * and along the left side of its parent. Set placement to 'right' to right
   * align the dropdown menu.
   */
  @Input() public placement: string;
  @Input() public key: string;
  @Input() public title = 'PRESETS.TITLE';
  @Input() public sortPresets = true;
  @Input() public canCreate = true;
  @Input() public company: Company;
  @Input() public set value(value: any) {
    this.innerValue = instanceToInstance(value);
    this.checkIfExists();
  };

  @Output() readonly valueChange = new EventEmitter<any>();
  /**
   * This output returns the complete preset with id, key, data, etc...
   */
  @Output() readonly onPresetSelected = new EventEmitter<any>();
  /**
   * This output returns a presets array without the delete one
   */
  @Output() readonly onPresetDeleted = new EventEmitter<any>();

  public readonly maxAllowedPresets: number = 20;
  public presets: Preset[];
  public newPreset: Preset;
  public selected: Preset;
  /**
   * Whether the current value is one of the existing [[Preset|Presets]] or
   * not.
   */
  public exists: boolean;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;

  private innerValue: any;
  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private subscriptions: Subscription[] = [];
  private user: User;

  constructor(
    private modalService: BsModalService,
    private presetService: PresetService,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      if (user) this.user = user;
    }));
  }

  public dropdownHandler(isOpen: boolean): void {
    if (isOpen) {
      if (!this.presets) this.loadData();
    }
  }

  private loadData(): void {
    this.processing = true;

    this.subscriptions.push(this.presetService.get(
      this.company.id,
      this.user.id,
      this.key,
      this.sortPresets
    ).subscribe(presets => this.dataLoaded(presets)));
  }

  private dataLoaded(data: Preset[]): void {
    this.presets = data;
    this.checkIfExists();
    this.processing = false;
  }

  public create(): void {
    this.newPreset = new Preset({
      companyId: this.company.id,
      userId: this.user.id,
      key: this.key,
      data: this.innerValue
    });

    this.openModal(this.createModal);
    this.presetDropdown.hide();
  }

  public submit(): void {
    this.processing = true;

    this.subscriptions.push(this.presetService.create(this.newPreset).subscribe(presetId => {
      if (presetId) {
        this.selected = { ...this.newPreset, id: presetId };
        this.presets.push(this.selected);
        this.exists = true;
      }
      this.closeModal();
      this.processing = false;
    }));
  }

  public delete(preset: Preset): void {
    this.processing = true;

    this.subscriptions.push(this.presetService.delete(preset).subscribe(response => {
      this.presetDropdown.hide();
      this.presets = this.presets.filter(pre => pre.id !== preset.id);
      this.onPresetDeleted.emit(this.presets);
      this.checkIfExists();
      this.processing = false;
    }));
  }

  public set(preset: Preset): void {
    if (!this.processing) {
      this.value = preset.data;
      this.valueChange.emit(this.innerValue);
      this.onPresetSelected.emit(preset);
      this.presetDropdown.hide();
    }
  }

  private isEqual(objA: any, objB: any): boolean {
    return JSON.stringify(instanceToPlain(objA, { excludePrefixes: ['_'] })) === JSON.stringify(instanceToPlain(objB, { excludePrefixes: ['_'] }));
  }

  private checkIfExists(): void {
    this.selected = this.presets?.find(p => this.isEqual(p.data, this.innerValue));

    this.exists = Boolean(this.selected) ||
      !this.innerValue ||
      this.isEqual({}, this.innerValue) ||
      this.isEqual([], this.innerValue);
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      // this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.closeModal();

    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
