<div *ngIf="selected?.length > 0">
  <div class="labels cf">
    <div
      class="tag flex-center-distributed clickable"
      *ngFor="let filter of selected"
      title="{{ filter.title | translate }}"
      (click)="remove(filter)"
    >
      <div class="grow">
        <div class="condition">{{ filter.title | translate }}</div>
        {{ filter.value | translate }}
      </div>
      <span class="remove">&times;</span>
    </div>
  </div>
  <a
    class="remove-all text-danger small"
    *ngIf="selected?.length > 1"
    (click)="clearAllFilters()"
  >
    <span class="material-symbols-rounded">filter_alt_off</span>
    {{ "FILTERS.REMOVE_ALL" | translate }}
  </a>
</div>
