import { HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Pagination, parsePagination } from '../../../models/pagination.model';

@Component({
  selector: 'paginator',
  exportAs: 'paginatorComponent',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorComponent implements OnInit {

  @Input() public set data(pagination: Pagination) {
    this._data = pagination;

    this.menuPages = this.setMenuPages();
  }
  public get data(): Pagination {
    return this._data;
  }

  @Input() public set headers(headers: HttpHeaders) {
    this.data = parsePagination(headers);
  }

  /** Flag used to indicate if parent component is loading information. */
  @Input() public loading: boolean;

  /** The language currently used. */
  public currentLang: string;
  public menuPages: number[];

  private _data: Pagination;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;
  }

  private setMenuPages(): number[] {
    let pages: number[];

    if (this.data.total_pages > 2) {
      const range = 6;
      const from = Math.max(0, Math.min(this.data.current_page - range, this.data.total_pages - (range * 2)));
      pages = Array.from({ length: this.data.total_pages }, (_, i) => i + 1).slice(from, from + range * 2);
    }

    return pages;
  }

  public goTo(page: number): void {
    if (this.data.current_page !== page) {
      this.router.navigate([], {
        queryParams: { ...this.route.parent.snapshot.queryParams, page: page },
        relativeTo: this.route
      });
    }
  }
}