<ag-envelopes-modals #envelopesModals
[company]="company"></ag-envelopes-modals>

<ng-container *ngIf="envelopes?.length && !compact; else placeholder">
  <div class="flex-center-distributed">
    <h4>{{ 'SIGNATURES.RELATED.TITLE'|translate }}</h4>
    <button type="button"
    *ngIf="canCreate"
    class="btn btn-link"
    [disabled]="disabled"
    (click)="create()" title="{{ 'SIGNATURES.CREATE_ENVELOPE'|translate }}">
      <span class="material-symbols-rounded">attach_email</span>
    </button>
  </div>

  <div class="horizontal-scroller">
    <table class="table table-hover table-condensed">
      <thead>
        <tr class="tr-align-center">
          <th class="sap">{{ 'GLOBAL.DATE'|translate }}</th>
          <th>{{ 'SIGNATURES.SUBJECT'|translate }}</th>
          <th class="sap"></th>
          <th>{{ 'GLOBAL.STATUS'|translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let envelope of envelopes"
        class="tr-align-center"
        [class.tr-danger]="envelope.status.id === 40">
          <!-- Envelope row -->
          <td class="sap">
            <ag-timestamp [date]="envelope.created_at"></ag-timestamp>
          </td>
          <td>
            <div><b><a [routerLink]="['/company', company.id, 'envelopes', envelope.id]">{{ envelope.subject }}</a></b></div>
            <span class="small text-muted"
            *ngIf="envelope.signers.length">{{ 'SIGNATURES.SIGNERS'|translate }}: {{ envelope.signers.length }}</span>
          </td>
          <td class="sap">
            <ng-container [ngSwitch]="envelope.status.id">
              <ng-container *ngSwitchCase="10">
                <span class="text-info material-symbols-rounded" *ngIf="!envelope.hasToSetSigners(company.id)">schedule</span>
                <span class="text-warning material-symbols-rounded blinking" *ngIf="envelope.hasToSetSigners(company.id)">warning</span>  
              </ng-container>
              <span *ngSwitchCase="20" class="text-muted material-symbols-rounded">schedule</span>
              <span *ngSwitchCase="30" class="text-success material-symbols-rounded">done</span>
            </ng-container>
          </td>
          <td>
            <ng-container [ngSwitch]="envelope.status.id">
              <ng-container *ngSwitchCase="10">
                <span class="text-info" *ngIf="!envelope.hasToSetSigners(company.id)">{{ 'SIGNATURES.STATUS.WAITING_SIGNERS'|translate }}</span>
                <span class="text-warning" *ngIf="envelope.hasToSetSigners(company.id)">{{ 'SIGNATURES.STATUS.DEFINE_SIGNERS'|translate }}</span>  
              </ng-container>
              <span *ngSwitchCase="20" class="text-muted">{{ 'SIGNATURES.STATUS.PENDING'|translate }}</span>
              <span *ngSwitchCase="30">{{ 'SIGNATURES.STATUS.COMPLETED'|translate }}</span>
              <span *ngSwitchCase="40" class="text-danger">{{ 'SIGNATURES.STATUS.CANCELED'|translate }}</span>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>

<ng-template #placeholder>
  <p class="small text-muted hidden-print" *ngIf="!disabled && canCreate">
    <a (click)="create()" [title]="!compact ?  ('SIGNATURES.CREATE_ENVELOPE'|translate)  : ''"
      [tooltip]="compact ? ('SIGNATURES.CREATE_ENVELOPE'|translate) : ''"><span
        class="material-symbols-rounded">attach_email</span></a>
    {{ !compact ? ('SIGNATURES.RELATED.PLACEHOLDER'|translate) : null }}
  </p>
</ng-template>