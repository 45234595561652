import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Company } from '../../../models/company.model';
import { SlotsBatch } from '../../../models/slots-batch.model';

@Component({
  selector: 'batch-destination',
  templateUrl: './batch-destination.component.html',
  styleUrls: ['./batch-destination.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatchDestinationComponent {

  @Input() public company: Company;
  @Input() public batch: SlotsBatch;
  @Input() public compact: boolean;
  @Input() public recipient: boolean = true;

  constructor() { }

}
