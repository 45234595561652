import { Directive, Input, OnInit } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { validateCURP } from '../../models/fiscal-id.model';

@Directive({
  selector: '[validCURP]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidCURPDirective, multi: true }]
})
export class ValidCURPDirective implements OnInit, Validator {

  @Input('validCURP') private condition: boolean;

  private validatorFn: ValidatorFn;

  constructor() { }

  /** @ignore */
  ngOnInit(): void {
    this.validatorFn = this.validator(this.condition);
  }

  /** @ignore */
  validate(control: AbstractControl): {} {
    this.validatorFn = this.validator(this.condition);
    return this.validatorFn(control);
  }

  private validator(condition: boolean): ValidatorFn {
    return (control: AbstractControl): {} => {
      const curp: string = control.value;
      if (!condition || !curp) return true;

      return validateCURP(curp) ? null : {
        "validIdentityId": false
      };
    }
  }
}
