<div class="expandable-content"
[class.contained]="expandableText.scrollHeight <= expandableText.clientHeight">
  <div class="expandable-text" #expandableText
  [style.line-height]="line_height"
  [style.max-height]="expanded ? 'initial' : (line_height * lines_to_show) + 'em'"><ng-content></ng-content></div>

  <a *ngIf="expanded || expandableText.scrollHeight > expandableText.clientHeight"
  class="small"
  (click)="expanded = !expanded"
  [innerHtml]="(expanded ? 'GLOBAL.TOGGLE_HIDE' : 'GLOBAL.TOGGLE_SHOW')|translate"></a>
</div>