import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Company } from '../../../models/company.model';

/**
 * CompanyLogo is a component that displays the [[Company|Companie's]] logo in
 * a normalized way.
 * 
 * ## Usage
 * ``` html
 * <company-logo
 * [company]="company"></company-logo>
 * ```
 */
@Component({
  selector: 'company-logo[company]',
  templateUrl: './company-logo.component.html',
  styleUrls: ['./company-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyLogoComponent {

  @Input() public company: Company;

  /** @ignore */
  constructor() { }

}
