<ng-container *ngIf="!compact; else compactMode">
  <span class="tag" *ngIf="currentPreset" [innerHTML]="'PRESETS.FILTERS_ACTIVE_LABEL'|translate"></span>
  <div class="d-flex wrapper">
    <!-- List -->
    <ag-presets (onPresetSelected)="setPreset($event)" (onPresetDeleted)="handleDelete($event)" [key]="key"
      [company]="company" [class.preseted]="currentPreset"
      [title]="currentPreset ? currentPreset.name : ('PRESETS.FILTERS_LABEL'|translate)" [canCreate]="false"
      [sortPresets]="false"></ag-presets>

    <!-- save -->
    <a [class.disabled]="processing" (click)="save()" [placement]="placement"
      tooltip="{{ 'PRESETS.SAVE_FILTERS' | translate }}" class="material-symbols-rounded">
      save_as
    </a>
  </div>
</ng-container>

<ng-template #compactMode>
  <div class="d-flex">
    <!-- delete -->
    <a (onConfirm)="deleteCurrentPreset()" [confirm-options]="{
                  title: 'GLOBAL.ARE_YOU_SURE'|translate,
                  body: 'PRESETS.FILTERS_DELETE_BODY'|translate,
                  action: 'GLOBAL.YES_DELETE'|translate
                }" [class.disabled]="processing" *ngIf="compact && currentPreset?.name"
      [tooltip]="'PRESETS.FILTERS_DELETE_TOOLTIP'|translate" class="text-danger"><span>{{currentPreset?.name}}</span>
      <span class="material-symbols-rounded">filter_alt_off</span></a>

    <!-- save -->
    <a *ngIf="!currentPreset" [class.disabled]="processing" (click)="save()" [placement]="placement"
      tooltip="{{ 'PRESETS.SAVE_FILTERS' | translate }}" class="material-symbols-rounded">
      save_as
    </a>
  </div>
</ng-template>

<ng-template #saveModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ "PRESETS.SAVE_FILTERS" | translate }}</h4>
    <p>{{ "PRESETS.SAVE_FILTERS_DESC" | translate }}</p>
  </div>
  <div class="modal-body">
    <form id="label-form" #presetForm="ngForm" (ngSubmit)="presetForm.valid && submit()">
      <div class="row">
        <div class="col-sm-7">
          <div class="form-group" hasError>
            <label class="control-label">{{ "GLOBAL.NAME" | translate }}</label>
            <input type="text" name="name" class="form-control" autocomplete="off" [disabled]="processing"
              maxlength="24" [modalFocus]="true" required [(ngModel)]="newPreset.name" />
            <error-list></error-list>
          </div>
        </div>
        <div class="col-sm-5">
          <div class="form-group">
            <div class="checkbox flex-center-distributed">
              <div>
                <input type="checkbox" class="ios" name="private" id="preset_private" [disabled]="processing"
                  [(ngModel)]="newPreset.private" />
              </div>
              <label for="preset_private" class="grow">{{
                "ORDER_FORM.SCOPE.PRIVATE" | translate
                }}</label>
            </div>
            <span class="small help-block" [innerHtml]="'PRESETS.PRIVATE_HELP' | translate"></span>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="closeModal()" [disabled]="processing" type="button" class="btn btn-link" data-dismiss="modal">
      {{ "GLOBAL.CANCEL" | translate }}
    </button>
    <button type="submit" form="label-form" class="btn btn-agree" [disabled]="processing || !presetForm.valid">
      {{ "GLOBAL.SAVE" | translate }}
    </button>
  </div>
</ng-template>