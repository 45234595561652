<ng-container *ngIf="price">
  <div class="form-group" hasError>

    <div class="input-group">

      <input type="number" [placeholder]="placeholder" class="form-control" name="priceValue" [(ngModel)]="price.value"
        [required]="required" number [min]="minPrice" [max]="maxPrice"
        autocomplete="off" [disabled]="disabled">

      <div dropdown agSelect name="priceUnit" class="btn-group input-group-btn" [class.single-unit]="currencies?.length <= 1"
        [(ngModel)]="price.unit" trackedControl [isDisabled]="disabled">
        <button dropdownToggle type="button" class="btn btn-default dropdown-toggle"
        data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          {{ price.unit?.name }}
          <span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
          <li *ngFor="let c of currencies" [agOption]="c"><a>{{ c.name }}</a></li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>

