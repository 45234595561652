import { Type } from "class-transformer";

export class FileManagerFile {
  /** Agree's internal unique identifier. */
  readonly id: string;

  /** Owner [[Company]] */
  readonly companyId: number;

  readonly name: string;
  readonly extension: string;
  readonly type: string;

  /** Only available on individual file GET. */
  readonly url?: string;

  @Type(() => Date)
  createdAt: Date;

  readonly size: number;
  readonly contentType: string;

  @Type(() => Date)
  dueDate?: Date;

  label?: string;

  public get filename(): string {
    return this.name + this.extension;
  }
}