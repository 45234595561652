/**
 * Sorts an array of objects by a specified property.
 * If the property name starts with a '-', the array will be sorted in descending order.
 * 
 * @template T - The type of objects in the collection.
 * @param {T[]} collection - The array of objects to be sorted.
 * @param {string} prop - The property name to sort by. Prefix with '-' for descending order. Supports nested properties: 'product.name'.
 */
function sortBy<T>(collection: T[], prop: string): void {
  const reverse: boolean = Array.from(prop)[0] == '-';
  if (reverse) prop = prop.substring(1);

  const getNestedValue = (obj: any, path: string): any => {
    return path.split('.').reduce((value, key) => value?.[key], obj);
  };

  collection.sort((a, b) => {
    const aValue = getNestedValue(a, prop) || '';
    const bValue = getNestedValue(b, prop) || '';

    if (aValue > bValue) return reverse ? -1 : 1;
    if (aValue < bValue) return reverse ? 1 : -1;
    return 0;
  });
}

/**
 * Sorts an object array in place.
 * This method mutates the array and returns a reference to the same array.
 * @param collection Objects array.
 * @param props Properties in order of appication. Supports 'propertyName' and '-propertyName' for reverse sorting.
 * ```ts
 * multiSort(collection, 'label', '-type', 'order');
 * ```
 * @returns 
 */
export function multiSort<T>(collection: T[], ...props: string[]): T[] {
  if (collection && collection.length > 0 && props[0]) {
    props.forEach(prop => {
      sortBy(collection, prop);
    });
  }
  return collection;
}

/**
 * Filters an array to remove duplicate values, ensuring each value is unique.
 *
 * @param {T[]} array - The array to be filtered.
 * @returns {T[]} - A new array with only unique values.
 */
export function uniqueArray<T>(array: T[]): T[] {
  return Array.from(new Set(array));
}

/**
 * Checks if the provided value is an empty array.
 *
 * @param {any} value - The value to check.
 * @returns {boolean} True if the value is an empty array, otherwise false.
 *
 * @example
 * // Returns true
 * isAnEmptyArray([]);
 *
 * @example
 * // Returns false
 * isAnEmptyArray([1, 2, 3]);
 *
 * @example
 * // Returns false
 * isAnEmptyArray(null);
 */
export function isAnEmptyArray(value: any): boolean {
  return Array.isArray(value) && value.length === 0;
}

/**
 * Removes all occurrences of a specified string from an array of strings.
 *
 * @param {string[]} source - The array of strings to filter.
 * @param {string} remove - The string to remove from the array.
 * @returns {string[]} A new array with all occurrences of the specified string removed.
 *
 * @example
 * // Returns ["apple", "orange"]
 * removeString(["apple", "banana", "orange"], "banana");
 *
 * @example
 * // Returns ["apple", "banana", "orange"]
 * removeString(["apple", "banana", "orange"], "grape");
 */
export function removeString(source: string[], remove: string): string[] {
  return source.filter(e => e !== remove);
}